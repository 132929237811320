import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ApiActionDto } from 'kes-common';
import { getRuleById } from '@/selectors/ruleSets';
import { actionDependentPropertyRemove, actionSelect } from '@/store/actions';
import State from '@/store/state';
import { Property, Rule as RuleType } from '@/store/types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { applicationProperty } from '@/routes';
import { PropertySelectionMode } from '@/store/reducers/properties';
import { applicationName as applicationNameSelector } from '@/selectors';
import { actionByPropertyAndRuleIds, actionDependentProperties } from '@/selectors/actions';
import * as Styled from '../RuleList.styled';

interface RuleProps {
	deleteById: (id: string) => void;
	id: RuleType['id'];
	propertyId: Property['id'];
}
const Rule: React.FC<RuleProps> = ({ deleteById, id, propertyId }) => {
	const dispatch = useDispatch();
	const rule = useSelector<State, RuleType>(getRuleById(id));
	const action = useSelector<State, ApiActionDto | null>(
		actionByPropertyAndRuleIds({ propertyId, ruleId: id }),
	);
	const dependentProperties = useSelector<State, Property[]>((state) => {
		if (action) {
			return actionDependentProperties({ actionId: action?.id })(state);
		}
		return [];
	});
	const applicationName = useSelector(applicationNameSelector);

	const deselectProperty = React.useCallback(
		(propertyIdToDeselect: string) => {
			if (action) {
				dispatch(
					actionDependentPropertyRemove({ actionId: action.id, propertyId: propertyIdToDeselect }),
				);
			}
		},
		[action, dispatch],
	);

	const selectProperty = React.useCallback(() => {
		if (action) {
			dispatch(
				actionSelect({
					id: action.id,
					propertyId,
					selectionMode: PropertySelectionMode.DependentRule,
				}),
			);
		}
	}, [action, dispatch]);

	return (
		<Styled.RuleListItem>
			<Styled.RuleListItemName>
				{rule.name}: {rule.check}
				<CloseIcon data-testid="delete-rule" cursor="pointer" onClick={() => deleteById(id)} />
			</Styled.RuleListItemName>
			<Styled.RuleListItemDependencies>
				<Typography gutterBottom>Show dependent properties:</Typography>
				{dependentProperties.map((dependentProperty) => (
					<Styled.Chip
						key={dependentProperty.id}
						label={
							<Styled.DependentPropertyLink
								to={applicationProperty({
									applicationName,
									propertyId: dependentProperty.id,
								})}
							>
								{dependentProperty.name}
							</Styled.DependentPropertyLink>
						}
						onDelete={() => deselectProperty(dependentProperty.id)}
					/>
				))}
				<Styled.Chip
					avatar={<AddCircleOutlineIcon />}
					label="SELECT PROPERTIES"
					onClick={selectProperty}
				/>
			</Styled.RuleListItemDependencies>
		</Styled.RuleListItem>
	);
};

export default Rule;
