// https://stackoverflow.com/a/53686938/1542723
/** Helper type for single arg function */
type Func<A, B> = (a: A) => B;

function compose<A, B>(a: Func<A, B>): Func<A, B>;
function compose<A, B, C>(a: Func<A, B>, b: Func<B, C>): Func<A, C>;
function compose<A, B, C, D>(a: Func<A, B>, b: Func<B, C>, c: Func<C, D>): Func<A, D>;
function compose<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	F1 extends Func<any, any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FN extends Func<any, any>[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	R extends Func<any, ReturnType<F1>>,
>(func: F1, ...funcs: FN): R {
	if (funcs.length === 0) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return func as any;
	}
	const allFuncs = [func, ...funcs];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function composed(raw: any): any {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return allFuncs.reduceRight((memo, reduceFunc): any => reduceFunc(memo), raw);
	} as R;
}

export default compose;
