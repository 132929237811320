export interface TestUserType {
	id: string;
	name: string;
	email: string;
	role: string;
	picture: string;
}

export const testUsers: TestUserType[] = [
	{
		name: 'James Hetfield',
		id: 'a9d9d10a-34d9-4072-bcde-a6df503138f5',
		email: 'james.hetfield.test@rhdhv.com',
		role: 'Administrator',
		picture: '/images/testUser3.png',
	},
	{
		name: 'Mike Tyson',
		id: '6da7b448-e562-4bca-889a-262da61dc642',
		email: 'mike.tyson.test@rhdhv.com',
		role: 'Viewer',
		picture: '/images/testUser2.png',
	},
	{
		name: 'Tom Araya',
		id: '17e42371-32da-489f-97cd-f7d99e9f221c',
		email: 'tom.araya.test@rhdhv.com',
		role: 'Viewer',
		picture: '/images/testUser3.png',
	},
];
