import { v4 as uuidv4 } from 'uuid';

const buildUrl = (prefix: string, prNumber: string | undefined) =>
	prNumber !== undefined ? `${prefix}-pr-${prNumber}.azurewebsites.net/` : undefined;

const isE2E = Boolean(window.Cypress);

export const BACKEND_URL: string =
	window?.environment?.REACT_APP_BACKEND_URL ??
	buildUrl('https://kes-backend', process.env.REACT_APP_HEROKU_PR_NUMBER) ??
	'http://localhost:8080/';

export const KES_FRONTEND_URL =
	window?.environment?.REACT_APP_KES_FRONTEND_URL ??
	buildUrl('https://kes-frontend-mono', process.env.REACT_APP_HEROKU_PR_NUMBER) ??
	'http://localhost:3000/';

export const APP_TITLE = 'Kes Studio | ';
export const UPLOAD_NEW_EVENT_DELAY = 50;
export const UPLOAD_OFFLINE_DELAY = 2000;
export const UPLOAD_COLLECT_MUTATIONS_DELAY = 600;
export const MAX_VALUE_LENGTH = 255;
export const PROPERTY_NEW_PREFIX = 'New property ';
export const ASSET_NEW_PREFIX = 'New asset ';
export const FLAG_NEW_PREFIX = 'New flag ';
export const CHOICE_NEW_PREFIX = 'New choice ';
export const SIDEBAR_CATEGORY_EXPANDED_BY_DEFAULT = false;
export const SIDEBAR_ASSET_EXPANDED_BY_DEFAULT = false;
export const CLIENT_ID = uuidv4().toString();
export const TEST_USERS_ENABLED = window?.environment?.REACT_APP_TEST_USERS_ENABLED === 'true';

export const RED_FLAG_ID = '4cc5a5f7-03f5-4c30-9f33-9d7b04a2f860';
export const APPLICATIONINSIGHTS_CONNECTION_STRING =
	window.environment.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;
export const GOOGLE_TAG_MANAGER_ID = window.environment.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const GOOGLE_TAG_MANAGER_AUTH = window.environment.REACT_APP_GOOGLE_TAG_MANAGER_AUTH;
export const GOOGLE_TAG_MANAGER_PREVIEW = window.environment.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW;

export const FIELD_CHOICE_NEW_PREFIX = 'New choice ';
export const FIELD_NEW_PREFIX = 'New field ';
export const TABLE_NEW_PREFIX = 'New table ';
export const ENVIRONMENT = window?.environment?.REACT_APP_ENVIRONMENT;
export const TITLE_PREFIX = window?.environment?.REACT_APP_TITLE_PREFIX;

export const AUTH0_AUDIENCE = window.environment.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_CLIENT_ID = window.environment.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_CONNECTION = window.environment.REACT_APP_AUTH0_CONNECTION;
export const AUTH0_DOMAIN = window.environment.REACT_APP_AUTH0_DOMAIN;

export const MSAL_CLIENT_ID = window.environment.REACT_APP_MSAL_CLIENT_ID;
export const MSAL_REDIRECT_URI = window.environment.REACT_APP_MSAL_REDIRECT_URI;
export const MSAL_TENANT_ID = window.environment.REACT_APP_MSAL_TENANT_ID;

export const SPLITIO_AUTHORIZATION_KEY = isE2E
	? 'localhost'
	: window.environment.REACT_APP_SPLITIO_AUTHORIZATION_KEY;
