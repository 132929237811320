import assertNever from '@/utils/assertNever';

function formatChildrenList(
	type: 'asset' | 'category' | 'property' | 'choice',
	hasChoices?: boolean,
): string {
	switch (type) {
		case 'asset':
			return 'All its properties will also be deleted.';
		case 'category':
			return 'All its assets will also be deleted.';
		case 'property':
			if (hasChoices) return 'All its choices will also be deleted.';
			return '';
		case 'choice':
			return (
				'All dependencies of properties on this choice will be' +
				' removed (not the properties themselves).'
			);
		default:
			return assertNever(type);
	}
}

// eslint-disable-next-line import/prefer-default-export
export function shouldDelete(
	type: 'asset' | 'category' | 'property' | 'choice',
	name: string,
	hasChoices?: boolean,
	hasDependents?: boolean,
	hasReferencedProperties?: boolean,
): boolean {
	let message =
		`Delete "${name}"\n\n` +
		`Are you sure you want to delete this ${type}? ${formatChildrenList(type, hasChoices)}`;
	if (hasDependents)
		message +=
			'\n\nSome of the choices have dependent properties. The dependency of the ' +
			'properties on the choices will be removed (not the properties themselves).';

	if (hasReferencedProperties) {
		message += ' All population by this property will also be deleted.';
	}

	// This should eventually throw an action to confirm a delete request, sagas shouldn't interact with the ui
	// eslint-disable-next-line no-restricted-globals, no-alert
	return confirm(message);
}
