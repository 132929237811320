import Typography from '@mui/material/Typography';
import MUIAddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styled from 'styled-components';

export const AddCircleOutlineIcon = styled(MUIAddCircleOutlineIcon)`
	color: white;
`;

export const ListItemText = styled(Typography)`
	&& {
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
