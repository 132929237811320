import React from 'react';
import { Property } from '@/store/types';
import { Box, Menu } from '@mui/material';
import InsertMenuOption from '@/components/SideBar/PropertyContextMenu/InsertMenuOption';
import MoveMenuOption from '@/components/SideBar/PropertyContextMenu/MoveMenuOption';
import MUIMoreHorizIcon from '@mui/icons-material/MoreHoriz';
import classes from '@/components/SideBar.module.css';
import DuplicateMenuOption from './DuplicateMenuOption';

interface PropertyContextMenuProps {
	assetId: string;
	item: Property;
}

const PropertyContextMenu: React.FC<PropertyContextMenuProps> = ({ assetId, item }) => {
	const [anchor, setAnchor] = React.useState<Element | null>(null);
	const closeMenu = React.useCallback(() => setAnchor(null), [setAnchor]);
	const openMenu = (event: React.MouseEvent<Element>) => setAnchor(event.currentTarget);

	return (
		<Box gridArea="insert" display="flex" alignItems="center" justifyContent="center">
			<MUIMoreHorizIcon
				className={classes.propertyMenuIcon}
				data-testid="property-context-menu__button"
				onClick={openMenu}
			/>

			<Menu
				anchorEl={anchor}
				data-testid="property-context-menu__menu"
				onClose={closeMenu}
				open={Boolean(anchor)}
			>
				<div>
					<InsertMenuOption assetId={assetId} closeMenu={closeMenu} itemId={item.id} />
				</div>
				<div>
					<DuplicateMenuOption item={item} closeMenu={closeMenu} />
				</div>
				<div>
					<MoveMenuOption assetId={assetId} item={item} />
				</div>
			</Menu>
		</Box>
	);
};

export default PropertyContextMenu;
