import * as actions from '../actions';
import { ReportValidationError } from '../types';
import AppAction from '../appAction';

const reportValidation = (
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state: ReportValidationError[] = [],
	action: AppAction,
): ReportValidationError[] => {
	switch (action.type) {
		case actions.applicationLoadSuccess.type:
			return [];
		case actions.reportValidationResult.type:
			return action.payload.result;

		default:
			return state;
	}
};

export default reportValidation;
