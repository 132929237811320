import Menu, { MenuProps } from '@mui/material/Menu';
import React from 'react';

import { Category } from '@/store/types';

import CreateSubCategory from './CreateSubCategory';
import Delete from './Delete';

interface CategoryContextMenuProps {
	anchorElement: MenuProps['anchorEl'];
	category: Category;
	onClose(): void;
}

const CategoryContextMenu: React.FC<CategoryContextMenuProps> = ({
	anchorElement,
	category,
	onClose,
}) => (
	<Menu anchorEl={anchorElement} keepMounted onClose={onClose} open={Boolean(anchorElement)}>
		<CreateSubCategory category={category} onClose={onClose} />
		<Delete category={category} onClose={onClose} />
	</Menu>
);

export default CategoryContextMenu;
