import { BACKEND_URL, TEST_USERS_ENABLED } from '@/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import { assetLibraryId, studyId } from '@/selectors';
import acquireAccessToken from '@/authentication/accesstoken';
import { useGoogleTagManager } from 'kes-common';

type DownloadScriptHandler = () => void;

interface DownloadScriptProps {
	renderControl: (downloadScript: DownloadScriptHandler) => JSX.Element;
}

const DownloadScript: React.FC<DownloadScriptProps> = ({ renderControl }): JSX.Element => {
	const hiddenForm = React.useRef<HTMLFormElement>(null);
	const hiddenInput = React.useRef<HTMLInputElement>(null);
	const hiddenInputForTestUser = React.useRef<HTMLInputElement>(null);
	const currentStudyId = useSelector(studyId);
	const currentAssetLibraryId = useSelector(assetLibraryId);
	const { trackCustomEvent } = useGoogleTagManager();

	const url = `${BACKEND_URL}study/${currentStudyId}/asset-library/${currentAssetLibraryId}`;

	const submit = async () => {
		const accessToken = await acquireAccessToken();
		hiddenInput.current?.setAttribute('value', accessToken || '');
		if (TEST_USERS_ENABLED) {
			const userFromLocalStorage = localStorage.getItem('Test-User-Id');
			if (userFromLocalStorage) {
				hiddenInputForTestUser.current?.setAttribute('value', userFromLocalStorage);
			}
		}
		hiddenForm.current?.submit();
		trackCustomEvent({
			event: 'script_downloaded',
			studyId,
		});
	};

	return (
		<form ref={hiddenForm} method="post" target="_self" action={url}>
			<input ref={hiddenInput} name="access_token" type="hidden" />
			{TEST_USERS_ENABLED && <input ref={hiddenInputForTestUser} name="test_user" type="hidden" />}
			{renderControl(submit)}
		</form>
	);
};

export default DownloadScript;
