import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'kes-common';

import ReportContent from '@/components/ReportContent';
import TopBar from '@/components/TopBar';
import useNotifyConnectionChange from '@/layouts/useNotifyConnectionChange';
import { applicationLoaded, applicationName as applicationNameSelector } from '@/selectors';
import useTitleChange from '@/utils/useChangeTitle';
import classes from './Base.module.css';
import DependencyGraphContainer from './DependencyGraphContainer';
import SidebarContainer from './SidebarContainer';

interface Props {
	children: React.ReactNode;
	interactionEnabled: boolean;
}

const BaseLayout: React.FC<Props> = ({ children, interactionEnabled }): JSX.Element => {
	const applicationHasLoaded = useSelector(applicationLoaded);
	const applicationName = useSelector(applicationNameSelector);
	useTitleChange();

	useNotifyConnectionChange();

	if (!applicationHasLoaded) return <Loader />;

	return (
		<>
			<TopBar />
			<DependencyGraphContainer />
			<SidebarContainer applicationName={applicationName} interactionEnabled={interactionEnabled} />
			<div className={classes.content}>
				<ReportContent interactionEnabled={interactionEnabled} />
				{children}
			</div>
		</>
	);
};

export default BaseLayout;
