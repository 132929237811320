import React from 'react';
import PanoramaIcon from '@mui/icons-material/Panorama';
import classes from './uploadbox.module.css';

const UploadBox: React.FC = () => (
	<div className={classes.root}>
		<PanoramaIcon className={classes.icon} />
		Inspectors will be able to upload an image from their files
	</div>
);

export default UploadBox;
