/* eslint-disable no-else-return */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import useAutoFocusRef from '@/components/hooks/useAutoFocusRef';
import SidePopup from '@/components/SidePopup';
import { PROPERTY_NEW_PREFIX } from '@/constants';
import { validate as propertyValidate } from '@/entities/property';
import { applicationIsMilicense, applicationName, assetsGet, propertiesGet } from '@/selectors';
import { actionsByPropertyId } from '@/selectors/actions';
import {
	propertyDeleteRequest as propertyDeleteRequestAction,
	propertyRemoveReference as propertyRemoveReferenceAction,
	propertySelect as propertySelectAction,
	propertyUpdate as propertyUpdateAction,
	propertyUpdateRequest as propertyUpdateRequestAction,
	reportUpdatePropertyName as reportUpdatePropertyNameAction,
} from '@/store/actions';
import { PropertySelectionMode } from '@/store/reducers/properties';
import State from '@/store/state';
import { Asset, Property, PropertyType } from '@/store/types';
import { ActionDispatcher } from '@/store/utils';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';
import { DynamicTextField } from '@/components/rhdhv/TextField/DynamicTextField';
import {
	getReferencedPropertiesForReferent,
	hasDependants as hasDependantsSelector,
} from '@/selectors/property';
import { FormHelperText } from '@mui/material';
import styled from 'styled-components';
import { applicationAsset, applicationProperty } from '@/routes';
import Link from '@/components/rhdhv/Link';
import TypeSelector from './TypeSelector';
import FullNameConnected from './FullNameConnected';
import QuestionSetup from '..';
import RulesSetup from '../../RulesSetup';
import InsertIntoTemplate from './InsertIntoTemplate';

export function shouldChangePropertyTypeWithPopulationRelationship(): boolean {
	const message =
		'Are you sure you want to change the type of this property? ' +
		'The population by this property wil be removed. Only single text/number properties are allowed to be used as populating answers.';
	// eslint-disable-next-line no-restricted-globals, no-alert
	return confirm(message);
}

export function shouldDeletePropertyTypeWithPopulationRelationship(): boolean {
	const message =
		'Are you sure you want to delete this property? ' +
		'The population by this property wil be removed.';
	// eslint-disable-next-line no-restricted-globals, no-alert
	return confirm(message);
}

const StyledFormHelperText = styled(FormHelperText)`
	color: #0d74dc;
	margin-bottom: 32px;
`;

const getAllowMultipleAnswersByType = (type: PropertyType, allowMultipleAnswers: boolean) =>
	type === PropertyType.MULTI_SELECT || type === PropertyType.SINGLE_SELECT
		? false
		: allowMultipleAnswers;

interface AsideProps {
	assetId: Asset['id'];
	property: Property;
	propertyUpdate: ActionDispatcher<typeof propertyUpdateAction>;
	propertyUpdateRequest: ActionDispatcher<typeof propertyUpdateRequestAction>;
	propertyDeleteRequest: ActionDispatcher<typeof propertyDeleteRequestAction>;
	propertySelect: ActionDispatcher<typeof propertySelectAction>;
	reportUpdatePropertyName: ActionDispatcher<typeof reportUpdatePropertyNameAction>;
}

/**
 * This component is responsible for rendering the left sidebar
 */
export const PropertyPanel = connect(
	(state: State, ownProps: { propertyId: Property['id'] }): Pick<AsideProps, 'property'> => ({
		property: propertiesGet(state, ownProps.propertyId),
	}),
	{
		propertyDeleteRequest: propertyDeleteRequestAction,
		propertyUpdate: propertyUpdateAction,
		propertyUpdateRequest: propertyUpdateRequestAction,
		propertySelect: propertySelectAction,
		reportUpdatePropertyName: reportUpdatePropertyNameAction,
	},
)(
	({
		propertyDeleteRequest,
		assetId,
		property,
		propertyUpdate,
		propertyUpdateRequest,
		propertySelect,
		reportUpdatePropertyName,
	}: AsideProps): JSX.Element => {
		const [autoSelect] = useState(property.name.startsWith(PROPERTY_NEW_PREFIX));
		const autoFocus = useAutoFocusRef(autoSelect);
		const dispatch = useDispatch();
		const { store } = useContext(ReactReduxContext);
		const isMilicense = useSelector((state: State) => applicationIsMilicense(state));
		const appName = useSelector((state: State) => applicationName(state));
		const asset = useSelector((state: State) => assetsGet(state, assetId));

		const validateProperty = useCallback(
			(propertyToValidate: Property): ReturnType<typeof propertyValidate> =>
				propertyValidate(propertyToValidate, assetId, store),
			[assetId, store],
		);

		const propertyUpdateWithName = useCallback(
			(updatedProperty: Property): string | null => {
				if (updatedProperty.name === '') {
					propertyUpdate({ ...updatedProperty, name: property.name });
					return property.name;
				} else {
					propertyUpdate(updatedProperty);
					return null;
				}
			},
			[asset, property],
		);

		const propertyUpdateWithPlaceholder = useCallback(
			(updatedProperty: Property): string | null => {
				if (updatedProperty.placeholder === '') {
					propertyUpdate({ ...updatedProperty, placeholder: property.placeholder });
					return property.placeholder;
				} else {
					propertyUpdate(updatedProperty);
					if (updatedProperty.placeholder !== property.placeholder) {
						reportUpdatePropertyName({
							propertyPlaceholderNew: updatedProperty.placeholder,
							propertyPlaceholderOld: property.placeholder,
						});
					}
					return null;
				}
			},
			[asset, property],
		);

		const propertyUpdateRequired = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				propertyUpdate({ ...property, required: event.target.checked });
			},
			[property],
		);

		const propertyUpdateInternalOnly = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				propertyUpdate({ ...property, internalOnly: event.target.checked });
			},
			[property],
		);

		useEffect(
			() => () => {
				propertySelect({ id: '', selectionMode: PropertySelectionMode.None });
			},
			[propertySelect],
		);

		const referencedProperties = useSelector<State, Property[]>((state) =>
			getReferencedPropertiesForReferent(state, property.id),
		);
		const hasDependants = useSelector(hasDependantsSelector(property.id));
		const propertyActions = useSelector(actionsByPropertyId(property.id));

		/*
		 * If changing from a conditionable property (Amount) to something else:
		 * - Show message about condition dependencies being removed (if applicable).
		 * If changing from a referencable property (Text or Amount) to something else:
		 * - Show message about references in other properties being removed (if applicable).
		 * If changing from a selectable property (Single or Multiple) to a different type:
		 * - Show message about the choices being deleted (if applicable).
		 * - Show message about dependants being removed (if applicable).
		 */
		const getUpdatePropertyTypeWarnings = (type: PropertyType): string[] => {
			const warnings: string[] = [];
			const CONDITIONABLE_TYPES = [PropertyType.DECIMAL];
			const REFERENCEABLE_TYPES = [PropertyType.DECIMAL, PropertyType.STRING];
			const SELECTABLE_TYPES = [PropertyType.MULTI_SELECT, PropertyType.SINGLE_SELECT];
			const isAmountType = CONDITIONABLE_TYPES.includes(type);
			const isReferenceableType = REFERENCEABLE_TYPES.includes(type);
			const isSelectType = SELECTABLE_TYPES.includes(type);
			const wasAmountType = CONDITIONABLE_TYPES.includes(property.type);
			const wasReferenceableType = REFERENCEABLE_TYPES.includes(property.type);
			const wasSelectType = SELECTABLE_TYPES.includes(property.type);
			if (wasAmountType && !isAmountType) {
				if (propertyActions.length > 0) {
					warnings.push('all its conditions will be removed');
				}
			}
			if (wasReferenceableType && !isReferenceableType) {
				if (referencedProperties.length > 0) {
					warnings.push('All its population relationships will be removed');
				}
			}
			if (wasSelectType && !isSelectType) {
				if (property.choiceIds.length > 0) {
					warnings.push('All its choices will be removed');
				}
				if (hasDependants) {
					warnings.push(
						[
							'Some of the choices have dependent properties.',
							'The dependency of the properties on the choices will be removed',
							'(not the properties themselves).',
						].join(' '),
					);
				}
			}
			return warnings;
		};

		const updatePropertyDateFormat = React.useCallback(
			(dateFormat: string) => {
				propertyUpdate({ dateFormat, id: property.id });
			},
			[property],
		);

		const updatePropertyType = (type: PropertyType) => {
			const allowMultipleAnswers = getAllowMultipleAnswersByType(
				type,
				property.allowMultipleAnswers,
			);
			const warnings = getUpdatePropertyTypeWarnings(type);
			if (warnings.length > 0) {
				warnings.unshift('Are you sure you want to change the type of this property?');
				// eslint-disable-next-line no-alert, no-restricted-globals
				if (confirm(warnings.join('\n'))) {
					propertyUpdateRequest({
						id: property.id,
						type,
						allowMultipleAnswers,
						hasOtherOption: false,
					});
				}
			} else {
				propertyUpdateRequest({
					id: property.id,
					type,
					allowMultipleAnswers,
					hasOtherOption: false,
				});
			}
		};

		const onChangeAllowMultipleAnswers = (allowed: boolean) => {
			// check for connected property for population
			if (referencedProperties.length > 0 && allowed) {
				if (shouldChangePropertyTypeWithPopulationRelationship()) {
					// eslint-disable-next-line no-restricted-syntax
					for (const referencedProperty of referencedProperties) {
						dispatch(
							propertyRemoveReferenceAction({
								propertyId: referencedProperty.id,
							}),
						);
					}

					propertyUpdate({
						id: property.id,
						allowMultipleAnswers: allowed,
					});
				}
			} else {
				propertyUpdate({
					id: property.id,
					allowMultipleAnswers: allowed,
				});
			}
		};

		const onDeleteProperty = () => {
			propertyDeleteRequest({ id: property.id });
		};

		const renderReferencedProperties = () =>
			referencedProperties.length > 0 && (
				<StyledFormHelperText data-testid={`referenced-properties-text-${property.name}`}>
					<>
						This property populates the options for properties:{' '}
						{referencedProperties.map((rp, index) => (
							<>
								<Link
									data-testid={`referenced-property-${rp.name}`}
									key={rp.id}
									className={classes.dependentPropertiesLink}
									to={applicationProperty}
									params={{
										propertyId: rp.id,
										applicationName: appName,
									}}
								>
									{rp.name}
								</Link>
								{referencedProperties.length > 1 &&
									(index === referencedProperties.length - 1 ? '.' : ', ')}
								{referencedProperties.length <= 1 && '.'}
							</>
						))}
					</>
				</StyledFormHelperText>
			);

		return (
			<SidePopup
				title={<FullNameConnected assetId={assetId} />}
				onDelete={(): void => onDeleteProperty()}
			>
				<div className={classes.formHelperBig}>
					<DynamicTextField
						data-testid="textfield-property-name"
						className={classes.formControl}
						InputProps={{ classes: { root: classes.propertyNameRoot } }}
						name="name"
						value={property}
						update={propertyUpdateWithName}
						placeholder="Property name"
						forwardRef={autoFocus}
						validator={validateProperty}
						variant="standard"
					/>
				</div>

				<div className={classes.formHelperBig}>
					<DynamicTextField
						data-testid="textfield-property-placeholder"
						className={classes.formControl}
						InputProps={{ classes: { root: classes.propertyNameRoot } }}
						name="placeholder"
						value={property}
						update={propertyUpdateWithPlaceholder}
						placeholder="Property placeholder"
						validator={validateProperty}
						variant="standard"
					/>
				</div>

				{renderReferencedProperties()}
				<h3 className={classes.formControlTitle}>Type</h3>
				<div className={classes.formHelperSpace}>
					<TypeSelector
						asset={asset}
						selectedType={property.type}
						onChange={updatePropertyType}
						isMilicense={isMilicense}
					/>
				</div>

				{property.type === PropertyType.DATE && (
					<div className={classes.formHelperBig}>
						<TextField
							fullWidth
							label="Date format"
							name="dateFormat"
							onChange={(event) => updatePropertyDateFormat(event.target.value)}
							select
							SelectProps={{ native: true }}
							value={property.dateFormat}
							variant="standard"
						>
							<option value="yyyy-MM-dd">2022-08-15</option>
							<option value="yyyy/MM/dd">2022/08/15</option>
							<option value="dd-MM-yyyy">15-08-2022</option>
							<option value="dd/MM/yyyy">15/08/2022</option>
							<option value="dd MMM yyyy">15 Aug 2022</option>
							<option value="dd MMMM yyyy">15 August 2022</option>
						</TextField>
					</div>
				)}

				<div className={classes.formHelperSpace}>
					<h3 className={classes.formControlTitle}>Required</h3>
					<Checkbox
						checked={property.required}
						onChange={propertyUpdateRequired}
						sx={{ margin: '0 0 0 auto' }}
					/>
				</div>

				<div className={classes.formHelperSpace}>
					<h3 className={classes.formControlTitle}>Internal only</h3>
					<Checkbox
						checked={property.internalOnly}
						onChange={propertyUpdateInternalOnly}
						sx={{ margin: '0 0 0 auto' }}
					/>
				</div>

				{(property.type === PropertyType.DECIMAL || property.type === PropertyType.STRING) && (
					<div className={classes.formHelperSpace}>
						<h3 className={classes.formControlTitle}>Allow multiple answers</h3>
						<Switch
							className={classes.computedToggle}
							color="primary"
							checked={property.allowMultipleAnswers}
							onChange={(e) => {
								onChangeAllowMultipleAnswers(e.target.checked);
							}}
						/>
					</div>
				)}

				<Divider />
				<Typography fontWeight={500} variant="subtitle1">
					Question setup
				</Typography>
				<QuestionSetup propertyId={property.id} />

				{property.type === PropertyType.DECIMAL && (
					<>
						<Divider />
						<Typography fontWeight={500} variant="subtitle1">
							Rules setup
						</Typography>
						<RulesSetup propertyId={property.id} />
					</>
				)}

				<Box marginY={2}>
					<Stack direction="column" spacing={1}>
						<InsertIntoTemplate assetId={assetId} propertyId={property.id} />
						<Button
							color="primary"
							component={RouterLink}
							data-testid="actions-property-done"
							fullWidth
							to={applicationAsset({ applicationName: appName, assetId })}
							variant="contained"
						>
							Done
						</Button>
					</Stack>
				</Box>
			</SidePopup>
		);
	},
);
