import React from 'react';
import { MenuItem } from '@mui/material';
import { propertyMoveRequest } from '@/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesGetAll } from '@/selectors';
import State from '@/store/state';
import { Property, UUID } from '@/store/types';
import useWhenOnline from '@/components/hooks/useWhenOnline';
import { filteredCategories } from './constants';
import SubMenu from './SubMenu';
import useMappedCategories from './useMappedCategories';

interface MoveMenuOptionProps {
	assetId: string;
	item: Property;
}

const MoveMenuOption: React.FC<MoveMenuOptionProps> = ({ assetId, item }) => {
	const categories = useSelector(categoriesGetAll);
	const studyId = useSelector<State, UUID>((state) => state.application.id);
	const dispatch = useDispatch();
	const categoriesMapped = useMappedCategories({ assetId, item });

	const handleMove = useWhenOnline('move properties', (destinationAssetId: string) => {
		dispatch(
			propertyMoveRequest({
				currentAssetId: assetId,
				destinationAssetId,
				propertyId: item.id,
				studyId,
				position: null,
			}),
		);
	});

	const showMoveOption = !filteredCategories.some(
		(c) =>
			c ===
			Object.values(categories).find((category) => category.assetTypeIds.includes(assetId))?.name,
	);

	if (showMoveOption && categoriesMapped.length > 0) {
		return (
			<SubMenu data-testid="property-context-menu__move-property" text="Move to...">
				{Object.values(categoriesMapped).map((category) => (
					<div key={category.id}>
						<SubMenu
							data-testid={`property-context-menu__move-property--category-${category.id}`}
							text={category.name}
						>
							{category.assetsForCategory.map((asset) => (
								<div key={asset.id}>
									<MenuItem
										data-testid={`property-context-menu__move-property--category-${asset.id}`}
										onClick={() => handleMove(asset.id)}
									>
										{asset.name}
									</MenuItem>
								</div>
							))}
						</SubMenu>
					</div>
				))}
			</SubMenu>
		);
	}
	return null;
};

export default MoveMenuOption;
