import React, { FC } from 'react';
import classes from '@/components/SideBar.module.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CollapseIcon: FC<{
	state: boolean;
	setState: (state: boolean) => void;
	shown: boolean;
	['data-testid']?: string;
}> = ({ state, setState, shown, ...props }): JSX.Element => (
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
	<div
		className={shown ? classes.expandIconContainer : classes.expandIconContainerHidden}
		onClick={(): void => setState(!state)}
		data-testid={props['data-testid']}
	>
		<ArrowDropDownIcon
			className={classes.expandIcon}
			style={{ transform: state ? 'rotate(0deg)' : 'rotate(-90deg)' }}
		/>
	</div>
);

export default CollapseIcon;
