import makeReducer from '@/store/makeReducer';
import { ApiChoice } from 'kes-common';
import { create } from '@/entities/choice';
import { CHOICE_NEW_PREFIX } from '@/constants';
import { Choice, ReportTextSource } from '../types';
import { initialState, makeRepository } from '../repository';
import * as actions from '../actions';

const repository = makeRepository<Choice>();

function mapApiToLocal(api: ApiChoice): Choice {
	return {
		...api,
		position: api.position || 0,
		reportTextSource: ReportTextSource[api.reportTextSource],
	};
}

export default makeReducer(initialState<Choice>(), {
	[actions.applicationLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.choicesById, mapApiToLocal);
	},
	[actions.choicePersist.type]: (draft, payload): void => {
		repository.upsert(draft, payload.choice);
	},
	[actions.choiceGenerate.type]: (draft, payload): void => {
		let name: string;
		let counter = 1;
		const values = Object.values(draft.byId);
		do {
			name = CHOICE_NEW_PREFIX + counter;
			counter += 1;
			// eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
		} while (values.find((e): boolean => e.name === name));
		repository.upsert(draft, {
			...create(payload.id),
			name,
			position: payload.position,
		});
	},
	[actions.choiceDelete.type]: (draft, payload): void => {
		repository.delete(draft, payload.id);
	},
	[actions.choiceUpdate.type]: (draft, payload): void => {
		repository.update(draft, payload);
	},
	[actions.choiceUpdateSuccess.type]: (draft, payload): void => {
		repository.update(draft, payload);
	},
	[actions.propertyAddDependent.type]: (draft, payload): void => {
		repository.modify(draft, payload.choiceId, (item): void => {
			const index = item.dependentPropertyIds.indexOf(payload.id);
			if (index === -1) {
				item.dependentPropertyIds.push(payload.id);
			}
		});
	},
	[actions.propertyRemoveDependent.type]: (draft, payload): void => {
		repository.modify(draft, payload.choiceId, (item): void => {
			const index = item.dependentPropertyIds.indexOf(payload.id);
			if (index !== -1) {
				item.dependentPropertyIds.splice(index, 1);
			}
		});
	},
	[actions.propertyDelete.type]: (draft, payload): void => {
		payload.parentChoiceIds.forEach((choiceId) => {
			repository.modify(draft, choiceId, (item) => {
				// eslint-disable-next-line no-param-reassign
				item.dependentPropertyIds = item.dependentPropertyIds.filter(
					(dependentPropertyId) => dependentPropertyId !== payload.id,
				);
			});
		});
	},
	[actions.propertyDuplicate.type]: (draft, payload): void => {
		Object.values(payload.choicesById).forEach((apiChoice) =>
			repository.upsert(draft, mapApiToLocal(apiChoice)),
		);
	},
});
