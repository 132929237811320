import NotApplicableOption from '@/pages/application/ReportPropertyUpdate/PropertyUpsert/QuestionSetup/ChoicesPanel/DefaultOptions/NotApplicableOption';
import NotApplicableOptionButton from '@/pages/application/ReportPropertyUpdate/NotApplicableOptionButton';
import { propertiesGet } from '@/selectors';
import {
	choiceGenerate,
	propertyRemoveReference,
	propertySelect,
	propertyUpdate,
} from '@/store/actions';
import { Asset, Property, PropertyType } from '@/store/types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';
import { RadioGroup } from '@mui/material';
import RadioButton from '@/components/rhdhv/RadioButton/RadioButton';
import State from '@/store/state';
import Chip from '@/components/Chip';
import AddIcon from '@mui/icons-material/AddCircle';
import { getAssetWithPropertyId } from '@/selectors/assets';
import FormControlLabel from '@/components/rhdhv/FormControlLabel/FormControlLabel';
import { PropertySelectionMode } from '@/store/reducers/properties';
import OtherOption from './DefaultOptions/OtherOption';
import ChoiceRenderer from '../../../ChoiceRendererProps';

export function propertyHasChoices(
	type: PropertyType,
): type is PropertyType.MULTI_SELECT | PropertyType.SINGLE_SELECT {
	return type === PropertyType.MULTI_SELECT || type === PropertyType.SINGLE_SELECT;
}

interface ChoicesPanelProps {
	propertyId: Property['id'];
}

enum ChoicePopulationType {
	MANUAL = 'manual',
	RELATIONSHIP = 'relationship',
}

/**
 * This component is responsible for rendering the choices, and the selector for multiple answers
 */
export function ChoicesPanel({ propertyId }: ChoicesPanelProps): JSX.Element | null {
	const property = useSelector((state) => propertiesGet(state as any, propertyId));
	const [choicesFrom, setChoicesFrom] = useState(
		(property.referencedAssetType &&
			property.identifyingProperty &&
			ChoicePopulationType.RELATIONSHIP) ||
			ChoicePopulationType.MANUAL,
	);

	const populatingProperty = useSelector<State, Property | undefined>((state) => {
		if (property.identifyingProperty) {
			return propertiesGet(state, property.identifyingProperty);
		}
		return undefined;
	});

	const parentAssetForPopulatingProperty = useSelector<State, Asset | undefined>((state) => {
		if (property.identifyingProperty) {
			return getAssetWithPropertyId(state, property.identifyingProperty);
		}
		return undefined;
	});

	const dispatch = useDispatch();
	const { type } = property;

	useEffect(() => {
		if (property.referencedAssetType && property.identifyingProperty) {
			setChoicesFrom(ChoicePopulationType.RELATIONSHIP);
		} else {
			setChoicesFrom(ChoicePopulationType.MANUAL);
		}
	}, [property]);

	const addChoice = (e: React.MouseEvent<HTMLButtonElement>): void => {
		dispatch(choiceGenerate({ id: uuidv4(), propertyId, position: property.choiceIds.length }));
		const { target } = e;
		window.requestAnimationFrame((): void => {
			if (target !== null && 'scrollIntoView' in target) {
				(target as HTMLElement).scrollIntoView(false);
			}
		});
	};

	const addOtherOption = () => {
		dispatch(
			propertyUpdate({
				...property,
				hasOtherOption: true,
			}),
		);
	};

	const onDeletePopulatingRelationship = () => {
		dispatch(
			propertyRemoveReference({
				propertyId: property.id,
			}),
		);
	};

	return (
		<>
			<div className={classes.options}>
				{[PropertyType.MULTI_SELECT, PropertyType.SINGLE_SELECT].includes(property.type) && (
					<RadioGroup
						row
						name="choices-population"
						value={choicesFrom}
						onChange={(e) => {
							const chosenChoicePopulationType = e.target.value as ChoicePopulationType;
							if (chosenChoicePopulationType === ChoicePopulationType.MANUAL) {
								onDeletePopulatingRelationship();
							}
							setChoicesFrom(chosenChoicePopulationType);
						}}
					>
						<FormControlLabel
							value={ChoicePopulationType.MANUAL}
							control={<RadioButton />}
							label="Add options manually"
						/>
						<FormControlLabel
							value={ChoicePopulationType.RELATIONSHIP}
							control={
								<RadioButton data-testid={`select-relationship-for-population-${property.name}`} />
							}
							label={
								<>
									Populate options with values from…
									{choicesFrom === ChoicePopulationType.RELATIONSHIP && !populatingProperty && (
										<Chip
											data-testid={`select-source-for-population-${property.name}`}
											textColor="purpley"
											className={classes.select}
											classes={{
												label: classes.dependentPropertiesChipLabel,
											}}
											icon={<AddIcon className={classes.selectPropertyIcon} />}
											onClick={(): void => {
												dispatch(
													propertySelect({
														id: property.id,
														selectionMode: PropertySelectionMode.Relationship,
													}),
												);
											}}
											label="Select source"
										/>
									)}
									{populatingProperty && parentAssetForPopulatingProperty && (
										<Chip
											onClick={(): void => {
												dispatch(
													propertySelect({
														id: property.id,
														selectionMode: PropertySelectionMode.Relationship,
													}),
												);
											}}
											label={
												<>
													{parentAssetForPopulatingProperty.name}:{populatingProperty.name}
												</>
											}
											onDelete={onDeletePopulatingRelationship}
										/>
									)}
								</>
							}
						/>
					</RadioGroup>
				)}
				{choicesFrom !== ChoicePopulationType.RELATIONSHIP &&
					propertyHasChoices(type) &&
					property.choiceIds.map(
						(choice): ReactNode => (
							<ChoiceRenderer
								key={choice}
								propertyType={type}
								propertyId={property.id}
								choiceId={choice}
							/>
						),
					)}
				{choicesFrom !== ChoicePopulationType.RELATIONSHIP && property.hasOtherOption && (
					<OtherOption property={property} />
				)}
				{choicesFrom !== ChoicePopulationType.RELATIONSHIP && property.hasNotApplicableOption && (
					<NotApplicableOption propertyId={property.id} />
				)}
			</div>
			{choicesFrom !== ChoicePopulationType.RELATIONSHIP && propertyHasChoices(type) && (
				<div className={classes.choicesAddOption} key="addOption">
					<button className={classes.choicesAddOptionButton} onClick={addChoice} type="button">
						<AddCircleOutlineIcon />
						Add Option
					</button>
					{[PropertyType.MULTI_SELECT, PropertyType.SINGLE_SELECT].includes(property.type) &&
						!property.hasOtherOption && (
							<button
								className={classes.choicesAddOptionButton}
								onClick={addOtherOption}
								type="button"
							>
								<AddCircleOutlineIcon />
								Add option other
							</button>
						)}
					<NotApplicableOptionButton propertyId={propertyId} />
				</div>
			)}
		</>
	);
}
