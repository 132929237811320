import React, { ClipboardEvent, KeyboardEvent, Ref } from 'react';
import classNames from 'classnames';
import MTextField, { TextFieldProps } from '@mui/material/TextField';
import classes from './TextField.module.css';

let target: HTMLElement;

type Props = TextFieldProps & {
	pattern?: RegExp;
	forwardRef?: Ref<HTMLInputElement>;
	inputRef: never;
	min?: number;
	max?: number;
};

function checkClipboard(event: ClipboardEvent, pattern?: RegExp): void {
	if (!pattern || !event.clipboardData) {
		return;
	}
	event.preventDefault();

	const newText = event.clipboardData
		.getData('text/plain')
		.split('')
		.filter((char): boolean => pattern.test(char))
		.join('');
	document.execCommand('insertText', false, newText);
}

const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
	event.preventDefault();
	target = event.target as HTMLElement;
};

const handleKeyPress = (
	event: KeyboardEvent<HTMLDivElement>,
	{ pattern, multiline }: Props,
): void => {
	if (!multiline && event.key === 'Enter' && target) {
		target.blur();
	}
	if (pattern && !pattern.test(event.key)) {
		event.preventDefault();
	}
};

const TextField: React.FC<Props> = (props) => {
	const {
		className,
		forwardRef,
		InputLabelProps,
		InputProps,
		max,
		min,
		onFocus,
		onKeyPress,
		onPaste,
		...filteredProps
	} = props;
	return (
		<MTextField
			FormHelperTextProps={{
				classes: {
					error: classes.error,
				},
			}}
			{...filteredProps}
			InputLabelProps={{
				...InputLabelProps,
				classes: {
					root: classes.root,
					shrink: classes.shrink,
					error: classes.error,
					...(InputLabelProps ? InputLabelProps.classes : undefined),
				},
			}}
			InputProps={{
				...InputProps,
				classes: {
					error: classes.textfieldError,
					...(InputProps ? InputProps.classes : undefined),
				},
				inputRef: forwardRef,
				inputProps: {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					['min' as any]: min,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					['max' as any]: max,
				},
			}}
			className={classNames(classes.root, className)}
			onFocus={(e): void => {
				handleFocus(e);
				if (onFocus) {
					onFocus(e);
				}
			}}
			onKeyPress={(e): void => {
				if (onKeyPress) {
					onKeyPress(e);
				} else {
					handleKeyPress(e, props);
				}
			}}
			onPaste={(e): void => {
				// eslint-disable-next-line react/destructuring-assignment
				checkClipboard(e, props.pattern);
				if (onPaste) {
					onPaste(e);
				}
			}}
		/>
	);
};

export default TextField;
