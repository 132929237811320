import { FeatureFlagsProvider, useUser } from 'kes-common';
import React from 'react';

import { SPLITIO_AUTHORIZATION_KEY } from '@/constants';

interface FeatureFlagsProps {
	children: React.ReactNode;
}

const FeatureFlags: React.FC<FeatureFlagsProps> = ({ children }) => {
	const { user } = useUser();
	return (
		<FeatureFlagsProvider
			splitioAuthorizationKey={SPLITIO_AUTHORIZATION_KEY}
			splitioFeaturesMock={window.features || {}}
			splitioKey={user.user.id}
		>
			{children}
		</FeatureFlagsProvider>
	);
};

export default FeatureFlags;
