import classnames from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@/components/Chip';
import withMap from '@/components/hoc/withMap';
import useAutoFocusRef from '@/components/hooks/useAutoFocusRef';
import {
	DynamicNullableTextField,
	DynamicTextField,
} from '@/components/rhdhv/TextField/DynamicTextField';
import { CHOICE_NEW_PREFIX } from '@/constants';
import { validate as choiceValidate } from '@/entities/choice';
import {
	choiceGet,
	choiceGetSiblingChoiceForProperty,
	studyId as studyIdSelector,
} from '@/selectors';
import {
	choiceDeleteRequest,
	choiceUpdate,
	choiceUpdateSuccess,
	propertySelect,
} from '@/store/actions';
import State from '@/store/state';
import { Choice, Property, PropertyType } from '@/store/types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React, { useCallback, useContext, useState } from 'react';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';
import { PropertySelectionMode } from '@/store/reducers/properties';
import { choiceReOrder } from '@/net/api';
import DependentPropertiesChip from '../DependentPropertiesChip';
import ReportTextSection from './ReportTextSection';

const ChoiceTypeIcon = withMap('type', {
	[PropertyType.SINGLE_SELECT]: RadioButtonUncheckedIcon,
	[PropertyType.MULTI_SELECT]: CheckBoxOutlineBlankIcon,
});

interface ChoiceRendererProps {
	choiceId: string;
	propertyType: PropertyType.MULTI_SELECT | PropertyType.SINGLE_SELECT;
	propertyId: Property['id'];
}

const ChoiceRenderer = ({
	propertyType,
	choiceId,
	propertyId,
}: ChoiceRendererProps): JSX.Element => {
	const $element = React.useRef<HTMLDivElement | null>(null);
	const [choiceMoved, setChoiceMoved] = React.useState<boolean>(false);
	const choice = useSelector((state: State) => choiceGet(state, choiceId));
	const choiceNext = useSelector(choiceGetSiblingChoiceForProperty(choiceId, 'next'));
	const choicePrevious = useSelector(choiceGetSiblingChoiceForProperty(choiceId, 'previous'));
	const studyId = useSelector(studyIdSelector);
	const dispatch = useDispatch();
	const [autoSelect] = useState(choice.name.startsWith(CHOICE_NEW_PREFIX));

	const autoFocus = useAutoFocusRef(true);
	const { store } = useContext(ReactReduxContext);

	const validateChoice = useCallback(
		(choiceToValidate: Choice): ReturnType<typeof choiceValidate> =>
			choiceValidate(choiceToValidate, propertyId, store),
		[propertyId, store],
	);

	const highlightChoice = React.useCallback(() => {
		setChoiceMoved(true);
		$element.current?.scrollIntoView({ block: 'center' });
	}, [$element, setChoiceMoved]);

	React.useEffect(() => {
		if (choiceMoved) {
			window.setTimeout(() => {
				setChoiceMoved(false);
			}, 2000);
		}
	}, [choiceMoved, setChoiceMoved]);

	return (
		<div
			className={classnames({ [classes.choice]: true, [classes.highlight]: choiceMoved })}
			ref={$element}
		>
			<CloseIcon
				className={classes.choiceDelete}
				onClick={(): void => {
					dispatch(choiceDeleteRequest(choice));
				}}
			/>
			<ChoiceTypeIcon className={classes.choiceIcon} type={propertyType} />
			<DynamicTextField
				className={classes.choiceAnswer}
				InputProps={{ classes: { root: classes.choiceAnswerRoot } }}
				placeholder="Answer"
				name="name"
				value={choice}
				update={(e): null => {
					dispatch(choiceUpdate(e));
					return null;
				}}
				validator={validateChoice}
				forwardRef={autoSelect ? autoFocus : undefined}
				variant="standard"
			/>
			<DynamicNullableTextField
				className={classes.choiceDescription}
				InputProps={{ classes: { root: classes.choiceRoot } }}
				InputLabelProps={{ classes: { root: classes.choiceRoot } }}
				label="Explanation"
				name="description"
				value={choice}
				update={(e): null => {
					dispatch(choiceUpdate(e));
					return null;
				}}
				multiline
				variant="standard"
			/>
			<ReportTextSection choice={choice} choiceUpdate={(e) => dispatch(choiceUpdate(e))} />
			<div className={classes.choiceDependent}>
				{choice.dependentPropertyIds.map((id) => (
					<DependentPropertiesChip key={id} selectedPropertyId={id} choiceId={choice.id} />
				))}
				<Chip
					textColor="purpley"
					label="Add dependent"
					className={classes.select}
					icon={<AddCircleOutlineIcon color="primary" className={classes.selectPropertyIcon} />}
					onClick={(): void => {
						dispatch(
							propertySelect({
								id: choice.id,
								selectionMode: PropertySelectionMode.Dependent,
							}),
						);
					}}
				/>
			</div>
			<div className={classes.choiceOrdering}>
				<Typography color="grey.600" component="span" paddingRight={1} variant="overline">
					Change order
				</Typography>
				<IconButton
					disabled={choicePrevious === null}
					onClick={(event) => {
						choiceReOrder(choice.id, studyId, { position: choice.position - 1 }).then(() => {
							highlightChoice();
							dispatch(choiceUpdateSuccess({ id: choice.id, position: choice.position - 1 }));
							dispatch(
								choiceUpdateSuccess({
									id: choicePrevious!.id,
									position: choicePrevious!.position + 1,
								}),
							);
						});
						event.preventDefault();
					}}
					type="button"
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					disabled={choiceNext === null}
					onClick={(event) => {
						choiceReOrder(choice.id, studyId, { position: choice.position + 1 }).then(() => {
							highlightChoice();
							dispatch(choiceUpdateSuccess({ id: choice.id, position: choice.position + 1 }));
							dispatch(
								choiceUpdateSuccess({ id: choiceNext!.id, position: choiceNext!.position - 1 }),
							);
						});
						event.preventDefault();
					}}
					type="button"
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		</div>
	);
};

export default ChoiceRenderer;
