import React from 'react';
import { assetsGetOrNull } from '@/selectors';
import State from '@/store/state';
import { Asset } from '@/store/types';
import { connect } from 'react-redux';

interface FullNameProps {
	title: string;
}

// eslint-disable-next-line react/jsx-no-useless-fragment
const FullName: React.FC<FullNameProps> = ({ title }): JSX.Element => <>{title}</>;

const FullNameConnected = connect(
	(state: State, ownProps: { assetId: Asset['id'] | null }): FullNameProps => {
		if (!ownProps.assetId) {
			return { title: '' };
		}
		const asset = assetsGetOrNull(state, ownProps.assetId);
		if (!asset) {
			return { title: '' };
		}
		return { title: `${asset.name}: Property` };
	},
	null,
	(stateProps): FullNameProps => stateProps,
)(FullName);

export default FullNameConnected;
