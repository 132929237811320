import MuiRadio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

const Radio = styled(MuiRadio)`
	&.MuiRadio-root,
	&.Mui-checked {
		color: white;
	}
	&.Mui-disabled {
		color: rgba(255, 255, 255, 0.26);
	}
`;

export default Radio;
