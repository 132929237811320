import { v4 as uuidv4 } from 'uuid';
import { Choice, Property, ReportTextSource } from '@/store/types';
import {
	validateObject,
	ValidatorFor,
	notEmptyString,
	notBlank,
	maxLength,
} from '@/utils/validate';
import { propertiesGet, choiceGet } from '@/selectors';
import State from '@/store/state';
import { MAX_VALUE_LENGTH } from '@/constants';
import { noForbiddenTemplateCharacters } from 'kes-common';

export function create(id: Choice['id'] = uuidv4()): Choice {
	return {
		id,
		stableId: uuidv4(),
		name: '',
		description: null,
		position: 0,
		reportText: null,
		computed: false,
		dependentPropertyIds: [],
		reportTextSource: ReportTextSource.ANSWER,
	};
}

const simpleValidate = validateObject<ValidatorFor<Choice>>({
	id: [],
	stableId: [],
	name: [
		notBlank('Please type an answer'),
		maxLength(MAX_VALUE_LENGTH),
		noForbiddenTemplateCharacters(),
	],
	description: [notEmptyString],
	reportText: [notEmptyString],
	computed: [],
	dependentPropertyIds: [],
	position: [],
	reportTextSource: [],
});

export const validate = (
	choice: Choice,
	propertyId: Property['id'],
	store: { getState(): State },
): ReturnType<typeof simpleValidate> => {
	let result = simpleValidate(choice);
	const state = store.getState();
	const property = propertiesGet(state, propertyId);
	// eslint-disable-next-line no-restricted-syntax
	for (const choiceId of property.choiceIds) {
		if (choiceId !== choice.id) {
			const storeChoice = choiceGet(state, choiceId);
			if (storeChoice.name === choice.name) {
				result = { ...result, name: 'This name is already in use' };
				break;
			}
		}
	}
	return result;
};
