import State from '@/store/state';
import { Choice, Property } from '@/store/types';
import { composeSelectors } from '@/store/utils';
import makeSlicer from '@/utils/makeSlicer';
import * as router from './router';
import * as asset from './assets';
import * as category from './category';
import * as forms from './forms';
import * as property from './property';
import * as application from './application';
import * as choice from './choice';
import * as changes from './changes';
import * as assetFilter from './assetFilter';
import * as reportValidation from './reportValidation';
import * as ui from './ui';

const rootSlicer = makeSlicer<State>();

const routerSlice = rootSlicer('router');
export const routerRedirectUrl = composeSelectors(routerSlice, router.redirectUrl);
export const routerCurrentUrl = composeSelectors(routerSlice, router.currentUrl);
export const routerParams = composeSelectors(routerSlice, router.params);

const assetsSlice = rootSlicer('assets');
export const assetsGet = composeSelectors(assetsSlice, asset.get);
export const assetsGetOrNull = composeSelectors(assetsSlice, asset.getOrNull);
export const assetsAllIds = composeSelectors(assetsSlice, asset.allIds);
export const assetsGetAll = composeSelectors(assetsSlice, asset.getAll);

const categoriesSlice = rootSlicer('categories');
export const categoriesAllIds = composeSelectors(categoriesSlice, category.allIds);
export const categoriesGet = composeSelectors(categoriesSlice, category.get);
export const categoriesGetAll = composeSelectors(categoriesSlice, category.getAll);
export const categoriesGetOrNull = composeSelectors(categoriesSlice, category.getOrNull);

const formsSlice = rootSlicer('forms');
export const formsAllIds = composeSelectors(formsSlice, forms.allIds);
export const formsGet = composeSelectors(formsSlice, forms.get);
export const formsGetAll = composeSelectors(formsSlice, forms.getAll);
export const formsGetField = composeSelectors(formsSlice, forms.getField);
export const formsGetFieldChoice = composeSelectors(formsSlice, forms.getFieldChoice);
export const formsGetOrNull = composeSelectors(formsSlice, forms.getOrNull);
export const formsSize = composeSelectors(formsSlice, forms.size);
export const formsStatus = composeSelectors(formsSlice, forms.status);

const propertiesSlice = rootSlicer('properties');
export const propertiesMendixStatus = composeSelectors(propertiesSlice, property.mendixStatus);
export const propertiesGet = composeSelectors(propertiesSlice, property.get);
export const propertiesGetAll = composeSelectors(propertiesSlice, property.getAll);
export const propertiesGetOrNull = composeSelectors(propertiesSlice, property.getOrNull);

const choiceSlice = rootSlicer('choice');
export const choiceGet = composeSelectors(choiceSlice, choice.get);
export const choiceGetAll = composeSelectors(choiceSlice, choice.getAll);
export const choiceGetOrNull = composeSelectors(choiceSlice, choice.getOrNull);
export const choiceGetSiblingChoiceForProperty = choice.getSiblingChoiceForProperty;

const applicationSlice = rootSlicer('application');
export const applicationName = composeSelectors(applicationSlice, application.name);
export const studyId = composeSelectors(applicationSlice, application.studyId);
export const assetLibraryId = composeSelectors(applicationSlice, application.assetLibraryId);
export const applicationLoaded = composeSelectors(applicationSlice, application.loaded);
export const applicationPrettyName = composeSelectors(applicationSlice, application.prettyName);
export const applicationCategories = composeSelectors(applicationSlice, application.categories);
export const applicationIsMilicense = composeSelectors(applicationSlice, application.isMilicense);
export const studyTestProjectId = composeSelectors(applicationSlice, application.testProjectId);

const changesSlice = rootSlicer('changes');
export const changesStatus = composeSelectors(changesSlice, changes.status);
export const changesUpdateCount = composeSelectors(changesSlice, changes.updateCount);
export const changesSavedAt = composeSelectors(changesSlice, changes.savedAt);

const assetFiltersSlice = rootSlicer('assetFilters');
export const assetFiltersGet = composeSelectors(assetFiltersSlice, assetFilter.get);

const reportValidationSlice = rootSlicer('reportValidation');
export const reportValidationGet = composeSelectors(reportValidationSlice, reportValidation.get);
export const reportValidationHasError = composeSelectors(
	reportValidationSlice,
	reportValidation.hasError,
);

const uiSlice = rootSlicer('ui');
export const uiIsDependencyGraphOpen = composeSelectors(uiSlice, ui.isDependencyGraphOpen);
export const uiGetSidebarMode = composeSelectors(uiSlice, ui.getSidebarMode);

// -----------------------------------------------------------------------------
// Cross-slice selectors
// -----------------------------------------------------------------------------
export interface PropertyDependency {
	choice: Choice;
	dependentProperties: Property[];
	parentProperty: Property;
}
export const propertyDependencies = (state: State): PropertyDependency[] => {
	const propertyAll = propertiesGetAll(state);
	return Object.values(choiceGetAll(state))
		.filter((choicePotential) => choicePotential.dependentPropertyIds.length > 0)
		.map((choiceConfirmed) => {
			const parentProperty = Object.values(propertyAll).find((propertyPotential) =>
				propertyPotential.choiceIds.includes(choiceConfirmed.id),
			);
			const dependentProperties = choiceConfirmed.dependentPropertyIds.map((dependentPropertyId) =>
				propertiesGet(state, dependentPropertyId),
			);
			return { choice: choiceConfirmed, dependentProperties, parentProperty };
		})
		.filter((propertyDependency) => propertyDependency.parentProperty) as PropertyDependency[];
};
