import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useSelector } from 'react-redux';

import SidePopup from '@/components/SidePopup';
import { applicationProperty, PropsOfRoute } from '@/routes';
import { propertiesGet } from '@/selectors';
import State from '@/store/state';
import { Property } from '@/store/types';

import Dependencies from './Dependencies';
import Question from './Question';

const QuestionPane: React.FC<PropsOfRoute<typeof applicationProperty>> = ({ match }) => {
	const [selectedTab, setSelectedTab] = React.useState<number>(0);

	const { propertyId } = match.params;
	const property = useSelector<State, Property>((state) => propertiesGet(state, propertyId));

	const onTabChange = React.useCallback(
		(event: React.SyntheticEvent, newTab: number) => {
			setSelectedTab(newTab);
		},
		[setSelectedTab],
	);

	return (
		<Box sx={{ bottom: 0, left: 0, position: 'absolute', right: 500, top: 0, width: 500 }}>
			<SidePopup title={property.question || property.name}>
				<Tabs onChange={onTabChange} value={selectedTab} variant="fullWidth">
					<Tab label="Question" />
					<Tab label="Dependencies" />
				</Tabs>
				<Box marginTop={2}>
					{selectedTab === 0 && <Question property={property} />}
					{selectedTab === 1 && <Dependencies question={property} />}
				</Box>
			</SidePopup>
		</Box>
	);
};

export default QuestionPane;
