import { SagaIterator } from '@redux-saga/core';
import * as actions from '@/store/actions';
import { Payload } from '@/store/utils';
import { APP_TITLE } from '@/constants';

// eslint-disable-next-line require-yield
function* setDocumentTitle(title: Payload<typeof actions.routerUpdateTitle>): SagaIterator {
	document.title = `${APP_TITLE}${title}`;
}

function* routerSaga(): SagaIterator {
	yield actions.routerUpdateTitle.takeEvery(setDocumentTitle);
}

export default routerSaga;
