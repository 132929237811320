import React from 'react';

import { PropertyDependency } from '@/selectors';
import { Choice, Property } from '@/store/types';

const useDependencyGraphDOT = (propertyDependencies: PropertyDependency[]): string => {
	const escape = React.useCallback(
		(unescaped: string): string => encodeURIComponent(unescaped),
		[],
	);

	const buildChoice = React.useCallback(
		(choice: Choice) =>
			`C${choice.id.replaceAll('-', '')} [color="#2a3448", label="${
				choice.name
			}", shape=diamond, tooltip="${escape(choice.description || choice.name)}"]`,
		[escape],
	);
	const buildConnectionChoiceProperty = React.useCallback(
		(parentId: string, childId: string) =>
			`C${parentId.replaceAll('-', '')} -> P${childId.replaceAll('-', '')}`,
		[],
	);
	const buildConnectionPropertyChoice = React.useCallback(
		(parentId: string, childId: string) =>
			`P${parentId.replaceAll('-', '')} -> C${childId.replaceAll('-', '')}`,
		[],
	);
	const buildProperty = React.useCallback(
		(property: Property) =>
			`P${property.id.replaceAll('-', '')} [color="#644bff", label="${
				property.name
			}", shape=box, tooltip="${escape(property.question || property.name)}"]`,
		[escape],
	);

	const dot = React.useMemo<string>(() => {
		const dotGraph = propertyDependencies
			.map((propertyDependency) => {
				const parentProperty = buildProperty(propertyDependency.parentProperty);
				const choice = buildChoice(propertyDependency.choice);
				const dependentProperties = propertyDependency.dependentProperties
					.map(buildProperty)
					.join('\n');
				const propertyChoiceConnection = buildConnectionPropertyChoice(
					propertyDependency.parentProperty.id,
					propertyDependency.choice.id,
				);
				const choicePropertyConnections = propertyDependency.dependentProperties
					.map((dependentProperty) =>
						buildConnectionChoiceProperty(propertyDependency.choice.id, dependentProperty.id),
					)
					.join('\n');
				return `${parentProperty}\n${choice}\n${dependentProperties}\n${propertyChoiceConnection}\n${choicePropertyConnections}`;
			})
			.join('\n');
		return `digraph Dependencies {
			edge [arrowsize=0.4, fontname="Barlow,sans-serif", fontsize=12]
			fontname="Barlow,sans-serif"
			node [fontname="Barlow,sans-serif", fontsize=12]
			${dotGraph}
		}`;
	}, [
		buildChoice,
		buildConnectionChoiceProperty,
		buildConnectionPropertyChoice,
		buildProperty,
		propertyDependencies,
	]);

	return dot;
};

export default useDependencyGraphDOT;
