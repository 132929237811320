import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Graphviz } from 'graphviz-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { propertyDependencies as propertyDependenciesSelector } from '@/selectors';

import useDependencyGraphDOT from './buildDotGraph';
import classes from './DependencyGraphDialog.module.css';

interface DependencyGraphDialogProps {
	isOpen: boolean;
	onClose(): void;
}

const DependencyGraphDialog: React.FC<DependencyGraphDialogProps> = ({ isOpen, onClose }) => {
	const propertyDependencies = useSelector(propertyDependenciesSelector);

	const dot = useDependencyGraphDOT(propertyDependencies);

	return (
		<Drawer
			anchor="right"
			onClose={onClose}
			open={isOpen}
			PaperProps={{ elevation: 4 }}
			variant="persistent"
		>
			{/* This `<Toolbar />` is here for spacing reasons */}
			<Toolbar />
			<DialogTitle>
				Property dependencies
				<IconButton onClick={onClose} sx={{ float: 'right' }}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ width: '550px' }}>
				{propertyDependencies.length === 0 && (
					<Alert severity="info">
						Currently there are no dependencies between properties yet. Once dependencies between
						properties have been added they will show up here.
					</Alert>
				)}
				{isOpen && (
					<Graphviz
						className={classes.dependencyGraphContainer}
						dot={dot}
						options={{ fit: false, height: null, useWorker: false, width: null }}
					/>
				)}
			</DialogContent>
		</Drawer>
	);
};

export default DependencyGraphDialog;
