import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import { red, teal } from '@mui/material/colors';

const config: Record<string, string> = {
	// while dragging in a valid position
	draggingBackground: teal[50],
	draggingBorder: `1px solid ${teal[300]}`,
	// while dragging in an invalid position
	draggingOutsideBackground: red[50],
	draggingOutsideBorder: `1px solid ${red[300]}`,
};

/**
 * This function return the default style to pass to a draggable element so that It will be styled consistently
 */
export default function getDraggableStyle(
	provided: DraggableProvided,
	snapshot: DraggableStateSnapshot,
) {
	const isDraggingOutside = snapshot.isDragging && snapshot.draggingOver === null;
	const { isDragging } = snapshot;

	let border;
	let backgroundColor;

	if (isDraggingOutside) {
		backgroundColor = config.draggingOutsideBackground;
		border = config.draggingOutsideBorder;
	} else if (isDragging) {
		backgroundColor = config.draggingBackground;
		border = config.draggingBorder;
	}

	return {
		border,
		backgroundColor,
		...provided.draggableProps.style, // default props from react-beautiful-dnd
	};
}
