/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { StateRouter as State } from '@/store/state';
import * as actions from '../actions';

const initialState: State = {
	path: '',
	params: {},
	pageTitle: '',
	redirectUrl: null,
};

export default makeReducer(initialState, {
	[actions.routerUpdatePath.type]: (draft, payload): void => {
		draft.path = payload.path;
		const match = payload.route.matchPath(payload.path);
		if (!match) {
			throw new Error('Provided route does not match the path');
		}
		draft.params = match.params;
		draft.redirectUrl = null;
	},
	[actions.routerUpdateTitle.type]: (draft, payload): void => {
		draft.pageTitle = payload;
	},
	[actions.routerRedirect.type]: (draft, payload): void => {
		draft.redirectUrl = payload === draft.path ? null : payload;
	},
});
