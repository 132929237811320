import React from 'react';
import { Typography } from '@mui/material';
import * as Styled from './LocationPreview.styles';

const LocationPreview: React.FC = () => (
	<Styled.LocationAnswerContainer>
		<Styled.MapImage alt="location type" src="/images/mapbox-location-type.png" />
		<Typography variant="body2" color="textSecondary">
			Inspectors will be able to select one or more locations
		</Typography>
	</Styled.LocationAnswerContainer>
);

export default LocationPreview;
