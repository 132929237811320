import { SagaIterator } from '@redux-saga/core';
import { takeLatest } from '@redux-saga/core/effects';
import * as actions from '@/store/actions';
import { onChoiceDeleteRequest } from './recursiveFunctions/deletePropertyChoices';
import { onPropertyDeleteRequest } from './recursiveFunctions/propertyDelete';
import { onPropertyUpdateRequest } from './recursiveFunctions/propertyUpdate';

function* recursiveDeleteSaga(): SagaIterator {
	yield takeLatest(actions.choiceDeleteRequest, onChoiceDeleteRequest);
	yield takeLatest(actions.propertyDeleteRequest, onPropertyDeleteRequest);
	yield takeLatest(actions.propertyUpdateRequest, onPropertyUpdateRequest);
}

export default recursiveDeleteSaga;
