export interface Validator<V> {
	(data: V): string | null;
}

export type ValidatorFor<O> = { [K in keyof O]: Validator<O[K]>[] };

export function validateField<V>(validators: Validator<V>[], data: V): string | null {
	// eslint-disable-next-line no-restricted-syntax
	for (const validator of validators) {
		const result = validator(data);
		if (result) {
			return result;
		}
	}
	return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateObject<V extends Record<string, Validator<any>[]>>(
	map: V,
): (input: { readonly [K in keyof V]: Parameters<V[K][number]>[0] }) => {
	[K in keyof V]: string | null;
} {
	return (data): { [K in keyof V]: string | null } => {
		const result = {} as Record<string, string | null>;
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, validators] of Object.entries(map)) {
			result[key] = validateField(validators, data[key]);
		}
		return result as { [K in keyof V]: string | null };
	};
}

export function validateHasErrors(result: Record<string, string | null>): boolean {
	// eslint-disable-next-line no-restricted-syntax
	for (const key in result) {
		if (result[key] !== null) {
			return true;
		}
	}
	return false;
}

// Simple validators:
export const notBlank =
	(message = 'This field should not be blank'): Validator<string | number | null> =>
	(data): string | null => {
		if (data === null || data === '') {
			return message;
		}
		return null;
	};

export const notEmptyString: Validator<string | null> = (data): string | null => {
	if (data === '') {
		return 'This field should not be empty';
	}
	return null;
};

export const maxLength =
	(maximum: number): Validator<string | number | null> =>
	(data): string | null => {
		if (data === null) {
			return null;
		}
		if (`${data}`.length > maximum) {
			return `This should not be longer than ${maximum} characters`;
		}
		return null;
	};

export const minLength =
	(minimum: number): Validator<string | number | null> =>
	(data): string | null => {
		if (data === null) {
			return null;
		}
		if (`${data}`.length < minimum) {
			return `This should be longer than ${minimum} characters`;
		}
		return null;
	};

export const greaterThan =
	(min: number): Validator<number | null> =>
	(data): string | null => {
		if (data === null) {
			return null;
		}
		if (data < min) {
			return `This should be bigger than ${min}`;
		}
		return null;
	};

export const smallerThan =
	(max: number): Validator<number | null> =>
	(data): string | null => {
		if (data === null) {
			return null;
		}
		if (data > max) {
			return `This should be smaller than ${max}`;
		}
		return null;
	};

export const shouldBe =
	<V>(expected: V): Validator<V> =>
	(data): string | null => {
		if (data !== expected) {
			return `This should be ${expected}`;
		}
		return null;
	};

export const shouldNotContain =
	(pattern: string): Validator<string | null> =>
	(data): string | null => {
		if (data === null) {
			return null;
		}
		// eslint-disable-next-line react/destructuring-assignment
		if (data.includes(pattern)) {
			return `This should not contain '${pattern}'`;
		}
		return null;
	};

export const shouldMatchRegexp =
	(pattern: RegExp, message: string): Validator<string | null> =>
	(data): string | null => {
		if (data === null) {
			return null;
		}
		if (!pattern.test(data)) {
			return message;
		}
		return null;
	};
