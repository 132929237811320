import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import React from 'react';

import { Category } from '@/store/types';

import CategoryDeleteDialog from '../CategoryDeleteDialog';

interface DeleteProps {
	category: Category;
	onClose(): void;
}

const Delete: React.FC<DeleteProps> = ({ category, onClose }) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const { enqueueSnackbar } = useSnackbar();

	const deleteDialogClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	const deleteDialogOpen = React.useCallback(() => {
		onClose();
		setIsOpen(true);
	}, [onClose, setIsOpen]);

	const onDelete = React.useCallback(() => {
		deleteDialogClose();
		enqueueSnackbar(`Category '${category.name}' successfully deleted`);
	}, [enqueueSnackbar, deleteDialogClose]);

	const onDeleteError = React.useCallback(() => {
		enqueueSnackbar('There was an issue deleting the category, please try again');
	}, [enqueueSnackbar]);

	return (
		<>
			<MenuItem disabled={category.isDefault} onClick={deleteDialogOpen}>
				<ListItemIcon>
					<DeleteIcon />
				</ListItemIcon>
				<ListItemText primary="Delete" />
			</MenuItem>

			<CategoryDeleteDialog
				category={category}
				isOpen={isOpen}
				onClose={deleteDialogClose}
				onDelete={onDelete}
				onError={onDeleteError}
			/>
		</>
	);
};

export default Delete;
