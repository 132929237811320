import React from 'react';
import useWhenOnline from '@/components/hooks/useWhenOnline';
import { propertyDuplicateRequest } from '@/store/actions';
import State from '@/store/state';
import { Property } from '@/store/types';
import { MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
	item: Property;
	closeMenu: () => void;
}

const DuplicateMenuOption: React.FC<Props> = ({ item, closeMenu }) => {
	const dispatch = useDispatch();
	const studyId = useSelector((state: State) => state.application.id);
	const duplicateProperty = useWhenOnline('duplicate properties', () => {
		dispatch(propertyDuplicateRequest({ property: item, studyId }));
		closeMenu();
	});

	return (
		<MenuItem data-testid="property-context-menu__duplicate-property" onClick={duplicateProperty}>
			Duplicate
		</MenuItem>
	);
};

export default DuplicateMenuOption;
