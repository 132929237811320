import { SagaIterator } from '@redux-saga/types';
import { call, select, takeLatest } from '@redux-saga/core/effects';

import {
	actionDependentPropertyAdd as actionDependentPropertyAddAPI,
	actionDependentPropertyRemove as actionDependentPropertyRemoveAPI,
} from '@/net/api';
import { studyId } from '@/selectors';
import * as actions from '@/store/actions';

function* actionDependentPropertyAdd(
	action: ReturnType<typeof actions.actionDependentPropertyAdd>,
): SagaIterator {
	const activeStudyId = yield select(studyId);
	yield call(
		actionDependentPropertyAddAPI,
		action.payload.propertyId,
		action.payload.actionId,
		activeStudyId,
	);
}

function* actionDependentPropertyRemove(
	action: ReturnType<typeof actions.actionDependentPropertyRemove>,
): SagaIterator {
	const activeStudyId = yield select(studyId);
	yield call(
		actionDependentPropertyRemoveAPI,
		action.payload.propertyId,
		action.payload.actionId,
		activeStudyId,
	);
}

function* actionsSaga(): SagaIterator {
	yield takeLatest(actions.actionDependentPropertyAdd, actionDependentPropertyAdd);
	yield takeLatest(actions.actionDependentPropertyRemove, actionDependentPropertyRemove);
}

export default actionsSaga;
