import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import {
	Breadcrumb,
	Breadcrumbs,
	BreadcrumbTitle,
	ChangesIndicator,
	KESLogo,
	UserMenu,
	useUser,
} from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENVIRONMENT, KES_FRONTEND_URL, TEST_USERS_ENABLED } from '@/constants';
import { validateReport } from '@/net/api';
import { applicationPrettyName, changesSavedAt, changesStatus, studyId } from '@/selectors';
import * as actions from '@/store/actions';
import State, { StateChanges } from '@/store/state';

import ButtonActivityPreview from './ButtonActivityPreview';
import OverflowMenu from './OverflowMenu';

const TopBar: React.FC = (): JSX.Element => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);
	const [isValidating, setIsValidating] = React.useState<boolean>(false);

	const id = useSelector(studyId);
	const appPrettyName = useSelector(applicationPrettyName);
	const statusState = useSelector<State, StateChanges['status']>(changesStatus);
	const savedAt = useSelector<State, Date | null | undefined>(changesSavedAt);

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { signout, user } = useUser();

	const validate = React.useCallback(() => {
		setIsValidating(true);
		return validateReport(id)
			.then((response) => {
				if (response.status === 200) {
					const errors = response.expectSuccess();
					dispatch(actions.reportValidationResult({ result: errors }));
					return errors.length === 0;
				}
				enqueueSnackbar('Could not validate Template');
				return false;
			})
			.catch(() => {
				enqueueSnackbar('Could not validate Template');
				return false;
			})
			.finally(() => {
				setIsValidating(false);
				return false;
			});
	}, [dispatch, enqueueSnackbar, setIsValidating]);

	return (
		<AppBar color="default" sx={{ zIndex: 1201 }}>
			<Toolbar>
				<Link href={KES_FRONTEND_URL}>
					<KESLogo environment={ENVIRONMENT} />
				</Link>
				<Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
					<Breadcrumbs>
						<Breadcrumb href={`${KES_FRONTEND_URL}templates`}>Templates</Breadcrumb>
						<Breadcrumb href={`${KES_FRONTEND_URL}templates/${id}`}>{appPrettyName}</Breadcrumb>
						<BreadcrumbTitle>Builder</BreadcrumbTitle>
					</Breadcrumbs>
					<Box
						gridTemplateColumns="1fr auto auto auto"
						columnGap={1}
						display="grid"
						justifyItems="center"
						alignItems="center"
					>
						<Box mr={2}>
							<ChangesIndicator status={statusState} savedAt={savedAt} />
						</Box>

						<ButtonActivityPreview
							disabled={isValidating}
							onBeforePreview={validate}
							studyId={id}
						/>

						<IconButton onClick={(event) => setAnchorElement(event.currentTarget)}>
							<MoreVertIcon />
						</IconButton>

						<OverflowMenu
							anchorElement={anchorElement}
							isValidating={isValidating}
							onClose={() => setAnchorElement(null)}
							studyId={id}
							validate={validate}
						/>

						<UserMenu
							goToProfile={() => {}}
							signoutEnabled={!TEST_USERS_ENABLED}
							signout={signout}
							userName={user.name ?? user.email}
							isEmail={user.name !== null}
						/>
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default TopBar;
