import Box from '@mui/material/Box';
import { CategoryForm, CategoryFormProps } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import SidePopup from '@/components/SidePopup';
import { PropsOfRoute, application, applicationCategoryUpdate } from '@/routes';
import { categoriesGet, studyId as studyIdSelector } from '@/selectors';
import { categoryUpdate } from '@/store/actions';
import State from '@/store/state';
import { Category } from '@/store/types';

const CategoryUpdate: React.FC<PropsOfRoute<typeof applicationCategoryUpdate>> = ({ match }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const category = useSelector<State, Category>((state) =>
		categoriesGet(state, match.params.categoryId),
	);
	const studyId = useSelector(studyIdSelector);
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = React.useCallback<CategoryFormProps['onSubmit']>(
		async (categoryNew) => {
			dispatch(categoryUpdate(categoryNew));
			history.push(application({ applicationName: studyId }));
			enqueueSnackbar(`Category '${categoryNew.name}' updated successfully`);
		},
		[dispatch, enqueueSnackbar, history, studyId],
	);

	return (
		<Box sx={{ bottom: 0, left: 0, position: 'absolute', right: 500, top: 0, width: 500 }}>
			<SidePopup title="Update Category">
				<CategoryForm
					category={category}
					l10n={{
						actionPrimary: 'Update category',
						fieldInformationHelperText:
							'Informatory text to display at the beginning of the category',
						fieldInformationLabel: 'Information',
						fieldNameErrorRequired: 'Please fill in a name for the Category',
						fieldNameHelperText: 'The name of the category',
						fieldNameLabel: 'Category name',
					}}
					onSubmit={onSubmit}
				/>
			</SidePopup>
		</Box>
	);
};

export default CategoryUpdate;
