import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React from 'react';

import { ApiProperty } from '../../net/swagger';

interface DependencyCombinatorProps {
	l10n: {
		dependencyCombinatorLabelAll: React.ReactNode;
		dependencyCombinatorLabelAny: React.ReactNode;
	};
	onDependencyCombinatorChange(dependencyCombinator: ApiProperty['dependentCombinator']): void;
	question: ApiProperty;
}

const DependencyCombinator: React.FC<DependencyCombinatorProps> = ({
	l10n,
	onDependencyCombinatorChange,
	question,
}) => {
	const onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
		React.useCallback(
			(event) => {
				onDependencyCombinatorChange(event.target.value as ApiProperty['dependentCombinator']);
			},
			[onDependencyCombinatorChange],
		);

	return (
		<TextField
			fullWidth
			inputProps={{ 'data-testid': 'question-dependencies__combinator' }}
			margin="normal"
			onChange={onChange}
			select
			value={question.dependentCombinator}
		>
			<MenuItem value="ANY">{l10n.dependencyCombinatorLabelAny}</MenuItem>
			<MenuItem value="ALL">{l10n.dependencyCombinatorLabelAll}</MenuItem>
		</TextField>
	);
};

export default DependencyCombinator;
