import { ApiActionDto } from 'kes-common';

import makeReducer from '@/store/makeReducer';

import * as actions from '../actions';
import { initialState, makeRepository } from '../repository';

const mapApiToLocal = (action: ApiActionDto): ApiActionDto => action;
const repository = makeRepository<ApiActionDto>();

export default makeReducer(initialState<ApiActionDto>(), {
	[actions.actionDelete.type]: (draft, payload) => {
		repository.delete(draft, payload.actionId);
	},

	[actions.applicationLoadSuccess.type]: (draft, payload) => {
		repository.replaceAll(draft, payload.assetLibrary.actionsById, mapApiToLocal);
	},

	[actions.actionDependentPropertyAdd.type]: (draft, payload) => {
		repository.modify(draft, payload.actionId, (item) => {
			item.dependentPropertyIds.push(payload.propertyId);
		});
	},

	[actions.actionDependentPropertyRemove.type]: (draft, payload) => {
		repository.modify(draft, payload.actionId, (item) => {
			const index = item.dependentPropertyIds.indexOf(payload.propertyId);
			if (index !== -1) {
				item.dependentPropertyIds.splice(index, 1);
			}
		});
	},

	[actions.propertyUpdateRules.type]: (draft, payload) => {
		payload.upsertActions.forEach((action) => {
			if (!draft.byId[action.id]) {
				repository.upsert(draft, {
					check: '',
					dependentPropertyIds: [],
					id: action.id,
					propertyId: payload.propertyId,
					rule: action.ruleId,
				});
			}
		});
		Object.values(draft.byId)
			.filter((action) => action.propertyId === payload.propertyId)
			.forEach((action) => {
				const payloadAction = payload.upsertActions.find(
					(upsertAction) => upsertAction.id === action.id,
				);
				if (!payloadAction) {
					repository.delete(draft, action.id);
				}
			});
	},
});
