import { propertiesGet } from '@/selectors';
import { actionsByPropertyId } from '@/selectors/actions';
import { getReferencedPropertiesForReferent } from '@/selectors/property';
import * as actions from '@/store/actions';
import { Property, PropertyType } from '@/store/types';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { deletePropertyChoices } from './deletePropertyChoices';

// eslint-disable-next-line import/prefer-default-export
export function* onPropertyUpdateRequest(
	action: ReturnType<typeof actions.propertyUpdateRequest>,
): SagaIterator {
	const property: Property = yield select(propertiesGet, action.payload.id);
	if (
		action.payload.type !== PropertyType.SINGLE_SELECT &&
		action.payload.type !== PropertyType.MULTI_SELECT &&
		property.choiceIds.length > 0
	) {
		const payload = { ...action.payload, choiceIds: [] };

		yield call(deletePropertyChoices, property);
		yield put(actions.propertyUpdate(payload));
	} else {
		if (property.type === PropertyType.DECIMAL && action.payload.type !== PropertyType.DECIMAL) {
			const propertyActions = yield select(actionsByPropertyId(property.id));
			// eslint-disable-next-line no-restricted-syntax
			for (const propertyAction of propertyActions) {
				yield put(actions.actionDelete({ actionId: propertyAction.id }));
			}
			yield put(actions.propertyRemoveRules({ propertyId: property.id }));
		}
		const referencingProperties: Property[] = yield select(
			getReferencedPropertiesForReferent,
			property.id,
		);
		if (
			action.payload.type !== PropertyType.DECIMAL &&
			action.payload.type !== PropertyType.STRING
		) {
			// eslint-disable-next-line no-restricted-syntax
			for (const referencingProperty of referencingProperties) {
				yield put(
					actions.propertyRemoveReference({
						propertyId: referencingProperty.id,
					}),
				);
			}
			yield put(actions.propertyUpdate(action.payload));
		} else {
			if (property.type === PropertyType.SINGLE_SELECT) {
				yield put(
					actions.propertyRemoveReference({
						propertyId: property.id,
					}),
				);
			}
			yield put(actions.propertyUpdate(action.payload));
		}
	}
}
