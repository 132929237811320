import React from 'react';

import { ApiUserProfile } from '../../net/swagger';

// This data shape makes no sense
const guestUser: ApiUserProfile = {
	email: 'guest@rhdhv.com',
	hasCreateProjectPrivilege: false,
	isGlobalAdmin: false,
	name: 'Guest',
	user: {
		email: 'guest@rhdhv.com',
		globalAdmin: false,
		id: '0',
		name: 'Guest',
		rhdhvUser: false,
	},
};

interface UserContextValue {
	signout(): void;
	user: ApiUserProfile;
}

const UserContext = React.createContext<UserContextValue>({ signout: () => null, user: guestUser });

export default UserContext;
