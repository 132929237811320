import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { FormikConfig, useFormik } from 'formik';
import React from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';

import { ApiAssetType } from '../../net/swagger';

const create = (
	partialAssetType: Partial<ApiAssetType> & Pick<ApiAssetType, 'name' | 'repeating' | 'type'>,
): ApiAssetType => ({
	assetFilterIds: [],
	fixed: false,
	id: v4(),
	info: null,
	isDefault: false,
	propertyIds: [],
	redFlagPropertyId: null,
	stableId: v4(),
	survey: true,
	...partialAssetType,
});

interface FormValues {
	info: string;
	name: ApiAssetType['name'];
	repeating: ApiAssetType['repeating'];
}

export interface SubCategoryFormProps {
	l10n: {
		actionPrimary: string;
		fieldInformationHelperText: string;
		fieldInformationLabel: string;
		fieldNameErrorRequired: string;
		fieldNameHelperText: string;
		fieldNameLabel: string;
		fieldRepeatingHelperText: string;
		fieldRepeatingLabel: string;
	};
	onSubmit(subCategory: ApiAssetType): Promise<void>;
	subCategory?: ApiAssetType;
}

const SubCategoryForm: React.FC<SubCategoryFormProps> = ({ l10n, onSubmit, subCategory }) => {
	const initialValues = React.useMemo<FormValues>(
		() => ({
			info: subCategory?.info || '',
			name: subCategory?.name || '',
			repeating: subCategory?.repeating || false,
		}),
		[subCategory],
	);

	const onSubmitFormik: FormikConfig<FormValues>['onSubmit'] = React.useCallback(
		async (values, formikHelpers) => {
			formikHelpers.setSubmitting(true);
			if (subCategory && subCategory.id) {
				await onSubmit({ ...subCategory, ...values });
			} else {
				await onSubmit(create({ ...values, type: 'SURVEY' }));
			}
			formikHelpers.setSubmitting(false);
		},
		[onSubmit, subCategory],
	);

	const validationSchema = yup.object({
		info: yup.string(),
		name: yup.string().required(l10n.fieldNameErrorRequired),
		repeating: yup.boolean(),
	});

	const formik = useFormik<FormValues>({
		enableReinitialize: true,
		initialValues,
		onSubmit: onSubmitFormik,
		validationSchema,
	});

	const fieldHasError = React.useCallback(
		(fieldName: keyof FormValues): boolean =>
			Boolean(formik.errors[fieldName] && formik.touched[fieldName]),
		[formik],
	);

	const getFieldHelperText = React.useCallback(
		(fieldName: keyof FormValues, helperText: string): string => {
			if (formik.touched[fieldName] && formik.errors[fieldName]) {
				// Somehow TypeScript is confused here even though I check the value above. Huh.
				return formik.errors[fieldName]!;
			}
			return helperText;
		},
		[formik],
	);

	return (
		<form onSubmit={formik.handleSubmit}>
			<TextField
				error={fieldHasError('name')}
				fullWidth
				helperText={getFieldHelperText('name', l10n.fieldNameHelperText)}
				inputProps={{ 'data-testid': 'sub-category-form__field--name' }}
				label={l10n.fieldNameLabel}
				margin="normal"
				name="name"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				value={formik.values.name}
			/>

			<TextField
				error={fieldHasError('info')}
				fullWidth
				helperText={getFieldHelperText('info', l10n.fieldInformationHelperText)}
				inputProps={{ 'data-testid': 'sub-category-form__field--info' }}
				label={l10n.fieldInformationLabel}
				margin="normal"
				multiline
				name="info"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				rows={4}
				value={formik.values.info}
			/>

			<FormControl margin="normal">
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								checked={formik.values.repeating}
								// Supposedly `daat-testid` (or any `data-*` attribute) cannot be added here
								// @ts-ignore
								inputProps={{ 'data-testid': 'sub-category-form__field--required' }}
								name="repeating"
								onChange={formik.handleChange}
							/>
						}
						label={l10n.fieldRepeatingLabel}
					/>
				</FormGroup>
				<FormHelperText>{l10n.fieldRepeatingHelperText}</FormHelperText>
			</FormControl>

			<Button
				color="primary"
				data-testid="sub-category-form__action--primary"
				disabled={formik.isSubmitting || formik.isValidating}
				fullWidth
				type="submit"
				variant="contained"
			>
				{l10n.actionPrimary}
			</Button>
		</form>
	);
};

export default SubCategoryForm;
