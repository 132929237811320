import React from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import MUIMoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ApiForm } from 'kes-common';
import * as actions from '@/store/actions';
import { applicationForms } from '@/routes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import tinymce from 'tinymce';
import classes from '../Sidebar.module.css';

interface FormContextMenuProps {
	form: ApiForm;
	applicationName: string;
}

const FormContextMenu: React.FC<FormContextMenuProps> = ({ form, applicationName }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [anchor, setAnchor] = React.useState<Element | null>(null);
	const closeMenu = () => setAnchor(null);
	const openMenu = (event: React.MouseEvent<Element>) => setAnchor(event.currentTarget);

	const insertIntoReport = (): void => {
		const text = `<span class="form mceNonEditable">Tables|${form.name}</span>`;
		tinymce.activeEditor.insertContent(text);
	};

	const deleteForm = React.useCallback(() => {
		dispatch(actions.formsDelete({ formId: form.id, studyId: applicationName }));
		history.push(applicationForms({ applicationName }));
	}, [applicationName, form]);

	return (
		<Box gridArea="insert" display="flex" alignItems="center" justifyContent="center">
			<MUIMoreHorizIcon
				className={classes.contextMenuButton}
				data-testid="form-context-menu__button"
				onClick={openMenu}
			/>

			<Menu
				anchorEl={anchor}
				data-testid="form-context-menu__menu"
				keepMounted
				onClose={closeMenu}
				open={Boolean(anchor)}
			>
				<div>
					<MenuItem data-testid="form-context-menu__insert-into-report" onClick={insertIntoReport}>
						Insert in template
					</MenuItem>
				</div>
				<div>
					<MenuItem data-testid="form-context-menu__delete" onClick={deleteForm}>
						Delete
					</MenuItem>
				</div>
			</Menu>
		</Box>
	);
};

export default FormContextMenu;
