import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { applicationPrettyName } from '@/selectors';
import { useSelector } from 'react-redux';
import State, { StateAsset } from '@/store/state';
import { Asset, Category, Property } from '@/store/types';
import { ApiForm } from 'kes-common';
import { getParentAssetsForProperties } from '@/selectors/assets';
import { getCategoryForAssetId } from '@/selectors/category';
import { State as RepositoryState } from '@/store/repository';
import { TITLE_PREFIX } from '@/constants';

const FULL_TITLE_PREFIX = `${TITLE_PREFIX} Studio | `;

type TitleType = 'asset' | 'property' | 'forms' | 'formId' | 'application';

const setDocumentTitle = (title: string) => {
	window.document.title = title;
};

const useTitleChange = () => {
	const history = useHistory();
	const appPrettyName = useSelector(applicationPrettyName);
	const assets = useSelector<State, StateAsset>((state) => state.assets);
	const categories = useSelector<State, RepositoryState<Category>>((state) => state.categories);
	const assetMatch = useRouteMatch<{ applicationName: string; assetId: string }>(
		'/:applicationName/asset/:assetId',
	);
	const propertyMatch = useRouteMatch<{ applicationName: string; propertyId: string }>(
		'/:applicationName/property/:propertyId',
	);
	const formIdMatch = useRouteMatch<{ applicationName: string; formId: string }>(
		'/:applicationName/forms/:formId',
	);

	const formsMatch = useRouteMatch<{ applicationName: string }>('/:applicationName/forms');

	const asset = useSelector<State, Asset | undefined>((state) => {
		if (
			assetMatch &&
			assetMatch.params.assetId !== 'create' &&
			Object.keys(state.assets.byId).length > 0
		) {
			return state.assets.byId[assetMatch?.params?.assetId];
		}
		return undefined;
	});

	const property = useSelector<State, Property | undefined>((state) => {
		if (
			propertyMatch &&
			propertyMatch.params.propertyId !== 'create' &&
			Object.keys(state.properties.byId).length > 0
		) {
			return state.properties.byId[propertyMatch?.params?.propertyId];
		}
		return undefined;
	});

	const form = useSelector<State, ApiForm | undefined>((state) => {
		if (formIdMatch && formIdMatch.isExact && Object.keys(state.forms.byId).length > 0) {
			return state.forms.byId[formIdMatch?.params.formId];
		}
		return undefined;
	});

	const composeTitle = (type: TitleType) => {
		switch (type) {
			case 'asset': {
				const category = getCategoryForAssetId(categories, asset?.id);
				return `${FULL_TITLE_PREFIX}${appPrettyName} | ${category?.name} | ${asset?.name}`;
			}
			case 'property': {
				// Sadly when having a property in the url you don't have the asset id so you have to get it manually
				const parentAssets = getParentAssetsForProperties(assets, property ? [property] : []);
				const category = getCategoryForAssetId(categories, parentAssets[0]);
				if (parentAssets.length > 0) {
					const parentAsset = assets.byId[parentAssets[0]];
					return `${FULL_TITLE_PREFIX}${appPrettyName} | ${category?.name} | ${parentAsset?.name} | ${property?.name}`;
				}
				return `${FULL_TITLE_PREFIX}${appPrettyName} | ${category?.name} | ${asset?.name} | ${property?.name}`;
			}
			case 'forms': {
				return `${FULL_TITLE_PREFIX}${appPrettyName} | Tables`;
			}
			case 'formId': {
				return `${FULL_TITLE_PREFIX}${appPrettyName} | Tables | ${form?.name}`;
			}
			default: {
				return `${FULL_TITLE_PREFIX}${appPrettyName}`;
			}
		}
	};

	useEffect(() => {
		let type: TitleType = 'application';

		// decides match that's happening
		if (assetMatch && assetMatch.isExact) {
			type = 'asset';
		} else if (propertyMatch && propertyMatch.isExact) {
			type = 'property';
		} else if (formsMatch && formsMatch.isExact) {
			type = 'forms';
		} else if (formIdMatch && formIdMatch.isExact) {
			type = 'formId';
		}
		setDocumentTitle(composeTitle(type));
	}, [history.location.pathname, appPrettyName]);
};

export default useTitleChange;
