import React from 'react';
import { ChoicesPanel } from './ChoicesPanel';
import QuestionPanel from './QuestionPanel';

interface QuestionSetupProps {
	propertyId: string;
}

const QuestionSetup: React.FC<QuestionSetupProps> = ({ propertyId }) => (
	<>
		<QuestionPanel propertyId={propertyId} />
		<ChoicesPanel propertyId={propertyId} />
	</>
);

export default QuestionSetup;
