import styled from 'styled-components';
import { Link } from '@mui/material';

const ExternalLink = styled(Link)`
	font-weight: 600;
	width: 100%;
	color: ${({ theme }) => theme.palette.primary.light};
	margin: 8px 0;
	text-decoration: none;
	font-size: 16px;
	line-height: 31px;
	text-transform: uppercase;
	&&:hover {
		text-decoration: none;
		text-underline: none;
	}
`;

export default ExternalLink;
