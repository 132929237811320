import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { assetTypeDelete } from '@/net/api';
import { application } from '@/routes';
import { studyId as studyIdSelector } from '@/selectors';
import { assetDelete } from '@/store/actions';
import { Asset } from '@/store/types';

interface SubCategoryDeleteDialogProps {
	isOpen: boolean;
	onClose(): void;
	onDelete(): void;
	onError(): void;
	subCategory: Asset;
}

const SubCategoryDeleteDialog: React.FC<SubCategoryDeleteDialogProps> = ({
	isOpen,
	onClose,
	onDelete,
	onError,
	subCategory,
}) => {
	const studyId = useSelector(studyIdSelector);

	const dispatch = useDispatch();
	const history = useHistory();

	const onSuccess = React.useCallback(() => {
		dispatch(assetDelete(subCategory));
		onDelete();
		history.push(application({ applicationName: studyId }));
	}, [dispatch, history, onDelete, studyId, subCategory]);

	const categoryDeleteMutation = useMutation(
		['sub-category', subCategory.id, 'delete'],
		() => assetTypeDelete(subCategory.id, studyId).then((response) => response.expectSuccess()),
		{ onError, onSuccess },
	);

	const handleDelete = React.useCallback(() => {
		categoryDeleteMutation.mutate();
	}, [categoryDeleteMutation]);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Delete sub-category &quot;{subCategory.name}&quot;</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you want to delete the sub-category &quot;{subCategory.name}&quot;?
				</Typography>
				<Typography>
					This will also delete all questions which fall underneath this sub-category.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button color="primary" onClick={handleDelete} variant="contained">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SubCategoryDeleteDialog;
