import MUICardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const CardContent = styled(MUICardContent)`
	&& {
		padding-top: ${({ theme }) => theme.spacing(5)}px;

		&:last-child {
			padding-bottom: ${({ theme }) => theme.spacing(2)};
		}
	}
`;
