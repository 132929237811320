import Alert from '@mui/material/Alert';
import Select from '@/components/rhdhv/Select';
import { propertiesGet } from '@/selectors';
import { getPropertyByChoiceId } from '@/selectors/property';
import { propertyUpdate as propertyUpdateAction } from '@/store/actions';
import State from '@/store/state';
import { Property, PropertyDependentCombinator, PropertyType } from '@/store/types';
import { ActionDispatcher } from '@/store/utils';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';
import NotApplicableOptionButton from '@/pages/application/ReportPropertyUpdate/NotApplicableOptionButton';
import UploadBox from '@/components/rhdhv/UploadBox';
import { DynamicNullableTextField } from '@/components/rhdhv/TextField/DynamicTextField';
import Box from '@mui/material/Box';
import LocationPreview from '../../../LocationPreview';
import DependentPropertiesChip from '../../../DependentPropertiesChip';
import { propertyHasChoices } from '../ChoicesPanel';
import ParentActionChip from './ParentActionChip';

interface DependentSelectItemProps {
	item: PropertyDependentCombinator;
}

const DependentSelectItem: FC<DependentSelectItemProps> = ({ item }): JSX.Element => (
	<span>{item.toLocaleLowerCase()}</span>
);

/**
 * This component is responsible for rendering the question properties
 */
interface QuestionPanelProps {
	property: Property;
	propertyUpdate: ActionDispatcher<typeof propertyUpdateAction>;
	state: State;
}

const QuestionPanel = connect(
	(
		state: State,
		ownProps: { propertyId: Property['id'] },
	): Pick<QuestionPanelProps, 'property' | 'state'> => {
		const property = propertiesGet(state, ownProps.propertyId);
		return {
			property,
			state,
		};
	},
	{
		propertyUpdate: propertyUpdateAction,
	},
)(
	({ property, propertyUpdate, state }: QuestionPanelProps): JSX.Element => (
		<div data-testid="question-panel">
			<div className={classes.parentChoices}>
				{property.parentChoiceIds.length + property.parentActionIds.length > 1 && (
					<div className={classes.parentChoicesTitle}>
						<span className={classes.parentChoicesTitleText}>Show the question after</span>
						<Select
							selectClassName={classes.dependentSelect}
							selectTextClassName={classes.dependentSelectText}
							value={property.dependentCombinator}
							render={DependentSelectItem}
							onChange={(value: PropertyDependentCombinator): void => {
								propertyUpdate({
									...property,
									dependentCombinator: value,
								});
							}}
							items={[PropertyDependentCombinator.ANY, PropertyDependentCombinator.ALL]}
						/>
						<span className={classes.parentChoicesTitleText}>
							of the following questions/rules have been selected/fulfilled:
						</span>
					</div>
				)}
				{property.parentChoiceIds.map((id) => (
					<div key={id} className={classes.parentChoicesItems}>
						<DependentPropertiesChip
							className={classes.parentChoicesChip}
							propertyId={property.id}
							selectedPropertyId={getPropertyByChoiceId(state, id).id}
							choiceId={id}
							showChoice
						/>
					</div>
				))}

				{property.parentActionIds.map((id) => (
					<div className={classes.parentChoicesItems} key={id}>
						<ParentActionChip
							actionId={id}
							className={classes.parentChoicesChip}
							propertyId={property.id}
						/>
					</div>
				))}
			</div>
			<div className={classes.questionTitle}>
				<DynamicNullableTextField
					data-testid="textfield-question-name"
					className={classes.formControl}
					InputProps={{ classes: { root: classes.questionRoot } }}
					label="Question"
					value={property}
					update={(e) => {
						propertyUpdate(e);
						return null;
					}}
					name="question"
					placeholder="Question"
					fullWidth
					variant="standard"
				/>
			</div>
			<div className={classes.questionHelpText}>
				<DynamicNullableTextField
					data-testid="textfield-question-explanation"
					className={classes.formControl}
					InputProps={{ classes: { root: classes.propertyDescriptionRoot } }}
					InputLabelProps={{ classes: { root: classes.propertyDescriptionRoot } }}
					label="Explanation"
					name="description"
					value={property}
					update={(e) => {
						propertyUpdate(e);
						return null;
					}}
					multiline
					fullWidth
					variant="standard"
				/>
			</div>
			{property.type === PropertyType.IMAGE && (
				<>
					<Box mb={2}>
						<UploadBox />
						<DynamicNullableTextField
							data-testid="image-caption-text"
							className={`${classes.formControl} ${classes.questionImageCaption}`}
							InputProps={{ classes: { root: classes.propertyDescriptionRoot } }}
							InputLabelProps={{ classes: { root: classes.propertyDescriptionRoot } }}
							label="Image caption"
							name="caption"
							value={property}
							update={(e) => {
								propertyUpdate(e);
								return null;
							}}
							multiline
							fullWidth
							variant="standard"
						/>
					</Box>

					<Box marginBottom={2}>
						<DynamicNullableTextField
							data-testid="image__width"
							fullWidth
							label="Width"
							name="width"
							update={(event) => {
								propertyUpdate(event);
								return null;
							}}
							value={property}
							variant="standard"
						/>
					</Box>

					<Box marginBottom={2}>
						<DynamicNullableTextField
							data-testid="image__height"
							fullWidth
							label="Height"
							name="height"
							update={(event) => {
								propertyUpdate(event);
								return null;
							}}
							value={property}
							variant="standard"
						/>
					</Box>

					<Box marginBottom={2}>
						<Alert severity="info">
							Setting both the <em>width</em> and <em>height</em> may result in some image not
							maintaining their aspect ratios. It is thus preferred to only set one or the other.
						</Alert>
					</Box>
				</>
			)}
			{!propertyHasChoices(property.type) && (
				<div className={classes.choicesAddOption} key="addOption">
					<NotApplicableOptionButton propertyId={property.id} />
				</div>
			)}
			{property.type === PropertyType.LOCATIONS && <LocationPreview />}
		</div>
	),
);

export default QuestionPanel;
