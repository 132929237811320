import { useAccessTokenFactory } from 'kes-common';

import auth0Client from '@/authentication/auth0';
import msalInstance from '@/authentication/msal';
import { MSAL_CLIENT_ID, TEST_USERS_ENABLED } from '@/constants';

export default useAccessTokenFactory({
	auth0Client,
	hasTestUsersEnabled: TEST_USERS_ENABLED,
	msalClientId: MSAL_CLIENT_ID,
	msalInstance,
});
