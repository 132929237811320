import { Menu, MenuItem } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React from 'react';

import classes from './SubMenu.module.css';

interface Props {
	children: React.ReactNode;
	'data-testid'?: string;
	text: string;
}

const SubMenu: React.FC<Props> = ({ children, 'data-testid': dataTestid, text }) => {
	const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
	const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchor(event.currentTarget);
	const closeMenu = () => setAnchor(null);

	return (
		<div data-testid={dataTestid} onMouseLeave={closeMenu} onMouseEnter={openMenu}>
			<MenuItem>
				{text}
				<ArrowRightIcon />
			</MenuItem>
			<Menu
				autoFocus={false}
				disableAutoFocus
				disableEnforceFocus
				anchorEl={anchor}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				keepMounted
				PopoverClasses={{
					paper: classes.popoverPaper,
					root: classes.popoverRoot,
				}}
				onClose={closeMenu}
				open={Boolean(anchor)}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				{children}
			</Menu>
		</div>
	);
};

export default SubMenu;
