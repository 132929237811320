import React, { ComponentType, useLayoutEffect, PropsWithChildren } from 'react';
import nameHoc from '@/utils/nameHoc';

interface SetTitle {
	(title: string): void;
}

const withTitle =
	(
		title: string,
	): (<P>(Component: ComponentType<P>) => ComponentType<P & { onTitleUpdate: SetTitle }>) =>
	<P,>(Component: ComponentType<P>): ComponentType<P & { onTitleUpdate: SetTitle }> => {
		function WithTitle(props: PropsWithChildren<P & { onTitleUpdate: SetTitle }>): JSX.Element {
			const { onTitleUpdate, ...rest } = props;
			useLayoutEffect((): void => {
				onTitleUpdate(title);
			}, [onTitleUpdate]);
			// TypeScript does not like the rest parameter in destructuring, we need to cast it
			// @ts-ignore
			return <Component {...(rest as P)} />;
		}
		return nameHoc(WithTitle, Component);
	};

export default withTitle;
