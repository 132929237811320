import Box from '@mui/material/Box';
import { SubCategoryForm, SubCategoryFormProps } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import SidePopup from '@/components/SidePopup';
import { PropsOfRoute, application, applicationSubCategoryUpdate } from '@/routes';
import { assetsGet, studyId as studyIdSelector } from '@/selectors';
import { assetUpdate, reportUpdateAssetName } from '@/store/actions';
import State from '@/store/state';
import { Asset } from '@/store/types';

const SubCategoryUpdate: React.FC<PropsOfRoute<typeof applicationSubCategoryUpdate>> = ({
	match,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const studyId = useSelector(studyIdSelector);
	const subCategory = useSelector<State, Asset>((state) =>
		assetsGet(state, match.params.subCategoryId),
	);
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = React.useCallback<SubCategoryFormProps['onSubmit']>(
		async (updatedSubCategory) => {
			dispatch(assetUpdate(updatedSubCategory));
			if (updatedSubCategory.name !== subCategory.name) {
				dispatch(
					reportUpdateAssetName({
						assetNameNew: updatedSubCategory.name,
						assetNameOld: subCategory.name,
					}),
				);
			}
			history.push(application({ applicationName: studyId }));
			enqueueSnackbar(`Sub-category '${updatedSubCategory.name}' updated successfully`);
		},
		[dispatch, enqueueSnackbar, history, studyId, subCategory],
	);

	return (
		<Box sx={{ bottom: 0, left: 0, position: 'absolute', right: 500, top: 0, width: 500 }}>
			<SidePopup title="Update Sub-category">
				<SubCategoryForm
					l10n={{
						actionPrimary: 'Update sub-category',
						fieldInformationHelperText:
							'This block of text will display in the activity, guiding the user through all questions within this sub-category',
						fieldInformationLabel: 'Info',
						fieldNameErrorRequired: 'Please fill in a name for the sub-category',
						fieldNameHelperText: 'Enter a name for the sub-category',
						fieldNameLabel: 'Sub-category name',
						fieldRepeatingHelperText:
							'A repeating sub-category can be added to a template, and will be rendered as a table in the resulting report.',
						fieldRepeatingLabel: 'Repeating',
					}}
					onSubmit={onSubmit}
					subCategory={subCategory}
				/>
			</SidePopup>
		</Box>
	);
};

export default SubCategoryUpdate;
