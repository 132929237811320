import React, { FC, ReactNode } from 'react';

interface Props {
	text: string;
}

const TextWithBreaks: FC<Props> = ({ text }): JSX.Element => {
	const nodes: ReactNode[] = [];
	// eslint-disable-next-line no-restricted-syntax
	for (const line of text.split('\n')) {
		nodes.push(line);
		nodes.push(<br key={nodes.length} />);
	}
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{nodes}</>;
};

export default TextWithBreaks;
