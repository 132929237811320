import { v4 as uuidv4 } from 'uuid';
import { ValidatorFor, validateObject, notBlank, maxLength } from '@/utils/validate';
import { Asset } from '@/store/types';
import { MAX_VALUE_LENGTH } from '@/constants';
import State from '@/store/state';
import { assetsGet, assetsAllIds } from '@/selectors';
import { noForbiddenTemplateCharacters } from 'kes-common';

export function create(
	// eslint-disable-next-line @typescript-eslint/default-param-last
	id: Asset['id'] = uuidv4(),
	name: string,
	repeating: boolean = false,
	type: 'FLAG' | 'FORM' | 'SURVEY' = 'SURVEY',
): Asset {
	return {
		id,
		isDefault: false,
		info: null,
		stableId: uuidv4(),
		name,
		repeating,
		propertyIds: [],
		fixed: false,
		redFlagPropertyId: null,
		survey: true,
		assetFilterIds: [],
		type,
	};
}

const simpleValidate = validateObject<ValidatorFor<Asset>>({
	id: [],
	isDefault: [],
	info: [],
	stableId: [],
	name: [
		notBlank('Please type an asset name'),
		maxLength(MAX_VALUE_LENGTH),
		noForbiddenTemplateCharacters(),
	],
	repeating: [],
	propertyIds: [],
	fixed: [],
	redFlagPropertyId: [],
	survey: [],
	assetFilterIds: [],
	type: [],
});

export const validate = (
	currentAsset: Asset,
	store: { getState(): State },
): ReturnType<typeof simpleValidate> => {
	let result = simpleValidate(currentAsset);
	const state = store.getState();
	const assets = assetsAllIds(state).map((id): Asset => assetsGet(state, id));
	// eslint-disable-next-line no-restricted-syntax
	for (const asset of assets) {
		if (currentAsset.id !== asset.id) {
			if (currentAsset.name === asset.name) {
				result = { ...result, name: 'This name is already in use' };
				break;
			}
		}
	}
	return result;
};
