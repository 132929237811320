import React, { ComponentType, ComponentProps } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function withMap<K extends string, M extends Record<string, ComponentType<any>>>(
	key: K,
	map: M,
): ComponentType<ComponentProps<M[keyof M]> & Record<K, keyof M>> {
	function WithMap({
		[key]: keyValue,
		...rest
	}: ComponentProps<M[keyof M]> & Record<K, keyof M>): JSX.Element {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const Component = map[keyValue] as any;
		if (!Component) {
			throw new Error(`Invalid '${key}' passed: ${keyValue} is not present in ${Object.keys(map)}`);
		}
		return <Component {...rest} />;
	}
	return WithMap;
}
