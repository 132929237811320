import React from 'react';
import Plus from '@mui/icons-material/ControlPoint';
import LinkContext from '@/components/LinkContext';
import * as routes from '@/routes';
import { useSelector } from 'react-redux';
import State from '@/store/state';
import { Category } from '@/store/types';
import { PropertySelectionMode } from '@/store/reducers/properties';

import classes from './AddNewAsset.module.css';

interface AddNewAssetProps {
	category: Category;
	onCreateNewAsset: () => void;
}

const AddNewAsset: React.FC<AddNewAssetProps> = ({ category, onCreateNewAsset }) => {
	const selectionMode = useSelector<State, PropertySelectionMode>(
		(state) => state.properties.selectionMode,
	);

	if (category.id && selectionMode === PropertySelectionMode.None) {
		return (
			<LinkContext
				data-testid="actions-add-new-asset"
				onClick={onCreateNewAsset}
				to={routes.applicationAssetCreate}
				params={{
					applicationName: null,
					categoryId: category.id,
				}}
				className={classes.assetContainer}
			>
				<Plus fontSize="small" className={classes.icon} />{' '}
				<span>{category.type === 'FLAG' ? 'Add flag' : 'Add asset'}</span>
			</LinkContext>
		);
	}
	return null;
};

export default AddNewAsset;
