import { Action, AnyAction } from 'redux';
import { SagaIterator } from '@redux-saga/core';
import { takeEvery, ForkEffect, fork } from 'redux-saga/effects';
import { Draft as ImmerDraft, produce } from 'immer';
import { defaultMemoize } from 'reselect';

export interface StoreAction extends Action<string> {
	// Use any payload here, as the actual actions differ between the types
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: any;
}

export type TypeConstant = string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Payload<A extends ActionCreator<any, any>> = A extends ActionCreator<infer P, any>
	? P
	: never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionDispatcher<A extends ActionCreator<any, any>> =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	A extends ActionCreator<void, any> ? (payload?: void) => void : (payload: Payload<A>) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionDispatchers<M extends { [key: string]: ActionCreator<any, any> }> = {
	[K in keyof M]: ActionDispatcher<M[K]>;
};

export interface SafeAction<P, T> {
	type: T;
	payload: P;
}

export interface ActionCreator<P, T extends TypeConstant> {
	(payload: P): SafeAction<P, T>;
	type: T;
	toString(): string;
	makeHandlerWithKey<S, K extends keyof P>(
		handler: ActionHandler<S, P[K]>,
		key: K,
	): TypedActionHandler<S, P, T>;
	makeDraftedHandler<S>(handler: DraftHandler<S, P>): TypedActionHandler<S, P, T>;
	makeHandler<S>(handler: ActionHandler<S, P>): TypedActionHandler<S, P, T>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	takeEvery(
		callable: (payload: P, ...args: any[]) => SagaIterator | void,
		...args: any[]
	): ForkEffect;
	isInstanceOf(other: AnyAction): other is SafeAction<P, T>;
}

export function makeAction<P = void>(): <T extends TypeConstant>(type: T) => ActionCreator<P, T> {
	return <T extends TypeConstant>(type: T): ActionCreator<P, T> => {
		function executeAction(payload: P): SafeAction<P, T> {
			return {
				type,
				payload,
			};
		}
		executeAction.type = type;

		// RdJ This is not entirely type safe because ...args is not correponding to the signature of callable.
		// I suspect this is a typescript regression, let's see what happens after 4.0.0
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		executeAction.takeEvery = (
			callable: (payload: P, ...args: any[]) => SagaIterator,
			...args: any[]
		): ForkEffect =>
			// eslint-disable-next-line func-names
			takeEvery(executeAction, function* (action: SafeAction<P, T>): SagaIterator {
				yield fork(callable, action.payload, ...args);
			});

		executeAction.toString = (): string => type;
		executeAction.makeHandlerWithKey = <S, K extends keyof P>(
			handler: ActionHandler<S, P[K]>,
			key: K,
		): TypedActionHandler<S, P, T> => {
			const func = (s: S, payload: P): S => handler(s, payload[key]);
			func.type = type;
			return func;
		};
		executeAction.makeHandler = <S>(handler: ActionHandler<S, P>): TypedActionHandler<S, P, T> => {
			const func = (s: S, payload: P): S => handler(s, payload);
			func.type = type;
			return func;
		};
		executeAction.makeDraftedHandler = <S>(
			handler: DraftHandler<S, P>,
		): TypedActionHandler<S, P, T> => {
			const func = (s: S, payload: P): S => produce(s, (draft): void => handler(draft, payload));
			func.type = type;
			return func;
		};
		executeAction.isInstanceOf = (other: AnyAction): other is SafeAction<P, T> =>
			other.type === type;
		return executeAction;
	};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionListener<A> = (arg: any) => A;

export function takeEveryAction<
	L extends ActionListener<A> | ActionListener<A>[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	A extends { type: string; payload: any },
>(
	action: L,
	callable: (payload: A['payload'], ...callableArgs: any[]) => SagaIterator,
	...args: any[]
): ForkEffect {
	// eslint-disable-next-line func-names
	return takeEvery(action, function* (actionInner: A): SagaIterator {
		yield fork(callable, actionInner.payload, ...args);
	});
}

export type DraftHandler<S, P> = (state: Draft<S>, payload: P) => void;

export type ActionHandler<S, P> = (state: S, payload: P) => S;

export interface TypedActionHandler<S, P, T extends TypeConstant> {
	(state: S, payload: P): S;
	type: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function composeSelectors<A, B, C, D extends any[]>(
	root: (rootState: A) => B,
	subSelector: (substate: B, ...args: D) => C,
): (state: A, ...args: D) => C {
	const modifiedSubSelector = subSelector.length === 1 ? defaultMemoize(subSelector) : subSelector;
	return (state: A, ...args: D): C => modifiedSubSelector(root(state), ...args);
}

export type Writeable<T> = { -readonly [P in keyof T]-?: T[P] };
export type DeeplyWriteable<T> = {
	-readonly [P in keyof T]-?: T[P] extends Record<string, unknown> ? DeeplyWriteable<T[P]> : T[P];
};

export type Draft<T> = ImmerDraft<T>;

export type ExcludedKeys<T, U> = { [P in keyof T]: T[P] extends U ? never : P }[keyof T];
export type FilteredKeys<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];

// Makes all values partial, without making objects partial, so its still esy to access said properties
export type PartialValues<T> = {
	[Q in FilteredKeys<T, Record<string, unknown>>]-?: PartialValues<T[Q]>;
} & {
	[Q in ExcludedKeys<T, Record<string, unknown>>]+?: T[Q];
};

// Unwraps a function returning a promise into the raw type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Result<T extends (...args: any[]) => Promise<any>> =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends (...args: any[]) => Promise<infer R> ? R : never;

export const generateUniqueEntityName = (names: string[], identifier: string): string => {
	const nameMatcher = new RegExp(`^${identifier}([0-9]+)$`);
	const maximum = names
		.map((name) => nameMatcher.exec(name))
		.filter((regexResult) => regexResult !== null)
		.map((regexResult) => regexResult![1])
		.map((positionIndex) => parseInt(positionIndex, 10))
		.reduce((accumulator, position) => (position > accumulator ? position : accumulator), 0);
	return `${identifier}${maximum + 1}`;
};
