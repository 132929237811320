import Menu, { MenuProps } from '@mui/material/Menu';
import React from 'react';

import { Asset } from '@/store/types';

import Delete from './Delete';
import InsertIntoTemplate from './InsertIntoTemplate';

interface SubCategoryContextMenuProps {
	anchorElement: MenuProps['anchorEl'];
	onClose(): void;
	subCategory: Asset;
}

const SubCategoryContextMenu: React.FC<SubCategoryContextMenuProps> = ({
	anchorElement,
	onClose,
	subCategory,
}) => (
	<Menu anchorEl={anchorElement} keepMounted onClose={onClose} open={Boolean(anchorElement)}>
		<InsertIntoTemplate onClose={onClose} subCategory={subCategory} />
		<Delete onClose={onClose} subCategory={subCategory} />
	</Menu>
);

export default SubCategoryContextMenu;
