import { call, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';

import * as actions from '@/store/actions';

function* uiSidebarModeToggle(
	action: ReturnType<typeof actions.uiSidebarModeToggle>,
): SagaIterator {
	yield call([localStorage, () => localStorage.setItem('SIDEBAR_MODE', action.payload)]);
}

function* uiSaga(): SagaIterator {
	yield takeLatest(actions.uiSidebarModeToggle, uiSidebarModeToggle);
}

export default uiSaga;
