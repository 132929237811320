import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React from 'react';

import * as Styled from './AddButton.styled';

interface AddButtonProps {
	alwaysHidden?: boolean;
	onClick: IconButtonProps['onClick'];
}

const AddButton: React.FC<AddButtonProps> = ({ alwaysHidden = false, onClick }) => (
	<Styled.Container alwaysHidden={alwaysHidden}>
		<Styled.VisibilityContainer>
			<Styled.LineLeft />
			<IconButton color="primary" onClick={onClick} size="large">
				<AddCircleOutlineIcon />
			</IconButton>
			<Styled.LineRight />
		</Styled.VisibilityContainer>
	</Styled.Container>
);

export default AddButton;
