import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ApiField, ApiFieldChoice, ApiForm, ApiStudyDto } from 'kes-common';
import { debounce } from 'lodash';
import React from 'react';
import { v4 as uuid } from 'uuid';

import * as actions from '@/store/actions';
import { useDispatch } from 'react-redux';

interface FieldChoicesProps {
	field: ApiField;
	formId: ApiForm['id'];
	studyId: ApiStudyDto['id'];
}

const FieldChoices: React.FC<FieldChoicesProps> = ({ field, formId, studyId }) => {
	const dispatch = useDispatch();
	const addChoice = React.useCallback(() => {
		dispatch(
			actions.formsFieldChoiceAdd({
				fieldId: field.id,
				formId,
				id: uuid(),
				position: 0,
				studyId,
			}),
		);
	}, [field, formId, studyId]);
	const removeChoice = React.useCallback(
		(choiceId: ApiFieldChoice['id']) => {
			dispatch(actions.formsFieldChoiceRemove({ choiceId, fieldId: field.id, formId, studyId }));
		},
		[field, formId, studyId],
	);
	const updateChoice = React.useCallback(
		debounce((choice: ApiFieldChoice) => {
			dispatch(
				actions.formsFieldChoiceUpdate({
					fieldId: field.id,
					formId,
					studyId,
					...choice,
				}),
			);
		}, 500),
		[field, formId, studyId],
	);

	return (
		<Box marginLeft={3} marginTop={1}>
			{field.choices.map((choice) => (
				<Grid container key={choice.id}>
					<Grid item xs={1}>
						<Box alignItems="center" display="flex" textAlign="center">
							{field.type === 'MULTIPLE_SELECT' && <Checkbox checked={false} />}
							{field.type === 'SINGLE_SELECT' && <Radio checked={false} />}
						</Box>
					</Grid>
					<Grid alignItems="center" container item xs={10}>
						<TextField
							defaultValue={choice.name}
							fullWidth
							name="choice[]"
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								updateChoice({ ...choice, name: event.target.value });
							}}
						/>
					</Grid>
					<Grid item xs={1}>
						<Box alignItems="center" display="flex" textAlign="center">
							<IconButton onClick={() => removeChoice(choice.id)} size="large">
								<CloseIcon />
							</IconButton>
						</Box>
					</Grid>
				</Grid>
			))}
			<Box marginLeft={1}>
				<Button onClick={addChoice} size="small" startIcon={<AddCircleOutlineIcon />}>
					Add option
				</Button>
			</Box>
		</Box>
	);
};

export default FieldChoices;
