import { useRef, useEffect } from 'react';

// Adapted from https://usehooks.com/usePrevious/
const usePrevious = <T>(value: T): T | undefined => {
	const ref = useRef<T | undefined>(undefined);

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
};

export default usePrevious;
