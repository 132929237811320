import React from 'react';
import { DistributiveOmit } from '@mui/types';
import MRadioButton, { RadioProps } from '@mui/material/Radio';
import classNames from 'classnames';
import classes from './RadioButton.module.css';

interface Props extends DistributiveOmit<RadioProps, 'color'> {
	color?: 'primary' | 'red' | 'orange' | 'green';
}

const RadioButton: React.FC<Props> = ({ color = 'primary', className, ...newProps }) => {
	const newClassName = classNames(classes.radioChecked, classes[color], className);

	return (
		<MRadioButton {...newProps} className={classes.button} classes={{ checked: newClassName }} />
	);
};

export default RadioButton;
