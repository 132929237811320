import * as actions from '@/store/actions';

import { choiceGet, propertiesGet } from '@/selectors';
import { Choice, Property } from '@/store/types';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { shouldDelete } from './shouldDelete';

function* onChoiceDelete(choice: Choice): SagaIterator {
	// eslint-disable-next-line no-restricted-syntax
	for (const propertyId of choice.dependentPropertyIds) {
		const property: Property = yield select(propertiesGet, propertyId);
		const parentChoiceIds = property.parentChoiceIds.filter(
			(parentChoiceId) => parentChoiceId !== choice.id,
		);
		yield put(actions.propertyUpdate({ id: propertyId, parentChoiceIds }));
	}
	yield put(actions.choiceDelete({ id: choice.id }));
}

export function* deletePropertyChoices(property: Property): SagaIterator {
	// eslint-disable-next-line no-restricted-syntax
	for (const propertyId of property.choiceIds) {
		const choice = yield select(choiceGet, propertyId);
		yield call(onChoiceDelete, choice);
	}
}

export function* onChoiceDeleteRequest(
	action: ReturnType<typeof actions.choiceDeleteRequest>,
): SagaIterator {
	const choice: Choice = yield select(choiceGet, action.payload.id);
	if (choice.dependentPropertyIds.length > 0 && shouldDelete('choice', choice.name)) {
		yield call(onChoiceDelete, choice);
	} else yield call(onChoiceDelete, choice);
}

// eslint-disable-next-line consistent-return
export function* checkDependents(property: Property): SagaIterator {
	// eslint-disable-next-line no-restricted-syntax
	for (const choiceId of property.choiceIds) {
		const choice: Choice = yield select(choiceGet, choiceId);
		if (choice.dependentPropertyIds.length > 0) {
			return true;
		}
	}
}
