import { actionsByPropertyId } from '@/selectors/actions';
import { propertyUpdateRules } from '@/store/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Rule from './Rule';
import * as Styled from './RuleList.styled';

interface RuleListProps {
	ruleIds: string[];
	propertyId: string;
}
const RuleList: React.FC<RuleListProps> = ({ ruleIds, propertyId }) => {
	const dispatch = useDispatch();
	const propertyActions = useSelector(actionsByPropertyId(propertyId));

	const deleteRule = (id: string) => {
		dispatch(
			propertyUpdateRules({
				propertyId,
				upsertActions: propertyActions
					.filter((upsertAction) => upsertAction.rule !== id)
					.map((upsertAction) => ({ id: upsertAction.id, ruleId: upsertAction.rule })),
			}),
		);
	};

	return (
		<Styled.RuleList>
			{ruleIds.length ? (
				ruleIds.map((ruleId) => (
					<Rule deleteById={deleteRule} id={ruleId} key={ruleId} propertyId={propertyId} />
				))
			) : (
				<Styled.RuleListItem>There are no rules added</Styled.RuleListItem>
			)}
		</Styled.RuleList>
	);
};

export default RuleList;
