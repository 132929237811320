import { css, styled } from '@mui/material/styles';

export const VisibilityContainer = styled('div')`
	align-items: center;
	display: flex;
	visibility: hidden;
`;

export const Container = styled('div')<{ alwaysHidden: boolean }>`
	margin-bottom: -24px;
	margin-top: -12px;

	&:hover ${VisibilityContainer} {
		visibility: ${({ alwaysHidden }) => (alwaysHidden ? 'hidden' : 'visible')};
	}
`;

const line = css`
	border: 1px solid ${({ theme }) => theme.palette.primary.main};
	flex-grow: 1;
	height: 0;
`;

export const LineLeft = styled('div')`
	${line}
	margin-right: 4px;
`;

export const LineRight = styled('div')`
	${line}
	margin-left: 4px;
`;
