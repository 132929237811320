import MUIChip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Chip = styled(MUIChip)`
	&& {
		background-color: #c8ced8;
		font-size: 13px;
	}
`;

export const DependentPropertyLink = styled(Link)`
	color: inherit;
	font-size: 13px;
	text-decoration: none;
`;

export const RuleList = styled.ul`
	padding: 0;
`;

export const RuleListItem = styled.li`
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 16px;
`;

export const RuleListItemName = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 16px;
`;

export const RuleListItemDependencies = styled.div`
	background-color: ${({ theme }) => theme.palette.grey[100]};
	padding: ${({ theme }) => theme.spacing(2)};
`;
