import React, { ComponentPropsWithRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Route } from '@/routes';
import Omit from '@/utils/Omit';

export interface LinkProps<P> {
	to: Route<P>;
	params: {} extends P ? P | undefined | void : P;
	hash?: string;
}

interface Props<P> extends Omit<ComponentPropsWithRef<typeof RouterLink>, 'to'>, LinkProps<P> {}

function Link<P>({ to, hash, params, ...rest }: Props<P>): JSX.Element {
	return <RouterLink {...rest} to={to(params, hash)} />;
}

export default Link;
