import { Auth0Client } from '@auth0/auth0-spa-js';
import { PublicClientApplication } from '@azure/msal-browser';

interface AcquireTokenOptions {
	auth0Client: Auth0Client;
	hasTestUsersEnabled: boolean;
	msalClientId: string;
	msalInstance: PublicClientApplication;
}

const acquireToken = async ({
	auth0Client,
	hasTestUsersEnabled,
	msalClientId,
	msalInstance,
}: AcquireTokenOptions): Promise<string> => {
	if (hasTestUsersEnabled) {
		return '';
	}

	const account = msalInstance.getActiveAccount();
	if (account !== null) {
		const kesScope = `api://${msalClientId}/KES`;
		const authenticationResult = await msalInstance.acquireTokenSilent({
			account,
			scopes: [kesScope],
		});
		return authenticationResult.accessToken;
	}

	const isAuthenticatedAuth0 = await auth0Client.isAuthenticated();
	if (isAuthenticatedAuth0) {
		const token = await auth0Client.getTokenSilently();
		return token;
	}

	return '';
};

export default acquireToken;
