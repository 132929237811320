import { ApiAssetFilter } from 'kes-common';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ItemProps } from '@/components/SideBar/List';
import React, { FC } from 'react';
import classes from '@/components/SideBar.module.css';
import Header from '@/components/SideBar/Header';
import InsertIcon from '@/components/SideBar/InsertIcon';
import State from '@/store/state';
import { assetFiltersGet } from '@/selectors';
import { useSelector } from 'react-redux';
import { Asset } from '@/store/types';
import { Box } from '@mui/material';

interface FilterItemProps extends ItemProps {
	id: ApiAssetFilter['id'];
	assetId: Asset['id'];
}

const FilterItem: FC<FilterItemProps> = ({ id, assetId }): JSX.Element => {
	const assetFilter = useSelector<State, ApiAssetFilter>((state) => assetFiltersGet(state, id));

	return (
		<li className={classes.item}>
			<Header
				data-testid="asset-filter-item"
				className={classes.propertyHeaderReadonly}
				titleClassName={classes.propertyLinkReadonly}
				title={assetFilter.name}
			>
				<Box mr={2} display="flex" justifyContent="center" alignItems="center">
					<FilterListIcon fontSize="small" />
				</Box>
				<InsertIcon assetId={assetId} filter={assetFilter.name} />
			</Header>
		</li>
	);
};

export default FilterItem;
