/* eslint-disable no-param-reassign */
import PartialExpect from '@/utils/PartialExpect';
import { Writeable } from './utils';

export const DELETE_ITEM = Symbol('DELETE ITEM');

export interface Entity {
	readonly id: string | number;
}

export interface State<T extends Entity> {
	byId: Record<T['id'], T>;
	allIds: T['id'][];
}

interface Selectors<T extends Entity> {
	allIds: (state: Readonly<Pick<State<T>, 'allIds'>>) => T['id'][];
	get(state: Readonly<Pick<State<T>, 'byId'>>, id: T['id']): T;
	getAll(state: Readonly<Pick<State<T>, 'byId'>>): Record<T['id'], T>;
	getOrNull(state: Readonly<Pick<State<T>, 'byId'>>, id: T['id']): T | null;
	size(state: State<T>): number;
}

interface Repository<T extends Entity> {
	upsert(draft: State<T>, object: T): void;
	update(draft: State<T>, newValue: PartialExpect<T, 'id'>): void;
	modify(
		draft: State<T>,
		id: T['id'],
		callback: (instance: Writeable<T>) => void | typeof DELETE_ITEM,
	): void;
	modifyAll(draft: State<T>, callback: (instance: Writeable<T>) => void | typeof DELETE_ITEM): void;
	delete(draft: State<T>, id: T['id']): void;
	clear(draft: State<T>): void;
	replaceAll<I, R>(
		draft: State<T>,
		values: Record<T['id'], I>,
		map: (input: I, key: T['id'], ...rest: R[]) => T,
		...rest: R[]
	): void;
}

function toWriteable<T>(input: T): Writeable<T> {
	return input as Writeable<T>;
}

export const makeSelectors = <T extends Entity>(): Selectors<T> => ({
	size: (state) => state.allIds.length,
	allIds: (state): T['id'][] => state.allIds,
	get(state, id): T {
		const item = state.byId[id];
		if (item === undefined) {
			throw new Error(`Item not found with id ${id}`);
		}
		return item;
	},
	getAll(state): Record<T['id'], T> {
		return state.byId;
	},
	getOrNull(state, id): T | null {
		const item = state.byId[id];
		if (item === undefined) {
			return null;
		}
		return item;
	},
});

export const makeRepository = <T extends Entity>(): Repository<T> => {
	const repository: Repository<T> = {
		upsert(draft, object): void {
			const key: T['id'] = object.id;
			const oldValue = draft.byId[key];
			if (!oldValue) {
				draft.allIds.push(object.id);
			}
			draft.byId[key] = object;
		},
		update(draft, newValue): void {
			const item = draft.byId[newValue.id];
			if (item === undefined) {
				throw new Error(`Item not found with id ${newValue.id}`);
			}
			draft.byId[newValue.id] = {
				...item,
				...newValue,
			};
		},
		modify(draft, id, callback): void {
			const item = draft.byId[id];
			if (item === undefined) {
				throw new Error(`Item not found with id ${id}`);
			}
			if (callback(toWriteable(item)) === DELETE_ITEM) {
				repository.delete(draft, id);
			}
		},
		modifyAll(draft, callback): void {
			// eslint-disable-next-line no-restricted-syntax
			for (const item of Object.values<T>(draft.byId)) {
				if (callback(toWriteable(item)) === DELETE_ITEM) {
					repository.delete(draft, item.id);
				}
			}
		},
		delete(draft, id): void {
			const item = draft.byId[id];
			if (item === undefined) {
				throw new Error(`Item not found with id ${id}`);
			}
			delete draft.byId[id];
			const index = draft.allIds.findIndex((e): boolean => e === id);
			draft.allIds.splice(index, 1);
		},
		clear(draft): void {
			draft.byId = {} as Record<T['id'], T>;
			draft.allIds = [];
		},
		replaceAll<I, R>(
			draft: State<T>,
			values: Record<T['id'], I>,
			map: (input: I, key: T['id'], ...rest: R[]) => T,
			...rest: R[]
		): void {
			repository.clear(draft);
			// eslint-disable-next-line no-restricted-syntax
			for (const id in values) {
				if (Object.prototype.hasOwnProperty.call(values, id)) {
					const key: T['id'] = id;
					const value: T = map(values[key], key, ...rest);
					if (!Object.is(key, value.id)) {
						throw new Error(
							`Invalid value encountered, ${id} is not the id of ${JSON.stringify(value)}`,
						);
					}
					draft.byId[key] = value;
					draft.allIds.push(key);
				}
			}
		},
	};
	return repository;
};

export const initialState = <T extends Entity>(): State<T> => ({
	byId: {} as Record<T['id'], T>,
	allIds: [],
});
