import { propertyUpdate } from '@/store/actions';
import { Property } from '@/store/types';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon, OptionContainer, TextContainer, Text } from '../DefaultOption.styles';

const OtherOption: React.FC<{ property: Property }> = ({ property }) => {
	const dispatch = useDispatch();
	const removeNotApplicableOption = () => {
		dispatch(
			propertyUpdate({
				...property,
				hasOtherOption: false,
			}),
		);
	};

	return (
		<OptionContainer>
			<Icon />
			<TextContainer>
				<Text variant="body1">Other</Text>
				<TextField
					variant="standard"
					placeholder="Free text to be filled in by the user"
					fullWidth
					disabled
				/>
			</TextContainer>
			<CloseIcon data-testid="remove" onClick={removeNotApplicableOption} />
		</OptionContainer>
	);
};

export default OtherOption;
