import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { applicationProperty } from '@/routes';
import { studyId } from '@/selectors';
import { Asset, Property } from '@/store/types';
import propertyTypeToLabel from '@/utils/propertyTypeToLabel';

import ContextMenu from './ContextMenu';

interface SidebarQuestionProps {
	asset: Asset | null;
	property: Property;
}

const SidebarQuestion: React.FC<SidebarQuestionProps> = ({ asset, property }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

	const applicationName = useSelector(studyId);

	return (
		<>
			<ListItem
				disablePadding
				key={property.id}
				secondaryAction={
					<IconButton onClick={(event) => setAnchorElement(event.currentTarget)}>
						<MoreVertIcon />
					</IconButton>
				}
			>
				<ListItemButton
					component={Link}
					selected={window.location.href.includes(property.id)}
					to={applicationProperty({ applicationName, propertyId: property.id })}
				>
					<ListItemText
						primary={property.question || property.name}
						primaryTypographyProps={{ noWrap: true }}
						secondary={`${property.placeholder} (${propertyTypeToLabel(property.type)})`}
						secondaryTypographyProps={{ noWrap: true }}
					/>
				</ListItemButton>
			</ListItem>

			<ContextMenu
				anchorElement={anchorElement}
				asset={asset}
				onClose={() => setAnchorElement(null)}
				property={property}
				studyId={applicationName}
			/>
		</>
	);
};

export default SidebarQuestion;
