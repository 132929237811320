import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import SidebarCategoriesList from '@/components/SidebarCategoriesList';
import SidebarQuestionsList from '@/components/SidebarQuestionsList';
import { application } from '@/routes';
import { uiSidebarModeToggle } from '@/store/actions';
import { SidebarMode } from '@/store/state';
import { studyId as studyIdSelector } from '@/selectors';

enum SidebarQuestionTab {
	QUESTIONS = 0,
	CATEGORIES = 1,
}

const SidebarContainerQuestions: React.FC = () => {
	const [activeTab, setActiveTab] = React.useState<SidebarQuestionTab>(
		SidebarQuestionTab.QUESTIONS,
	);

	const studyId = useSelector(studyIdSelector);

	const dispatch = useDispatch();
	const history = useHistory();

	const onSwapSidebarMode = React.useCallback(() => {
		history.push(application({ applicationName: studyId }));
		dispatch(uiSidebarModeToggle(SidebarMode.CLASSIC));
	}, [dispatch, history, studyId]);

	const onTabChange = React.useCallback(
		(event: React.SyntheticEvent<Element, Event>, tab: SidebarQuestionTab) => setActiveTab(tab),
		[setActiveTab],
	);

	return (
		<Box
			sx={{ height: 'calc(100% - 64px)', left: 0, overflowY: 'auto', position: 'fixed', top: 64 }}
		>
			<Tabs onChange={onTabChange} value={activeTab} variant="fullWidth">
				<Tab label="Questions" />
				<Tab label="Categories" />
			</Tabs>

			{activeTab === SidebarQuestionTab.QUESTIONS && <SidebarQuestionsList />}

			{activeTab === SidebarQuestionTab.CATEGORIES && <SidebarCategoriesList />}

			<Box marginY={1} textAlign="center">
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<Link component="button" onClick={onSwapSidebarMode} textAlign="center" underline="hover">
					Switch to classic layout
				</Link>
			</Box>
		</Box>
	);
};

export default SidebarContainerQuestions;
