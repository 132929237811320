import React, { ComponentProps, FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

import * as routes from '@/routes';
import { applicationLoad, routerUpdatePath, routerUpdateTitle } from '@/store/actions';
import State from '@/store/state';
import { routerRedirectUrl } from '@/selectors';
import BaseLayout from '@/layouts/Base';
import usePrevious from '@/components/hooks/usePrevious';
import RouterSwitch, { defineRoute, RouteInformation } from '@/components/Router';
import Omit from '@/utils/Omit';

import Application from './application';
import CategoryCreate from './application/CategoryCreate';
import CategoryUpdate from './application/CategoryUpdate';
import {
	ApplicationReportAssetCreate,
	ApplicationReportAssetModify,
} from './application/ReportAssetUpsert';
import NoApplicationSelectedPage from './application/NoApplicationSelected';
import FormEditor from './application/FormEditor';
import PropertyContainer from './application/PropertyContainer';
import SubCategoryCreate from './application/SubCategoryCreate';
import SubCategoryUpdate from './application/SubCategoryUpdate';
import QuestionPaneCreate from './application/QuestionPane/QuestionPaneCreate';
import { PropertyCreate as ApplicationReportPropertyCreate } from './application/ReportPropertyUpdate/ReportPropertyUpdate';

const routeList: RouteInformation[] = [
	defineRoute(routes.applicationSubCategoryCreate, SubCategoryCreate, BaseLayout, {
		interactionEnabled: true,
	}),
	defineRoute(routes.applicationSubCategoryUpdate, SubCategoryUpdate, BaseLayout, {
		interactionEnabled: true,
	}),
	defineRoute(routes.applicationCategoryCreate, CategoryCreate, BaseLayout, {
		interactionEnabled: true,
	}),
	defineRoute(routes.applicationCategoryUpdate, CategoryUpdate, BaseLayout, {
		interactionEnabled: true,
	}),
	defineRoute(routes.applicationPropertyCreate, ApplicationReportPropertyCreate, BaseLayout, {
		interactionEnabled: false,
	}),
	defineRoute(routes.applicationPropertyCreateQuestion, QuestionPaneCreate, BaseLayout, {
		interactionEnabled: false,
	}),
	defineRoute(routes.applicationProperty, PropertyContainer, BaseLayout, {
		interactionEnabled: true,
	}),
	defineRoute(routes.applicationAssetCreate, ApplicationReportAssetCreate, BaseLayout, {
		interactionEnabled: true,
	}),
	defineRoute(routes.applicationAsset, ApplicationReportAssetModify, BaseLayout, {
		interactionEnabled: true,
	}),
	defineRoute(routes.applicationForm, FormEditor, BaseLayout, { interactionEnabled: true }),

	defineRoute(routes.application, Application, BaseLayout, { interactionEnabled: true }),

	defineRoute(routes.home, NoApplicationSelectedPage),
];

type Props = Omit<ComponentProps<typeof RouterSwitch>, 'routeList'>;

const Pages: FC<Props> = function Pages(props): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();
	const match = useRouteMatch<{ applicationName: string | undefined }>({
		path: '/:applicationName',
	});

	const applicationName = match?.params?.applicationName;
	const previousApplicationName = usePrevious(applicationName);

	useEffect(() => {
		if (previousApplicationName === undefined && applicationName !== undefined) {
			dispatch(applicationLoad(applicationName));
		}

		if (previousApplicationName !== undefined && applicationName !== undefined) {
			history.go(0); // Refresh page to reinitiaze app.
		}
	}, [applicationName]);

	return <RouterSwitch {...props} routeList={routeList} />;
};

const mapStateToProps = (state: State): Pick<Props, 'redirectUrl'> => ({
	redirectUrl: routerRedirectUrl(state),
});

const mapDispatchToProps = {
	onRouteUpdate: routerUpdatePath,
	onTitleUpdate: routerUpdateTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
