import { useCallback } from 'react';

export default function useAutoFocusRef(selectText?: boolean): (node: HTMLElement | null) => void {
	return useCallback((node: HTMLElement | null): void => {
		if (node !== null) {
			window.requestAnimationFrame((): void => {
				node.focus({
					preventScroll: false,
				});
				if (selectText && 'select' in node) {
					(node as HTMLInputElement).select();
				}
			});
		}
	}, []);
}
