import React, { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import * as routes from '@/routes';
import LinkContext from './LinkContext';
import classes from './SidePopup.module.css';

interface Props {
	children: ReactNode;
	onDelete?: () => void;
	title: ReactNode;
}

const SidePopup: FC<Props> = ({ children, onDelete, title }): JSX.Element => (
	<div className={classes.root}>
		<div className={classes.header}>
			<LinkContext
				to={routes.application}
				params={{ applicationName: null }}
				className={classes.back}
			>
				<CloseIcon />
			</LinkContext>
			<span className={classes.title}>{title}</span>
			{onDelete && (
				<DeleteIcon data-testid="actions-delete" className={classes.delete} onClick={onDelete} />
			)}
		</div>
		<div className={classes.contents}>{children}</div>
	</div>
);

export default SidePopup;
