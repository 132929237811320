import { PublicClientApplication, InteractionType, AccountInfo } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import {
	AuthCodeMSALBrowserAuthenticationProvider,
	AuthCodeMSALBrowserAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

interface BuildMSALGraphClientOptions {
	account: AccountInfo;
	publicClientApplication: PublicClientApplication;
}

let client: Client | null = null;

const buildMSALGraphClient = (options: BuildMSALGraphClientOptions): Client => {
	if (client === null) {
		const providerOptions: AuthCodeMSALBrowserAuthenticationProviderOptions = {
			account: options.account,
			interactionType: InteractionType.Redirect,
			scopes: ['User.Read'],
		};
		const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
			options.publicClientApplication,
			providerOptions,
		);
		client = Client.initWithMiddleware({ authProvider });
	}
	return client;
};

export default buildMSALGraphClient;
