import React from 'react';
import classes from '@/components/SideBar.module.css';
import Header from '@/components/SideBar/Header';
import CompareIcon from '@mui/icons-material/Compare';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import State from '@/store/state';
import { Rule, RuleSet } from '@/store/types';
import { getRuleById, getRuleSetByRuleId } from '@/selectors/ruleSets';
import InsertIcon from '../../InsertIcon';

interface RuleItemProps {
	assetId: string;
	id: string;
	propertyId: string;
}
const RuleItem: React.FC<RuleItemProps> = ({ id, assetId, propertyId }) => {
	const rule = useSelector<State, Rule>(getRuleById(id));
	const ruleSet = useSelector<State, RuleSet>(getRuleSetByRuleId(id));

	return (
		<li className={classes.item}>
			<Header
				data-testid="rule-item"
				className={classes.propertyHeaderReadonly}
				titleClassName={classes.propertyLinkReadonly}
				title={`${ruleSet.name}: ${rule.name}`}
			>
				<Box mr={2} display="flex" justifyContent="center" alignItems="center">
					<CompareIcon fontSize="small" />
				</Box>
				<InsertIcon
					assetId={assetId}
					propertyId={propertyId}
					ruleSet={ruleSet.name}
					rule={rule.name}
				/>
			</Header>
		</li>
	);
};

export default RuleItem;
