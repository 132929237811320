import { buffers, Channel, channel, SagaIterator } from '@redux-saga/core';
import {
	call,
	debounce,
	delay,
	flush,
	fork,
	put,
	retry,
	select,
	take,
	takeEvery,
	takeLatest,
} from '@redux-saga/core/effects';
import { ApiAssetType, ApiMutation, ApiProperty, ApiReportTemplate } from 'kes-common';
import { v4 } from 'uuid';

import * as actions from '@/store/actions';
import {
	assetLibraryPost,
	builderPostReportTemplate,
	getBuilderProject,
	moveProperty as movePropertyApi,
	duplicateProperty as duplicatePropertyApi,
	validateReport,
} from '@/net/api';
import { Result } from '@/store/utils';
import {
	AuthorizationError,
	MendixUnavailableError,
	Property,
	PropertyType,
	Choice,
	Report,
	ValidationError,
	PropertyDependentCombinator,
} from '@/store/types';
import State from '@/store/state';
import assertNever from '@/utils/assertNever';
import {
	applicationCategories,
	assetsGet,
	categoriesGet,
	choiceGet,
	propertiesGet,
	studyId,
} from '@/selectors';
import {
	UPLOAD_COLLECT_MUTATIONS_DELAY,
	UPLOAD_NEW_EVENT_DELAY,
	UPLOAD_OFFLINE_DELAY,
} from '@/constants';
import {
	propertyDuplicate,
	propertyDuplicateError,
	propertyDuplicateRequest,
	propertyMove,
	propertyMoveError,
	propertyMoveRequest,
	propertyNewQuestion,
	propertyUpdate,
	choicePersist,
} from '@/store/actions';
import { applicationProperty } from '@/routes';

function* loadReportValidation(loadStudyId: string): SagaIterator {
	try {
		const response: Result<typeof validateReport> = yield retry(
			2,
			100,
			validateReport,
			loadStudyId,
		);
		yield put(
			actions.reportValidationResult({
				result: response.expectSuccess(),
			}),
		);
	} catch (e) {
		yield put(actions.reportValidationError(e as Error));
	}
}

function* validateReportSaga(action: ReturnType<typeof actions.reportValidate>): SagaIterator {
	yield call(loadReportValidation, action.payload.studyId);
}

function* loadApplication(action: ReturnType<typeof actions.applicationLoad>): SagaIterator {
	while (true) {
		try {
			const response: Result<typeof getBuilderProject> = yield retry(
				2,
				100,
				getBuilderProject,
				action.payload,
			);

			if (response.status === 401) {
				throw new AuthorizationError('You are not authorized to access this study');
			}
			if (response.status === 503) {
				throw new MendixUnavailableError(response.result.message);
			}
			if (response.status === 200) {
				yield put(actions.applicationLoadSuccess(response.expect(200)));
				yield call(loadReportValidation, response.expectSuccess().studyId);
			}
		} catch (e) {
			yield put(actions.applicationLoadError(e as Error));
		}
		yield take(actions.applicationReload);
	}
}

const actionList = [
	actions.assetGenerate,
	actions.assetUpdate,
	actions.assetPersist,

	actions.categoryUpdate,
	actions.categoryPersist,

	actions.propertyGenerate,
	actions.propertyUpdate,
	actions.propertyPersist,
	actions.propertyDelete,
	actions.propertyConnectAsInput,
	actions.propertyDisconnectFromInput,
	actions.propertyAddDependent,
	actions.propertyRemoveDependent,
	actions.propertyAddReference,
	actions.propertyRemoveReference,
	actions.propertyUpdateRules,

	actions.choiceGenerate,
	actions.choiceUpdate,
	actions.choicePersist,
	actions.choiceDelete,

	actions.reportPersist,
];
type InterestedActions = ReturnType<(typeof actionList)[number]>;

function propertyTypeToDto(input: PropertyType): ApiProperty['type'] {
	switch (input) {
		case PropertyType.DATE:
			return 'DATE';
		case PropertyType.RICH_TEXT:
			return 'RICH_TEXT';
		case PropertyType.STRING:
			return 'STRING';
		case PropertyType.SINGLE_SELECT:
			return 'SINGLE_SELECT';
		case PropertyType.DECIMAL:
			return 'DECIMAL';
		case PropertyType.MULTI_SELECT:
			return 'MULTI_SELECT';
		case PropertyType.SINGLE_SUBSTANCE:
			return 'SINGLE_SUBSTANCE';
		case PropertyType.MULTI_SUBSTANCE:
			return 'MULTI_SUBSTANCE';
		case PropertyType.IMAGE:
			return 'IMAGE';
		case PropertyType.LOCATIONS:
			return 'LOCATIONS';
		default:
			return assertNever(input);
	}
}

function propertyToDTO(input: Property): ApiProperty {
	const { unpushed, lastExportDate, ...filteredInput } = input;
	return {
		...filteredInput,
		type: propertyTypeToDto(input.type),
	};
}

function deletePropertyMutation(propertyId: string): ApiMutation {
	return {
		mutationType: 'DeleteProperty',
		categoryIds: null,
		category: null,
		assetType: null,
		property: null,
		choice: null,
		deleteCategory: null,
		deleteAssetType: null,
		deleteChoice: null,
		deleteProperty: { propertyId },
		actionMutation: null,
	};
}

function deleteChoiceMutation(choiceId: string): ApiMutation {
	return {
		mutationType: 'DeleteChoice',
		categoryIds: null,
		category: null,
		assetType: null,
		property: null,
		choice: null,
		deleteCategory: null,
		deleteAssetType: null,
		deleteChoice: { choiceId },
		deleteProperty: null,
		actionMutation: null,
	};
}

type ApiMutationFields<O extends keyof ApiMutation, R extends keyof ApiMutation> = Partial<
	Pick<ApiMutation, O>
> &
	Pick<ApiMutation, R>;

function persistMutation(
	type: 'UpsertProperty',
	obj: ApiMutationFields<'assetType', 'property'>,
	id: string,
): ApiMutation;

function persistMutation(
	type: 'UpsertAssetType',
	obj: ApiMutationFields<'category', 'assetType'>,
	id: string,
): ApiMutation;

function persistMutation(
	type: 'UpsertCategory',
	obj: ApiMutationFields<'categoryIds', 'category'>,
	id: string,
): ApiMutation;

function persistMutation(
	type: 'UpsertChoice',
	obj: ApiMutationFields<'property', 'choice'>,
	id: string,
): ApiMutation;

function persistMutation(
	type: 'Action',
	obj: ApiMutationFields<'property', 'actionMutation'>,
	id: string,
): ApiMutation;

function persistMutation(
	mutationType: ApiMutation['mutationType'],
	payload: Partial<ApiMutation>,
): ApiMutation {
	return {
		mutationType,
		categoryIds: payload.categoryIds || null,
		category: payload.category || null,
		assetType: payload.assetType || null,
		property: payload.property || null,
		choice: payload.choice || null,
		deleteCategory: null,
		deleteAssetType: null,
		deleteProperty: null,
		deleteChoice: null,
		actionMutation: payload.actionMutation || null,
	};
}

function mapMutation(state: State, action: InterestedActions): ApiMutation | ApiReportTemplate {
	switch (action.type) {
		case actions.choicePersist.type:
			return persistMutation(
				'UpsertChoice',
				{
					choice: action.payload.choice,
					property: propertyToDTO(propertiesGet(state, action.payload.propertyId)),
				},
				action.payload.choice.id,
			);
		case actions.choiceGenerate.type:
			return persistMutation(
				'UpsertChoice',
				{
					choice: choiceGet(state, action.payload.id),
					property: propertyToDTO(propertiesGet(state, action.payload.propertyId)),
				},
				action.payload.id,
			);
		case actions.choiceUpdate.type:
			return persistMutation(
				'UpsertChoice',
				{
					choice: choiceGet(state, action.payload.id),
				},
				action.payload.id,
			);
		case actions.choiceDelete.type:
			return deleteChoiceMutation(action.payload.id);

		case actions.propertyPersist.type:
			return persistMutation(
				'UpsertProperty',
				{
					assetType: assetsGet(state, action.payload.assetId) as ApiAssetType,
					property: propertyToDTO(action.payload.property),
				},
				action.payload.property.id,
			);
		case actions.propertyGenerate.type:
			return persistMutation(
				'UpsertProperty',
				{
					assetType: assetsGet(state, action.payload.assetId) as ApiAssetType,
					property: propertyToDTO(propertiesGet(state, action.payload.id)),
				},
				action.payload.id,
			);
		case actions.propertyUpdate.type:
			return persistMutation(
				'UpsertProperty',
				{
					property: propertyToDTO(propertiesGet(state, action.payload.id)),
				},
				action.payload.id,
			);
		case actions.propertyDelete.type:
			return deletePropertyMutation(action.payload.id);
		case actions.propertyConnectAsInput.type:
		case actions.propertyDisconnectFromInput.type:
			return persistMutation(
				'UpsertProperty',
				{
					property: propertyToDTO(propertiesGet(state, action.payload.computedPropertyId)),
				},
				action.payload.computedPropertyId,
			);
		case actions.propertyAddDependent.type:
			return persistMutation(
				'UpsertProperty',
				{
					property: propertyToDTO(propertiesGet(state, action.payload.id)),
				},
				action.payload.id,
			);

		case actions.propertyAddReference.type:
			return persistMutation(
				'UpsertProperty',
				{
					property: propertyToDTO(propertiesGet(state, action.payload.propertyId)),
				},
				action.payload.propertyId,
			);
		case actions.propertyRemoveReference.type:
			return persistMutation(
				'UpsertProperty',
				{
					property: propertyToDTO(propertiesGet(state, action.payload.propertyId)),
				},
				action.payload.propertyId,
			);
		case actions.propertyRemoveDependent.type:
			return persistMutation(
				'UpsertProperty',
				{
					property: propertyToDTO(propertiesGet(state, action.payload.id)),
				},
				action.payload.id,
			);
		case actions.propertyUpdateRules.type:
			return persistMutation(
				'Action',
				{
					property: propertyToDTO(propertiesGet(state, action.payload.propertyId)),
					actionMutation: action.payload,
				},
				action.payload.propertyId,
			);
		case actions.assetPersist.type:
			return persistMutation(
				'UpsertAssetType',
				{
					category: categoriesGet(state, action.payload.categoryId),
					assetType: action.payload.asset as ApiAssetType,
				},
				action.payload.asset.id,
			);
		case actions.assetGenerate.type:
			return persistMutation(
				'UpsertAssetType',
				{
					category: categoriesGet(state, action.payload.categoryId),
					assetType: assetsGet(state, action.payload.id) as ApiAssetType,
				},
				action.payload.id,
			);
		case actions.assetUpdate.type:
			return persistMutation(
				'UpsertAssetType',
				{
					assetType: assetsGet(state, action.payload.id) as ApiAssetType,
				},
				action.payload.id,
			);

		case actions.categoryPersist.type:
			return persistMutation(
				'UpsertCategory',
				{
					categoryIds: applicationCategories(state),
					category: action.payload.category,
				},
				action.payload.category.id,
			);
		case actions.categoryUpdate.type:
			return persistMutation(
				'UpsertCategory',
				{
					category: categoriesGet(state, action.payload.id),
				},
				action.payload.id,
			);

		case actions.reportPersist.type:
			return action.payload.report;

		default:
			return assertNever(action);
	}
}

function* handleAction(
	action: InterestedActions,
	actionChannel: Channel<ApiMutation | ApiReportTemplate>,
): SagaIterator {
	const asMutation: ApiMutation = yield select(mapMutation, action);
	yield put(actionChannel, asMutation);
}

function* awaitActions(awaitChannel: Channel<ApiMutation | ApiReportTemplate>): SagaIterator {
	while (true) {
		const action: InterestedActions = yield take(actionList);
		yield fork(handleAction, action, awaitChannel);
	}
}

function isApiReportTemplate(input: ApiMutation | ApiReportTemplate): input is ApiReportTemplate {
	return 'template' in input;
}

function* persistEntities(events: (ApiMutation | ApiReportTemplate)[]): SagaIterator {
	const activeStudyId = yield select(studyId);
	const libraryMutations = events.filter((m): m is ApiMutation => !isApiReportTemplate(m));
	if (libraryMutations.length > 0) {
		const response: Result<typeof assetLibraryPost> = yield call(assetLibraryPost, activeStudyId, {
			mutations: libraryMutations,
		});
		if (response.status === 400 && response.result.validationError) {
			const e = new ValidationError(response.result.message);
			yield put(actions.saveValidationError(e));
			return;
		}
		response.expectSuccess();
	}
}

function* moveProperty(action: ReturnType<typeof propertyMoveRequest>): SagaIterator {
	try {
		const { studyId: payloadStudyId, propertyId, destinationAssetId, position } = action.payload;
		const response = yield call(movePropertyApi, propertyId, payloadStudyId, {
			destinationAssetId,
			position,
		});
		if (response.status === 204) {
			yield put(propertyMove(action.payload));
		} else {
			yield put(propertyMoveError(response.result.statusText));
		}
	} catch (e) {
		yield put(propertyMoveError('Something went wrong'));
	}
}

function* duplicateProperty(action: ReturnType<typeof propertyDuplicateRequest>): SagaIterator {
	try {
		const { studyId: payloadStudyId, property } = action.payload;
		const response = yield call(duplicatePropertyApi, property.id, payloadStudyId);
		if (response.status === 200) {
			yield put(propertyDuplicate(response.expect(200)));
		} else {
			yield put(propertyDuplicateError(response.result.statusText));
		}
	} catch (e) {
		yield put(propertyDuplicateError('Something went wrong'));
	}
}

function* storeProject(): SagaIterator {
	const ch = channel<ApiMutation | ApiReportTemplate>(buffers.expanding(4));
	yield fork(awaitActions, ch);
	while (true) {
		const event: ApiMutation | ApiReportTemplate = yield take(ch);
		yield put(actions.saveStarting());
		let events = [event];
		// Add an inner while loop so the upload sttsus doesn't keep flashing to "all changes saved"
		do {
			let hasMoreEventsCaptured: boolean;
			do {
				yield delay(UPLOAD_NEW_EVENT_DELAY);
				const newEvents: (ApiMutation | ApiReportTemplate)[] = yield flush(ch);
				events.push(...newEvents);
				hasMoreEventsCaptured = newEvents.length > 0;
			} while (hasMoreEventsCaptured);

			if (window.navigator.onLine === false) {
				// The browser is offline, wait while syncing
				yield put(actions.saveOffline());
				yield delay(UPLOAD_OFFLINE_DELAY);
				// eslint-disable-next-line no-continue
				continue;
			}
			yield put(actions.saveUpdate());

			try {
				yield call(persistEntities, events);
			} catch (e) {
				yield put(actions.saveError(e as Error));
				return;
			}
			yield delay(UPLOAD_COLLECT_MUTATIONS_DELAY);
			// Catch any other pending actions that happened while we were waiting
			events = yield flush(ch);
		} while (events.length > 0);
		yield put(actions.saveDone());
	}
}

function* persistTemplate(action: ReturnType<typeof actions.reportUpdate>): SagaIterator {
	const activeStudyId = yield select(studyId);
	const latestReport: Report | null = yield select<(state: State) => Report | null>(
		(state: State) => state.report,
	);
	if (latestReport) {
		yield put(actions.saveStarting());
		const result: Result<typeof builderPostReportTemplate> = yield call(
			builderPostReportTemplate,
			activeStudyId,
			{
				...(action.payload as ApiReportTemplate),
				name: latestReport.name || 'Test template',
				updatedAt: latestReport.updatedAt,
			},
		);
		const updatedAtUpdate = result.expectSuccess();
		yield put(
			actions.reportUpdateSuccess({
				updatedAt: updatedAtUpdate.updatedAt,
			}),
		);
		yield put(actions.saveDone());
	}
}

function* propertyNewQuestionSaga(action: ReturnType<typeof propertyNewQuestion>) {
	const { formValues, assetId, applicationName } = action.payload;

	const property: Property = {
		allowMultipleAnswers: formValues.multiple,
		caption: null,
		choiceIds: [],
		dateFormat: formValues.dateFormat || null,
		dependentCombinator: 'ANY' as PropertyDependentCombinator,
		description: null,
		fixed: false,
		hasNotApplicableOption: formValues.notApplicable,
		hasOtherOption: formValues.otherOption,
		height: null,
		id: v4(),
		identifyingProperty: '',
		inspection: true,
		internalOnly: formValues.internalOnly,
		name: formValues.title,
		parentActionIds: [],
		parentChoiceIds: [],
		placeholder: formValues.placeholder,
		position: null,
		question: formValues.title,
		referencedAssetType: '',
		required: true,
		ruleIds: [],
		stableId: v4(),
		sourceUrl: '',
		survey: true,
		type: formValues.type as PropertyType,
		width: null,
	};

	const assetType: ApiAssetType = yield select(assetsGet, assetId);
	const mutationProperty = persistMutation(
		'UpsertProperty',
		{
			assetType,
			property: propertyToDTO(property),
		},
		property.id,
	);
	yield put(actions.saveStarting());
	yield put(actions.propertyGenerateSilent({ id: property.id, assetId }));
	yield call(persistEntities, [mutationProperty]);
	yield put(propertyUpdate(property));

	for (const newChoice of formValues.choices) {
		yield put(
			choicePersist({
				choice: newChoice as Choice,
				propertyId: property.id,
			}),
		);
	}
	yield put(actions.saveDone());
	yield put(
		actions.routerRedirect(applicationProperty({ applicationName, propertyId: property.id })),
	);
}

function* applicationSaga(): SagaIterator {
	yield fork(storeProject);
	yield debounce(500, actions.reportUpdate, persistTemplate);
	yield takeEvery(propertyMoveRequest, moveProperty);
	yield takeEvery(propertyDuplicateRequest, duplicateProperty);
	yield takeEvery(actions.applicationLoad, loadApplication);
	yield takeLatest(actions.reportValidate, validateReportSaga);
	yield takeLatest(actions.propertyNewQuestion, propertyNewQuestionSaga);
}

export default applicationSaga;
