import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';

const initialize = (msalInstance: PublicClientApplication): void => {
	if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
		msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] ?? null);
	}
	msalInstance.enableAccountStorageEvents();
	msalInstance.addEventCallback((event) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			// The typings of `event` are not strict enough to infer the type of `payload`,
			// thus we have to cast it here.
			const { account } = event.payload as AuthenticationResult;
			msalInstance.setActiveAccount(account);
		}
	});
};

export default initialize;
