import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useSelector } from 'react-redux';

import { KES_FRONTEND_URL } from '@/constants';
import { studyTestProjectId } from '@/selectors';

interface ActivityPreviewDrawerProps {
	isOpen: boolean;
	onClose(): void;
}

const ActivityPreviewDrawer: React.FC<ActivityPreviewDrawerProps> = ({ isOpen, onClose }) => {
	const testProjectId = useSelector(studyTestProjectId);

	return (
		<Drawer anchor="right" open={isOpen} onClose={onClose}>
			<Box margin={2} sx={{ height: '100%', width: 900 }}>
				<Toolbar />
				<iframe
					height="100%"
					src={`${KES_FRONTEND_URL}project/${testProjectId}/preview?hideAppBar=1`}
					style={{
						border: '1px solid #eee',
						height: 'calc(100% - 64px)',
					}}
					title="Activity preview"
					width="100%"
				/>
			</Box>
		</Drawer>
	);
};

export default ActivityPreviewDrawer;
