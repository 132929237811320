import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { ReactReduxContext } from 'react-redux';
import tinymce from 'tinymce';

import { getReferenceText } from '@/components/SideBar/InsertIcon/InsertIcon.utils';
import State from '@/store/state';
import { Asset } from '@/store/types';

interface InsertIntoTemplateProps {
	onClose(): void;
	subCategory: Asset;
}

const InsertIntoTemplate: React.FC<InsertIntoTemplateProps> = ({ onClose, subCategory }) => {
	const { store } = React.useContext(ReactReduxContext);

	const onInsert = React.useCallback(() => {
		const state: State = store.getState();
		const text = getReferenceText({ asset: subCategory, state });
		tinymce.activeEditor.insertContent(text);
		onClose();
	}, [onClose, store, subCategory]);

	return (
		<MenuItem disabled={!subCategory.repeating} onClick={onInsert}>
			<ListItemIcon>
				<NoteAddIcon />
			</ListItemIcon>
			<ListItemText primary="Insert into Template" />
		</MenuItem>
	);
};

export default InsertIntoTemplate;
