import React, { ComponentType, ReactNode, useCallback, useState } from 'react';

import nameHoc from '@/utils/nameHoc';

type AllKeys<T> = T[keyof T];
type OmitWithPartial<T, E> = {
	[A in AllKeys<{ [K in Exclude<keyof T, E>]: undefined extends T[K] ? K : never }>]?: T[A];
} & { [A in AllKeys<{ [K in Exclude<keyof T, E>]: undefined extends T[K] ? never : K }>]: T[A] };

type ValueUpdaterProps<
	P,
	O,
	K extends string | number,
	A extends { [V in K]: O } & { id: string },
> = OmitWithPartial<P, 'onChange' | 'value' | 'error'> & {
	update(payload: { [V in K]: O } & { id: string }): O | null;
	value: A;
	// eslint-disable-next-line react/require-default-props
	validator?: (value: A) => { [V in K]: string | null };
	name: K;
};

export default function withValueUpdater<
	P extends {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onChange?: (payload: any) => void;
		value?: unknown;
		error?: boolean;
		helperText?: ReactNode;
		placeholder?: string;
	},
>(
	Component: ComponentType<P>,
): <
	K extends string | number,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	A extends { [V in K]: any } & { id: string },
>(
	props: ValueUpdaterProps<P, A[K], K, A>,
) => JSX.Element {
	const WithValueUpdater = <
		K extends string | number,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		A extends { [V in K]: any } & { id: string },
	>({
		update,
		value,
		name,
		validator = undefined,
		...rest
	}: ValueUpdaterProps<P, A[K], K, A>): JSX.Element => {
		const newId = value.id;
		const [id, setId] = useState<string>(newId);
		const [fieldValue, setFieldValue] = useState<A[K]>(value[name]);
		const [errorMessage, setErrorMessage] = useState<string | null>((): string | null =>
			validator ? validator(value)[name] : null,
		);
		if (newId !== id) {
			setId(newId);
			setFieldValue(value[name]);
			setErrorMessage(null);
		}

		const onChange = useCallback(
			(payload: A[K]): void => {
				const validation =
					validator && fieldValue ? validator({ ...value, [name]: payload })[name] : null;
				setFieldValue(payload);
				setErrorMessage(validation);
			},
			[name, setErrorMessage, setFieldValue, validator, value],
		);

		const onBlur = useCallback((): void => {
			const validation =
				validator && fieldValue ? validator({ ...value, [name]: fieldValue })[name] : null;
			if (validation === null) {
				const trimmedFieldValue = fieldValue.trim();
				const newValue = update({ [name]: trimmedFieldValue, id: value.id } as {
					[I in K]: A[I];
				} & {
					id: string;
				});
				setFieldValue(newValue ?? trimmedFieldValue);
			}
		}, [update, fieldValue]);

		const props = {
			onChange,
			onBlur,
			value: fieldValue,
			error: !!errorMessage,
			helperText: errorMessage,
			...rest,
			// This casting is required for a typescript bug that gives problems with generics and rest
		} as unknown as P;
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} />;
	};
	return nameHoc(WithValueUpdater, Component);
}
