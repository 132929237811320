import { SplitFactory } from '@splitsoftware/splitio-react';
import { ISplitFactoryChildProps } from '@splitsoftware/splitio-react/types/types';
import React from 'react';

import Loader from '../../components/Loader';

interface FeatureFlagsProviderProps {
	children: React.ReactNode;
	splitioAuthorizationKey: SplitIO.IBrowserSettings['core']['authorizationKey'];
	splitioFeaturesMock: SplitIO.IBrowserSettings['features'];
	splitioKey: SplitIO.IBrowserSettings['core']['key'];
}

const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({
	children,
	splitioAuthorizationKey,
	splitioFeaturesMock,
	splitioKey,
}) => {
	const sdkConfig: SplitIO.IBrowserSettings = React.useMemo(
		() => ({
			core: {
				authorizationKey: splitioAuthorizationKey,
				key: splitioKey,
			},
			features: splitioFeaturesMock,
			startup: {
				readyTimeout: 5,
				retriesOnFailureBeforeReady: 1,
			},
		}),
		[splitioAuthorizationKey, splitioKey],
	);

	return (
		<SplitFactory config={sdkConfig}>
			{({ isReady }: ISplitFactoryChildProps) => {
				if (!isReady) {
					return <Loader />;
				}
				// eslint-disable-next-line react/jsx-no-useless-fragment
				return <>{children}</>;
			}}
		</SplitFactory>
	);
};

export default FeatureFlagsProvider;
