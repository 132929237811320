import React from 'react';
import { Fade, SnackbarOrigin } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import StyledSnackbarProvider from './SnackbarProvider.styled';

const AUTO_HIDE_DURATION = 10000;

const anchorOrigin: SnackbarOrigin = {
	vertical: 'bottom',
	horizontal: 'center',
};

interface SnackbarProviderProps {
	children: React.ReactNode;
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => (
	<StyledSnackbarProvider
		anchorOrigin={anchorOrigin}
		autoHideDuration={AUTO_HIDE_DURATION}
		disableWindowBlurListener
		TransitionComponent={Fade as React.FC<TransitionProps>}
		transitionDuration={{ enter: 1000, exit: 1000 }}
	>
		{children}
	</StyledSnackbarProvider>
);

export default SnackbarProvider;
