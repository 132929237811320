import { MsalProvider } from '@azure/msal-react';
import React from 'react';

import Provider from './Provider';
import { AuthenticationProviderProps } from './types';

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
	auth0Client,
	children,
	fetchUserProfile,
	hasTestUsersEnabled,
	msalInstance,
	onSuccess,
	updateUserProfile,
}) => {
	if (hasTestUsersEnabled) {
		return (
			<Provider
				auth0Client={auth0Client}
				fetchUserProfile={fetchUserProfile}
				hasTestUsersEnabled={hasTestUsersEnabled}
				msalInstance={msalInstance}
				onSuccess={onSuccess}
				updateUserProfile={updateUserProfile}
			>
				{children}
			</Provider>
		);
	}
	return (
		<MsalProvider instance={msalInstance}>
			<Provider
				auth0Client={auth0Client}
				fetchUserProfile={fetchUserProfile}
				hasTestUsersEnabled={hasTestUsersEnabled}
				msalInstance={msalInstance}
				onSuccess={onSuccess}
				updateUserProfile={updateUserProfile}
			>
				{children}
			</Provider>
		</MsalProvider>
	);
};

export default AuthenticationProvider;
