import React from 'react';
import { propertyUpdate } from '@/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { propertiesGet } from '@/selectors';
import { Property } from '@/store/types';
import State from '@/store/state';
import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const NotApplicableOptionButton: React.FC<{ propertyId: string }> = ({ propertyId }) => {
	const dispatch = useDispatch();
	const property = useSelector<State, Property>((state) => propertiesGet(state, propertyId));

	const addNotApplicableOption = () => {
		dispatch(
			propertyUpdate({
				...property,
				hasNotApplicableOption: true,
			}),
		);
	};

	if (!property.hasNotApplicableOption) {
		return (
			<button
				className={classes.choicesAddOptionButton}
				onClick={addNotApplicableOption}
				type="button"
			>
				<AddCircleOutlineIcon />
				Add not applicable
			</button>
		);
	}
	return null;
};

export default NotApplicableOptionButton;
