import { makeSelectors } from '@/store/repository';
import { Property, PropertyType, UUID } from '@/store/types';
import State from '@/store/state';
import { PropertyState } from '@/store/reducers/properties';
import makeSlicer from '@/utils/makeSlicer';

const selector = makeSelectors<Property>();
const rootSlicer = makeSlicer<PropertyState<Property>>();

export const { get, getAll, getOrNull } = selector;
export const mendixStatus = rootSlicer('mendixStatus');

export const getPropertyByChoiceId = (state: State, choiceId: string): Property => {
	// eslint-disable-next-line no-restricted-syntax
	for (const propertyId of Object.keys(state.properties.byId)) {
		const property = get(state.properties, propertyId);
		if (property.choiceIds.includes(choiceId)) return property;
	}
	throw new Error(`No property found for choice ${choiceId}`);
};

export const getSelectableParentProperties = (
	state: State,
	propertyId: Property['id'],
): Property[] =>
	Object.values(state.properties.byId)
		.filter((singleProperty) =>
			[PropertyType.MULTI_SELECT, PropertyType.SINGLE_SELECT].includes(singleProperty.type),
		)
		.filter((singleProperty) => singleProperty.id !== propertyId);

/**
 * @param state Store state
 * @param referentPropertyId The propertyId that is being refered to
 * @returns The list of properties that are refering to the referentProperty
 */
export const getReferencedPropertiesForReferent = (
	state: State,
	referentPropertyId: UUID,
): Property[] =>
	Object.values(state.properties.byId).filter(
		(item) => item.identifyingProperty === referentPropertyId,
	);

/**
 *
 * @param state Store state
 * @param assetId The id of the asset in which it's properties may have been referenced
 * @returns List of properties having a reference to the asset's properties
 */
export const getReferencedPropertiesForAsset = (state: State, assetId: UUID): Property[] => {
	const propertiesWithReference: Property[] = [];
	const asset = state.assets.byId[assetId];

	const propertiesForAsset = Object.entries(state.properties.byId)
		.filter((item) => asset.propertyIds.includes(item[0]))
		.map((i) => i[1]);

	propertiesForAsset.forEach((pa) => {
		const connectedProperties = getReferencedPropertiesForReferent(state, pa.id);
		propertiesWithReference.push(...connectedProperties);
	});

	return propertiesWithReference;
};

/**
 * @method hasDependants
 * @param propertyId The id of the property to check
 * @returns A selector which returns (boolean) whether the choice has any dependant properties
 */
export const hasDependants =
	(propertyId: Property['id']) =>
	(state: State): boolean => {
		const property = state.properties.byId[propertyId];
		if (!property) {
			return false;
		}
		return property.choiceIds.some((choiceId) => {
			const choice = state.choice.byId[choiceId];
			return choice ? choice.dependentPropertyIds.length > 0 : false;
		});
	};
