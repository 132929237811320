import FactCheckIcon from '@mui/icons-material/FactCheck';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ApiStudyDto, useGoogleTagManager } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

interface ValidateProps {
	disabled: boolean;
	onClose(): void;
	studyId: ApiStudyDto['id'];
	validate(): Promise<boolean>;
}

const Validate: React.FC<ValidateProps> = ({ disabled, onClose, studyId, validate }) => {
	const { trackCustomEvent } = useGoogleTagManager();
	const { enqueueSnackbar } = useSnackbar();

	const onClick = React.useCallback(async () => {
		trackCustomEvent({ event: 'template_validated', templateId: studyId });
		const result = await validate();
		if (result === true) {
			enqueueSnackbar('The template is valid, and may be published');
		} else {
			enqueueSnackbar('The template contains errors. They are highlighted in the editor');
		}
		onClose();
	}, [enqueueSnackbar, onClose, studyId, trackCustomEvent, validate]);

	return (
		<Tooltip
			placement="left"
			title="Validate the template, checking whether it is ready for publishing"
		>
			{/* The <span> is to make sure the child element of the <Tooltip> is always hover-able */}
			<span>
				<MenuItem disabled={disabled} onClick={onClick}>
					<ListItemIcon>
						<FactCheckIcon />
					</ListItemIcon>
					<ListItemText primary="Validate template" />
				</MenuItem>
			</span>
		</Tooltip>
	);
};

export default Validate;
