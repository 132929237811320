import { makeSelectors } from '@/store/repository';
import State from '@/store/state';
import { Choice } from '@/store/types';

const selector = makeSelectors<Choice>();

export const { get, getAll, getOrNull } = selector;

export const getSiblingChoiceForProperty =
	(choiceId: Choice['id'], placement: 'next' | 'previous') =>
	(state: State): Choice | null => {
		const parentProperty = Object
			// eslint-disable-next-line react/destructuring-assignment
			.values(state.properties.byId)
			.find((property) => property.choiceIds.includes(choiceId));
		if (parentProperty) {
			const choiceIndex = parentProperty.choiceIds.findIndex((id) => choiceId === id);
			if (choiceIndex !== -1) {
				if (placement === 'next') {
					const nextChoiceId = parentProperty.choiceIds[choiceIndex + 1]
						? parentProperty.choiceIds[choiceIndex + 1]
						: null;
					return nextChoiceId ? state.choice.byId[nextChoiceId] : null;
				}
				const previousChoiceId = parentProperty.choiceIds[choiceIndex - 1]
					? parentProperty.choiceIds[choiceIndex - 1]
					: null;
				return previousChoiceId ? state.choice.byId[previousChoiceId] : null;
			}
		}
		return null;
	};

export const getChoicesByIds =
	(choiceIds: string[]) =>
	(state: State): Choice[] => {
		const choices = choiceIds.map((choiceId) => {
			const choice = get(state.choice, choiceId);
			return choice;
		});
		return choices;
	};
