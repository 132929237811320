/* eslint-disable no-param-reassign, no-restricted-syntax */
import { Reducer, Action, combineReducers } from 'redux';
import { ApiAssetFilter } from '@/../../common/src';
import { assetFiltersGet, assetsGet } from '@/selectors';
import AppAction from '../appAction';
import State from '../state';
import actionsReducer from './actions';
import application from './application';
import router from './router';
import assets from './assets';
import categories from './categories';
import forms from './forms';
import properties from './properties';
import choice from './choice';
import changes from './changes';
import report from './reports';
import reportValidation from './reportsValidation';
import ruleSets from './ruleSets';
import assetFilters from './assetFilters';
import ui from './ui';

import * as actions from '../actions';
import makeReducer from '../makeReducer';
import { makeRepository } from '../repository';
import { Asset, Category, Choice, Property, PropertyType } from '../types';

const combinedReducer = combineReducers<State, AppAction>({
	actions: actionsReducer,
	application,
	router,
	assets,
	categories,
	forms,
	properties,
	choice,
	changes,
	report,
	reportValidation,
	ruleSets,
	assetFilters,
	ui,
});

const repositoryAssets = makeRepository<Asset>();
const repositoryCategories = makeRepository<Category>();
const repositoryChoices = makeRepository<Choice>();
const repositoryProperties = makeRepository<Property>();
const repositoryFilters = makeRepository<ApiAssetFilter>();

const deleteAsset = (draft: State, payload: Asset) => {
	repositoryCategories.modifyAll(draft.categories, (item): void => {
		const index = item.assetTypeIds.indexOf(payload.id);
		if (index >= 0) {
			item.assetTypeIds.splice(index, 1);
		}
	});
	for (const propertyId of payload.propertyIds) {
		const property: Property = draft.properties.byId[propertyId];
		repositoryChoices.modifyAll(draft.choice, (item) => {
			item.dependentPropertyIds = item.dependentPropertyIds.filter(
				(dependentPropertyId) => dependentPropertyId !== propertyId,
			);
		});
		for (const choiceId of property.choiceIds) {
			repositoryChoices.delete(draft.choice, choiceId);
			repositoryProperties.modifyAll(draft.properties, (item): void => {
				item.parentChoiceIds = item.parentChoiceIds.filter(
					(parentChoiceId) => parentChoiceId !== choiceId,
				);
				item.choiceIds = item.choiceIds.filter((itemChoiceId) => itemChoiceId !== choiceId);
			});
		}
		repositoryProperties.delete(draft.properties, propertyId);
		repositoryAssets.modifyAll(draft.assets, (item): void => {
			const index = item.propertyIds.indexOf(propertyId);
			if (index >= 0) {
				item.propertyIds.splice(index, 1);
			}
		});
	}
	for (const filterId of payload.assetFilterIds) {
		repositoryFilters.delete(draft.assetFilters, filterId);
	}
	repositoryAssets.delete(draft.assets, payload.id);
};

const crossSliceReducer = makeReducer(combinedReducer(undefined, { type: '@@INIT' }), {
	[actions.assetDelete.type]: deleteAsset,

	// When an entire category is fully deleted there is a lot going on
	// we have to reach in to many slices of state to make sure everything is properly cleaned up.
	// The only slice we are not reaching into here is `application`,
	// that one must still take care of itself for now
	[actions.categoryDelete.type]: (draft, payload): void => {
		for (const assetId of payload.assetTypeIds) {
			const asset: Asset = draft.assets.byId[assetId];
			deleteAsset(draft, asset);
		}
		repositoryCategories.delete(draft.categories, payload.id);
	},
	[actions.assetUpdate.type]: (draft, payload): void => {
		const currentAsset = assetsGet(draft, payload.id);
		if (currentAsset === undefined) return;

		if (payload?.repeating === false) {
			repositoryAssets.modify(draft.assets, payload.id, (asset) => {
				asset.assetFilterIds = [];
			});
			for (const filterId of currentAsset.assetFilterIds) {
				repositoryFilters.delete(draft.assetFilters, filterId);
			}
		}
	},
	[actions.propertyUpdate.type]: (draft, payload): void => {
		if (payload.type === undefined || payload?.type === PropertyType.DECIMAL) return;

		repositoryAssets.modifyAll(draft.assets, (item): void => {
			const index = item.propertyIds.indexOf(payload.id);
			if (index >= 0) {
				item.assetFilterIds = item.assetFilterIds.filter(
					(filterId) => assetFiltersGet(draft, filterId)?.propertyId !== payload.id,
				);
			}
		});
	},
	[actions.propertyDelete.type]: (draft, payload): void => {
		repositoryAssets.modifyAll(draft.assets, (item): void => {
			const index = item.propertyIds.indexOf(payload.id);
			if (index >= 0) {
				item.assetFilterIds = item.assetFilterIds.filter(
					(filterId) => assetFiltersGet(draft, filterId)?.propertyId !== payload.id,
				);
			}
		});
	},
	[actions.choiceUpdateSuccess.type]: (draft, payload): void => {
		const parentProperty = Object.values(draft.properties.byId).find((property) =>
			property.choiceIds.includes(payload.id),
		);
		if (parentProperty) {
			repositoryProperties.modify(draft.properties, parentProperty.id, (item) => {
				item.choiceIds.sort((a, b) => {
					const choiceA = draft.choice.byId[a];
					const choiceB = draft.choice.byId[b];
					return choiceA.position > choiceB.position ? 1 : -1;
				});
			});
		}
	},
});

const rootReducer: Reducer<State, Action> = (
	state: State | undefined,
	action: AppAction,
): State => {
	const intermediateState = combinedReducer(state, action);
	return crossSliceReducer(intermediateState, action);
};

export default rootReducer;
