import { auth0 } from 'kes-common';

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_CONNECTION, AUTH0_DOMAIN } from '@/constants';

const auth0Client = auth0.auth0ClientFactory({
	audience: AUTH0_AUDIENCE,
	clientId: AUTH0_CLIENT_ID,
	connection: AUTH0_CONNECTION,
	domain: AUTH0_DOMAIN,
});

export default auth0Client;
