/* eslint-disable no-else-return */
import React, { FC, useCallback, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect, ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import { Category, Asset, Property } from '@/store/types';
import State from '@/store/state';
import {
	categoriesGet,
	categoriesAllIds,
	categoriesGetOrNull,
	assetsGetOrNull,
	propertiesGet,
	categoriesGetAll,
	studyId as studyIdSelector,
	applicationName as applicationNameSelector,
} from '@/selectors';
import LinkContext from '@/components/LinkContext';
import Select from '@/components/rhdhv/Select';
import { ActionDispatchers } from '@/store/utils';
import {
	application,
	PropsOfRoute,
	applicationAsset,
	applicationAssetCreate,
	applicationPropertyCreate,
	applicationProperty,
} from '@/routes';
import {
	categoryPersist as categoryPersistAction,
	assetUpdate as assetUpdateAction,
	assetGenerate,
	assetPersist as assetPersistAction,
	assetDelete,
	propertyRemoveReference,
	reportUpdateAssetName as reportUpdateAssetNameAction,
} from '@/store/actions';
import withTitle from '@/components/hoc/withTitle';
import FormControlLabel from '@/components/rhdhv/FormControlLabel/FormControlLabel';
import useAutoFocusRef from '@/components/hooks/useAutoFocusRef';
import { validate as assetValidate } from '@/entities/asset';
import { ASSET_NEW_PREFIX } from '@/constants';
import { requires } from '@/components/hoc/withRenderGuard';
import SidePopup from '@/components/SidePopup';
import compose from '@/utils/compose';
import { DynamicTextField } from '@/components/rhdhv/TextField/DynamicTextField';
import { getReferencedPropertiesForAsset } from '@/selectors/property';
import { assetTypeDelete } from '@/net/api';
import Filters from './Asset/filters';
import classes from './ReportAssetUpsert.module.css';
import InsertIntoTemplate from './Asset/InsertIntoTemplate';

interface CategorySelectItemProps {
	category: Category | null;
}
const CategorySelectItem: FC<CategorySelectItemProps> = ({ category }): JSX.Element => (
	<span>{category && category.name}</span>
);

const ConnectedCategorySelectItem = connect(
	(state: State, ownProps: { item: Category['id'] }): CategorySelectItemProps => ({
		category: categoriesGetOrNull(state, ownProps.item),
	}),
)(CategorySelectItem);

const PropertiesChip = ({ propertyId }: { propertyId: Property['id'] }): JSX.Element => {
	const name = useSelector((state: State): string => propertiesGet(state, propertyId).name);
	return (
		<LinkContext
			to={applicationProperty}
			params={{
				applicationName: null,
				propertyId,
			}}
			className={classes.chipLink}
		>
			<Chip
				color="secondary"
				data-testid={`chip-property-${name}`}
				label={name}
				sx={{ marginRight: 0.5, marginY: 0.5 }}
			/>
		</LinkContext>
	);
};

interface PropertiesProps {
	assetId: Asset['id'];
	propertyIds: Asset['propertyIds'];
}

const Properties: FC<PropertiesProps> = ({ assetId, propertyIds }): JSX.Element => (
	<>
		<h3 className={classes.formControlTitle}>Properties</h3>
		<div>
			{propertyIds.map(
				(propertyId): JSX.Element => (
					<PropertiesChip propertyId={propertyId} key={propertyId} />
				),
			)}
		</div>
		<LinkContext
			data-testid="actions-create-property"
			to={applicationPropertyCreate}
			params={{
				applicationName: null,
				assetId,
			}}
			className={classes.chipLink}
		>
			<Chip
				color="secondary"
				icon={<AddCircleOutlineIcon />}
				label="Create property"
				sx={{ marginY: 0.5 }}
			/>
		</LinkContext>
	</>
);

const mapDispatchToProps = {
	categoryPersist: categoryPersistAction,
	assetUpdate: assetUpdateAction,
	assetPersist: assetPersistAction,
	reportUpdateAssetName: reportUpdateAssetNameAction,
};

export function shouldChangeAssetTypeWithPopulationRelationship(): boolean {
	const message =
		'Are you sure you want to change the type of this asset? ' +
		"All population by it's properties will be removed. Only single text/number properties in repeating assets are allowed to be used as populating answers.";
	// eslint-disable-next-line no-restricted-globals, no-alert
	return confirm(message);
}

interface Props extends ActionDispatchers<typeof mapDispatchToProps> {
	asset: Asset;
	categoryIds: Category['id'][];
	categoryId: Category['id'];
	categories: Record<Category['id'], Category>;
}

const AssetUpsert: FC<Props> = ({
	asset,
	categoryIds,
	categoryId,
	assetUpdate,
	assetPersist,
	categories,
	reportUpdateAssetName,
}): JSX.Element => {
	const history = useHistory();
	const autoFocus = useAutoFocusRef(asset.name.startsWith(ASSET_NEW_PREFIX));
	const { id } = asset;
	const dispatch = useDispatch();
	const applicationName = useSelector(applicationNameSelector);
	const referencedPropertiesForAsset = useSelector<State, Property[]>((state) =>
		getReferencedPropertiesForAsset(state, id),
	);
	const studyId = useSelector(studyIdSelector);
	const category = useSelector<State, Category>((state) => categoriesGet(state, categoryId));

	const categoryIdsToFilter = Object.keys(categories).filter((key) => {
		const filterCategory = categories[key];
		return filterCategory.fixed === true || filterCategory.type === 'FLAG';
	});
	const { store } = useContext(ReactReduxContext);

	const validateAsset = useCallback(
		(assetToValidate: Asset): ReturnType<typeof assetValidate> =>
			assetValidate(assetToValidate, store),
		[store],
	);

	const onDeleteAsset = (assetId: string) => {
		let message = `Delete ${asset.name}\n\n`;
		message += 'Are you sure you want to delete this asset?';
		message += ' All its properties will also be deleted.';
		message +=
			referencedPropertiesForAsset.length > 0
				? ' All population by this property will also be deleted.'
				: '';
		// eslint-disable-next-line no-alert, no-restricted-globals
		if (confirm(message)) {
			assetTypeDelete(assetId, studyId).then((response) => {
				if (response.status === 204) {
					dispatch(assetDelete(asset));
					history.push(application({ applicationName: studyId }));
				}
			});
		}
	};

	const assetUpdateWithName = useCallback(
		(updatedAsset: Asset): string | null => {
			if (updatedAsset.name === '') {
				assetUpdate({ ...updatedAsset, name: asset.name });
				return asset.name;
			} else {
				assetUpdate(updatedAsset);
				if (updatedAsset.name !== asset.name) {
					reportUpdateAssetName({ assetNameNew: updatedAsset.name, assetNameOld: asset.name });
				}
				return null;
			}
		},
		[asset],
	);

	const onChangeRepeating = (repeating: boolean) => {
		if (referencedPropertiesForAsset.length > 0 && !repeating) {
			if (shouldChangeAssetTypeWithPopulationRelationship()) {
				// eslint-disable-next-line no-restricted-syntax
				for (const referencedProperty of referencedPropertiesForAsset) {
					dispatch(
						propertyRemoveReference({
							propertyId: referencedProperty.id,
						}),
					);
				}
				assetUpdate({ id, repeating });
			}
		} else {
			assetUpdate({ id, repeating });
		}
	};

	return (
		<div className={classes.base}>
			<SidePopup
				title={asset.type === 'FLAG' ? 'Flag' : 'Asset'}
				onDelete={(): void => onDeleteAsset(asset.id)}
			>
				<div className={classes.form}>
					{asset.type === 'FLAG' && (
						<Box marginBottom={2}>
							<Alert severity="info">
								Flags allow the user to mark a property in any way you configure here. For instance:
								the user could mark an asset in the survey &quot;High&quot;, &quot;Medium&quot; or
								&quot;Low&quot; Risk. In that case you would create a single select property called
								Risk. You can use any property type. Use Text or Number for simple strings, Image
								for adding a photo or image to answer etc.
							</Alert>
						</Box>
					)}

					<div className={classes.formHelperBig}>
						<DynamicTextField
							data-testid="textfield-asset-name"
							className={classes.formControl}
							InputProps={{ classes: { root: classes.assetNameRoot } }}
							InputLabelProps={{ classes: { root: classes.assetNameRoot } }}
							name="name"
							value={asset}
							update={assetUpdateWithName}
							placeholder="Asset name"
							forwardRef={autoFocus}
							validator={validateAsset}
							variant="standard"
						/>
					</div>
					{category.type !== 'FLAG' && (
						<>
							<div className={classes.formHelperBig}>
								<Select
									className={classes.formControl}
									value={categoryIds.length === 0 ? undefined : categoryId || undefined}
									render={ConnectedCategorySelectItem}
									onChange={(changedCategoryId): void => {
										assetPersist({
											asset,
											categoryId: changedCategoryId,
											position: categories[changedCategoryId]
												? categories[changedCategoryId].assetTypeIds.length
												: 0,
										});
									}}
									items={categoryIds.filter(
										(itemCategoryId) => !categoryIdsToFilter.includes(itemCategoryId),
									)}
									label="Category"
								/>
							</div>
							<div className={classes.formHelperBig}>
								<DynamicTextField
									className={classes.formControl}
									data-testid="textfield-asset-info"
									InputLabelProps={{ classes: { root: classes.assetNameRoot } }}
									InputProps={{
										classes: { root: classes.assetNameRoot },
										endAdornment: (
											<InputAdornment position="end" sx={{ cursor: 'pointer' }}>
												<Tooltip title="Informational text is used to introduce the topic to your user in the survey. It is shown below the Title.">
													<InfoIcon />
												</Tooltip>
											</InputAdornment>
										),
									}}
									label="Info"
									maxRows={6}
									minRows={3}
									multiline
									name="info"
									placeholder="Info"
									update={assetUpdateWithName}
									validator={validateAsset}
									value={asset}
									variant="standard"
								/>
							</div>
						</>
					)}
					{category.type !== 'FLAG' && <h3 className={classes.formControlTitle}>Options</h3>}
					{category.type !== 'FLAG' && (
						<div className={classes.formHelperSmall}>
							<FormControlLabel
								data-testid="repeating-asset-checkbox"
								label="Repeating"
								control={
									<Checkbox
										data-testid={`select-asset-repeating-${asset.name}`}
										className={classes.formHelperSmall}
										checked={asset.repeating}
										onChange={(): void => onChangeRepeating(!asset.repeating)}
										color="primary"
									/>
								}
							/>
						</div>
					)}
					{asset.repeating && <Filters asset={asset} />}
					<Properties assetId={id} propertyIds={asset.propertyIds} />

					<Box marginY={2}>
						<Stack direction="column" spacing={1}>
							{asset.repeating && <InsertIntoTemplate assetId={asset.id} />}
							<Button
								color="primary"
								component={Link}
								fullWidth
								to={application({ applicationName })}
								variant="contained"
							>
								Done
							</Button>
						</Stack>
					</Box>
				</div>
			</SidePopup>
		</div>
	);
};

export const ApplicationReportAssetModify = withTitle('Edit asset')(
	connect(
		(
			state: State,
			ownProps: PropsOfRoute<typeof applicationAsset>,
		): {
			asset: Asset | null;
			categoryIds: Category['id'][];
			categoryId: Category['id'] | null;
			categories: Record<Category['id'], Category>;
		} => {
			const cat = categoriesAllIds(state)
				.map((id): Category => categoriesGet(state, id))
				.find(
					(category): boolean => category.assetTypeIds.indexOf(ownProps.match.params.assetId) >= 0,
				);
			return {
				asset: assetsGetOrNull(state, ownProps.match.params.assetId),
				categoryId: cat ? cat.id : null,
				categoryIds: categoriesAllIds(state),
				categories: categoriesGetAll(state),
			};
		},
		mapDispatchToProps,
	)(requires('asset', 'categoryId')(AssetUpsert)),
);

const mapDispatchToPropsAssetCreate = {
	categoriesGetOrNull,
};

export const ApplicationReportAssetCreate = compose(
	connect(null, mapDispatchToPropsAssetCreate),
	withTitle('Create asset'),
)((props: PropsOfRoute<typeof applicationAssetCreate>): JSX.Element | null => {
	const [newId, setNewId] = useState<Asset['id'] | null>(null);
	const dispatch = useDispatch();
	const state: State = useContext(ReactReduxContext).store.getState();
	useEffect((): void => {
		const id = uuidv4();
		let { categoryId } = props.match.params;
		const category = categoriesGetOrNull(state, categoryId);

		if (!category) {
			const categoryToPersist: Category = {
				id: categoryId,
				isDefault: false,
				stableId: categoryId,
				info: null,
				name: 'New Category',
				assetTypeIds: [],
				fixed: false,
				type: 'SURVEY',
			};
			dispatch(categoryPersistAction({ category: categoryToPersist }));
			categoryId = categoryToPersist.id;
		}
		dispatch(
			assetGenerate({
				id,
				categoryId,
				repeating: category?.type === 'FLAG' || false,
				type: category?.type || 'SURVEY',
			}),
		);
		setNewId(id);
	}, []);
	if (newId) {
		return (
			<Redirect
				to={applicationAsset({
					...props.match.params,
					assetId: newId,
				})}
			/>
		);
	}
	return null;
});
