import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

// eslint-disable-next-line import/prefer-default-export
export const loadAppInsights = (
	connectionString: string | undefined,
): ApplicationInsights | undefined => {
	if (connectionString) {
		const appInsights = new ApplicationInsights({
			config: {
				connectionString,
				// These options enable correlation, see
				// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#enable-correlation
				disableFetchTracking: false,
				enableCorsCorrelation: true,
				enableRequestHeaderTracking: true,
				enableResponseHeaderTracking: true,
				distributedTracingMode: DistributedTracingModes.W3C,
				// We use auto route tracking instead of the react plugin, since using the plugin requires a more
				// complicated setup where all history manipulation is done through a history initialized by us, see
				// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin#configuration
				// and the react-router faq page it links to. In my tests, page view tracking with the react plugin
				// fails when the useHistory hook is used, meaning we would have to ensure our function is used
				// everywhere in the application.
				enableAutoRouteTracking: true,
				autoTrackPageVisitTime: true,
				enableUnhandledPromiseRejectionTracking: true,
				correlationHeaderExcludedDomains: ['*.auth0.com', '*.split.io'],
			},
		});

		appInsights.loadAppInsights();
		appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
		return appInsights;
	}
	return undefined;
};
