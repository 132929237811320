import {
	ApiChoice,
	ApiProperty,
	QuestionDependencies,
	QuestionDependenciesProps,
} from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { choiceGetAll, propertiesGetAll } from '@/selectors';
import { propertyAddDependent, propertyRemoveDependent, propertyUpdate } from '@/store/actions';
import { PropertyDependentCombinator } from '@/store/types';

interface DependenciesProps {
	question: ApiProperty;
}

const Dependencies: React.FC<DependenciesProps> = ({ question }) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const choicesById = useSelector(choiceGetAll);
	const propertiesById = useSelector(propertiesGetAll);

	const choices: ApiChoice[] = React.useMemo(() => Object.values(choicesById), [choicesById]);
	const questions: ApiProperty[] = React.useMemo(
		() => Object.values(propertiesById),
		[propertiesById],
	);

	const onDependencyCombinatorChange: QuestionDependenciesProps['onDependencyCombinatorChange'] =
		React.useCallback(
			(dependentCombinator) => {
				dispatch(
					propertyUpdate({
						id: question.id,
						dependentCombinator: dependentCombinator as PropertyDependentCombinator,
					}),
				);
			},
			[dispatch],
		);

	const onParentChoiceAdd: QuestionDependenciesProps['onParentChoiceAdd'] = React.useCallback(
		(parentChoiceId) => {
			dispatch(propertyAddDependent({ choiceId: parentChoiceId, id: question.id }));
			enqueueSnackbar('Dependency successfully added');
		},
		[dispatch, enqueueSnackbar],
	);

	const onParentChoiceDelete: QuestionDependenciesProps['onParentChoiceDelete'] = React.useCallback(
		(parentChoice) => {
			dispatch(propertyRemoveDependent({ choiceId: parentChoice.id, id: question.id }));
		},
		[dispatch],
	);

	return (
		<QuestionDependencies
			choices={choices}
			key={question.id}
			l10n={{
				add: 'Add dependency',
				addLabelChoice: 'Choice',
				addLabelQuestion: 'Question',
				addTitle: 'Add a new parent question/choice',
				dependencyCombinatorDescription: (
					<>
						Decide how dependencies are handled. This question can either show when{' '}
						<strong>any</strong> of the dependencies are matched, or when <strong>all</strong> are
						matched.
					</>
				),
				dependencyCombinatorLabelAll: 'All',
				dependencyCombinatorLabelAny: 'Any',
				dependencyCombinatorTitle: 'How are dependencies handled?',
				errorNotDependent: 'This question has no dependencies on other questions',
				introduction:
					'Add dependencies here to make your question dependent on other questions/choices. Your question will thus then only show when the parent choice is selected.',
				questionDependentOn: 'This question is dependent on:',
			}}
			onDependencyCombinatorChange={onDependencyCombinatorChange}
			onParentChoiceAdd={onParentChoiceAdd}
			onParentChoiceDelete={onParentChoiceDelete}
			question={question}
			questions={questions}
		/>
	);
};

export default Dependencies;
