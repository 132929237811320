import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DependencyGraphDialog from '@/components/DependencyGraphDialog';
import { uiIsDependencyGraphOpen } from '@/selectors';
import { uiDependencyGraphToggle } from '@/store/actions';

const DependencyGraphContainer: React.FC = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(uiIsDependencyGraphOpen);

	const onToggle = React.useCallback(() => {
		dispatch(uiDependencyGraphToggle());
	}, [dispatch]);

	return <DependencyGraphDialog isOpen={isOpen} onClose={onToggle} />;
};

export default DependencyGraphContainer;
