import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
	background-color: #e8eaec;
	height: 100%;
	min-height: 100%;
	position: relative;
	z-index: 2;
`;
