import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { applicationForm } from '@/routes';
import { applicationName as applicationNameSelector, formsGetAll, formsStatus } from '@/selectors';
import * as actions from '@/store/actions';
import { useHistory } from 'react-router';

import FormContextMenu from '@/components/Forms/FormContextMenu/FormContextMenu';
import classes from './Sidebar.module.css';
import * as Styled from './Sidebar.styles';

const Sidebar: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const applicationName = useSelector(applicationNameSelector);
	const forms = useSelector(formsGetAll);
	const status = useSelector(formsStatus);
	React.useEffect(() => {
		dispatch(actions.formsLoad({ studyId: applicationName }));
	}, [applicationName]);

	const createForm = React.useCallback(() => {
		const formId = uuid();
		dispatch(actions.formsAdd({ id: formId, studyId: applicationName }));
		history.push(applicationForm({ applicationName, formId }));
	}, []);

	return (
		<div style={{ width: '400px' }}>
			{status === 'error' && <Typography>Failed to fetch the list of tables</Typography>}
			{status === 'loading' && (
				<>
					<Skeleton variant="text" />
					<Skeleton variant="text" />
					<Skeleton variant="text" />
				</>
			)}
			{status === 'success' && (
				<List>
					{Object.values(forms)
						.sort((a, b) => a.position - b.position)
						.map((form) => (
							<ListItem
								button
								classes={{ container: classes.listItemRoot }}
								className={classes.listItem}
								component={Link}
								key={form.id}
								to={applicationForm({ applicationName, formId: form.id })}
							>
								<ListItemText primary={<Styled.ListItemText>{form.name}</Styled.ListItemText>} />
								<ListItemSecondaryAction>
									<FormContextMenu form={form} applicationName={applicationName} />
								</ListItemSecondaryAction>
							</ListItem>
						))}
					<ListItem button onClick={createForm} className={classes.listItem}>
						<ListItemIcon>
							<Styled.AddCircleOutlineIcon />
						</ListItemIcon>
						<ListItemText style={{ textTransform: 'uppercase' }}>Add table</ListItemText>
					</ListItem>
				</List>
			)}
		</div>
	);
};

export default Sidebar;
