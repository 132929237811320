// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import styled, { css } from 'styled-components';

const linkStyles = css`
	&& {
		font-size: 16px;
		font-weight: 600;
		line-height: 31px;
		margin: 8px 0;
		text-decoration: none;
		width: 100%;
	}
`;

export const Breadcrumb = styled(Link)`
	${linkStyles}
	&& {
		color: ${({ theme }) => theme.palette.grey[500]};
		&:hover {
			text-decoration: underline;
		}
	}
`;

export const BreadcrumbTitle = styled(Typography)`
	${linkStyles}
	&& {
		color: ${({ theme }) => theme.palette.text.primary};
	}
`;
