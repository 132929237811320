import React, { FC, useContext } from 'react';
import { Asset, Property } from '@/store/types';
import { ReactReduxContext } from 'react-redux';
import State from '@/store/state';
import { assetsGet } from '@/selectors';
import tinymce from 'tinymce';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import classes from '@/components/SideBar.module.css';
import { useGoogleTagManager } from 'kes-common';
import ContentInsertTextContext from '../contexts/ContentInsertTextContext';
import { getReferenceText } from './InsertIcon.utils';

const InsertIcon: FC<{
	assetId: Asset['id'];
	propertyId?: Property['id'];
	ruleSet?: string;
	rule?: string;
	filter?: string;
}> = ({ assetId, propertyId, ruleSet, rule, filter }): JSX.Element | null => {
	const { interactionEnabled } = useContext(ContentInsertTextContext);
	// Bind the store here manually, so we get cheaper rerenders, and we don't need to pass the asset up into the
	// property renderers
	const { store } = useContext(ReactReduxContext);
	const { trackCustomEvent } = useGoogleTagManager();

	const onClick = (): void => {
		const state: State = store.getState();
		const asset = assetsGet(state, assetId);
		const text = getReferenceText({ state, asset, propertyId, ruleSet, rule, filter });
		trackCustomEvent({ event: 'ra-filter-used' });
		tinymce.activeEditor.insertContent(text);
	};

	return interactionEnabled ? (
		<SaveAltIcon onClick={onClick} className={classes.insertIcon} />
	) : null;
};

export default InsertIcon;
