import React from 'react';
import { useSelector } from 'react-redux';

import { assetsGetAll, categoriesGetAll } from '@/selectors';
import { getParentAssetsForProperties } from '@/selectors/assets';
import { getReferencedPropertiesForReferent } from '@/selectors/property';
import State from '@/store/state';
import { Asset, Category, Property, UUID } from '@/store/types';

import { filteredCategories } from './constants';

interface Parameters {
	assetId: string;
	item: Property;
}

interface MappedCategory extends Category {
	assetsForCategory: Asset[];
}

const useMappedCategories = ({ assetId, item }: Parameters): MappedCategory[] => {
	const categories = useSelector(categoriesGetAll);
	const assets = useSelector(assetsGetAll);

	const referencedProperties = useSelector<State, Property[]>((state) =>
		getReferencedPropertiesForReferent(state, item.id),
	);

	const filteredParentAssets = useSelector<State, UUID[]>((state) =>
		getParentAssetsForProperties(state.assets, referencedProperties),
	);

	const categoriesMapped = React.useMemo(
		() =>
			Object.values(categories)
				.filter((category) => !filteredCategories.some((c) => c === category.name))
				.map((category) => {
					const assetsForCategory = category.assetTypeIds
						.filter((id) => !filteredParentAssets.includes(id))
						.filter((id) => id !== assetId)
						.filter((id) => id !== item.referencedAssetType)
						.map((id) => assets[id]);
					return {
						...category,
						assetsForCategory,
					};
				})
				.filter((category) => category.assetsForCategory.length > 0),
		[assetId, assets, categories, filteredParentAssets, item],
	);

	return categoriesMapped;
};

export default useMappedCategories;
