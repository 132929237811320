import { createTheme, ThemeOptions } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { merge } from 'lodash';

interface UseCreateThemeOptions {
	allowColorSchemeOverride?: boolean;
}

const useCreateTheme = ({ allowColorSchemeOverride = true }: UseCreateThemeOptions) => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const mode = prefersDarkMode && allowColorSchemeOverride ? 'dark' : 'light';

	const themeDefaults: ThemeOptions = {
		palette: {
			mode,
			primary: { main: '#0086a8' },
			secondary: { main: '#00567d' },
		},
		typography: {
			fontFamily: 'Barlow, sans-serif',
		},
	};

	const themeOverrideDark: Partial<ThemeOptions> = {};

	const themeOverrideLight: Partial<ThemeOptions> = {
		components: {
			MuiAppBar: {
				styleOverrides: {
					colorDefault: {
						backgroundColor: '#fff',
						color: '#222',
					},
					colorPrimary: {
						backgroundColor: '#fff',
						color: '#222',
					},
				},
			},
		},
		palette: {
			background: { default: '#f5f5f5' },
		},
	};

	return createTheme(
		merge(themeDefaults, mode === 'dark' ? themeOverrideDark : themeOverrideLight),
	);
};

export default useCreateTheme;
