import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
const MenuCheckbox = styled(Checkbox)`
	&.MuiCheckbox-root,
	&.Mui-checked {
		color: white;
	}
	&.Mui-disabled {
		color: rgba(255, 255, 255, 0.26);
	}
`;

export default MenuCheckbox;
