import makeReducer from '@/store/makeReducer';
import { ApiRuleSetDto } from 'kes-common';
import * as actions from '../actions';
import { initialState, makeRepository } from '../repository';
import { RuleSet } from '../types';

const repository = makeRepository<RuleSet>();

function mapApiToLocal(api: ApiRuleSetDto): RuleSet {
	return api;
}

export default makeReducer(initialState<RuleSet>(), {
	[actions.applicationLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.ruleSetsById, mapApiToLocal);
	},
});
