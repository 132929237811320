import { changesStatus } from '@/selectors';
import { useSelector } from 'react-redux';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import usePrevious from '../components/hooks/usePrevious';

const useNotifyConnectionChange = () => {
	const connectionStatus = useSelector(changesStatus);
	const offlineSnackbarHandle = useRef<SnackbarKey>(0);
	const onlineSnackbarHandle = useRef<SnackbarKey>(0);
	const previousConnectionStatus = usePrevious(connectionStatus);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const closeSnackbarByHandle = (handle: React.MutableRefObject<SnackbarKey>) => {
		closeSnackbar(handle.current);
		handle.current = 0; // eslint-disable-line no-param-reassign
	};

	useEffect(() => {
		if (connectionStatus === 'offline' && offlineSnackbarHandle.current === 0) {
			closeSnackbarByHandle(onlineSnackbarHandle);
			offlineSnackbarHandle.current = enqueueSnackbar(
				'You are offline. If you close your window you will lose data.',
				{
					variant: 'warning',
					persist: true,
				},
			);
		}

		if (
			connectionStatus === 'done' &&
			previousConnectionStatus === 'offline' &&
			onlineSnackbarHandle.current === 0
		) {
			closeSnackbarByHandle(offlineSnackbarHandle);
			onlineSnackbarHandle.current = enqueueSnackbar('You are back online. Changes are saved.', {
				variant: 'info',
			});
		}
	}, [connectionStatus, previousConnectionStatus]);
};

export default useNotifyConnectionChange;
