import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ApiStudyDto, useGoogleTagManager } from 'kes-common';
import React from 'react';

import ScriptDownloadWrapper from '../DownloadScript';

interface DownloadScriptProps {
	onClose(): void;
	studyId: ApiStudyDto['id'];
}

const DownloadScript: React.FC<DownloadScriptProps> = ({ onClose, studyId }) => {
	const { trackCustomEvent } = useGoogleTagManager();

	return (
		<ScriptDownloadWrapper
			renderControl={(downloadScript) => (
				<Tooltip placement="left" title="Download the same Python script to use as a template">
					<MenuItem
						onClick={() => {
							downloadScript();
							onClose();
							trackCustomEvent({ event: 'template_script_downloaded', templateId: studyId });
						}}
					>
						<ListItemIcon>
							<img alt="Python" src="/python.svg" style={{ height: '1em' }} />
						</ListItemIcon>
						<ListItemText primary="Download script" />
					</MenuItem>
				</Tooltip>
			)}
		/>
	);
};

export default DownloadScript;
