import { propertiesGet } from '@/selectors';
import State from '@/store/state';
import { Asset } from '@/store/types';

const SEPARATOR = '|';

interface GetReferenceTextProps {
	state: State;
	asset: Asset;
	propertyId?: string;
	ruleSet?: string;
	rule?: string;
	filter?: string;
}

const propertyReference = (state: State, asset: Asset, propertyId: string): string => {
	const propertyClass = asset.repeating ? 'property-filter' : 'property';
	return `<span class='${propertyClass} mceNonEditable'>${[
		propertiesGet(state, propertyId).placeholder,
	].join(SEPARATOR)}</span>`;
};

const flagReference = (state: State, asset: Asset): string =>
	`<span class='flag mceNonEditable'>Flags|${asset.name}</span>`;

const assetReference = (state: State, asset: Asset): string => {
	const assetReferenceSpan = `<span class='asset mceNonEditable'>${asset.name}</span>`;
	return !asset.repeating
		? assetReferenceSpan
		: asset.propertyIds.reduce(
				(text, propertyId) => text + propertyReference(state, asset, propertyId),
				assetReferenceSpan,
		  );
};

const ruleReference = (
	state: State,
	asset: Asset,
	propertyId: string,
	ruleSet: string,
	rule: string,
): string =>
	`<span class='rule mceNonEditable'>${[
		asset.name,
		propertiesGet(state, propertyId).placeholder,
		ruleSet,
		rule,
	].join(SEPARATOR)}</span>`;

const filterReference = (asset: Asset, filter: string): string =>
	`<span class='asset-filter mceNonEditable'>${['Filters', asset.name, filter].join(
		SEPARATOR,
	)}</span>`;

// eslint-disable-next-line import/prefer-default-export
export const getReferenceText = (params: GetReferenceTextProps): string => {
	const { state, asset, propertyId, ruleSet, rule, filter } = params;
	if (!ruleSet && !rule && !propertyId && filter) return filterReference(asset, filter);
	if (!ruleSet && !rule && !propertyId && asset.type === 'FLAG') return flagReference(state, asset);
	if (!ruleSet && !rule && !propertyId) return assetReference(state, asset);
	if (!ruleSet && !rule && propertyId) return propertyReference(state, asset, propertyId);
	if (ruleSet && rule && propertyId) return ruleReference(state, asset, propertyId, ruleSet, rule);
	return '';
};
