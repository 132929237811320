import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React from 'react';

import NameAvatar from '../NameAvatar';

interface Props {
	goToProfile: () => void;
	isEmail: boolean;
	signout: () => void;
	signoutEnabled: boolean;
	userName: string;
}

const UserMenu: React.FC<Props> = ({ goToProfile, isEmail, signout, signoutEnabled, userName }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);
	const isOpen = Boolean(anchorElement);

	const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorElement(event.currentTarget);
	};
	const onClose = () => {
		setAnchorElement(null);
	};

	return (
		<>
			<NameAvatar avatarProps={{ onClick }} name={userName} isEmail={isEmail} />
			<Menu
				anchorEl={anchorElement}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				data-testid="central-navigation-menu__menu"
				keepMounted
				MenuListProps={{
					subheader: (
						<ListSubheader sx={{ lineHeight: 'normal', marginY: 1.5 }}>{userName}</ListSubheader>
					),
				}}
				onClose={onClose}
				open={isOpen}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
			>
				<MenuItem onClick={goToProfile}>
					<ListItemIcon>
						<ListItemIcon>
							<AccountCircleIcon color="secondary" />
						</ListItemIcon>
						<ListItemText color="grey.900" primary="Profile" />
					</ListItemIcon>
				</MenuItem>
				<MenuItem disabled={!signoutEnabled} onClick={signout}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Sign out" />
				</MenuItem>
			</Menu>
		</>
	);
};

export default UserMenu;
