import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useFeatureFlag } from 'kes-common';
import { useDispatch, useSelector } from 'react-redux';
import { categoryDelete } from '@/net/api';
import { application } from '@/routes';
import { Category } from '@/store/types';
import State, { SidebarMode } from '@/store/state';
import { applicationCategories, categoriesGet, studyId as studyIdSelector } from '@/selectors';
import { Box, Button } from '@mui/material';
import {
	categoryDelete as categoryDeleteAction,
	propertySelect,
	sidebarOrderMode,
	uiSidebarModeToggle,
} from '@/store/actions';
import List from '@/components/SideBar/List';
import CategoryItem from '@/components/SideBar/CategoryItem';
import { PropertySelectionMode } from '@/store/reducers/properties';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ContentInsertTextContext from './SideBar/contexts/ContentInsertTextContext';
import classes from './SideBar.module.css';
import CategoryDialogContainer, { CategoryDialogMode } from './SideBar/CategoryDialog';
import { filteredCategories } from './SideBar/PropertyContextMenu/constants';

interface SideBarProps {
	interactionEnabled: boolean;
}

const SideBar: FC<SideBarProps> = ({ interactionEnabled }): JSX.Element => {
	const history = useHistory();
	const [categoryId, setCategoryId] = useState('');
	const [categoryMenuAnchorEl, setCategoryMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [categoryDialogMode, setCategoryDialogMode] = useState<CategoryDialogMode>({
		state: 'Hide',
	});
	const selectionMode = useSelector<State, PropertySelectionMode>(
		(state) => state.properties.selectionMode,
	);

	const dispatch = useDispatch();
	const QUESTIONS_FIRST = useFeatureFlag({ name: 'QUESTIONS_FIRST' });

	const studyId = useSelector(studyIdSelector);

	const categories = useSelector<State, Category['id'][]>((state) => applicationCategories(state));
	const selected = useSelector<State, string>((state) => state.properties.selected);
	const orderMode = useSelector<State, boolean>((state) => state.properties.orderMode);
	const category = useSelector<State, Category | null>((state) => {
		if (categoryId) {
			return categoriesGet(state, categoryId);
		}
		return null;
	});

	const handleCategoryMenuButtonClick = (
		id: string,
		event: React.MouseEvent<HTMLElement>,
	): void => {
		setCategoryId(id);
		setCategoryMenuAnchorEl(event.currentTarget);
	};
	const handleCategoryMenuButtonClose = (): void => {
		setCategoryMenuAnchorEl(null);
	};
	const handleCategoryDelete = (): void => {
		setCategoryMenuAnchorEl(null);
		let message = category ? `Delete ${category.name}\n\n` : 'Delete category\n\n';
		message += 'Are you sure you want to delete this category?';
		message += ' All its assets will also be deleted.';
		// eslint-disable-next-line no-alert, no-restricted-globals
		if (confirm(message)) {
			setCategoryId('');
			categoryDelete(categoryId, studyId).then((response) => {
				if (response.status === 204) {
					history.push(application({ applicationName: studyId }));
					if (category) {
						dispatch(categoryDeleteAction(category));
					}
				}
			});
		}
	};

	const handleCategoryEdit = useCallback((): void => {
		setCategoryMenuAnchorEl(null);
		setCategoryDialogMode({
			state: 'Edit',
			categoryId,
		});
	}, [categoryId]);

	const handleCategoryClose = useCallback((): void => {
		setCategoryDialogMode({ state: 'Hide' });
	}, []);

	const onSwitchSidebarMode = React.useCallback(() => {
		history.push(application({ applicationName: studyId }));
		dispatch(uiSidebarModeToggle(SidebarMode.QUESTION));
	}, [dispatch, history, studyId]);

	const propertySelectDoneButton = selected && (
		<Box mr={2}>
			<Button
				data-testid="sidebar-select-done"
				onClick={() =>
					dispatch(propertySelect({ id: '', selectionMode: PropertySelectionMode.None }))
				}
				variant="contained"
				color="primary"
			>
				Done
			</Button>
		</Box>
	);
	const orderModeButton =
		!selected &&
		(!orderMode ? (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<span
				className={classes.topbarInteraction}
				onClick={(): void => {
					dispatch(sidebarOrderMode(true));
				}}
			>
				<SortIcon />
			</span>
		) : (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<span
				className={classes.topbarInteraction}
				onClick={(): void => {
					dispatch(sidebarOrderMode(false));
				}}
			>
				<DoneIcon />
			</span>
		));

	const contentInsertTextContextValue = React.useMemo(
		() => ({ interactionEnabled }),
		[interactionEnabled],
	);

	return (
		<div className={classNames(classes.root, { [classes.rootSelecting]: selected })}>
			<CategoryDialogContainer {...categoryDialogMode} onClose={handleCategoryClose} />
			<div className={classNames(classes.topbar, { [classes.topbarSelecting]: selected })}>
				<div className={classes.topbarTitle}>
					{selected ? 'select properties' : 'Asset library'}
				</div>
				<span className={classes.topbarInteraction} style={{ display: 'none' }}>
					<SearchIcon />
				</span>
				<span className={classes.topbarInteraction} style={{ display: 'none' }}>
					<SortIcon />
				</span>
				{propertySelectDoneButton}
				{orderModeButton}
			</div>
			<ContentInsertTextContext.Provider value={contentInsertTextContextValue}>
				<List
					component={CategoryItem}
					list={categories}
					handleCategoryMenuButtonClick={handleCategoryMenuButtonClick}
					orderMode={orderMode}
				/>
				{/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
				{selectionMode === PropertySelectionMode.None && (
					<div
						data-testid="actions-create-category"
						className={classes.categoryContainer}
						onClick={() => setCategoryDialogMode({ state: 'Add' })}
					>
						<ControlPointIcon fontSize="small" className={classes.icon} /> <span>Add category</span>
					</div>
				)}
			</ContentInsertTextContext.Provider>
			<Menu
				open={Boolean(categoryMenuAnchorEl)}
				onClose={handleCategoryMenuButtonClose}
				anchorEl={categoryMenuAnchorEl}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<MenuItem
					data-testid="actions-category-edit"
					disabled={
						category
							? filteredCategories.includes(category!.name) || category.type === 'FLAG'
							: true
					}
					onClick={handleCategoryEdit}
				>
					<CreateIcon className={classes.editIcon} />
					Edit
				</MenuItem>
				<MenuItem
					data-testid="actions-category-delete"
					disabled={
						category ? filteredCategories.includes(category.name) || category.type === 'FLAG' : true
					}
					onClick={handleCategoryDelete}
				>
					<DeleteIcon className={classes.deleteIcon} />
					Delete
				</MenuItem>
			</Menu>

			{QUESTIONS_FIRST && (
				<Box marginTop={1} textAlign="center">
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<Link
						component="button"
						onClick={onSwitchSidebarMode}
						textAlign="center"
						underline="hover"
					>
						Switch to questions layout
					</Link>
				</Box>
			)}
		</div>
	);
};

export default SideBar;
