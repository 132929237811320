import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ApiStudyDto, useGoogleTagManager } from 'kes-common';
import React from 'react';
import { useDispatch } from 'react-redux';

import { uiDependencyGraphToggle } from '@/store/actions';

interface ViewDependencyGraphProps {
	onClose(): void;
	studyId: ApiStudyDto['id'];
}

const ViewDependencyGraph: React.FC<ViewDependencyGraphProps> = ({ onClose, studyId }) => {
	const dispatch = useDispatch();
	const { trackCustomEvent } = useGoogleTagManager();

	const onToggle = React.useCallback(() => {
		trackCustomEvent({ event: 'template_dependency_graph_viewed', templateId: studyId });
		dispatch(uiDependencyGraphToggle());
		onClose();
	}, [dispatch, onClose, trackCustomEvent]);

	return (
		<Tooltip placement="left" title="View a graph showing all the dependencies between questions">
			<MenuItem onClick={onToggle}>
				<ListItemIcon>
					<AccountTreeIcon />
				</ListItemIcon>
				<ListItemText primary="View dependency graph" />
			</MenuItem>
		</Tooltip>
	);
};

export default ViewDependencyGraph;
