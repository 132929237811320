import FlagIcon from '@mui/icons-material/Flag';
import { Category } from '@/store/types';
import React, { FC, useState } from 'react';
import { SIDEBAR_CATEGORY_EXPANDED_BY_DEFAULT } from '@/constants';
import classes from '@/components/SideBar.module.css';
import Header from '@/components/SideBar/Header';
import classNames from 'classnames';
import CollapseIcon from '@/components/SideBar/CollapseIcon';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import State from '@/store/state';
import { categoriesGet } from '@/selectors';
import { categoryPersist } from '@/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import AssetItem from '@/components/SideBar/AssetItem';
import AddNewAsset from '@/components/SideBar/AddNewAsset/AddNewAsset';
import List, { ItemProps } from './List';
import LockIndicator from './LockIndicator';

interface CategoryItemProps extends ItemProps {
	handleCategoryMenuButtonClick: (categoryId: string, event: React.MouseEvent<HTMLElement>) => void;
	orderMode: boolean;
}

const CategoryItem: FC<CategoryItemProps> = ({
	id,
	handleCategoryMenuButtonClick,
	orderMode,
	index,
	listLength,
}): JSX.Element => {
	const [open, setOpen] = useState(SIDEBAR_CATEGORY_EXPANDED_BY_DEFAULT);

	const dispatch = useDispatch();

	const selected = useSelector<State, string>((state) => state.properties.selected);
	const item = useSelector<State, Category>((state) => categoriesGet(state, id));

	return (
		<li className={classes.item} data-testid={`category-list-item-${item.name}`}>
			<Header
				className={classNames(classes.categoryHeader, {
					[classes.headerSelecting]: selected,
				})}
				titleClassName={classes.categoryLink}
				title={item.name}
				data-testid="category-header"
			>
				{(item.fixed || item.isDefault) && <LockIndicator />}
				{item.type === 'FLAG' && <FlagIcon sx={{ marginRight: 2 }} />}
				<CollapseIcon
					state={open}
					setState={setOpen}
					shown={item.assetTypeIds.length > 0}
					data-testid={`expand-category-${item.name}`}
				/>
				<div className={classes.orderIcons}>
					{orderMode && (
						<ArrowUpwardIcon
							className={classNames(classes.orderIconUp, {
								[classes.orderIconDisabled]: index === 0,
							})}
							onClick={(): void => {
								dispatch(categoryPersist({ category: item, position: index - 1 }));
							}}
						/>
					)}
					{orderMode && (
						<ArrowDownwardIcon
							className={classNames(classes.orderIconDown, {
								[classes.orderIconDisabled]: index === listLength - 1,
							})}
							onClick={(): void => {
								dispatch(categoryPersist({ category: item, position: index + 1 }));
							}}
						/>
					)}
				</div>
				{!item.fixed && !item.isDefault && !selected && !orderMode && (
					<IconButton
						data-testid={`actions-category-overflow-${item.name}`}
						className={classes.categoryMenuButton}
						disableRipple
						onClick={(e): void => handleCategoryMenuButtonClick(item.id, e)}
						size="large"
					>
						<MoreHorizIcon />
					</IconButton>
				)}
			</Header>
			<List
				hidden={!open}
				list={item.assetTypeIds}
				component={AssetItem}
				orderMode={orderMode}
				assetList
				categoryId={id}
			/>
			{open && item.name !== 'Study Parameters' && item.assetTypeIds.length > 0 && (
				<AddNewAsset category={item} onCreateNewAsset={() => setOpen(true)} />
			)}
			{item.name !== 'Study Parameters' && item.assetTypeIds.length === 0 && (
				<AddNewAsset category={item} onCreateNewAsset={() => setOpen(true)} />
			)}
		</li>
	);
};

export default CategoryItem;
