import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { ApiForm } from 'kes-common';
import { debounce } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import TextField from '@mui/material/TextField';
import { applicationForm, applicationForms, PropsOfRoute } from '@/routes';
import { applicationName as applicationNameSelector, formsGetOrNull } from '@/selectors';
import * as actions from '@/store/actions';
import State from '@/store/state';
import { Box, Container, IconButton, Typography } from '@mui/material';
import * as Styled from './FormEditor.styles';
import FormFields from './FormFields';

const FormEditor: React.FC<PropsOfRoute<typeof applicationForm>> = ({ match }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const applicationName = useSelector(applicationNameSelector);
	const form = useSelector<State, ApiForm | null>((state) =>
		formsGetOrNull(state, match.params.formId),
	);
	const [name, setName] = React.useState<ApiForm['name']>(form?.name || '');

	React.useEffect(() => {
		if (form && form.name !== name.trim()) {
			setName(form.name);
		}
	}, [form]);

	const closeForm = React.useCallback(() => {
		history.push(applicationForms({ applicationName }));
	}, [applicationName]);

	const updateForm = React.useCallback(
		debounce((newFormValues: Partial<ApiForm> = {}) => {
			if (form) {
				const { fields, position, ...formValues } = form;
				dispatch(
					actions.formsUpdate({
						...formValues,
						...newFormValues,
						studyId: applicationName,
					}),
				);
			}
		}, 500),
		[applicationName, form],
	);

	const moveFormField = ({ destination, source, draggableId }: DropResult) => {
		if (!destination) return;
		if (destination?.index === source.index) return;

		dispatch(
			actions.formsFieldReorder({
				formId: form!!.id,
				fieldId: draggableId,
				position: destination.index,
				studyId: applicationName,
			}),
		);
	};

	return (
		<Styled.Container>
			{form && (
				<>
					<Box display="flex" flexDirection="row" alignItems="center">
						<Box marginRight={1.5}>
							<IconButton onClick={closeForm} size="large">
								<CloseIcon />
							</IconButton>
						</Box>
						<Box>
							<Typography variant="h4">{form.name}</Typography>
						</Box>
					</Box>
					<Box marginTop={4}>
						<Container maxWidth="md" style={{ backgroundColor: 'transparent' }}>
							<Grid container>
								<Grid item xs={4}>
									<TextField
										fullWidth
										inputProps={{ maxLength: '80' }}
										label="Table name"
										margin="normal"
										name="name"
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											const newName = event.target.value;
											setName(newName);
											updateForm({ name: newName.trim() });
										}}
										placeholder="Table name"
										value={name}
										variant="standard"
									/>
								</Grid>
							</Grid>
							<Box marginTop={2}>
								<FormFields
									studyId={applicationName}
									formId={form.id}
									fields={form.fields}
									onMoveFormField={moveFormField}
								/>
							</Box>
							<Box marginBottom={2} marginTop={2} textAlign="center">
								<Button color="secondary" onClick={closeForm} variant="contained">
									Done
								</Button>
							</Box>
						</Container>
					</Box>
				</>
			)}
		</Styled.Container>
	);
};

export default FormEditor;
