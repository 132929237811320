import { Auth0Client } from '@auth0/auth0-spa-js';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated } from '@azure/msal-react';
import { useState } from 'react';

interface UseAccessTokenFactoryOptions {
	auth0Client: Auth0Client;
	hasTestUsersEnabled: boolean;
	msalClientId: string;
	msalInstance: PublicClientApplication;
}

const useAccessTokenFactory =
	({
		auth0Client,
		hasTestUsersEnabled,
		msalClientId,
		msalInstance,
	}: UseAccessTokenFactoryOptions) =>
	() => {
		const [accessToken, setAccessToken] = useState<string | null>(null);
		const isAuthenticatedMsal = useIsAuthenticated();

		if (!hasTestUsersEnabled) {
			if (isAuthenticatedMsal) {
				const kesScope = `api://${msalClientId}/KES`;
				const request = {
					scopes: [kesScope],
					account: msalInstance.getActiveAccount() || undefined,
				};
				msalInstance
					.acquireTokenSilent(request)
					.then((response) => {
						setAccessToken(response.accessToken);
					})
					.catch((error) => {
						if (error instanceof InteractionRequiredAuthError) {
							msalInstance.acquireTokenPopup(request).then((response) => {
								setAccessToken(response.accessToken);
							});
						}
					});
			}
			auth0Client.isAuthenticated().then((isAuthenticatedAuth0) => {
				if (isAuthenticatedAuth0) {
					auth0Client.getTokenSilently().then((auth0AccessToken) => {
						setAccessToken(auth0AccessToken);
					});
				}
			});
		}
		return accessToken;
	};

export default useAccessTokenFactory;
