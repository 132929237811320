/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';

import * as actions from '../actions';
import { SidebarMode, StateUI } from '../state';

const initialState: StateUI = {
	isDependencyGraphOpen: false,
	sidebarMode:
		localStorage.getItem('SIDEBAR_MODE') !== null
			? (localStorage.getItem('SIDEBAR_MODE') as SidebarMode)
			: SidebarMode.QUESTION,
};

export default makeReducer(initialState, {
	[actions.uiDependencyGraphToggle.type]: (draft): void => {
		draft.isDependencyGraphOpen = !draft.isDependencyGraphOpen;
	},

	[actions.uiSidebarModeToggle.type]: (draft, payload): void => {
		draft.sidebarMode = payload;
	},
});
