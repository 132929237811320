import React, {
	ComponentType,
	ChangeEventHandler,
	ChangeEvent,
	useMemo,
	PropsWithChildren,
} from 'react';
import nameHoc from '@/utils/nameHoc';

type OnChange = ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

interface HasOnChange {
	onChange?: OnChange | undefined;
}

type StringEvented<P extends HasOnChange> = {
	[K in keyof P]?: K extends 'onChange' ? (payload: string) => void : P[K];
};

const withUnwrappedEvent = <P extends HasOnChange>(
	Component: ComponentType<P>,
): ComponentType<StringEvented<P>> => {
	const WithUnwrappedEvent = (props: PropsWithChildren<StringEvented<P>>): JSX.Element => {
		const { onChange, ...rest } = props;
		// Use a memo for the next function, so the heavy function is only created once
		const newOnChange = useMemo(
			(): OnChange =>
				(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
					if (onChange) {
						onChange(e.target.value);
					}
				},
			[onChange],
		);

		const newProps: P = {
			...rest,
			onChange: newOnChange,
		} as unknown as P; // This casting is required for a typescript bug that gives problems with generics and rest
		return <Component {...newProps} />;
	};
	return nameHoc(WithUnwrappedEvent, Component);
};

export default withUnwrappedEvent;
