import { createStore, applyMiddleware, Store } from 'redux';
import launchSagas from '@/sagas';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { appInsights } from '@/index';
import * as actionCreators from './actions';
import State from './state';
import rootReducer from './reducers';
import AppAction from './appAction';

const sagaMiddleware = createSagaMiddleware({
	onError: (error, { sagaStack }) => {
		// eslint-disable-next-line no-console
		console.error(error);
		// eslint-disable-next-line no-console
		console.error(sagaStack);
		appInsights?.trackException({ exception: error });
	},
});
const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });

const store: Store<State, AppAction> = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(launchSagas);

export default store;
