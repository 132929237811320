import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const useWhenOnline = <A extends any[]>(feature: string, fn: (...args: A) => void) => {
	const { enqueueSnackbar } = useSnackbar();

	return useCallback(
		(...args: A) => {
			if (window.navigator.onLine) {
				fn(...args);
			} else {
				enqueueSnackbar(`You cannot ${feature} when you are offline`, {
					variant: 'warning',
					preventDuplicate: true,
				});
			}
		},
		[feature, fn],
	);
};

export default useWhenOnline;
