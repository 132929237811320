/* eslint-disable no-template-curly-in-string */
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import Pages from '@/pages/index';
import store from '@/store';
import '@/styles/index.css';
import { BrowserRouter } from 'react-router-dom';
import msalInstance from '@/authentication/msal';
import ReactQueryProviders from '@/providers/ReactQueryProviders';
import TestUserSelector from '@/components/TestUserSelector';
import {
	APPLICATIONINSIGHTS_CONNECTION_STRING,
	GOOGLE_TAG_MANAGER_AUTH,
	GOOGLE_TAG_MANAGER_ID,
	GOOGLE_TAG_MANAGER_PREVIEW,
	TEST_USERS_ENABLED,
} from '@/constants';
import { Provider as AuthenticationProvider } from '@/providers/Authentication';
import FeatureFlagsProvider from '@/providers/FeatureFlags';
import {
	msal,
	ThemeProviders,
	loadAppInsights,
	useGoogleTagManager,
	ApiUserProfile,
} from 'kes-common';
import SnackbarProvider from '@/providers/SnackbarProvider';

// eslint-disable-next-line import/prefer-default-export
export const appInsights = loadAppInsights(APPLICATIONINSIGHTS_CONNECTION_STRING);

const Root: React.FC = () => {
	const { initialize } = useGoogleTagManager();
	React.useEffect(() => {
		initialize({
			auth: GOOGLE_TAG_MANAGER_AUTH,
			gtmId: GOOGLE_TAG_MANAGER_ID,
			preview: GOOGLE_TAG_MANAGER_PREVIEW,
		});
	}, []);

	React.useEffect(() => {
		msal.initialize(msalInstance);
	}, []);

	const onAuthenticationSuccess = React.useCallback((user: ApiUserProfile) => {
		appInsights?.setAuthenticatedUserContext(user.user.id);
	}, []);

	return (
		<ReactQueryProviders>
			{TEST_USERS_ENABLED && <TestUserSelector />}
			<ThemeProviders allowColorSchemeOverride={false}>
				<SnackbarProvider>
					<Provider store={store}>
						<AuthenticationProvider onSuccess={onAuthenticationSuccess}>
							<BrowserRouter>
								<FeatureFlagsProvider>
									<Pages />
								</FeatureFlagsProvider>
							</BrowserRouter>
						</AuthenticationProvider>
					</Provider>
				</SnackbarProvider>
			</ThemeProviders>
		</ReactQueryProviders>
	);
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);
