import CancelIcon from '@mui/icons-material/Cancel';
import { ApiActionDto } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chip from '@/components/Chip';
import { getRuleById } from '@/selectors/ruleSets';
import { actionDependentPropertyRemove } from '@/store/actions';
import State from '@/store/state';
import { Property, Rule } from '@/store/types';

interface ParentActionChipProps {
	actionId: ApiActionDto['id'];
	className?: string;
	propertyId: Property['id'];
}

const ParentActionChip: React.FC<ParentActionChipProps> = ({ actionId, className, propertyId }) => {
	const dispatch = useDispatch();
	const action = useSelector<State, ApiActionDto>((state) => state.actions.byId[actionId]);
	const property = useSelector<State, Property>(
		(state) => state.properties.byId[action.propertyId],
	);
	const rule = useSelector<State, Rule>(getRuleById(action.rule));
	const onDelete = React.useCallback(() => {
		dispatch(actionDependentPropertyRemove({ actionId, propertyId }));
	}, []);
	return (
		<Chip
			deleteIcon={<CancelIcon color="primary" fontSize="small" />}
			className={className}
			label={`${property.name}: ${rule.name}`}
			onDelete={onDelete}
		/>
	);
};

export default ParentActionChip;
