import { PropertySelectionMode } from '@/store/reducers/properties';
import { useSelector } from 'react-redux';
import State from '@/store/state';
import { Asset, Choice, Property, PropertyType } from '@/store/types';
import { getPropertyByChoiceId } from '@/selectors/property';
import { choiceGet, propertiesGet, propertiesGetOrNull } from '@/selectors';
import { getAssetWithPropertyId } from '@/selectors/assets';
import { RED_FLAG_ID } from '@/constants';
import { ApiActionDto } from 'kes-common';

const usePropertyItemProps = (id: string) => {
	let checked = false;
	let enabled = false;

	const selectionMode = useSelector<State, PropertySelectionMode>(
		(state) => state.properties.selectionMode,
	);
	const item = useSelector<State, Property>((state) => propertiesGet(state, id));

	const selected = useSelector<State, Property | null>((state) => {
		if (selectionMode === PropertySelectionMode.Dependent) {
			return getPropertyByChoiceId(state, state.properties.selected);
		}

		return propertiesGetOrNull(state, state.properties.selected);
	});

	const selectedAction = useSelector<State, ApiActionDto | null>((state) => {
		if (state.properties.selectedAction) {
			return state.actions.byId[state.properties.selectedAction] || null;
		}
		return null;
	});

	const choice = useSelector<State, Choice | undefined>((state) => {
		if (selectionMode === PropertySelectionMode.Dependent) {
			return choiceGet(state, state.properties.selected);
		}
		return undefined;
	});

	const selectedParentAsset = useSelector<State, Asset | undefined>((state) => {
		if (selected) {
			return getAssetWithPropertyId(state, selected.id);
		}
		return undefined;
	});

	const childAsset = useSelector<State, Asset>((state) => getAssetWithPropertyId(state, item.id));

	const isRedFlagAsset = childAsset.stableId === RED_FLAG_ID;

	if (
		[PropertySelectionMode.Dependent, PropertySelectionMode.DependentRule].includes(
			selectionMode,
		) &&
		selectedParentAsset
	) {
		enabled =
			selected !== null &&
			selected.id !== item.id &&
			(!selectedParentAsset.repeating || selectedParentAsset.id === childAsset.id);
	}
	if (selectionMode === PropertySelectionMode.Dependent) {
		checked = selected !== null && item.parentChoiceIds.includes(choice!.id);
	}
	if (selectionMode === PropertySelectionMode.DependentRule) {
		if (selectedAction) {
			checked = item.parentActionIds.includes(selectedAction.id);
		}
	}

	const currentPropertyParentAssetId = useSelector<State, Asset | undefined>((state) => {
		if (selected) {
			return getAssetWithPropertyId(state, item.id);
		}
		return undefined;
	});

	const isRelationshipSameAsset = currentPropertyParentAssetId?.id === selectedParentAsset?.id;

	const isCurrentParentAssetRepeating = currentPropertyParentAssetId?.repeating;

	const isNotStringOrDecimal =
		item.type !== PropertyType.STRING && item.type !== PropertyType.DECIMAL;

	const isMultiValue = item.allowMultipleAnswers;

	const isRelationshipRadioEnabled =
		selected?.identifyingProperty === item.id &&
		selected?.referencedAssetType === currentPropertyParentAssetId?.id;

	const { ruleIds } = item;

	return {
		checked,
		enabled,
		selectionMode,
		selected,
		selectedAction,
		item,
		isRelationshipSameAsset,
		isCurrentParentAssetRepeating,
		isNotStringOrDecimal,
		isMultiValue,
		isRelationshipRadioEnabled,
		currentPropertyParentAssetId,
		choice,
		isRedFlagAsset,
		ruleIds,
	};
};

export default usePropertyItemProps;
