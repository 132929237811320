/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import * as actions from '../actions';
import { StateApplication as State } from '../state';

const initialState: State = {
	id: '',
	assetLibraryId: '',
	name: '',
	prettyName: '',
	testProjectId: '',
	categories: [],
	isMilicense: false,
	loaded: false,
};

export default makeReducer(initialState, {
	[actions.applicationLoad.type]: (draft, payload): void => {
		draft.name = payload;
	},
	[actions.applicationLoadSuccess.type]: (draft, payload): void => {
		draft.categories = payload.assetLibrary.categoryIds;
		draft.prettyName = payload.assetLibrary.studyName;
		draft.testProjectId = payload.testProjectId;
		draft.isMilicense = payload.assetLibrary.featureMilicense;
		draft.id = payload.studyId;
		draft.assetLibraryId = payload.assetLibrary.id;
		draft.loaded = true;
	},
	[actions.categoryPersist.type]: (draft, payload): void => {
		if (payload.position === undefined) {
			draft.categories.push(payload.category.id);
		} else {
			const index = draft.categories.indexOf(payload.category.id);
			if (index !== payload.position) {
				if (index >= 0) draft.categories.splice(index, 1);
				draft.categories.splice(payload.position, 0, payload.category.id);
			}
		}
	},
	[actions.categoryDelete.type]: (draft, payload): void => {
		const index = draft.categories.indexOf(payload.id);
		if (index >= 0) {
			draft.categories.splice(index, 1);
		}
	},
});
