import { applicationSubCategoryCreate } from '@/routes';
import AddIcon from '@mui/icons-material/Add';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { studyId as studyIdSelector } from '@/selectors';
import { Category } from '@/store/types';

interface CreateSubCategoryProps {
	category: Category;
	onClose(): void;
}

const CreateSubCategory: React.FC<CreateSubCategoryProps> = ({ category, onClose }) => {
	const studyId = useSelector(studyIdSelector);

	return (
		<MenuItem
			component={Link}
			onClick={onClose}
			to={applicationSubCategoryCreate({ applicationName: studyId, categoryId: category.id })}
		>
			<ListItemIcon>
				<AddIcon />
			</ListItemIcon>
			<ListItemText primary="Create sub-category" />
		</MenuItem>
	);
};

export default CreateSubCategory;
