import { ApiAssetFilter } from 'kes-common';
import { DELETE_ITEM, makeRepository, State as RepositoryState } from '@/store/repository';
import makeReducer from '@/store/makeReducer';
import * as actions from '@/store/actions';
import { PropertyType } from '../types';

const repository = makeRepository<ApiAssetFilter>();

const initialState: RepositoryState<ApiAssetFilter> = {
	byId: {} as Record<ApiAssetFilter['id'], ApiAssetFilter>,
	allIds: [],
};

function mapApiToLocal(api: ApiAssetFilter): ApiAssetFilter {
	return api;
}

export default makeReducer(initialState, {
	[actions.applicationLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.assetFiltersById, mapApiToLocal);
	},
	[actions.filterCreate.type]: (draft, { assetId, ...filter }): void => {
		repository.upsert(draft, filter);
	},
	[actions.filterUpdate.type]: (draft, payload): void => {
		repository.upsert(draft, payload);
	},
	[actions.filterDelete.type]: (draft, payload): void => {
		repository.delete(draft, payload.filterId);
	},
	[actions.propertyUpdateRequest.type]: (draft, payload): void => {
		// eslint-disable-next-line consistent-return
		repository.modifyAll(draft, (filter) => {
			if (payload.id === filter.propertyId && payload.type !== PropertyType.DECIMAL)
				return DELETE_ITEM;
		});
	},
	[actions.propertyDelete.type]: (draft, payload): void => {
		// eslint-disable-next-line consistent-return
		repository.modifyAll(draft, (filter) => {
			if (payload.id === filter.propertyId) return DELETE_ITEM;
		});
	},
});
