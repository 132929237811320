import { propertiesGet } from '@/selectors';
import State from '@/store/state';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React from 'react';
import { useSelector } from 'react-redux';
import RuleList from './RuleList';
import { RulesDialog } from './RulesDialog';

interface RulesSetupProps {
	propertyId: string;
}

const RulesSetup: React.FC<RulesSetupProps> = ({ propertyId }) => {
	const ruleIds = useSelector((state: State) => propertiesGet(state, propertyId).ruleIds);
	const [showDialog, setShowDialog] = React.useState(false);

	return (
		<>
			<div>
				Create conditions to compare the input answer to one or more rules
				<RuleList ruleIds={ruleIds} propertyId={propertyId} />
				<Button
					color="primary"
					data-testid="rules-setup__add-rule"
					onClick={() => {
						setShowDialog(true);
					}}
				>
					<AddCircleOutlineIcon />
					Add rule
				</Button>
			</div>
			<RulesDialog
				show={showDialog}
				onClose={() => setShowDialog(false)}
				propertyId={propertyId}
				propertyRuleIds={ruleIds}
			/>
		</>
	);
};

export default RulesSetup;
