import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

import { ApiChoice, ApiProperty } from '../../net/swagger';

interface ParentChoiceProps {
	choiceId: ApiChoice['id'];
	choices: ApiChoice[];
	onParentChoiceDelete(parentChoice: ApiChoice): void;
	questions: ApiProperty[];
}

const ParentChoice: React.FC<ParentChoiceProps> = ({
	choices,
	choiceId,
	onParentChoiceDelete,
	questions,
}) => {
	const parentChoice = React.useMemo<ApiChoice>(
		() => choices.find((potentialChoice) => potentialChoice.id === choiceId)!,
		[choices, choiceId],
	);
	const parentChoiceProperty = React.useMemo<ApiProperty>(
		() =>
			questions.find((potentialQuestion) =>
				potentialQuestion.choiceIds?.includes(parentChoice.id),
			)!,
		[parentChoice, questions],
	);

	return (
		<ListItem
			disablePadding
			secondaryAction={
				<IconButton
					data-testid="question-dependencies__remove"
					onClick={() => onParentChoiceDelete(parentChoice)}
				>
					<RemoveIcon />
				</IconButton>
			}
		>
			<ListItemButton>
				<ListItemText
					primary={parentChoice.name}
					secondary={parentChoiceProperty.question || parentChoiceProperty.name}
				/>
			</ListItemButton>
		</ListItem>
	);
};

export default ParentChoice;
