import { propertiesGet } from '@/selectors';
import { propertyUpdate } from '@/store/actions';
import State from '@/store/state';
import { Property } from '@/store/types';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptionContainer, Icon, Text } from '../DefaultOption.styles';

const NotApplicableOption: React.FC<{ propertyId: string }> = ({ propertyId }) => {
	const dispatch = useDispatch();
	const property = useSelector<State, Property>((state) => propertiesGet(state, propertyId));

	const removeNotApplicableOption = () => {
		dispatch(
			propertyUpdate({
				...property,
				hasNotApplicableOption: false,
			}),
		);
	};

	return (
		<OptionContainer>
			<Icon />
			<Text variant="body1">Not applicable</Text>
			<CloseIcon data-testid="remove" onClick={removeNotApplicableOption} />
		</OptionContainer>
	);
};

export default NotApplicableOption;
