import React from 'react';
import { Box } from '@mui/material';
import * as styles from './KESLogo.styles';
import Logo from './logo.svg';
import TrainingLogo from './logo-training.svg';

interface Props {
	environment: string | undefined;
}

const KESLogo: React.FC<Props> = ({ environment }) => (
	<Box display="flex" flexDirection="row" alignItems="center" ml="8px" mr="24px">
		{environment === 'training' ? (
			<>
				<styles.default src={TrainingLogo} alt="kes training logo" />
				<Box color="#6358AF">Training</Box>
			</>
		) : (
			<styles.default src={Logo} alt="kes logo" />
		)}
	</Box>
);

export default KESLogo;
