import * as routes from '@/routes';
import LinkContext, { LinkProps, PartialRoute } from '@/components/LinkContext';
import React, { ComponentType, ReactNode } from 'react';
import Span from '@/components/SideBar/Span';
import classes from '@/components/SideBar.module.css';

interface HeaderBodyProps {
	children: React.ReactNode;
	className: string;
	['data-testid']?: string;
	linkProps: LinkProps;
	matches: boolean;
	title: string | JSX.Element;
	titleClassName: string;
	TitleComponent: ComponentType<{ children: React.ReactNode; className?: string } & LinkProps>;
}

const HeaderBody: React.FC<HeaderBodyProps> = ({
	children,
	className,
	'data-testid': dataTestid,
	linkProps,
	matches,
	title,
	titleClassName,
	TitleComponent,
}) => (
	<div
		data-testid={`${dataTestid}-${title}`}
		className={matches ? `${classes.headerActive} ${className}` : className}
	>
		{children}
		<TitleComponent {...linkProps} className={titleClassName}>
			{title}
		</TitleComponent>
	</div>
);

interface HeaderProps {
	to?: routes.Route<any>;
	params?: PartialRoute<routes.Route<any>>;
	className: string;
	titleClassName: string;
	title: string | JSX.Element;
	children?: ReactNode;
	['data-testid']?: string;
}

const Header: React.FC<HeaderProps> = ({
	children = null,
	className,
	'data-testid': dataTestid,
	params = undefined,
	to = undefined,
	...props
}) => {
	if (to && params) {
		return (
			<LinkContext to={to} params={params}>
				{(context): JSX.Element => (
					<HeaderBody
						data-testid={dataTestid}
						matches={context.matches}
						TitleComponent={context.Link}
						linkProps={context.linkProps}
						className={className}
						titleClassName={props.titleClassName}
						title={props.title}
					>
						{children}
					</HeaderBody>
				)}
			</LinkContext>
		);
	}
	return (
		<HeaderBody
			data-testid={dataTestid}
			matches={false}
			TitleComponent={Span}
			linkProps={{ href: '#', navigate: () => {} }}
			className={className}
			titleClassName={props.titleClassName}
			title={props.title}
		>
			{children}
		</HeaderBody>
	);
};

export default Header;
