import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import * as Styled from './ChangesIndicator.styles';

interface ChangesProps {
	status: 'saving' | 'offline' | 'done' | 'failed';
	savedAt?: Date | null;
}

const ChangesIndicator: React.FC<ChangesProps> = ({ savedAt, status }) => {
	const [ready, setReady] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setReady(true);
		}, 1500);
		return () => {
			setReady(false);
			clearTimeout(timer);
		};
	}, [status]);

	switch (status) {
		case 'done':
			if (!ready && savedAt) {
				return (
					<Box display="flex" alignItems="center">
						<Styled.Check color="secondary">done</Styled.Check>
						<p>Saved</p>
					</Box>
				);
			}
			return (
				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography variant="subtitle2">All changes saved</Typography>
					{savedAt !== null && (
						<Typography variant="caption">
							{' '}
							Last updated{' '}
							{new Intl.DateTimeFormat('en-GB', {
								hour: '2-digit',
								minute: '2-digit',
							}).format(savedAt)}
						</Typography>
					)}
				</Box>
			);

		case 'saving':
			return (
				<Box display="flex" alignItems="center" justifyItems="space-between">
					<Styled.CircularProgress color="secondary" size={20} />
					<Typography variant="subtitle1">Saving</Typography>
				</Box>
			);
			break;
		case 'offline':
			return (
				<Box display="flex" alignItems="center">
					<Styled.CircularProgress color="secondary" size={20} />
					<Typography variant="subtitle1">Restoring connection</Typography>
				</Box>
			);
			break;
		case 'failed':
			return (
				<Box display="flex" alignItems="center">
					<Styled.ErrorIcon color="secondary" />
					<Typography variant="subtitle1">Failed to save</Typography>
				</Box>
			);
		default:
			return null;
	}
};

export default ChangesIndicator;
