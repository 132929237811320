import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Choice, ReportTextSource } from '@/store/types';
import React, { FC } from 'react';
import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';
import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ActionDispatcher } from '@/store/utils';
import { choiceUpdate as choiceUpdateAction } from '@/store/actions';

import * as Styled from './index.styles';

const ReportTextSourceNames: Record<ReportTextSource, string> = {
	[ReportTextSource.ANSWER]: 'answer text',
	[ReportTextSource.FALSE]: 'no text',
	[ReportTextSource.ALTERNATE]: 'alternative text',
};

interface ReportTextSourceSelectorProps {
	choice: Choice;
	choiceUpdate: ActionDispatcher<typeof choiceUpdateAction>;
}

const ReportTextSection: FC<ReportTextSourceSelectorProps> = ({ choice, choiceUpdate }) => {
	const [pastedText, setPastedText] = React.useState<string | null>(null);
	const [alternateValue, setAlternateValue] = React.useState<string | null>(choice.reportText);
	const hasPastedText = React.useMemo(() => !!pastedText, [pastedText]);

	const ReportTextSectionInner = (event: SelectChangeEvent<Choice['reportTextSource']>) => {
		choiceUpdate({ id: choice.id, reportTextSource: event.target.value as ReportTextSource });
	};

	const formatPastedText = React.useCallback(() => {
		if (pastedText) {
			let formattedText = pastedText;
			while (formattedText.includes('\n\n')) {
				formattedText = formattedText.replace('\n\n', '\n');
			}
			setAlternateValue(formattedText);
			choiceUpdate({ id: choice.id, reportText: formattedText });
			setPastedText(null);
		}
	}, [choice, choiceUpdate, pastedText, setPastedText, setAlternateValue]);

	const onBlur = React.useCallback(() => {
		choiceUpdate({ id: choice.id, reportText: alternateValue });
	}, [alternateValue, choiceUpdate]);

	const onChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const { value } = event.target;
			setAlternateValue(value);
			if (value !== pastedText) {
				setPastedText(null);
			}
		},
		[pastedText, setAlternateValue, setPastedText],
	);

	const onPaste = React.useCallback(
		(event: React.ClipboardEvent<HTMLDivElement>) => {
			const text = event.clipboardData.getData('text');
			setPastedText(text);
		},
		[setPastedText],
	);

	return (
		<Styled.ChoiceReport>
			<Styled.ReportSelect>
				<Styled.ReportSelectText>If this answer is selected display</Styled.ReportSelectText>
				<Select
					className={classes.choiceReportSelect}
					value={choice.reportTextSource}
					onChange={ReportTextSectionInner}
					variant="standard"
				>
					{Object.entries(ReportTextSourceNames).map((reportName) => (
						<MenuItem value={reportName[0]} key={reportName[0]}>
							{reportName[1]}
						</MenuItem>
					))}
				</Select>
				<Styled.ReportSelectText>in template</Styled.ReportSelectText>
			</Styled.ReportSelect>
			{choice.reportTextSource === ReportTextSource.ALTERNATE && (
				<TextField
					helperText={
						hasPastedText ? (
							<Typography>
								Pasted text, {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link onClick={formatPastedText} sx={{ cursor: 'pointer' }}>
									format?
								</Link>
							</Typography>
						) : undefined
					}
					InputLabelProps={{ classes: { root: classes.choiceRoot } }}
					InputProps={{ classes: { root: classes.choiceRoot } }}
					label="Alternative template text"
					margin="normal"
					multiline
					name="reportText"
					onBlur={onBlur}
					onChange={onChange}
					onPaste={onPaste}
					value={alternateValue}
				/>
			)}
		</Styled.ChoiceReport>
	);
};

export default ReportTextSection;
