import { CircularProgress as MUICircularProgress } from '@mui/material';
import { Check as MUICheck } from '@mui/icons-material';
import MUIErrorIcon from '@mui/icons-material/Error';
import styled from 'styled-components';

export const Root = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const CircularProgress = styled(MUICircularProgress)`
	margin-right: 8px;
`;

export const Check = styled(MUICheck)`
	margin-right: 8px;
`;

export const ErrorIcon = styled(MUIErrorIcon)`
	margin-right: 8px;
`;
