import * as routes from '@/routes';
import { propertiesGet } from '@/selectors';
import { getReferencedPropertiesForReferent } from '@/selectors/property';
import * as actions from '@/store/actions';
import { Property } from '@/store/types';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import redirect from '../internal/redirect';
import { checkDependents, deletePropertyChoices } from './deletePropertyChoices';
import { shouldDelete } from './shouldDelete';

export function* onPropertyDelete(property: Property): SagaIterator {
	const referencingProperties: Property[] = yield select(
		getReferencedPropertiesForReferent,
		property.id,
	);

	// eslint-disable-next-line no-restricted-syntax
	for (const referencingProperty of referencingProperties) {
		yield put(
			actions.propertyRemoveReference({
				propertyId: referencingProperty.id,
			}),
		);
	}

	yield call(deletePropertyChoices, property);
	yield put(actions.propertyDelete(property));
}

export function* onPropertyDeleteRequest(
	action: ReturnType<typeof actions.propertyDeleteRequest>,
): SagaIterator {
	const property: Property = yield select(propertiesGet, action.payload.id);
	const hasDependents = yield call(checkDependents, property);
	const hasConnectedProperties = yield select(getReferencedPropertiesForReferent, property.id);

	if (
		shouldDelete(
			'property',
			property.name,
			property.choiceIds.length > 0,
			hasDependents,
			hasConnectedProperties.length > 0,
		)
	) {
		yield call(redirect, routes.application, routes.home);
		yield call(onPropertyDelete, property);
	}
}
