import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';

interface Props {
	avatarProps?: Partial<AvatarProps>;
	isEmail: boolean;
	name: string | null;
	tooltipProps?: Partial<TooltipProps>;
}

const getInitials = (isEmail: Props['isEmail'], name: Props['name']): string => {
	if (name) {
		if (isEmail) {
			return (
				name
					.split('@')[0]
					?.split('.')
					?.map((part) => part[0])
					?.join('') ?? ''
			);
		}
		return name
			.split(' ')
			.map((initial) => initial[0])
			.join('');
	}
	return '';
};

const NameAvatar: React.FC<Props> = ({ avatarProps, isEmail, name, tooltipProps }) => {
	if (name) {
		return (
			<Tooltip data-testid="name-avatar__tooltip" title={name} {...tooltipProps}>
				<Avatar
					color="secondary"
					data-testid="name-avatar__initials"
					{...avatarProps}
					sx={{
						fontSize: '0.875rem',
						height: '30px',
						textTransform: 'uppercase',
						width: '30px',
						...avatarProps?.sx,
					}}
				>
					{getInitials(isEmail, name)}
				</Avatar>
			</Tooltip>
		);
	}
	return null;
};

export default NameAvatar;
