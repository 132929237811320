import React, { FC, ComponentType, useMemo, useLayoutEffect, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Route, PropsOfRoute } from '@/routes';

export interface RouteInformation {
	matchesPath(
		path: string,
		props: RouteComponentProps & { onTitleUpdate: (payload: string) => void },
	): JSX.Element | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	route: Route<any>;
}

// eslint-disable-next-line react/jsx-no-useless-fragment
const IdentityLayout: FC<{ children: ReactNode }> = ({ children }): JSX.Element => <>{children}</>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineRoute<R extends Route<any>>(
	route: R,
	Component: ComponentType<PropsOfRoute<R> & { onTitleUpdate: (payload: string) => void }>,
): RouteInformation;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineRoute<R extends Route<any>>(
	route: R,
	Component: ComponentType<PropsOfRoute<R> & { onTitleUpdate: (payload: string) => void }>,
	Layout: ComponentType<{ children: ReactNode }>,
): RouteInformation;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineRoute<R extends Route<any>, P>(
	route: R,
	Component: ComponentType<PropsOfRoute<R> & { onTitleUpdate: (payload: string) => void }>,
	Layout: ComponentType<{ children: ReactNode } & P>,
	LayoutProps: P,
): RouteInformation;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineRoute<R extends Route<any>, P>(
	route: R,
	Component: ComponentType<PropsOfRoute<R> & { onTitleUpdate: (payload: string) => void }>,
	Layout: ComponentType<{ children: ReactNode } & P>,
	LayoutProps: (props: PropsOfRoute<R>) => P,
): RouteInformation;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineRoute<R extends Route<any>, P>(
	route: R,
	Component: ComponentType<PropsOfRoute<R> & { onTitleUpdate: (payload: string) => void }>,
	Layout?: ComponentType<{ children: ReactNode } & P>,
	layoutProps?: P | ((props: PropsOfRoute<R>) => P),
): RouteInformation {
	return {
		matchesPath(path, props): JSX.Element | null {
			const match = route.matchPath(path);
			if (!match) {
				return null;
			}

			const childrenProps = { ...props, match } as PropsOfRoute<R> & {
				onTitleUpdate: (payload: string) => void;
			};
			const children = <Component {...childrenProps} />;
			if (Layout && layoutProps instanceof Function) {
				return <Layout {...layoutProps(childrenProps)}>{children}</Layout>;
			}
			if (Layout && layoutProps) {
				const simpleProps = layoutProps as P;
				return <Layout {...simpleProps}>{children}</Layout>;
			}
			if (Layout) {
				const CastedLayout = Layout as ComponentType<{ children: ReactNode }>;
				return <CastedLayout>{children}</CastedLayout>;
			}
			return <IdentityLayout>{children}</IdentityLayout>;
		},
		route,
	};
}

interface Props extends RouteComponentProps {
	redirectUrl: string | null;
	onRouteUpdate: (payload: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		route: Route<any>;
		path: string;
	}) => void;
	// eslint-disable-next-line react/no-unused-prop-types
	onTitleUpdate: (payload: string) => void;
	routeList: RouteInformation[];
}

const RouterSwitch: FC<Props> = function RouterSwitch(props): JSX.Element | null {
	const {
		location: { pathname },
		routeList,
	} = props;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result = useMemo((): { element: JSX.Element; route: Route<any> } | null => {
		// eslint-disable-next-line no-restricted-syntax
		for (const info of routeList) {
			const match = info.matchesPath(pathname, props);
			if (match) {
				return { element: match, route: info.route };
			}
		}
		return null;
	}, [props, routeList, pathname]);

	const resultRoute = result ? result.route : null;

	// Effect for informating the route listener
	const { onRouteUpdate } = props;
	useLayoutEffect((): void => {
		if (resultRoute) {
			onRouteUpdate({
				route: resultRoute,
				path: pathname,
			});
		}
	}, [onRouteUpdate, resultRoute, pathname]);

	// Effect for resetting the scroll position
	useLayoutEffect((): void => {
		window.scrollTo(0, 0);
	}, [resultRoute]);

	// Special case for supporting redirection
	const { history, location, redirectUrl } = props;
	useLayoutEffect((): void => {
		if (redirectUrl && location.pathname !== redirectUrl) {
			history.push(redirectUrl);
		}
	}, [history, location.pathname, redirectUrl, resultRoute]);
	return result ? result.element : null;
};

export default withRouter(RouterSwitch);
