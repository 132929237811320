import { ApiUserProfile } from 'kes-common';
import { getUserProfile } from '@/net/api';
import { Avatar } from '@mui/material';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled, { keyframes } from 'styled-components';
import { testUsers, TestUserType } from './users';

const appear = keyframes`
   0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
`;

const IdAndNameContainer = styled.div`
	margin: auto 8px;
`;

const P = styled.p`
	margin: 0;
	padding: 0;
`;

const TestUserContainer = styled.div<{ selected: boolean }>`
	display: flex;
	font-size: 11px;
	font-weight: 600;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	background-color: ${({ selected }) => (selected ? '#3498db' : '#fff')};
	color: ${({ selected }) => (selected ? '#fff' : '#000')};
	padding: 8px;
	width: 400px;
	border-radius: 15px;
	margin: 4px;
`;

const TestUsersContainer = styled.div`
	display: flex;
	flex-direction: column;
	animation: ${appear} 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const TestUserSelectorContainer = styled.div`
	position: fixed;
	right: 0;
	top: 30%;
	display: flex;
	background-color: #fff7e6bb;
	border-radius: 15px;
	z-index: 100;
	padding: 8px;
`;

const TestUser: React.FC<{
	user: TestUserType;
	selected: boolean;
	selectUser: (id: string) => void;
	userProfile?: ApiUserProfile;
}> = ({ user, selected, selectUser, userProfile }) => (
	<TestUserContainer onClick={() => selectUser(user.id)} selected={selected}>
		<Avatar alt="test-user-avatar" src={user.picture} />
		<IdAndNameContainer>
			<P>
				<strong>Id:</strong>
				{user.id}
			</P>
			<P>
				<strong>Name:</strong>
				{user.name}
			</P>
			<P>
				<strong>Role:</strong>
				{user.role}
			</P>
			{!userProfile && <P>Click to load profile</P>}
		</IdAndNameContainer>
	</TestUserContainer>
);

const TestUserSelector = () => {
	const [hidden, setHidden] = useState(true);
	const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);
	const { data } = useQuery(['getUserProfiles'], () => getUserProfile());
	const setUser = (id: string) => {
		localStorage.setItem('Test-User-Id', id);
		setSelectedUser(id);
		window.location.reload();
	};

	useEffect(() => {
		const userFromLocalStorage = localStorage.getItem('Test-User-Id');
		if (userFromLocalStorage) {
			setSelectedUser(userFromLocalStorage);
		} else {
			localStorage.setItem('Test-User-Id', testUsers[1].id);
			window.location.reload();
		}
	}, []);

	return (
		<TestUserSelectorContainer>
			{hidden ? (
				<ArrowLeft onClick={() => setHidden(false)} />
			) : (
				<ArrowRight onClick={() => setHidden(true)} />
			)}
			{!hidden && (
				<TestUsersContainer>
					{testUsers.map((testUser) => (
						<TestUser
							selectUser={(id) => setUser(id)}
							selected={selectedUser === testUser.id}
							key={testUser.id}
							user={testUser}
							userProfile={
								data?.status === 200 && data.result.email === testUser.email
									? data.result
									: undefined
							}
						/>
					))}
				</TestUsersContainer>
			)}
		</TestUserSelectorContainer>
	);
};

export default TestUserSelector;
