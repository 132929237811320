import styled from 'styled-components';

export const LocationAnswerContainer = styled.div`
	padding: 16px;
	background-color: white;
	margin: 8px 0;
	display: flex;
`;

export const MapImage = styled.img`
	width: 100px;
	height: 100px;
	margin-right: 16px;
`;
