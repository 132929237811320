import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ApiStudyDto } from 'kes-common';
import React from 'react';
import { ReactReduxContext, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import tinymce from 'tinymce';

import { application } from '@/routes';
import { propertyDeleteRequest } from '@/store/actions';
import State from '@/store/state';
import { Asset, Property } from '@/store/types';
// What the hell is up with this import?
import { getReferenceText } from '@/components/SideBar/InsertIcon/InsertIcon.utils';

interface ContextMenuProps {
	anchorElement: HTMLElement | null;
	asset: Asset | null;
	onClose(): void;
	property: Property;
	studyId: ApiStudyDto['id'];
}

const ContextMenu: React.FC<ContextMenuProps> = ({
	anchorElement,
	asset,
	onClose,
	property,
	studyId,
}) => {
	const storeValue = React.useContext(ReactReduxContext);

	const dispatch = useDispatch();
	const history = useHistory();

	const onDelete = React.useCallback(() => {
		history.push(application({ applicationName: studyId }));
		dispatch(propertyDeleteRequest({ id: property.id }));
	}, [dispatch, history, property, studyId]);

	const onInsert = React.useCallback(() => {
		if (asset) {
			const state: State = storeValue.store.getState();
			const text = getReferenceText({ state, asset, propertyId: property.id });
			tinymce.activeEditor.insertContent(text);
			onClose();
		}
	}, [asset, onClose, property, storeValue]);

	return (
		<Menu anchorEl={anchorElement} onClose={onClose} open={anchorElement !== null}>
			<MenuItem onClick={onInsert}>Insert into template</MenuItem>
			<MenuItem disabled={property.fixed} onClick={onDelete}>
				Delete
			</MenuItem>
		</Menu>
	);
};

export default ContextMenu;
