import { Asset } from '@/store/types';
import React, { FC } from 'react';
import classes from '@/components/SideBar.module.css';
import Header from '@/components/SideBar/Header';
import * as routes from '@/routes';
import classNames from 'classnames';
import MenuCheckbox from '@/components/SideBar/MenuCheckbox';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
	actionDependentPropertyAdd,
	actionDependentPropertyRemove,
	propertyAddDependent,
	propertyAddReference,
	propertyDisconnectFromInput,
	propertyPersist,
	propertyRemoveDependent,
} from '@/store/actions';
import { useDispatch } from 'react-redux';
import LockIndicator from '@/components/SideBar/LockIndicator';
import List, { ItemProps } from '@/components/SideBar/List';
import Radio from '@/components/SideBar/Radio';
import { PropertySelectionMode } from '@/store/reducers/properties';
import usePropertyItemProps from '@/components/SideBar/PropertyItem/usePropertyItemProps';
import PropertyContextMenu from '@/components/SideBar/PropertyContextMenu';
import { Box } from '@mui/material';
import CollapseIcon from '../CollapseIcon';
import RuleItem from './RuleItem';

interface PropertyItemProps extends ItemProps {
	assetId: Asset['id'];
	orderMode: boolean;
}

const PropertyItem: FC<PropertyItemProps> = ({
	id,
	assetId,
	orderMode,
	index,
	listLength,
}): JSX.Element => {
	const [showRules, setShowRules] = React.useState(false);
	const dispatch = useDispatch();
	const {
		checked,
		enabled,
		selectionMode,
		item,
		selected,
		selectedAction,
		choice,
		currentPropertyParentAssetId,
		isCurrentParentAssetRepeating,
		isMultiValue,
		isNotStringOrDecimal,
		isRelationshipRadioEnabled,
		isRelationshipSameAsset,
		isRedFlagAsset,
		ruleIds,
	} = usePropertyItemProps(id);

	const selectPropertyAsRelationshipForPopulation = () => {
		if (selected && currentPropertyParentAssetId) {
			dispatch(
				propertyAddReference({
					propertyId: selected.id,
					targetPropertyId: item.id,
					parentAssetTypeId: currentPropertyParentAssetId.id,
				}),
			);
		}
	};

	const onChange = () => {
		if (selected != null) {
			if (selectionMode === PropertySelectionMode.Computed) {
				if (checked) {
					dispatch(
						propertyDisconnectFromInput({
							computedPropertyId: selected.id,
							id,
						}),
					);
				} else {
					dispatch(
						propertyDisconnectFromInput({
							computedPropertyId: selected.id,
							id,
						}),
					);
				}
			} else if (selectionMode === PropertySelectionMode.Dependent && choice) {
				if (checked) {
					dispatch(
						propertyRemoveDependent({
							id,
							choiceId: choice.id,
						}),
					);
				} else {
					dispatch(
						propertyAddDependent({
							id,
							choiceId: choice.id,
						}),
					);
				}
			} else if (selectionMode === PropertySelectionMode.DependentRule) {
				if (selectedAction) {
					if (checked) {
						dispatch(
							actionDependentPropertyRemove({ actionId: selectedAction.id, propertyId: id }),
						);
					} else {
						dispatch(actionDependentPropertyAdd({ actionId: selectedAction.id, propertyId: id }));
					}
				}
			}
		}
	};

	return (
		<li className={classes.item}>
			<Header
				data-testid="propertyitem"
				to={item.fixed || selected ? undefined : routes.applicationProperty}
				params={{ propertyId: item.id, applicationName: null }}
				className={classNames(
					item.fixed ? classes.propertyHeaderReadonly : classes.propertyHeaderEditable,
					{ [classes.headerSelecting]: selected },
					{ [classes.headerActive]: checked },
				)}
				titleClassName={item.fixed ? classes.propertyLinkReadonly : classes.propertyLink}
				title={item.name}
			>
				<CollapseIcon state={showRules} setState={setShowRules} shown={!!ruleIds.length} />
				{selected && selectionMode !== PropertySelectionMode.Relationship ? (
					<div className={classes.checkBox}>
						<MenuCheckbox
							onChange={onChange}
							checked={checked}
							disabled={!enabled}
							data-testid={`select-propertyitem-${item.name}`}
						/>
					</div>
				) : null}
				{selectionMode === PropertySelectionMode.Relationship && (
					<div className={classes.radio}>
						<Radio
							data-testid={`select-propertyitem-${item.name}`}
							checked={isRelationshipRadioEnabled}
							onClick={selectPropertyAsRelationshipForPopulation}
							disabled={
								!isCurrentParentAssetRepeating ||
								isNotStringOrDecimal ||
								isRelationshipSameAsset ||
								isMultiValue ||
								isRedFlagAsset
							}
						/>
					</div>
				)}
				{item.fixed && !selected && <LockIndicator />}
				<div className={classes.orderIcons}>
					{orderMode && (
						<ArrowUpwardIcon
							className={classNames(classes.orderIconUp, {
								[classes.orderIconDisabled]: index === 0,
							})}
							onClick={(): void => {
								dispatch(
									propertyPersist({
										property: item,
										assetId,
										position: index - 1,
									}),
								);
							}}
						/>
					)}
					{orderMode && (
						<ArrowDownwardIcon
							className={classNames(classes.orderIconDown, {
								[classes.orderIconDisabled]: index === listLength - 1,
							})}
							onClick={(): void => {
								dispatch(
									propertyPersist({
										property: item,
										assetId,
										position: index + 1,
									}),
								);
							}}
						/>
					)}
				</div>
				{!selected && !orderMode && <PropertyContextMenu assetId={assetId} item={item} />}
			</Header>
			<Box ml={5}>
				<List
					hidden={!showRules}
					list={ruleIds}
					component={RuleItem}
					assetId={assetId}
					assetRepeating={isCurrentParentAssetRepeating}
					orderMode={orderMode}
					propertyId={item.id}
				/>
			</Box>
		</li>
	);
};

export default PropertyItem;
