import { SagaIterator } from '@redux-saga/core';
import { select, put } from '@redux-saga/core/effects';
import { Route } from '@/routes';
import { routerParams } from '@/selectors';
import { routerRedirect } from '@/store/actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function paramsMatches<T>(route: Route<T>, params: any): params is T {
	// eslint-disable-next-line no-restricted-syntax
	for (const key of route.keys) {
		if (!(key in params)) {
			return false;
		}
	}
	return true;
}

/**
 * Redirect the user to a route from an saga, automatically infering any route parameters depending on the current route
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* redirect(...routes: Route<any>[]): SagaIterator {
	const currentParams: ReturnType<typeof routerParams> = yield select(routerParams);
	// eslint-disable-next-line no-restricted-syntax
	for (const route of routes) {
		if (paramsMatches(route, currentParams)) {
			yield put(routerRedirect(route(currentParams)));
			return true;
		}
	}
	return false;
}
