import { Configuration, PublicClientApplication } from '@azure/msal-browser';

interface PublicClientApplicationFactoryOptions {
	clientId: string;
	redirectUri: string;
	tenantId: string;
}

const msalInstanceFactory = ({
	clientId,
	redirectUri,
	tenantId,
}: PublicClientApplicationFactoryOptions): PublicClientApplication => {
	const config: Configuration = {
		auth: {
			authority: `https://login.microsoftonline.com/${tenantId}`,
			clientId,
			redirectUri: `${window.location.origin}${redirectUri || ''}`,
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: true,
		},
	};

	return new PublicClientApplication(config);
};

export default msalInstanceFactory;
