import React from 'react';
import { propertyPersist } from '@/store/actions';
import { Asset, Property } from '@/store/types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Collapse,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import getDraggableStyle from '@/styles/dragAndDrop';
import SidebarQuestion from '../SidebarQuestion';

interface SibebarQuestionsListProps {
	asset: Asset;
	properties: Record<string, Property>;
}
const SidebarQuestionsSublist = ({ asset, properties }: SibebarQuestionsListProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isDisabled, setIsDisabled] = React.useState(true);
	const isZeroLength = asset.propertyIds.length === 0;
	const isOneLength = asset.propertyIds.length === 1;

	React.useEffect(() => {
		setIsDisabled(isZeroLength);
	}, [asset]);

	const dispatch = useDispatch();

	const onDragEnd = React.useCallback(
		({ destination, draggableId }: DropResult) => {
			if (!destination) return; // dropped outside the list
			dispatch(
				propertyPersist({
					property: properties[draggableId],
					assetId: asset.id,
					position: destination.index,
				}),
			);
		},
		[asset, dispatch, properties],
	);

	return (
		<>
			<ListItemButton disabled={isDisabled} onClick={() => setIsOpen(!isOpen)}>
				<ListItemIcon>
					<IconButton>{isOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
				</ListItemIcon>
				<ListItemText primary={asset.name} />
			</ListItemButton>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="questions">
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								<List sx={{ width: '400px' }}>
									{asset.propertyIds.map((propertyId, index) => {
										const property = properties[propertyId];
										return (
											<Draggable
												isDragDisabled={isOneLength}
												draggableId={property.id}
												index={index}
												key={property.id}
											>
												{(providedInner, snapshotInner) => (
													<div
														ref={providedInner.innerRef}
														{...providedInner.draggableProps}
														{...providedInner.dragHandleProps}
														style={getDraggableStyle(providedInner, snapshotInner)}
													>
														<SidebarQuestion asset={asset} property={property} />
													</div>
												)}
											</Draggable>
										);
									})}
								</List>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</Collapse>
		</>
	);
};

export default SidebarQuestionsSublist;
