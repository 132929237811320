import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { useFeatureFlag } from 'kes-common';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SidebarForms from '@/components/Forms/Sidebar';
import SideBar from '@/components/SideBar';
import { application, applicationForms } from '@/routes';
import { uiGetSidebarMode } from '@/selectors';
import { SidebarMode } from '@/store/state';

import classes from './Base.module.css';
import SidebarContainerQuestions from './SidebarContainerQuestions';

enum SidebarTabs {
	SURVEY = 0,
	TABLES = 1,
}

interface SidebarContainerProps {
	applicationName: string;
	interactionEnabled: boolean;
}

const SidebarContainer: React.FC<SidebarContainerProps> = ({
	applicationName,
	interactionEnabled,
}) => {
	const [activeTab, setActiveTab] = React.useState<number>(() =>
		window.location.href.includes('/forms') ? SidebarTabs.TABLES : SidebarTabs.SURVEY,
	);

	const sidebarMode = useSelector(uiGetSidebarMode);

	const QUESTIONS_FIRST = useFeatureFlag({ name: 'QUESTIONS_FIRST' });
	const history = useHistory();

	const handleTabChange = React.useCallback(
		(event: React.ChangeEvent<{}>, newTab: number): void => {
			if (newTab !== activeTab) {
				setActiveTab(newTab);
				history.push(
					newTab === SidebarTabs.SURVEY
						? application({ applicationName })
						: applicationForms({ applicationName }),
				);
			}
		},
		[activeTab, applicationName, setActiveTab],
	);

	if (QUESTIONS_FIRST && sidebarMode === SidebarMode.QUESTION) {
		return <SidebarContainerQuestions />;
	}

	return (
		<nav className={classes.sidebar}>
			<Tabs onChange={handleTabChange} value={activeTab} variant="fullWidth">
				<Tab label="Survey" />
				<Tab
					disabled
					label={
						<Tooltip title="Forms are currently disabled while in production. Please use 'Flags' as a replacement of previous functionality.">
							<span>Forms</span>
						</Tooltip>
					}
					style={{ pointerEvents: 'auto' }}
				/>
			</Tabs>
			{activeTab === SidebarTabs.SURVEY && <SideBar interactionEnabled={interactionEnabled} />}
			{activeTab === SidebarTabs.TABLES && <SidebarForms />}
		</nav>
	);
};

export default SidebarContainer;
