import { useIsAuthenticated } from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ApiUserProfile } from '../../net/swagger';
import getUserFieldsFromAD from '../../utils/getUserFieldsFromAD';
import { Provider as UserProvider } from '../User';
import ProviderSelector from './ProviderSelector';
import { AuthenticationProviderProps } from './types';

enum AuthenticationState {
	Done,
	Error,
	Initializing,
	Selecting,
}

const Provider: React.FC<AuthenticationProviderProps> = ({
	auth0Client,
	children,
	fetchUserProfile,
	hasTestUsersEnabled,
	msalInstance,
	onSuccess,
	updateUserProfile,
}) => {
	const [state, setState] = React.useState<AuthenticationState>(AuthenticationState.Initializing);
	const [user, setUser] = React.useState<ApiUserProfile>();
	const isAuthenticatedMsal = useIsAuthenticated();

	const fetchUserDetails = React.useCallback(() => {
		const fetchUserDetailsAsync = async () => {
			try {
				const userProfile = await fetchUserProfile();
				setUser(userProfile);
				if (onSuccess) {
					onSuccess(userProfile);
				}
				setState(AuthenticationState.Done);
			} catch (_) {
				setState(AuthenticationState.Error);
			}
		};
		return fetchUserDetailsAsync();
	}, [fetchUserProfile]);

	const checkLoginAuth0 = React.useCallback(() => {
		const checkLoginAuth0Async = async () => {
			if (
				window.location.search.includes('state=') &&
				(window.location.search.includes('code=') || window.location.search.includes('error='))
			) {
				await auth0Client.handleRedirectCallback();
			}
			await auth0Client.checkSession();
			const isAuthenticated = await auth0Client.isAuthenticated();
			if (isAuthenticated) {
				await fetchUserDetails();
				return true;
			}
			return false;
		};
		return checkLoginAuth0Async();
	}, [auth0Client]);

	const checkLoginMsal = React.useCallback(() => {
		const checkLoginMsalAsync = async () => {
			if (isAuthenticatedMsal) {
				await fetchUserDetails();
				const account = msalInstance.getActiveAccount();
				if (account !== null) {
					const userFieldsFromActiveDirectoryV2 = await getUserFieldsFromAD({
						account,
						publicClientApplication: msalInstance,
					});
					await updateUserProfile(userFieldsFromActiveDirectoryV2);
				}
				return true;
			}
			return false;
		};
		return checkLoginMsalAsync();
	}, [msalInstance, isAuthenticatedMsal, updateUserProfile]);

	const signout = React.useCallback(() => {
		const signoutAsync = async () => {
			if (isAuthenticatedMsal) {
				await msalInstance.logoutRedirect();
			}
			if (await auth0Client.isAuthenticated()) {
				auth0Client.logout({ federated: true, returnTo: window.location.origin });
			}
		};
		return signoutAsync();
	}, [auth0Client, isAuthenticatedMsal, msalInstance]);

	const redirect = React.useCallback(() => {
		const redirectAsync = async () => {
			const query = new URLSearchParams(window.location.search);
			if (query.has('code') && query.has('state')) {
				const locationPath = (await auth0Client.handleRedirectCallback()).appState ?? '';
				window.history.replaceState({}, document.title, locationPath);
			}
		};
		return redirectAsync();
	}, []);

	React.useEffect(() => {
		const checkLoginAsync = async () => {
			if (hasTestUsersEnabled) {
				await fetchUserDetails();
			} else {
				const isMsal = await checkLoginMsal();
				const isAuth0 = await checkLoginAuth0();
				if (isMsal || isAuth0) {
					await redirect();
				} else {
					setState(AuthenticationState.Selecting);
				}
			}
		};
		checkLoginAsync();
	}, [checkLoginAuth0, checkLoginMsal, hasTestUsersEnabled, fetchUserDetails]);

	return (
		<>
			{state === AuthenticationState.Error && <Typography>Authentication error</Typography>}
			{state === AuthenticationState.Selecting && <ProviderSelector auth0Client={auth0Client} />}
			{state === AuthenticationState.Done && user && (
				<UserProvider signout={signout} value={user}>
					{children}
				</UserProvider>
			)}
			{state === AuthenticationState.Initializing && null}
		</>
	);
};

export default Provider;
