import React from 'react';
import classes from './NoApplicationSelected.module.css';

const NoApplicationSelectedPage = (): JSX.Element => (
	<div className={classes.container}>
		<h1>
			Whoops! We could not find that page{' '}
			<span aria-label=":(" role="img">
				😕
			</span>
		</h1>
		<p>
			{`There was no application selected. Make sure your URL matches
				the following pattern: https://${window.location.host}/{applicationName}`}
		</p>
	</div>
);

export default NoApplicationSelectedPage;
