import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { applicationPropertyCreateQuestion } from '@/routes';
import { assetsGetAll, propertiesGetAll, studyId } from '@/selectors';
import { getFirstAssetForQuestionCreation } from '@/selectors/assets';
import SidebarQuestionsSublist from './SidebarQuestionsSublist';

const SidebarQuestionsList = () => {
	const propertiesById = useSelector(propertiesGetAll);
	const applicationName = useSelector(studyId);
	const assets = useSelector(assetsGetAll);
	const asset = useSelector(getFirstAssetForQuestionCreation);

	return (
		<>
			<List sx={{ width: 400 }}>
				{Object.values(assets).map((ass) => (
					<SidebarQuestionsSublist key={ass.id} asset={ass} properties={propertiesById} />
				))}
			</List>

			<Box marginX={2}>
				<Button
					data-testid="create-question-button"
					component={Link}
					disabled={asset === null}
					fullWidth
					to={
						asset ? applicationPropertyCreateQuestion({ applicationName, assetId: asset?.id }) : ''
					}
					variant="contained"
				>
					Create Question
				</Button>
			</Box>
		</>
	);
};

export default SidebarQuestionsList;
