import { makeSelectors } from '@/store/repository';
import { Rule, RuleSet } from '@/store/types';
import State from '@/store/state';

const selector = makeSelectors<RuleSet>();

export const { get } = selector;

export const getRuleById =
	(ruleId: string) =>
	(state: State): Rule => {
		// eslint-disable-next-line no-restricted-syntax
		for (const ruleSetId in state.ruleSets.byId) {
			if (Object.prototype.hasOwnProperty.call(state.ruleSets.byId, ruleSetId)) {
				const ruleSet = get(state.ruleSets, ruleSetId);
				const rule = ruleSet.rules.find((ruleSetRule) => ruleSetRule.id === ruleId);
				if (rule) return rule;
			}
		}
		throw new Error(`getRuleById: No rule found with ruleId: ${ruleId}`);
	};

export const getRuleSetByRuleId =
	(ruleId: string) =>
	(state: State): RuleSet => {
		// eslint-disable-next-line no-restricted-syntax
		for (const ruleSetId in state.ruleSets.byId) {
			if (Object.prototype.hasOwnProperty.call(state.ruleSets.byId, ruleSetId)) {
				const ruleSet = get(state.ruleSets, ruleSetId);
				const ruleIsInSet = ruleSet.rules.some((rule) => rule.id === ruleId);
				if (ruleIsInSet) return ruleSet;
			}
		}
		throw new Error(`getRuleSetByRuleId: No rule set found which contains ruleId: ${ruleId}`);
	};

export const getRuleSetList = (state: State): RuleSet[] => Object.values(state.ruleSets.byId);
