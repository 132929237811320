import { fork } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import reports from './reports';
import router from './router';
import error from './error';
import recursiveDelete from './recursiveDelete';
import actions from './actions';
import application from './application';
import forms from './forms';
import ui from './ui';

export default function* init(): SagaIterator {
	yield fork(reports);
	yield fork(router);
	yield fork(error);
	yield fork(recursiveDelete);
	yield fork(actions);
	yield fork(application);
	yield fork(forms);
	yield fork(ui);
}
