import React, { ComponentType } from 'react';
import nameHoc from '@/utils/nameHoc';

export default function withRenderGuard<P, E extends P>(
	guard: (props: P) => props is E,
): (Component: ComponentType<E>) => ComponentType<P> {
	return (Component: ComponentType<E>): ComponentType<P> => {
		const WithRenderGuard = (props: P): JSX.Element | null => {
			if (guard(props)) {
				// @ts-ignore
				return <Component {...props} />;
			}
			return null;
		};
		return nameHoc(WithRenderGuard, Component);
	};
}

type AllowNull<I> = {
	[K in keyof I]?: I[K] | undefined | null;
};
type AllowNullExpect<O, K extends keyof O> = Pick<O, K> & AllowNull<Pick<O, Exclude<keyof O, K>>>;

export function requires<K extends string>(
	...names: K[]
): <E>(Component: ComponentType<E>) => ComponentType<AllowNullExpect<E, Exclude<keyof E, K>>> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return withRenderGuard((props): props is any => {
		// eslint-disable-next-line no-restricted-syntax
		for (const name of names) {
			const key = name as unknown as keyof typeof props;
			if (props[key] === null || props[key] === undefined) {
				return false;
			}
		}
		return true;
	});
}
