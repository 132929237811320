/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import { ApiAssetFilter, ApiProperty, useGoogleTagManager } from 'kes-common';
import { createFilter, updateFilter } from '@/net/api';
import { assetFiltersGet } from '@/selectors';
import { filterCreate, filterUpdate } from '@/store/actions';
import State from '@/store/state';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import FilterDialog from './FilterDialog';

/* eslint-disable default-case */
export interface FilterDialogEdit {
	state: 'Edit';
	filterId: string;
}

export interface FilterDialogAdd {
	state: 'Add';
}

export interface FilterDialogHide {
	state: 'Hide';
}

export type FilterDialogMode = FilterDialogEdit | FilterDialogAdd | FilterDialogHide;

export interface CommonFilterDialogProps {
	assetId: string;
	existingFilterNames: string[];
	onClose: () => void;
	properties: ApiProperty[];
}

const makeFilter = (): ApiAssetFilter => ({
	id: '',
	checkType: 'EQUALS',
	propertyId: '',
	name: '',
	checkValue: 0,
	upperBound: 0,
});

const FilterDialogContainer: FC<CommonFilterDialogProps & FilterDialogMode> = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const [errors, setErrors] = useState<yup.ValidationError[]>([]);

	const filter = useSelector((state: State) =>
		props.state === 'Edit' ? assetFiltersGet(state, props.filterId) : makeFilter(),
	);

	const dispatch = useDispatch();

	const namesWithoutSelf = props.existingFilterNames.filter((name) => name !== filter.name);
	const validationSchema = yup.object().shape({
		checkType: yup.string().required(),
		propertyId: yup.string().required(),
		name: yup
			.string()
			.required()
			.notOneOf(namesWithoutSelf, 'Filter with this name already exists'),
		checkValue: yup.number().required(),
		upperBound: yup.number().when('checkType', {
			is: 'RANGE',
			then: (schema) =>
				schema.required().moreThan(yup.ref('checkValue'), 'Upper bound must be higher'),
		}),
	});

	const { trackCustomEvent } = useGoogleTagManager();

	const createAction = async (newFilter: ApiAssetFilter) => {
		try {
			await validationSchema.validate(newFilter, { abortEarly: false });
			setErrors([]);

			const { id, ...apiCreateFilter } = newFilter;
			const apiCall = await createFilter(apiCreateFilter);

			if (apiCall.ok) {
				const { assetFilterId } = apiCall.expectSuccess();
				dispatch(filterCreate({ ...newFilter, assetId: props.assetId, id: assetFilterId }));
				props.onClose();
				trackCustomEvent({ event: 'ra-filter-created' });
			} else throw Error(`Unexpected response from api: ${apiCall.statusText}`);
		} catch (exception) {
			if (exception instanceof yup.ValidationError) setErrors(exception.inner);
			else if (exception instanceof Error)
				enqueueSnackbar(`Error saving filter: ${exception.message}`, { variant: 'error' });
		}
	};

	const editAction = async (editFilter: ApiAssetFilter) => {
		try {
			await validationSchema.validate(editFilter, { abortEarly: false });
			setErrors([]);

			const { id, ...apiUpdateFilter } = editFilter;
			const apiCall = await updateFilter(id, apiUpdateFilter);

			if (apiCall.ok) {
				dispatch(filterUpdate(editFilter));
				props.onClose();
			} else throw Error(`Unexpected response from api: ${apiCall.statusText}`);
		} catch (exception) {
			if (exception instanceof yup.ValidationError) setErrors(exception.inner);
			else if (exception instanceof Error)
				enqueueSnackbar(`Error saving filter: ${exception.message}`, { variant: 'error' });
		}
	};

	switch (props.state) {
		case 'Hide':
			return null;
		case 'Add':
			return (
				<FilterDialog
					errors={errors}
					title="New filter"
					onClose={props.onClose}
					filter={filter}
					onAction={createAction}
					properties={props.properties}
					show
				/>
			);
		case 'Edit':
			return (
				<FilterDialog
					errors={errors}
					title="Edit filter"
					onClose={props.onClose}
					filter={filter}
					onAction={editAction}
					properties={props.properties}
					show
				/>
			);
	}
};

export default FilterDialogContainer;
