import React from 'react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import GlobalStyles from './globalStyles';

import useCreateTheme from '../../styling/theme';

import '@fontsource/barlow';

interface ThemeProvidersProps {
	allowColorSchemeOverride?: boolean;
	children: React.ReactNode;
}

const ThemeProviders: React.FC<ThemeProvidersProps> = ({ allowColorSchemeOverride, children }) => {
	const theme = useCreateTheme({ allowColorSchemeOverride });

	return (
		<StyledEngineProvider>
			<ThemeProvider theme={theme}>
				<StyledComponentsThemeProvider theme={theme}>
					<>
						<GlobalStyles />
						<CssBaseline />
						{children}
					</>
				</StyledComponentsThemeProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default ThemeProviders;
