import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import { ApiStudyDto } from 'kes-common';
import React from 'react';

import DownloadScript from './DownloadScript';
import Publish from './Publish';
import Validate from './Validate';
import ViewDependencyGraph from './ViewDependencyGraph';
import EditEndMessage from './EditEndMessage';

interface OverflowMenuProps {
	anchorElement: HTMLElement | null;
	isValidating: boolean;
	onClose(): void;
	studyId: ApiStudyDto['id'];
	validate(): Promise<boolean>;
}

const OverflowMenu: React.FC<OverflowMenuProps> = ({
	anchorElement,
	isValidating,
	onClose,
	studyId,
	validate,
}) => (
	<Menu anchorEl={anchorElement} onClose={onClose} open={Boolean(anchorElement)}>
		<MenuList>
			<EditEndMessage onClose={onClose} studyId={studyId} />
			<Validate disabled={isValidating} onClose={onClose} studyId={studyId} validate={validate} />
			<Publish
				disabled={isValidating}
				onBeforePublish={validate}
				onClose={onClose}
				studyId={studyId}
			/>

			<DownloadScript onClose={onClose} studyId={studyId} />
			<ViewDependencyGraph onClose={onClose} studyId={studyId} />
		</MenuList>
	</Menu>
);

export default OverflowMenu;
