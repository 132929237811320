import { acquireToken, Api, Security, SecurityProviders } from 'kes-common';
import auth0Client from '@/authentication/auth0';
import msalInstance from '@/authentication/msal';
import { testUsers } from '@/components/TestUserSelector/users';
import { TEST_USERS_ENABLED, BACKEND_URL, MSAL_CLIENT_ID } from '@/constants';

const singleton = new Api(BACKEND_URL);

type BearerAuth = Security<'BearerAuth', never>;

type ApiParams = readonly any[];

interface Options {
	cache?: Request['cache'];
	headers?: Record<string, string>;
}

function withAuthentication<T extends ApiParams, R extends any>(
	apiCall: (sec: BearerAuth, ...args: [...T, Options]) => Promise<R>,
): (...args: T) => Promise<R> {
	return async function (...args: T): Promise<R> { // eslint-disable-line func-names
		const options: Options = {};

		if (TEST_USERS_ENABLED) {
			// push the options argument as the last one in the args
			options.headers = {
				'Test-User-Id': localStorage.getItem('Test-User-Id') || testUsers[0].id,
			};
		}
		const accessToken = await acquireToken({
			auth0Client,
			hasTestUsersEnabled: TEST_USERS_ENABLED,
			msalClientId: MSAL_CLIENT_ID,
			msalInstance,
		});

		const security = SecurityProviders.BearerAuth(accessToken);
		return apiCall(security, ...args, options);
	};
}

export const actionDependentPropertyAdd = withAuthentication(
	singleton.actionPutStudyWithStudyidWithidDependentPropertyWithPropertyid.bind(singleton),
);

export const actionDependentPropertyRemove = withAuthentication(
	singleton.actionDeleteStudyWithStudyidWithidDependentPropertyWithPropertyid.bind(singleton),
);

export const assetTypeDelete = withAuthentication(
	singleton.assettypeDeleteStudyWithStudyidWithid.bind(singleton),
);

export const categoryDelete = withAuthentication(
	singleton.categoryDeleteStudyWithStudyidWithid.bind(singleton),
);

export const choiceReOrder = withAuthentication(
	singleton.choicePostStudyWithStudyidWithid.bind(singleton),
);

export const getBuilderProject = withAuthentication(
	singleton.builderGetStudyWithStudyidProject.bind(singleton),
);

export const formsAdd = withAuthentication(singleton.formPostStudyWithStudyid.bind(singleton));
export const formsDelete = withAuthentication(
	singleton.formDeleteStudyWithStudyidWithid.bind(singleton),
);
export const formsFieldAdd = withAuthentication(
	singleton.fieldPostStudyWithStudyid.bind(singleton),
);
export const formsFieldChoiceAdd = withAuthentication(
	singleton.fieldchoicePostStudyWithStudyid.bind(singleton),
);
export const formsFieldChoiceRemove = withAuthentication(
	singleton.fieldchoiceDeleteStudyWithStudyidWithid.bind(singleton),
);
export const formsFieldChoiceUpdate = withAuthentication(
	singleton.fieldchoicePutStudyWithStudyidWithid.bind(singleton),
);
export const formFieldReorder = withAuthentication(
	singleton.fieldPostStudyWithStudyidWithidReorder.bind(singleton),
);

export const formsFieldRemove = withAuthentication(
	singleton.fieldDeleteStudyWithStudyidWithid.bind(singleton),
);
export const formsFieldUpdate = withAuthentication(
	singleton.fieldPutStudyWithStudyidWithid.bind(singleton),
);
export const formsGet = withAuthentication(singleton.formGetStudyWithStudyid.bind(singleton));
export const formsUpdate = withAuthentication(
	singleton.formPutStudyWithStudyidWithid.bind(singleton),
);

export const imageUpload = withAuthentication(
	singleton.templateimagePostStudyWithStudyidImageWithImageid.bind(singleton),
);

export const assetLibraryPost = withAuthentication(
	singleton.assetlibraryPostStudyWithStudyid.bind(singleton),
);
export const builderPostReportTemplate = withAuthentication(
	singleton.builderPostStudyWithStudyidReport.bind(singleton),
);
export const validateReport = withAuthentication(singleton.reportValidate.bind(singleton));

export const getUserProfile = withAuthentication(singleton.userGetprofile.bind(singleton));

export const updateUserFromActiveDirectory = withAuthentication(
	singleton.userPutProfileAdFields.bind(singleton),
);

export const studyListRuleSets = withAuthentication(singleton.studyListRuleSets.bind(singleton));

export const moveProperty = withAuthentication(
	singleton.propertyPostStudyWithStudyidWithidMove.bind(singleton),
);

export const duplicateProperty = withAuthentication(
	singleton.propertyPostStudyWithStudyidWithidDuplicate.bind(singleton),
);

export const createFilter = withAuthentication(singleton.assetfilterPost.bind(singleton));

export const deleteFilter = withAuthentication(singleton.assetfilterDeleteWithid.bind(singleton));

export const updateFilter = withAuthentication(singleton.assetfilterPutWithid.bind(singleton));

export const updateEndNote = withAuthentication(singleton.studyUpdateEndNote.bind(singleton));