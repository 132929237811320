import PublishIcon from '@mui/icons-material/Publish';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ApiStudyDto, useGoogleTagManager } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import { KES_FRONTEND_URL } from '@/constants';

interface PublishProps {
	disabled: boolean;
	onBeforePublish(): Promise<boolean>;
	onClose(): void;
	studyId: ApiStudyDto['id'];
}

const Publish: React.FC<PublishProps> = ({ disabled, onBeforePublish, onClose, studyId }) => {
	const { trackCustomEvent } = useGoogleTagManager();
	const { enqueueSnackbar } = useSnackbar();

	const publish = React.useCallback(async () => {
		const canPublish = await onBeforePublish();
		if (canPublish) {
			trackCustomEvent({ event: 'template_publish_clicked', templateId: studyId });
			window.location.href = `${KES_FRONTEND_URL}templates?templateId=${studyId}&publish=true`;
		} else {
			enqueueSnackbar('There are errors in the Template. Cannot publish.');
		}
		onClose();
	}, [onBeforePublish, onClose, studyId, trackCustomEvent]);

	return (
		<Tooltip
			placement="left"
			title="Publish this working copy to make it the latest version available for use as an activity"
		>
			{/* The <span> is to make sure the child element of the <Tooltip> is always hover-able */}
			<span>
				<MenuItem disabled={disabled} onClick={publish}>
					<ListItemIcon>
						<PublishIcon />
					</ListItemIcon>
					<ListItemText primary="Publish" />
				</MenuItem>
			</span>
		</Tooltip>
	);
};

export default Publish;
