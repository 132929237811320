import { getRuleSetList } from '@/selectors/ruleSets';
import State from '@/store/state';
import { RuleSet } from '@/store/types';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import RuleList from './RuleList';

interface RuleSelectorProps {
	setSelectedRuleIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedRuleIds: string[];
}

const RuleSelector: React.FC<RuleSelectorProps> = ({ setSelectedRuleIds, selectedRuleIds }) => {
	const ruleSets = useSelector<State, RuleSet[]>(getRuleSetList);
	const [selectedRuleSet, setSelectedRuleSet] = React.useState<RuleSet | undefined>(() =>
		ruleSets.length ? ruleSets[0] : undefined,
	);

	const toggleSelectedRule = (ruleId: string) => {
		setSelectedRuleIds((prevRules) => {
			if (prevRules.some((id) => id === ruleId))
				return [...prevRules].filter((id) => id !== ruleId);

			return [...prevRules, ruleId];
		});
	};

	if (!selectedRuleSet) {
		return (
			<Box my={2}>
				<Typography>
					This study does not contain any rules. Please add rules to the Template first!
				</Typography>
				<br />
				<Typography>
					{/* eslint-disable-next-line react/no-unescaped-entities */}
					<b>You have to refresh after you've added the new Rule Sets!</b>
				</Typography>
			</Box>
		);
	}

	return (
		<>
			<Box my={2}>
				<Typography>Select the rules you want to compare the input number with.</Typography>
			</Box>
			<FormControl>
				<InputLabel>Rule set</InputLabel>
				<Select
					data-testid="rule-select"
					value={selectedRuleSet.name}
					onChange={(e) => {
						setSelectedRuleSet(ruleSets.find((ruleSet) => ruleSet.name === e.target.value));
					}}
					label="rules"
				>
					{ruleSets.map((ruleSet) => (
						<MenuItem key={ruleSet.id} value={ruleSet.name}>
							{ruleSet.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<RuleList
				selectedRuleIds={selectedRuleIds}
				toggleRule={toggleSelectedRule}
				ruleSet={selectedRuleSet}
			/>
		</>
	);
};

export default RuleSelector;
