import React from 'react';
import MFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import cssClasses from './FormControlLabel.module.css';

const FormControlLabel: React.FC<FormControlLabelProps> = ({ classes, ...newProps }) => (
	<MFormControlLabel
		{...newProps}
		className={cssClasses.FormControlLabel}
		classes={{
			...classes,
			label: classes && classes.label ? `${cssClasses.label} ${classes.label}` : cssClasses.label,
		}}
	/>
);

export default FormControlLabel;
