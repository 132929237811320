import React from 'react';
import TagManager from 'react-gtm-module';

interface InitializeProps {
	auth?: string;
	gtmId?: string;
	preview?: string;
}

interface CartItem {
	index?: number;
	item_brand?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_id: number | string;
	item_list_id?: number | string;
	item_list_name?: string;
	item_name: string;
	item_variant?: string;
	price?: number;
	quantity?: number;
}

interface TrackAddToCartProps {
	items: CartItem[];
}

interface TrackCustomEventProps extends Record<string, any> {
	event: string;
}

interface TrackPurchaseProps {
	affiliation?: string;
	items: CartItem[];
	transaction_id: string;
}

interface TrackViewItemProps {
	items: CartItem[];
}

interface TrackRemoveFromCartProps {
	items: CartItem[];
}

const useGoogleTagManager = () => {
	const initialize = React.useCallback(({ auth, gtmId, preview }: InitializeProps) => {
		if (auth && gtmId) {
			const googleTagManagerArguments = { auth, gtmId, ...(preview && { preview }) };
			TagManager.initialize(googleTagManagerArguments);
		}
	}, []);

	// https://developers.google.com/tag-manager/ecommerce-ga4#add_a_product_to_a_shopping_cart
	const trackAddToCart = React.useCallback(({ items }: TrackAddToCartProps) => {
		TagManager.dataLayer({ dataLayer: { ecommerce: null } });
		TagManager.dataLayer({ dataLayer: { event: 'add_to_cart', ecommerce: { items } } });
	}, []);

	const trackCustomEvent = React.useCallback(({ event, ...rest }: TrackCustomEventProps) => {
		TagManager.dataLayer({ dataLayer: { event, ...rest, customEvent: true } });
	}, []);

	// https://developers.google.com/tag-manager/ecommerce-ga4#measure_purchases
	const trackPurchase = React.useCallback(
		({ affiliation, items, transaction_id }: TrackPurchaseProps) => {
			TagManager.dataLayer({ dataLayer: { ecommerce: null } });
			TagManager.dataLayer({
				dataLayer: {
					event: 'purchase',
					ecommerce: {
						affiliation,
						currency: 'EUR',
						items,
						transaction_id,
						value: items.reduce((accumulator, item) => accumulator + (item.price || 0), 0),
					},
				},
			});
		},
		[],
	);

	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_item
	const trackViewItem = React.useCallback(({ items }: TrackViewItemProps) => {
		TagManager.dataLayer({ dataLayer: { ecommerce: null } });
		TagManager.dataLayer({
			dataLayer: {
				event: 'view_item',
				ecommerce: {
					currency: 'EUR',
					items,
					value: items.reduce((accumulator, item) => accumulator + (item.price || 0), 0),
				},
			},
		});
	}, []);

	// https://developers.google.com/tag-manager/ecommerce-ga4#remove_a_product_from_a_shopping_cart
	const trackRemoveFromCart = React.useCallback(({ items }: TrackRemoveFromCartProps) => {
		TagManager.dataLayer({ dataLayer: { ecommerce: null } });
		TagManager.dataLayer({ dataLayer: { event: 'remove_from_cart', ecommerce: { items } } });
	}, []);

	const hook = React.useMemo(
		() => ({
			initialize,
			trackAddToCart,
			trackCustomEvent,
			trackPurchase,
			trackRemoveFromCart,
			trackViewItem,
		}),
		[
			initialize,
			trackAddToCart,
			trackCustomEvent,
			trackPurchase,
			trackRemoveFromCart,
			trackViewItem,
		],
	);

	return hook;
};

export default useGoogleTagManager;
