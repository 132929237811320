import React, { ComponentType, ReactNode, ComponentProps, useCallback } from 'react';
import SelectM, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem, FormControl, InputLabel } from '@mui/material';
import classes from './index.module.css';

/* eslint-disable react/require-default-props */
interface Props<E extends string | number | undefined> {
	disabled?: boolean;
	value: E | undefined;
	render: ComponentType<{ item: E }>;
	onChange: (payload: E) => void;
	items: E[];
	className?: string;
	label?: string;
	selectClassName?: string;
	selectTextClassName?: string;
}
/* eslint-enable react/require-default-props */

function Select<E extends string | number | undefined>(props: Props<E>): JSX.Element {
	const {
		className,
		disabled,
		items,
		label,
		onChange,
		render,
		selectClassName,
		selectTextClassName,
		value,
	} = props;
	const ItemRenderer = render;
	const valueRender = useCallback(
		(renderValue: ComponentProps<typeof SelectM>['value']): JSX.Element => (
			<ItemRenderer item={renderValue as E} />
		),
		[],
	);

	const onChangeHandler = useCallback(
		(e: SelectChangeEvent<E | undefined>): void => {
			const val = e.target.value;
			onChange(val as unknown as E);
		},
		[onChange],
	);

	return (
		<FormControl className={className} variant="standard">
			{label && <InputLabel className={classes.label}>{label}</InputLabel>}
			<SelectM
				disabled={disabled}
				value={value}
				onChange={onChangeHandler}
				renderValue={valueRender}
				autoWidth
				className={selectClassName}
				classes={{
					select: selectTextClassName,
				}}
				variant="standard"
			>
				{items.map(
					(item): ReactNode => (
						<MenuItem value={item} key={item}>
							<ItemRenderer item={item} />
						</MenuItem>
					),
				)}
			</SelectM>
		</FormControl>
	);
}

export default Select;
