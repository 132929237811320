import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import React from 'react';

import Add from './Add';
import DependencyCombinator from './DependencyCombinator';
import ParentChoice from './ParentChoice';
import { ApiChoice, ApiProperty } from '../../net/swagger';

export interface QuestionDependenciesProps {
	choices: ApiChoice[];
	l10n: {
		add: React.ReactNode;
		addLabelChoice: React.ReactNode;
		addLabelQuestion: React.ReactNode;
		addTitle: React.ReactNode;
		dependencyCombinatorDescription: React.ReactNode;
		dependencyCombinatorLabelAll: React.ReactNode;
		dependencyCombinatorLabelAny: React.ReactNode;
		dependencyCombinatorTitle: React.ReactNode;
		errorNotDependent: React.ReactNode;
		introduction: React.ReactNode;
		questionDependentOn: React.ReactNode;
	};
	onDependencyCombinatorChange(dependencyCombinator: ApiProperty['dependentCombinator']): void;
	onParentChoiceAdd(parentChoiceId: ApiChoice['id']): void;
	onParentChoiceDelete(parentChoice: ApiChoice): void;
	question: ApiProperty;
	questions: ApiProperty[];
}

const QuestionDependencies: React.FC<QuestionDependenciesProps> = ({
	choices,
	l10n,
	onDependencyCombinatorChange,
	onParentChoiceAdd,
	onParentChoiceDelete,
	question,
	questions,
}) => (
	<div data-testid="question-dependencies">
		<Alert severity="info">{l10n.introduction}</Alert>

		<FormControl fullWidth margin="normal">
			<FormLabel>{l10n.questionDependentOn}</FormLabel>
			{question.parentChoiceIds.length === 0 && (
				<Box marginTop={2}>
					<Alert severity="info">{l10n.errorNotDependent}</Alert>
				</Box>
			)}
			{question.parentChoiceIds.length > 0 && (
				<List>
					{question.parentChoiceIds.map((parentChoiceId) => (
						<ParentChoice
							choiceId={parentChoiceId}
							choices={choices}
							key={parentChoiceId}
							onParentChoiceDelete={onParentChoiceDelete}
							questions={questions}
						/>
					))}
				</List>
			)}
		</FormControl>

		{question.parentChoiceIds.length > 1 && (
			<FormControl fullWidth margin="normal">
				<FormLabel>{l10n.dependencyCombinatorTitle}</FormLabel>
				<Box marginTop={2}>
					<Alert severity="info">{l10n.dependencyCombinatorDescription}</Alert>
				</Box>
				<DependencyCombinator
					l10n={{
						dependencyCombinatorLabelAll: l10n.dependencyCombinatorLabelAll,
						dependencyCombinatorLabelAny: l10n.dependencyCombinatorLabelAny,
					}}
					onDependencyCombinatorChange={onDependencyCombinatorChange}
					question={question}
				/>
			</FormControl>
		)}

		<FormControl fullWidth margin="normal">
			<FormLabel>{l10n.addTitle}</FormLabel>
			<Add
				choices={choices}
				l10n={{
					add: l10n.add,
					addLabelChoice: l10n.addLabelChoice,
					addLabelQuestion: l10n.addLabelQuestion,
				}}
				onParentChoiceAdd={onParentChoiceAdd}
				question={question}
				questions={questions}
			/>
		</FormControl>
	</div>
);

export default QuestionDependencies;
