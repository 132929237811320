import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';

interface Auth0ClientFactoryOptions {
	audience: string;
	clientId: string;
	connection: string;
	domain: string;
}

const auth0ClientFactory = ({
	audience,
	clientId,
	connection,
	domain,
}: Auth0ClientFactoryOptions): Auth0Client => {
	const config: Auth0ClientOptions = {
		audience,
		client_id: clientId,
		connection,
		domain,
		redirect_uri: `${window.location.origin}/`,
	};
	return new Auth0Client(config);
};

export default auth0ClientFactory;
