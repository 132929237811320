/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import * as actions from '../actions';
import { StateChanges as State } from '../state';

const initialState: State = {
	status: 'done',
	updateCount: 0,
	savedAt: null,
};

export default makeReducer(initialState, {
	[actions.saveStarting.type]: (draft): void => {
		draft.status = 'saving';
	},
	[actions.saveDone.type]: (draft): void => {
		draft.status = 'done';
		draft.savedAt = new Date();
	},
	[actions.saveFailed.type]: (draft): void => {
		draft.status = 'failed';
	},
	[actions.saveOffline.type]: (draft): void => {
		draft.status = 'offline';
		draft.updateCount += 1;
		if (draft.updateCount === 3) {
			draft.updateCount = 0;
		}
	},
	[actions.saveUpdate.type]: (draft): void => {
		draft.updateCount += 1;
		if (draft.updateCount === 3) {
			draft.updateCount = 0;
		}
	},
});
