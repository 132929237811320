import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body, #root, html {
      height: 100%;
      padding:0;
      margin:0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
  }

  #root {
     font-family: Barlow;
     font-weight: normal;
     font-style: normal;
     font-stretch: normal;
     letter-spacing: normal;
     font-size: 16px;
  }
`;

export default GlobalStyles;
