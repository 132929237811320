import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { categoryDelete } from '@/net/api';
import { application } from '@/routes';
import { studyId as studyIdSelector } from '@/selectors';
import { categoryDelete as categoryDeleteAction } from '@/store/actions';
import { Category } from '@/store/types';

interface CategoryDeleteDialogProps {
	category: Category;
	isOpen: boolean;
	onClose(): void;
	onDelete(): void;
	onError(): void;
}

const CategoryDeleteDialog: React.FC<CategoryDeleteDialogProps> = ({
	category,
	isOpen,
	onClose,
	onDelete,
	onError,
}) => {
	const studyId = useSelector(studyIdSelector);

	const dispatch = useDispatch();
	const history = useHistory();

	const onSuccess = React.useCallback(() => {
		dispatch(categoryDeleteAction(category));
		onDelete();
		history.push(application({ applicationName: studyId }));
	}, [category, dispatch, history, onDelete, studyId]);

	const categoryDeleteMutation = useMutation(
		['category', category.id, 'delete'],
		() => categoryDelete(category.id, studyId).then((response) => response.expectSuccess()),
		{ onError, onSuccess },
	);

	const handleDelete = React.useCallback(() => {
		categoryDeleteMutation.mutate();
	}, [categoryDeleteMutation]);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Delete category &quot;{category.name}&quot;</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you want to delete the category &quot;{category.name}&quot;?
				</Typography>
				<Typography>
					This will also delete all sub-categories and questions which fall underneath this
					category.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button color="primary" onClick={handleDelete} variant="contained">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CategoryDeleteDialog;
