import { put, select, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';

import { reportTemplateGet } from '@/selectors/reports';
import * as actions from '@/store/actions';

function escapeRegExp(entityName: string) {
	return entityName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function* reportTemplateUpdateAssetReferences(
	action: ReturnType<typeof actions.reportUpdateAssetName>,
): SagaIterator {
	const { assetNameNew, assetNameOld } = action.payload;
	const template = yield select(reportTemplateGet);
	if (template) {
		const assetFilterRegex = new RegExp(`>Filters\\|${escapeRegExp(assetNameOld)}\\|(.+)<`, 'g');
		const assetNameRegex = new RegExp(`>${escapeRegExp(assetNameOld)}<`, 'g');
		const updatedTemplate = template
			.replaceAll(assetNameRegex, `>${assetNameNew}<`)
			.replaceAll(assetFilterRegex, `>Filters|${assetNameNew}|$1<`);
		yield put(actions.reportUpdate({ template: updatedTemplate }));
	}
}

function* reportTemplateUpdatePropertyReferences(
	action: ReturnType<typeof actions.reportUpdatePropertyName>,
): SagaIterator {
	const { propertyPlaceholderNew, propertyPlaceholderOld } = action.payload;
	const template = yield select(reportTemplateGet);
	if (template) {
		const propertyPlaceholderRegex = new RegExp(`>(${escapeRegExp(propertyPlaceholderOld)})<`, 'g');
		const updatedTemplate = template.replaceAll(
			propertyPlaceholderRegex,
			`>${propertyPlaceholderNew}<`,
		);
		yield put(actions.reportUpdate({ template: updatedTemplate }));
	}
}

function* reportsSaga(): SagaIterator {
	yield takeLatest(actions.reportUpdateAssetName, reportTemplateUpdateAssetReferences);
	yield takeLatest(actions.reportUpdatePropertyName, reportTemplateUpdatePropertyReferences);
}

export default reportsSaga;
