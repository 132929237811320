import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { applicationCategoryUpdate } from '@/routes';
import { studyId as studyIdSelector } from '@/selectors';
import { Category as CategoryType } from '@/store/types';

import { Draggable } from 'react-beautiful-dnd';
import getDraggableStyle from '@/styles/dragAndDrop';
import CategoryContextMenu from './CategoryContextMenu';
import SubCategory from './SubCategory';

interface CategoryProps {
	category: CategoryType;
	index: number;
}

const Category: React.FC<CategoryProps> = ({ category, index }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);
	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
	const studyId = useSelector(studyIdSelector);
	const location = useLocation();
	const onOpenContextMenu: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
		(event) => {
			event.preventDefault();
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement],
	);

	const onToggleExpanded: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
		(event) => {
			event.preventDefault();
			setIsExpanded((currentlyExpanded) => !currentlyExpanded);
		},
		[setIsExpanded],
	);

	const isDisabled = category.fixed || category.isDefault;

	return (
		<Draggable draggableId={category.id} index={index}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					style={getDraggableStyle(provided, snapshot)}
				>
					<ListItem
						disablePadding
						secondaryAction={
							<IconButton disabled={isDisabled} onClick={onOpenContextMenu}>
								<MoreVertIcon />
							</IconButton>
						}
					>
						<ListItemButton
							{...provided.dragHandleProps}
							component={Link}
							to={
								isDisabled
									? location.pathname
									: applicationCategoryUpdate({
											applicationName: studyId,
											categoryId: category.id,
									  })
							}
						>
							<ListItemIcon>
								<IconButton disabled={isDisabled} onClick={onToggleExpanded}>
									{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
								</IconButton>
							</ListItemIcon>
							<ListItemText
								sx={{
									color: (theme) => (isDisabled ? theme.palette.text.disabled : undefined),
								}}
								primary={category.name}
							/>
						</ListItemButton>
					</ListItem>

					<Collapse in={isExpanded}>
						<List>
							{category.assetTypeIds.map((assetTypeId) => (
								<SubCategory
									categoryId={category.id}
									key={assetTypeId}
									subCategoryId={assetTypeId}
								/>
							))}
						</List>
					</Collapse>

					<CategoryContextMenu
						anchorElement={anchorElement}
						category={category}
						onClose={() => setAnchorElement(null)}
					/>
				</div>
			)}
		</Draggable>
	);
};

export default Category;
