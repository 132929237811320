import { PropertyType } from '@/store/types';

const propertyTypeLabels: Record<PropertyType, string> = {
	[PropertyType.DATE]: 'Date',
	[PropertyType.DECIMAL]: 'Number',
	[PropertyType.IMAGE]: 'Image',
	[PropertyType.LOCATIONS]: 'Locations',
	[PropertyType.MULTI_SELECT]: 'Multiple select',
	[PropertyType.MULTI_SUBSTANCE]: 'Multiple substances',
	[PropertyType.RICH_TEXT]: 'Rich text',
	[PropertyType.SINGLE_SELECT]: 'Single select',
	[PropertyType.SINGLE_SUBSTANCE]: 'Single substance',
	[PropertyType.STRING]: 'Text',
};

const propertyTypeToLabel = (propertyType: PropertyType): string =>
	propertyTypeLabels[propertyType];

export default propertyTypeToLabel;
