import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React from 'react';

import { ApiChoice, ApiProperty } from '../../net/swagger';

interface AddProps {
	choices: ApiChoice[];
	l10n: {
		add: React.ReactNode;
		addLabelChoice: React.ReactNode;
		addLabelQuestion: React.ReactNode;
	};
	onParentChoiceAdd(parentChoiceId: ApiChoice['id']): void;
	question: ApiProperty;
	questions: ApiProperty[];
}

const Add: React.FC<AddProps> = ({ choices, l10n, onParentChoiceAdd, question, questions }) => {
	const [selectedChoiceId, setSelectedChoiceId] = React.useState<ApiChoice['id']>('');
	const [selectedPropertyId, setSelectedPropertyId] = React.useState<ApiProperty['id']>('');

	const selectableChoices = React.useMemo(() => {
		if (selectedPropertyId) {
			const selectedQuestion = questions.find(
				(potentialQuestion) => potentialQuestion.id === selectedPropertyId,
			)!;
			if (selectedQuestion.choiceIds) {
				return selectedQuestion.choiceIds.map((choiceId) => {
					const choice = choices.find((potentialChoice) => potentialChoice.id === choiceId)!;
					return choice;
				});
			}
		}
		return [];
	}, [choices, questions, selectedPropertyId]);

	const selectableQuestions = React.useMemo<ApiProperty[]>(
		() =>
			questions.filter((potentialQuestion) => {
				if (potentialQuestion.id === question.id) {
					return false;
				}
				if (!['MULTI_SELECT', 'SINGLE_SELECT'].includes(potentialQuestion.type)) {
					return false;
				}
				return potentialQuestion.choiceIds && potentialQuestion.choiceIds.length > 0;
			}),
		[questions],
	);

	const onClick = React.useCallback(() => {
		onParentChoiceAdd(selectedChoiceId);
		setSelectedChoiceId('');
	}, [onParentChoiceAdd, selectedChoiceId]);

	const onSelectChoice: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
		React.useCallback(
			(event) => {
				setSelectedChoiceId(event.target.value);
			},
			[setSelectedChoiceId],
		);

	const onSelectProperty: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
		React.useCallback(
			(event) => {
				setSelectedChoiceId('');
				setSelectedPropertyId(event.target.value);
			},
			[setSelectedChoiceId, setSelectedPropertyId],
		);

	return (
		<>
			<TextField
				fullWidth
				inputProps={{ 'data-testid': 'question-dependencies__add__field--question' }}
				label={l10n.addLabelQuestion}
				margin="normal"
				name="property"
				onChange={onSelectProperty}
				select
				value={selectedPropertyId}
			>
				{selectableQuestions.map((selectableQuestion) => (
					<MenuItem key={selectableQuestion.id} value={selectableQuestion.id}>
						{selectableQuestion.question || selectableQuestion.name}
					</MenuItem>
				))}
			</TextField>
			<TextField
				disabled={selectedPropertyId === ''}
				fullWidth
				inputProps={{ 'data-testid': 'question-dependencies__add__field--choice' }}
				label={l10n.addLabelChoice}
				margin="normal"
				name="choice"
				onChange={onSelectChoice}
				select
				value={selectedChoiceId}
			>
				{selectableChoices.map((choice) => (
					<MenuItem
						disabled={question.parentChoiceIds.includes(choice.id)}
						key={choice.id}
						value={choice.id}
					>
						{choice.name}
					</MenuItem>
				))}
			</TextField>
			<Button
				data-testid="question-dependencies__add"
				disabled={selectedChoiceId === ''}
				fullWidth
				onClick={onClick}
				startIcon={<LinkIcon />}
			>
				{l10n.add}
			</Button>
		</>
	);
};

export default Add;
