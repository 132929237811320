import { SagaIterator } from '@redux-saga/core';
import { takeEvery } from '@redux-saga/core/effects';
import AppAction from '@/store/appAction';
import { AuthorizationError, MendixUnavailableError, ValidationError } from '@/store/types';

// eslint-disable-next-line require-yield
function* onAction(action: AppAction): SagaIterator {
	if ('payload' in action && action.payload instanceof Error) {
		// eslint-disable-next-line no-alert
		const content =
			action.payload instanceof MendixUnavailableError ||
			action.payload instanceof AuthorizationError ||
			action.payload instanceof ValidationError
				? action.payload.message
				: 'An unexpected ' +
				  'error occurred. Please refresh the page and contact the Digital Lab if this error keeps occurring.';
		const color =
			action.payload instanceof MendixUnavailableError ? '195, 247, 247' : '255,191,191';
		const node = document.createElement('style');
		node.innerHTML =
			'#root:after { position: fixed; top: 0; left:0; bottom: 0; right: 0;' +
			`background: rgba(${color}, 1);` +
			'z-index: 10000;' +
			'display: flex;' +
			'align-items: center;' +
			'justify-content: center;' +
			'font-size: 2vh;' +
			`content: '${content}';` +
			'}';
		document.body.appendChild(node);
		throw action.payload;
	}
}

function* errorSaga(): SagaIterator {
	yield takeEvery('*', onAction);
}

export default errorSaga;
