import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { categoriesGetAll } from '@/selectors';
import { categoryPersist, categoryUpdate } from '@/store/actions';
import State from '@/store/state';
import { Category } from '@/store/types';
import { SafeAction } from '@/store/utils';

import CategoryDialog from './CategoryDialog';

export interface CategoryDialogEdit {
	categoryId: string;
	state: 'Edit';
}

export interface CategoryDialogAdd {
	categoryId?: undefined;
	state: 'Add';
}

export interface CategoryDialogHide {
	categoryId?: undefined;
	state: 'Hide';
}

export type CategoryDialogMode = CategoryDialogEdit | CategoryDialogAdd | CategoryDialogHide;

export interface CommmonCategoryDialogProps {
	onClose: () => void;
}

type CategoryActionCreator = (name: string, info: string | null) => SafeAction<any, any>;

const CategoryDialogContainer: FC<CommmonCategoryDialogProps & CategoryDialogMode> = ({
	categoryId,
	onClose,
	state,
}) => {
	const categories = useSelector<State, Record<string, Category>>((storeState) =>
		categoriesGetAll(storeState),
	);

	const dispatch = useDispatch();
	const [error, setError] = useState<string | undefined>();
	const lookupCategoryName = (id: string) => categories[id].name;
	const lookupCategoryInfo = (id: string) => categories[id].info;

	const createCategoryPersist = (categoryName: string, categoryInfo: string | null) => {
		const category: Category = {
			id: uuidv4(),
			isDefault: false,
			stableId: uuidv4(),
			name: categoryName,
			info: categoryInfo,
			assetTypeIds: [],
			fixed: false,
			type: 'SURVEY',
		};
		return categoryPersist({ category, position: Object.keys(categories).length });
	};

	const createCategoryUpdate =
		(newCategoryId: string) => (categoryName: string, categoryInfo: string | null) =>
			categoryUpdate({ id: newCategoryId, info: categoryInfo, name: categoryName });

	const validateName = (categoryName: string): string | undefined => {
		if (categoryName === '') {
			return 'You must enter a valid category name';
		}

		const existing = Object.values(categories)
			.filter((cat) => !(state === 'Edit' && categoryId === cat.id))
			.find((cat) => cat.name === categoryName);
		if (existing) {
			return 'Please enter a unique category name';
		}

		return undefined;
	};

	const handleClose = () => {
		setError('');
		onClose();
	};

	const handleCategoryUpdate =
		(actionCreator: CategoryActionCreator) =>
		(categoryName: string, categoryInfo: string | null) => {
			const validationError = validateName(categoryName);
			if (validationError !== undefined) {
				setError(validationError);
				return;
			}

			dispatch(actionCreator(categoryName, categoryInfo));
			handleClose();
		};

	// eslint-disable-next-line default-case
	switch (state) {
		case 'Edit':
			return (
				<CategoryDialog
					title="Edit category"
					actionCaption="Update"
					categoryInfo={lookupCategoryInfo(categoryId)}
					categoryName={lookupCategoryName(categoryId)}
					error={error}
					onClose={handleClose}
					onCategoryAction={handleCategoryUpdate(createCategoryUpdate(categoryId))}
				/>
			);
		case 'Add':
			return (
				<CategoryDialog
					title="Add new category"
					actionCaption="Create"
					categoryInfo={null}
					categoryName=""
					error={error}
					onClose={handleClose}
					onCategoryAction={handleCategoryUpdate(createCategoryPersist)}
				/>
			);
		case 'Hide':
		default:
			return null;
	}
};

export default CategoryDialogContainer;
