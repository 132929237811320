import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import React from 'react';

import { Asset } from '@/store/types';

import SubCategoryDeleteDialog from '../SubCategoryDeleteDialog';

interface DeleteProps {
	onClose(): void;
	subCategory: Asset;
}

const Delete: React.FC<DeleteProps> = ({ onClose, subCategory }) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const { enqueueSnackbar } = useSnackbar();

	const deleteDialogClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	const deleteDialogOpen = React.useCallback(() => {
		onClose();
		setIsOpen(true);
	}, [onClose, setIsOpen]);

	const onDelete = React.useCallback(() => {
		deleteDialogClose();
		enqueueSnackbar(`Sub-category '${subCategory.name}' successfully deleted`);
	}, [enqueueSnackbar, deleteDialogClose]);

	const onDeleteError = React.useCallback(() => {
		enqueueSnackbar('There was an issue deleting the sub-category, please try again');
	}, [enqueueSnackbar]);

	return (
		<>
			<MenuItem disabled={subCategory.isDefault || subCategory.fixed} onClick={deleteDialogOpen}>
				<ListItemIcon>
					<DeleteIcon />
				</ListItemIcon>
				<ListItemText primary="Delete" />
			</MenuItem>

			<SubCategoryDeleteDialog
				isOpen={isOpen}
				onClose={deleteDialogClose}
				onDelete={onDelete}
				onError={onDeleteError}
				subCategory={subCategory}
			/>
		</>
	);
};

export default Delete;
