import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';

import {
	formFieldReorder,
	formsAdd,
	formsDelete,
	formsFieldAdd,
	formsFieldChoiceAdd,
	formsFieldChoiceRemove,
	formsFieldChoiceUpdate,
	formsFieldRemove,
	formsFieldUpdate,
	formsGet,
	formsUpdate,
} from '@/net/api';
import * as actions from '@/store/actions';
import { Result } from '@/store/utils';
import { formsGetFieldChoice, formsGetField, formsGet as formsGetSelector } from '@/selectors';

function* formsAddField(action: ReturnType<typeof actions.formsFieldAdd>): SagaIterator {
	const { formId, id, studyId } = action.payload;
	yield put(actions.saveStarting());
	const { choices, ...field } = yield select(formsGetField, id, formId);
	if (field) {
		try {
			const response = yield call(formsFieldAdd, studyId, field);
			if (response.status === 201) {
				yield put(actions.saveDone());
			} else {
				throw new Error('Failed to add field');
			}
		} catch (error) {
			yield put(actions.saveFailed());
		}
	}
}

function* formsReorderField(action: ReturnType<typeof actions.formsFieldReorder>): SagaIterator {
	const { studyId, fieldId, position } = action.payload;
	yield put(actions.saveStarting());
	try {
		const response = yield call(formFieldReorder, fieldId, studyId, { position });
		if (response.status === 200) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to add field');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsAddFieldChoice(
	action: ReturnType<typeof actions.formsFieldChoiceAdd>,
): SagaIterator {
	const { fieldId, formId, id, studyId } = action.payload;
	yield put(actions.saveStarting());
	const { position, ...choice } = yield select(formsGetFieldChoice, id, fieldId, formId);
	try {
		const response = yield call(formsFieldChoiceAdd, studyId, { ...choice, fieldId });
		if (response.status === 201) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to add field choice');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsDeleteForm(action: ReturnType<typeof actions.formsDelete>): SagaIterator {
	const { formId, studyId } = action.payload;
	yield put(actions.saveStarting());
	try {
		const response = yield call(formsDelete, formId, studyId);
		if (response.status === 204) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to delete form');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsCreate(action: ReturnType<typeof actions.formsAdd>): SagaIterator {
	yield put(actions.saveStarting());
	const { fields, position, ...form } = yield select(formsGetSelector, action.payload.id);
	try {
		const response = yield call(formsAdd, action.payload.studyId, form);
		if (response.status === 201) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to create Form');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsLoad(action: ReturnType<typeof actions.formsLoad>): SagaIterator {
	const response: Result<typeof formsGet> = yield call(formsGet, action.payload.studyId);
	if (response.status === 200) {
		yield put(actions.formsLoadSuccess(response.result));
	} else {
		yield put(actions.formsLoadError(new Error(response.result.message)));
	}
}

function* formsRemoveField(action: ReturnType<typeof actions.formsFieldRemove>): SagaIterator {
	const { fieldId, studyId } = action.payload;
	yield put(actions.saveStarting());
	try {
		const response = yield call(formsFieldRemove, fieldId, studyId);
		if (response.status === 204) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to remove form field');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsRemoveFieldChoice(
	action: ReturnType<typeof actions.formsFieldChoiceRemove>,
): SagaIterator {
	const { choiceId, studyId } = action.payload;
	yield put(actions.saveStarting());
	try {
		const response = yield call(formsFieldChoiceRemove, choiceId, studyId);
		if (response.status === 204) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to remove field choice');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsUpdateField(action: ReturnType<typeof actions.formsFieldUpdate>): SagaIterator {
	const { studyId, ...field } = action.payload;
	yield put(actions.saveStarting());
	try {
		const response = yield call(formsFieldUpdate, field.id, studyId, field);
		if (response.status === 200) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to update form field');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsUpdateFieldChoice(
	action: ReturnType<typeof actions.formsFieldChoiceUpdate>,
): SagaIterator {
	const { position, formId, fieldId, studyId, ...choice } = action.payload;
	yield put(actions.saveStarting());
	try {
		const response = yield call(formsFieldChoiceUpdate, choice.id, studyId, { ...choice, fieldId });
		if (response.status === 200) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to update form field choice');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsUpdateForm(action: ReturnType<typeof actions.formsUpdate>): SagaIterator {
	const { studyId, ...form } = action.payload;
	yield put(actions.saveStarting());
	try {
		const response = yield call(formsUpdate, form.id, studyId, form);
		if (response.status === 200) {
			yield put(actions.saveDone());
		} else {
			throw new Error('Failed to update form');
		}
	} catch (error) {
		yield put(actions.saveFailed());
	}
}

function* formsSaga(): SagaIterator {
	yield takeLatest(actions.formsAdd, formsCreate);
	yield takeLatest(actions.formsDelete, formsDeleteForm);
	yield takeLatest(actions.formsFieldAdd, formsAddField);
	yield takeLatest(actions.formsFieldChoiceAdd, formsAddFieldChoice);
	yield takeLatest(actions.formsFieldChoiceRemove, formsRemoveFieldChoice);
	yield takeLatest(actions.formsFieldChoiceUpdate, formsUpdateFieldChoice);
	yield takeLatest(actions.formsFieldRemove, formsRemoveField);
	yield takeLatest(actions.formsFieldUpdate, formsUpdateField);
	yield takeLatest(actions.formsLoad, formsLoad);
	yield takeLatest(actions.formsUpdate, formsUpdateForm);
	yield takeLatest(actions.formsFieldReorder, formsReorderField);
}

export default formsSaga;
