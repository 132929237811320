import Chip from '@mui/material/Chip';
import FilterDialogContainer, { FilterDialogMode } from '@/components/FilterDialog';
import { AddCircleOutlineIcon } from '@/components/Forms/Sidebar.styles';
import { assetFiltersGet, propertiesGet } from '@/selectors';
import State from '@/store/state';
import { Asset } from '@/store/types';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterDelete } from '@/store/actions';
import { deleteFilter as apiDeleteFilter } from '@/net/api';
import { useGoogleTagManager } from 'kes-common';
import { useSnackbar } from 'notistack';

interface FilterProps {
	asset: Asset;
}

const Filters: FC<FilterProps> = ({ asset }): JSX.Element => {
	const filters = useSelector((state: State) =>
		asset.assetFilterIds.map((fid) => assetFiltersGet(state, fid)),
	);

	const decimalProperties = useSelector((state: State) =>
		asset.propertyIds
			.map((pid) => propertiesGet(state, pid))
			.filter((prop) => prop.type === 'DECIMAL'),
	);

	const [filterDialogMode, setDialogMode] = useState<FilterDialogMode>({ state: 'Hide' });
	const closeFilterDialog = () => setDialogMode({ state: 'Hide' });

	const { trackCustomEvent } = useGoogleTagManager();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const deleteFilter = async (filterId: string) => {
		try {
			const apiCall = await apiDeleteFilter(filterId);
			if (apiCall.ok) {
				dispatch(filterDelete({ filterId, assetId: asset.id }));
				trackCustomEvent({ event: 'ra-filter-deleted' });
			} else throw Error(`Unexpected response from api: ${apiCall.statusText}`);
		} catch (exception) {
			enqueueSnackbar(`Error saving filter: ${(exception as Error).message}`, { variant: 'error' });
		}
	};

	return (
		<>
			<h5>Filters</h5>
			<FilterDialogContainer
				properties={decimalProperties}
				onClose={closeFilterDialog}
				assetId={asset.id}
				existingFilterNames={filters.map((filter) => filter.name)}
				{...filterDialogMode}
			/>
			<div>
				{filters.map((filter) => (
					<Chip
						label={filter.name}
						key={filter.id}
						onClick={() => setDialogMode({ state: 'Edit', filterId: filter.id })}
						onDelete={() => deleteFilter(filter.id)}
					/>
				))}
			</div>
			<div>
				<Chip
					color="secondary"
					disabled={decimalProperties.length === 0}
					icon={<AddCircleOutlineIcon />}
					label="Create filter"
					onClick={() => setDialogMode({ state: 'Add' })}
				/>
			</div>
		</>
	);
};

export default Filters;
