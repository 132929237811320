import { makeSelectors } from '@/store/repository';
import { Category } from '@/store/types';
import State from '@/store/state';

const selector = makeSelectors<Category>();

export const { allIds, get, getAll, getOrNull } = selector;

export const categoryGetAllOrdered = (state: State): Category[] => {
	const categoryIds = state.application.categories;
	return categoryIds.map((categoryId) => state.categories.byId[categoryId]);
};

export const getCategoryWithAssetTypeId = (state: State, assetTypeId: string): Category => {
	// eslint-disable-next-line no-restricted-syntax
	for (const categoryId in state.categories.byId) {
		if (Object.prototype.hasOwnProperty.call(state.categories.byId, categoryId)) {
			const category = get(state.categories, categoryId);
			if (category.assetTypeIds.includes(assetTypeId)) return category;
		}
	}
	throw new Error(`No categorys found for asset type ${assetTypeId}`);
};

export const getCategoryForAssetId = (
	categories: State['categories'],
	assetId?: string,
): Category | undefined => {
	if (assetId) {
		return Object.values(categories.byId).find((c) => c.assetTypeIds.includes(assetId));
	}
	return undefined;
};
