/* eslint-disable no-param-reassign */
import { ApiForm } from 'kes-common';

import { FIELD_CHOICE_NEW_PREFIX, FIELD_NEW_PREFIX, TABLE_NEW_PREFIX } from '@/constants';

import * as actions from '../actions';
import makeReducer from '../makeReducer';
import { initialState as makeInitialState, makeRepository } from '../repository';
import { StateForms } from '../state';
import { generateUniqueEntityName } from '../utils';
import { size } from '../../selectors/forms';

const initialState: StateForms = { ...makeInitialState<ApiForm>(), status: 'loading' };
const repository = makeRepository<ApiForm>();

export default makeReducer(initialState, {
	[actions.formsAdd.type]: (state, payload) => {
		const name = generateUniqueEntityName(
			Object.values(state.byId).map((form) => form.name),
			TABLE_NEW_PREFIX,
		);
		const { studyId, ...newForm } = payload;
		repository.upsert(state, { ...newForm, name, fields: [], position: size(state) });
	},

	[actions.formsDelete.type]: (state, payload) => {
		repository.delete(state, payload.formId);
	},

	[actions.formsFieldAdd.type]: (state, payload) => {
		const name = generateUniqueEntityName(
			Object.values(state.byId[payload.formId].fields).map((field) => field.name),
			FIELD_NEW_PREFIX,
		);
		const { formId, studyId, ...field } = payload;
		repository.modify(state, formId, (form) => {
			form.fields = form.fields.map((formField) => ({
				...formField,
				position:
					formField.position >= field.position ? formField.position + 1 : formField.position,
			}));
			form.fields.push({ ...field, formId, name, choices: [] });
		});
	},

	[actions.formsFieldChoiceAdd.type]: (state, payload) => {
		const field = Object.values(state.byId[payload.formId].fields).find(
			(formField) => formField.id === payload.fieldId,
		);
		const name = generateUniqueEntityName(
			field?.choices.map((choice) => choice.name) || [],
			FIELD_CHOICE_NEW_PREFIX,
		);
		const { fieldId, formId, studyId, ...choice } = payload;
		repository.modify(state, formId, (form) => {
			form.fields = form.fields.map((formField) => {
				if (formField.id === fieldId) {
					return { ...formField, choices: [...formField.choices, { ...choice, name }] };
				}
				return formField;
			});
		});
	},

	[actions.formsFieldChoiceRemove.type]: (state, payload) => {
		const { choiceId, fieldId, formId } = payload;
		repository.modify(state, formId, (form) => {
			form.fields.forEach((formField) => {
				if (formField.id === fieldId) {
					const choiceIndex = formField.choices.findIndex((choice) => choice.id === choiceId);
					if (choiceIndex > -1) {
						formField.choices.splice(choiceIndex, 1);
					}
				}
			});
		});
	},

	[actions.formsFieldChoiceUpdate.type]: (state, payload) => {
		const { fieldId, formId, studyId, ...choice } = payload;
		repository.modify(state, formId, (form) => {
			form.fields
				.find((field) => field.id === fieldId)
				?.choices?.map((fieldChoice) => (fieldChoice.id === choice.id ? choice : fieldChoice));
		});
	},

	[actions.formsFieldRemove.type]: (state, payload) => {
		const { fieldId, formId } = payload;
		repository.modify(state, formId, (form) => {
			const fieldIndex = form.fields.findIndex((formField) => formField.id === fieldId);
			if (fieldIndex === -1) return;

			const { position } = form.fields[fieldIndex];
			form.fields.forEach((field) => {
				if (field.position > position) {
					field.position -= 1;
				}
			});
			form.fields.splice(fieldIndex, 1);
		});
	},

	[actions.formsFieldUpdate.type]: (state, payload) => {
		const { formId, studyId, ...field } = payload;
		repository.modify(state, formId, (form) => {
			form.fields = form.fields.map((formField) =>
				formField.id === field.id ? { ...field, formId, choices: formField.choices } : formField,
			);
		});
	},

	[actions.formsLoad.type]: (state) => {
		state.status = 'loading';
	},

	[actions.formsLoadError.type]: (state) => {
		state.status = 'error';
	},

	[actions.formsLoadSuccess.type]: (state, payload) => {
		state.status = 'success';
		repository.clear(state);
		payload.forEach((form) => repository.upsert(state, form));
	},

	[actions.formsUpdate.type]: (state, payload) => {
		const { studyId, ...form } = payload;
		repository.update(state, form);
	},
	[actions.formsFieldReorder.type]: (state, payload) => {
		const { formId, fieldId, position: newPosition } = payload;

		repository.modify(state, formId, (form) => {
			const targetField = form.fields.find((field) => field.id === fieldId);
			if (!targetField) return;
			const oldPosition = targetField.position;
			targetField.position = newPosition;

			form.fields.forEach((field) => {
				if (field.id !== fieldId) {
					if (field.position > oldPosition && field.position <= newPosition) {
						field.position -= 1;
					} else if (field.position >= newPosition && field.position < oldPosition) {
						field.position += 1;
					}
				}
			});
		});
	},
});
