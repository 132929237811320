/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { ApiAssetType } from 'kes-common';
import { create } from '@/entities/asset';
import { ASSET_NEW_PREFIX, FLAG_NEW_PREFIX } from '@/constants';
import { StateAsset as State } from '@/store/state';
import { Asset } from '../types';
import { makeRepository } from '../repository';
import * as actions from '../actions';
import { generateUniqueEntityName } from '../utils';

const repository = makeRepository<Asset>();

function mapApiToLocal(api: ApiAssetType): Asset {
	return api;
}

const initialState: State = {
	byId: {} as Record<Asset['id'], Asset>,
	allIds: [],
	error: null,
};

export default makeReducer(initialState, {
	[actions.applicationLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.assetTypesById, mapApiToLocal);
	},
	[actions.assetGenerate.type]: (draft, payload): void => {
		const name = generateUniqueEntityName(
			Object.values(draft.byId).map((asset) => asset.name),
			payload.type === 'FLAG' ? FLAG_NEW_PREFIX : ASSET_NEW_PREFIX,
		);
		repository.upsert(draft, create(payload.id, name, payload.repeating, payload.type));
	},
	[actions.assetPersist.type]: (draft, payload): void => {
		repository.upsert(draft, payload.asset);
	},
	[actions.assetUpdate.type]: (draft, payload): void => {
		repository.update(draft, payload);
	},
	[actions.propertyPersist.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.propertyIds.indexOf(payload.property.id);
			if (payload.assetId === item.id && index !== payload.position) {
				if (index >= 0) item.propertyIds.splice(index, 1);
				item.propertyIds.splice(payload.position, 0, payload.property.id);
			}
			if (payload.assetId !== item.id && index >= 0) {
				item.propertyIds.splice(index, 1);
			}
		});
	},
	[actions.propertyGenerate.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.propertyIds.indexOf(payload.id);
			if (index < 0) {
				if (payload.assetId === item.id) {
					item.propertyIds.push(payload.id);
				}
				// While the following else block should never be triggered in practice, it still happens when a
				// generate action gets called with an id that already exists, in this case, we regenerate the
				// passed in id, and re-assing it to the correct parent
			} else if (payload.assetId !== item.id) {
				item.propertyIds.splice(index, 1);
			}
		});
	},
	[actions.propertyGenerateSilent.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.propertyIds.indexOf(payload.id);
			if (index < 0) {
				if (payload.assetId === item.id) {
					item.propertyIds.push(payload.id);
				}
				// While the following else block should never be triggered in practice, it still happens when a
				// generate action gets called with an id that already exists, in this case, we regenerate the
				// passed in id, and re-adding it to the correct parent
			} else if (payload.assetId !== item.id) {
				item.propertyIds.splice(index, 1);
			}
		});
	},
	[actions.propertyDelete.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.propertyIds.indexOf(payload.id);
			if (index >= 0) {
				item.propertyIds.splice(index, 1);
			}
		});
	},
	[actions.assetClearError.type]: (draft): void => {
		draft.error = null;
	},
	[actions.propertyMove.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item) => {
			if (item.id === payload.currentAssetId) {
				item.propertyIds = item.propertyIds.filter((p) => p !== payload.propertyId);
			}
			if (item.id === payload.destinationAssetId) {
				item.propertyIds.push(payload.propertyId);
			}
		});
	},
	[actions.propertyDuplicate.type]: (draft, payload): void => {
		repository.modify(draft, payload.assetTypeId, (asset) => {
			asset.propertyIds.push(payload.property.id);
		});
	},
	[actions.filterCreate.type]: (draft, payload): void => {
		repository.modify(draft, payload.assetId, (asset) => {
			asset.assetFilterIds.push(payload.id);
		});
	},
	[actions.filterDelete.type]: (draft, payload): void => {
		repository.modify(draft, payload.assetId, (asset) => {
			const index = asset.assetFilterIds.indexOf(payload.filterId);
			if (index >= 0) asset.assetFilterIds.splice(index, 1);
		});
	},
});
