import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { applicationSubCategoryUpdate } from '@/routes';
import { assetsGet, studyId as studyIdSelector } from '@/selectors';
import State from '@/store/state';
import { Asset, Category } from '@/store/types';

import SubCategoryContextMenu from './SubCategoryContextMenu';

interface SubCategoryProps {
	categoryId: Category['id'];
	subCategoryId: Asset['id'];
}

const SubCategory: React.FC<SubCategoryProps> = ({ categoryId, subCategoryId }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

	const studyId = useSelector(studyIdSelector);
	const subCategory = useSelector<State, Asset>((state) => assetsGet(state, subCategoryId));

	const onOpenContextMenu: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
		(event) => {
			event.preventDefault();
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement],
	);

	return (
		<>
			<ListItem
				disablePadding
				secondaryAction={
					<IconButton onClick={onOpenContextMenu}>
						<MoreVertIcon />
					</IconButton>
				}
			>
				<ListItemButton
					component={Link}
					sx={{ paddingLeft: 11 }}
					to={applicationSubCategoryUpdate({ applicationName: studyId, categoryId, subCategoryId })}
				>
					<ListItemText
						primary={subCategory.name}
						primaryTypographyProps={{ noWrap: true }}
						secondary={subCategory.repeating ? 'Repeating' : 'Non-repeating'}
						secondaryTypographyProps={{ noWrap: true }}
					/>
				</ListItemButton>
			</ListItem>

			<SubCategoryContextMenu
				anchorElement={anchorElement}
				onClose={() => setAnchorElement(null)}
				subCategory={subCategory}
			/>
		</>
	);
};

export default SubCategory;
