import { makeSelectors } from '@/store/repository';
import { Asset, Property, UUID } from '@/store/types';
import State, { StateAsset } from '@/store/state';

const selector = makeSelectors<Asset>();

export const { allIds, get, getAll, getOrNull } = selector;

export const getAssetWithPropertyId = (state: State, propertyId: string): Asset => {
	// eslint-disable-next-line no-restricted-syntax
	for (const assetId in state.assets.byId) {
		if (Object.prototype.hasOwnProperty.call(state.assets.byId, assetId)) {
			const asset = get(state.assets, assetId);
			if (asset.propertyIds.includes(propertyId)) return asset;
		}
	}
	throw new Error(`No assets found for property ${propertyId}`);
};

export const getParentAssetsForProperties = (
	assets: StateAsset,
	properties: Property[],
): UUID[] => {
	const parentAssets: UUID[] = [];
	Object.keys(assets.byId).forEach((assetKey) => {
		if (
			assets.byId[assetKey].propertyIds.some((propertyId) =>
				properties.map((p) => p.id).includes(propertyId),
			)
		) {
			parentAssets.push(assetKey);
		}
	});
	return parentAssets;
};

export const getFirstAssetForQuestionCreation = (state: State): Asset | null => {
	const assets = getAll(state.assets);
	const firstSurveyAsset = Object.values(assets).find((asset) => asset.survey && !asset.fixed);
	return firstSurveyAsset || null;
};
