import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { ApiField } from 'kes-common';
import React from 'react';
import { DragDropContext, Droppable, DragDropContextProps } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { applicationName as applicationNameSelector } from '@/selectors';
import * as actions from '@/store/actions';

import AddButton from './AddButton';
import Field from './Field';
import * as Styled from './FormFields.styled';

interface FormFieldProps {
	fields: readonly ApiField[];
	onMoveFormField: DragDropContextProps['onDragEnd'];
	studyId: string;
	formId: string;
}

const FormFields: React.FC<FormFieldProps> = ({ fields, onMoveFormField, formId, studyId }) => {
	const [isReordering, setIsReordering] = React.useState<boolean>(false);
	const dispatch = useDispatch();
	const applicationName = useSelector(applicationNameSelector);
	const addField = React.useCallback(() => {
		dispatch(
			actions.formsFieldAdd({
				formId,
				id: uuid(),
				position: 0,
				studyId: applicationName,
				type: 'STRING',
			}),
		);
	}, [applicationName, formId]);

	return (
		<Box marginTop={2}>
			<DragDropContext
				onDragEnd={(result, provided) => {
					onMoveFormField(result, provided);
					setIsReordering(false);
				}}
				onDragStart={() => setIsReordering(true)}
			>
				<Card>
					<Droppable droppableId="table">
						{({ droppableProps, innerRef, placeholder }) => (
							<Styled.CardContent ref={innerRef} {...droppableProps}>
								{fields.length === 0 && (
									<>
										<Typography variant="body1">There are no fields in this table.</Typography>
										<AddButton onClick={addField} />
									</>
								)}
								{[...fields]
									.sort((a, b) => a.position - b.position)
									.map((field) => (
										<Field
											canAddField={!isReordering}
											field={field}
											formId={formId}
											key={field.id}
											studyId={studyId}
										/>
									))}
								{placeholder}
							</Styled.CardContent>
						)}
					</Droppable>
				</Card>
			</DragDropContext>
		</Box>
	);
};

export default FormFields;
