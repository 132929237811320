import React, {
	ComponentType,
	forwardRef,
	MouseEvent,
	Ref,
	ReactNode,
	MouseEventHandler,
} from 'react';
import { __RouterContext as RouterContext, matchPath } from 'react-router';
import { Route, RouteType } from '@/routes';
import { createLocation } from 'history';

function isModifiedEvent(event: MouseEvent<HTMLAnchorElement>): boolean {
	return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export interface LinkProps {
	navigate: (replace?: boolean) => void;
	href: string;
	replace?: boolean;
	className?: string;
	id?: string;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const Link: ComponentType<LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = forwardRef(
	(
		{
			onClick,
			navigate,
			replace,
			...rest
		}: LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>,
		forwardedRef: Ref<HTMLAnchorElement>,
	): JSX.Element => {
		const { target } = rest;

		const props = {
			...rest,
			onClick: (event: MouseEvent<HTMLAnchorElement>): void => {
				try {
					if (onClick) onClick(event);
				} catch (ex) {
					event.preventDefault();
					throw ex;
				}

				if (
					!event.defaultPrevented && // onClick prevented default
					event.button === 0 && // ignore everything but left clicks
					(!target || target === '_self') && // let browser handle "target=_blank" etc.
					!isModifiedEvent(event) // ignore clicks with modifier keys
				) {
					event.preventDefault();
					navigate(replace);
				}
			},
			ref: forwardedRef,
		};
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		return <a {...props} />;
	},
);

Link.displayName = 'Link';

type AnnotateRouteProps<P> = {
	[K in keyof P]: P[K] | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialRoute<R extends Route<any>> = AnnotateRouteProps<RouteType<R>>;

interface Context {
	Link: typeof Link;
	linkProps: LinkProps;
	matches: boolean;
	pathname: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function calculateToPath<R extends Route<any>>(
	route: R,
	location: string,
	hash?: string,
): string | null {
	if (route.templatePath.includes(':')) {
		const split = route.templatePath.split('/');
		let lastUnknownLocation = 0;
		for (let i = 0; i < split.length; i += 1) {
			if (split[i].startsWith(':')) {
				lastUnknownLocation = i;
			}
		}
		const newPath = split.slice(0, lastUnknownLocation + 1).join('/');
		const match = matchPath<RouteType<R>>(location, newPath);
		return match ? route(match.params, hash) : null;
	}
	return route.templatePath;
}

interface Props {
	to: Route<any>;
	params: PartialRoute<Route<any>>;
	hash?: string;
	children: ReactNode | ((context: Context) => JSX.Element);
	beforeNavigate?: (onContinue: () => void) => void;
	className?: string;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
	replace?: boolean;
	['data-testid']?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkContext: React.FC<Props> = ({ to, ...props }) => {
	const filteredParams: Partial<RouteType<Route<any>>> = {};
	// eslint-disable-next-line no-restricted-syntax
	for (const key in props.params) {
		if (Object.prototype.hasOwnProperty.call(props.params, key)) {
			const val = props.params[key];
			if (val !== null) {
				filteredParams[key] = val as NonNullable<typeof val>;
			}
		}
	}
	const partiallyResolved = to.resolvePartially(filteredParams);

	return (
		<RouterContext.Consumer>
			{(context): ReactNode => {
				const { history } = context;

				const toPath = calculateToPath(partiallyResolved, history.location.pathname, props.hash);
				if (!toPath) {
					return null;
				}

				const location = createLocation(toPath, null, '', context.location);

				const href = location ? history.createHref(location) : '';
				const linkContext: Context = {
					Link,
					matches: toPath === history.location.pathname,
					pathname: history.location.pathname,
					linkProps: {
						href,
						className: props.className,
						replace: props.replace,
						onClick: props.onClick,
						navigate(replace?: boolean): void {
							const method = replace ? history.replace : history.push;
							if (props.beforeNavigate) {
								props.beforeNavigate((): void => method(toPath));
							} else {
								method(toPath);
							}
						},
					},
				};
				return props.children instanceof Function ? (
					props.children(linkContext)
				) : (
					<linkContext.Link data-testid={props['data-testid']} {...linkContext.linkProps}>
						{props.children}
					</linkContext.Link>
				);
			}}
		</RouterContext.Consumer>
	);
};

export default LinkContext;
