import { ComponentType } from 'react';
import getDisplayName from './getDisplayName';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function nameHoc<H extends ComponentType<any>>(
	Hoc: H,
	Component: { readonly displayName?: string; readonly name?: string },
): H {
	// eslint-disable-next-line no-param-reassign
	Hoc.displayName = `${Hoc.name}(${getDisplayName(Component)})`;
	return Hoc;
}
