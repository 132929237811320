import React, { FC, cloneElement } from 'react';
import MuiChip, { ChipProps } from '@mui/material/Chip';
import classNames from 'classnames';
import assertNever from '@/utils/assertNever';
import classes from './Chip.module.css';

type Props = ChipProps & {
	textColor?: 'purpley';
};

function classForColor(textColor: Props['textColor']): string {
	switch (textColor) {
		case undefined:
			return classes.chipDefault;
		case 'purpley':
			return classes.chipPurpley;
		default:
			return assertNever(textColor);
	}
}

const Chip: FC<Props> = ({
	textColor,
	className,
	avatar,
	classes: propClasses,
	...rest
}): JSX.Element => (
	<MuiChip
		className={classNames(className, classForColor(textColor))}
		avatar={
			avatar
				? cloneElement(avatar, {
						className: classNames(classes.avatar, avatar.props.className),
				  })
				: avatar
		}
		classes={{
			...propClasses,
			label: classNames(propClasses ? propClasses.label : undefined, classes.label),
		}}
		{...rest}
	/>
);

export default Chip;
