import { ApiUpsertAction } from 'kes-common';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { studyId as studyIdSelector } from '@/selectors';
import { KES_FRONTEND_URL } from '@/constants';
import { propertyUpdateRules } from '@/store/actions';
import State from '@/store/state';
import { RuleSet } from '@/store/types';
import { actionsByPropertyId } from '@/selectors/actions';
import { getRuleSetList } from '@/selectors/ruleSets';
import RuleSelector from './RuleSelector';

interface RulesDialogProps {
	show: boolean;
	onClose: () => void;
	propertyId: string;
	propertyRuleIds: string[];
}

export const orderRuleIdsByRuleSetOrder = (ruleIds: string[], ruleSets: RuleSet[]): string[] => {
	let orderedIds: string[] = [];
	ruleSets.forEach((ruleSet) => {
		ruleSet.rules.forEach((rule) => {
			if (ruleIds.some((id) => id === rule.id)) {
				orderedIds = [...orderedIds, rule.id];
			}
		});
	});
	return orderedIds;
};

export const RulesDialog: React.FC<RulesDialogProps> = ({
	show,
	onClose,
	propertyId,
	propertyRuleIds,
}) => {
	const studyId = useSelector(studyIdSelector);
	const ruleSets = useSelector<State, RuleSet[]>(getRuleSetList);

	const dispatch = useDispatch();
	const [selectedRuleIds, setSelectedRuleIds] = React.useState<string[]>([]);

	// Set selectedRuleIds to the the id's in the ruleId's because these should show as checked on initial load.
	React.useEffect(() => {
		setSelectedRuleIds(propertyRuleIds);
	}, [propertyRuleIds]);

	const propertyActions = useSelector(actionsByPropertyId(propertyId));

	const updateRules = () => {
		const orderedRules = orderRuleIdsByRuleSetOrder(selectedRuleIds, ruleSets);

		const updateAction = propertyUpdateRules({
			propertyId,
			upsertActions: orderedRules.map((ruleId) => {
				const action = propertyActions.find((propertyAction) => propertyAction.rule === ruleId);
				if (!action) {
					return { id: uuid(), ruleId } as ApiUpsertAction;
				}
				return { id: action.id, ruleId: action.rule };
			}),
		});

		// This should never be the case, yet the test clearly shows it happening.
		// The `makeAction` utility returns `undefined` the second time it is called.
		// Rule sets are not being used so I am not going to spend time on this more.
		if (updateAction) {
			dispatch(updateAction);
		}
		onClose();
	};

	const closeDialog = () => {
		setSelectedRuleIds(propertyRuleIds);
		onClose();
	};

	return (
		<Dialog maxWidth="md" fullWidth open={show} onClose={closeDialog}>
			<DialogContent>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="body1" style={{ fontWeight: 500 }} color="primary">
						Select rules
					</Typography>
					<a
						href={`${KES_FRONTEND_URL}templates?templateId=${studyId}`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						<Button color="primary" variant="contained">
							CREATE NEW SET
						</Button>
					</a>
				</Box>
				<RuleSelector selectedRuleIds={selectedRuleIds} setSelectedRuleIds={setSelectedRuleIds} />
			</DialogContent>
			<DialogActions>
				<Button
					data-testid="rule-dialog__actions__cancel"
					onClick={closeDialog}
					variant="text"
					autoFocus
				>
					Cancel
				</Button>
				<Button
					data-testid="actions-save-category"
					color="primary"
					onClick={updateRules}
					variant="contained"
					autoFocus
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
