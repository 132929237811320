import {
	Dialog,
	DialogContent,
	Box,
	Typography,
	DialogActions,
	DialogTitle,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Grid,
} from '@mui/material';
import React, { FC, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ApiAssetFilter, ApiProperty } from '@/../../common/src';
import { ValidationError } from 'yup';

interface FilterDialogProps {
	show: boolean;
	title: string;
	properties: ApiProperty[];
	onClose: () => void;
	filter: ApiAssetFilter;
	onAction: (filter: ApiAssetFilter) => void;
	errors: ValidationError[];
}

const FilterDialog: FC<FilterDialogProps> = ({
	show,
	title,
	properties,
	onClose,
	filter,
	onAction,
	errors,
}) => {
	const [workingFilter, setWorkingFilter] = useState(filter);

	const fieldErrors = (key: keyof ApiAssetFilter) =>
		errors
			.filter((error) => error.path === key)
			.map((error) => error.message)
			.join(', ');

	return (
		<Dialog maxWidth="md" fullWidth open={show}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Box mb={2} display="flex">
					<Box pr={1}>
						<InfoOutlinedIcon />
					</Box>
					<Typography color="textSecondary">
						Here you can set up filtered tables that match the criteria below. The output will be a
						tag for the filter you created.
					</Typography>
				</Box>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Box mx={1}>
							<TextField
								variant="filled"
								data-testid="filter-name"
								label="Filter name"
								value={workingFilter.name}
								onChange={(e) => {
									const { value } = e.target;
									setWorkingFilter((old: ApiAssetFilter) => ({ ...old, name: value }));
								}}
								fullWidth
								error={fieldErrors('name') !== ''}
								helperText={` ${fieldErrors('name')}`}
							/>
						</Box>
					</Grid>
					<Grid item xs={4}>
						<Box mx={1}>
							<FormControl fullWidth variant="filled">
								<InputLabel id="property-label">Property</InputLabel>
								<Select
									labelId="property-label"
									data-testid="property-select"
									variant="filled"
									value={workingFilter.propertyId}
									error={fieldErrors('propertyId') !== ''}
									MenuProps={{
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left',
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left',
										},
									}}
									onChange={(e) => {
										const { value } = e.target;
										setWorkingFilter((old: ApiAssetFilter) => ({
											...old,
											propertyId: value as string,
										}));
									}}
								>
									{properties.map((prop) => (
										<MenuItem key={prop.id} value={prop.id}>
											{prop.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<Box mx={1}>
							<FormControl required fullWidth variant="filled">
								<InputLabel id="check-type-label">Check Type</InputLabel>
								<Select
									labelId="check-type-label"
									data-testid="check-type-select"
									value={workingFilter.checkType}
									onChange={(e) => {
										const { value } = e.target;
										setWorkingFilter((old: ApiAssetFilter) => ({
											...old,
											checkType: value as typeof old.checkType,
										}));
									}}
									error={fieldErrors('checkType') !== ''}
									MenuProps={{
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left',
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left',
										},
									}}
								>
									<MenuItem value="MIN">Is less than</MenuItem>
									<MenuItem value="MAX">Is greater than</MenuItem>
									<MenuItem value="EQUALS">Equals</MenuItem>
									<MenuItem value="RANGE">Range</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box mx={1}>
							<FormControl fullWidth>
								<TextField
									label={workingFilter.checkType === 'RANGE' ? 'Lower bound' : 'Check value'}
									id="check-value-label"
									type="number"
									variant="filled"
									value={Number.isNaN(workingFilter.checkValue) ? '' : workingFilter.checkValue}
									onChange={(e) => {
										const { value } = e.target;
										setWorkingFilter((old: ApiAssetFilter) => ({
											...old,
											checkValue: parseFloat(value),
										}));
									}}
									error={fieldErrors('checkValue') !== ''}
									helperText={` ${fieldErrors('checkValue')}`}
								/>
							</FormControl>
						</Box>
					</Grid>
					{workingFilter.checkType === 'RANGE' && (
						<Grid item xs={3}>
							<Box mx={1}>
								<FormControl fullWidth>
									<TextField
										label="Upper bound"
										variant="filled"
										id="upper-bound"
										type="number"
										value={Number.isNaN(workingFilter.upperBound) ? '' : workingFilter.upperBound}
										onChange={(e) => {
											const { value } = e.target;
											setWorkingFilter((old: ApiAssetFilter) => ({
												...old,
												upperBound: parseFloat(value),
											}));
										}}
										error={fieldErrors('upperBound') !== ''}
										helperText={` ${fieldErrors('upperBound')}`}
									/>
								</FormControl>
							</Box>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="text" autoFocus onClick={onClose}>
					Cancel
				</Button>
				<Button
					data-testid="actions-save-filter"
					color="primary"
					variant="contained"
					autoFocus
					onClick={() => {
						onAction(workingFilter);
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FilterDialog;
