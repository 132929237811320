import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { applicationCategoryCreate } from '@/routes';
import { studyId as studyIdSelector } from '@/selectors';

import { categoryGetAllOrdered } from '@/selectors/category';
import { categoryPersist } from '@/store/actions';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import Category from './Category';

const SidebarCategoriesList: React.FC = () => {
	const categories = useSelector(categoryGetAllOrdered);
	const studyId = useSelector(studyIdSelector);
	const dispatch = useDispatch();

	const onDragEnd = ({ destination, source }: DropResult) => {
		if (!destination) return; // dropped outside the list
		dispatch(
			categoryPersist({
				category: categories[source.index],
				position: destination.index,
			}),
		);
	};
	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable direction="vertical" droppableId="categories">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<List sx={{ width: 400 }}>
								{Object.values(categories).map((category, index) => (
									<Category category={category} index={index} key={category.id} />
								))}
							</List>
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<Box marginX={2}>
				<Button
					component={Link}
					fullWidth
					to={applicationCategoryCreate({ applicationName: studyId })}
					variant="contained"
				>
					Create Category
				</Button>
			</Box>
		</>
	);
};

export default SidebarCategoriesList;
