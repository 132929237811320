import { updateEndNote } from '@/net/api';
import EditIcon from '@mui/icons-material/Edit';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Button,
	Alert,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useMutation } from '@tanstack/react-query';
import { ApiStudyDto, ApiEndNote } from 'kes-common';
import React from 'react';

interface EditEndMessageProps {
	onClose(): void;
	studyId: ApiStudyDto['id'];
}

const EditEndMessage: React.FC<EditEndMessageProps> = ({ onClose, studyId }) => {
	const [openDialog, setOpenDialog] = React.useState(false);
	const handleOpenDialog = () => setOpenDialog(true);
	const handleCloseDialog = () => {
		setOpenDialog(false);
		onClose(); // Close the overflow menu
	};

	const [endNote, setEndNote] = React.useState<string>('');

	const { mutate, isError, isLoading } = useMutation(
		['endnote', studyId, endNote],
		() =>
			updateEndNote(studyId, { text: endNote.length > 0 ? endNote : null } as ApiEndNote).then(
				(response) => response.expectSuccess(),
			),
		{
			onSuccess: () => {
				handleCloseDialog();
			},
		},
	);

	const handleSaveNote = () => {
		mutate();
	};

	return (
		<>
			<Tooltip
				placement="left"
				title="Edit the message that will be shown when the user completes the survey"
			>
				<span>
					<MenuItem onClick={handleOpenDialog}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						<ListItemText primary="Edit end message" />
					</MenuItem>
				</span>
			</Tooltip>
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogTitle color="primary">Edit end message</DialogTitle>
				<DialogContent>
					<DialogContentText mb={4}>
						If you wish so you can edit the message that will be shown when the user completes the
						survey
					</DialogContentText>
					<TextField
						autoFocus
						required
						multiline
						id="end-message"
						name="end-message"
						value={endNote}
						label="Write your custom end of survey message, or leave it empty to use the default message."
						onChange={(event) => setEndNote(event.target.value)}
						type="text"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				{isError && (
					<Alert sx={{ m: 3 }} variant="filled" severity="error">
						Something wrong happened, please try again later.
					</Alert>
				)}
				<DialogActions>
					<Button onClick={handleCloseDialog}>Cancel</Button>
					<Button disabled={isLoading} onClick={handleSaveNote}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditEndMessage;
