import { RouteComponentProps, matchPath, match } from 'react-router';

type MatchResult<T> = {
	[K in keyof T]: string;
};

type RouteParams<T> = Record<string, unknown> extends T ? T | undefined | void : T;

export interface Route<T> {
	readonly templatePath: string;
	(params: RouteParams<T>, hash?: string): string;
	matchPath(url: string): null | match<MatchResult<T>>;
	matchPathWithProps(url: string, params: RouteParams<T>): null | match<Record<string, undefined>>;
	resolvePartially<P extends Partial<T>>(params: P): Route<Pick<T, Exclude<keyof T, keyof P>>>;
	readonly hasParams: boolean;
	markOptional(): this;
	readonly hasOptional: boolean;
	readonly keys: (keyof T)[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PropsOfRoute<R extends Route<any>> = R extends Route<infer P>
	? RouteComponentProps<MatchResult<P>>
	: RouteComponentProps<Record<string, undefined>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RouteType<R extends Route<any>> = R extends Route<infer P> ? P : never;

function route<
	V extends string,
	R = Record<string, unknown> extends { [K in V]: string | number }
		? Record<string, unknown>
		: { [K in V]: string | number },
>(literals: TemplateStringsArray, ...placeholders: V[]): Route<R> {
	let templatePath = '';
	for (let i = 0; i < placeholders.length; i += 1) {
		templatePath += literals[i];
		templatePath += `:${placeholders[i]}`;
	}
	templatePath += literals[literals.length - 1];
	function linkTo(params: RouteParams<R>, hash?: string): string {
		let url = '';
		for (let i = 0; i < placeholders.length; i += 1) {
			url += literals[i];
			// Typescript is not smart enough to automatically check the types here, but the signature is correct
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			url += (params as any)[placeholders[i]];
		}
		url += literals[literals.length - 1];
		if (hash) {
			url += `#${hash}`;
		}
		return url;
	}
	linkTo.matchPath = (url: string): ReturnType<Route<R>['matchPath']> =>
		matchPath(url, templatePath);
	linkTo.matchPathWithProps = (
		url: string,
		params: RouteParams<R>,
	): ReturnType<Route<R>['matchPathWithProps']> => matchPath(url, linkTo(params));
	linkTo.resolvePartially = <P extends Partial<R>>(
		params: P,
	): Route<Pick<R, Exclude<keyof R, keyof P>>> => {
		const newLiterals = [] as unknown as { raw: readonly string[] } & string[];
		const newPlaceholders: string[] = [];

		let pendingLiterall = '';

		for (let i = 0; i < placeholders.length; i += 1) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const castedParams = params as any;

			if (placeholders[i] in castedParams) {
				pendingLiterall = pendingLiterall + literals[i] + castedParams[placeholders[i]];
			} else {
				newLiterals.push(pendingLiterall + literals[i]);
				pendingLiterall = '';
				newPlaceholders.push(placeholders[i]);
			}
		}
		newLiterals.push(pendingLiterall + literals[placeholders.length]);
		newLiterals.raw = literals.raw;
		const newRoute: Route<Pick<R, Exclude<keyof R, keyof P>>> = route(
			newLiterals,
			...newPlaceholders,
		);
		if (linkTo.hasOptional) {
			return newRoute.markOptional();
		}
		return newRoute;
	};
	linkTo.templatePath = templatePath;
	linkTo.hasParams = placeholders.length > 0;
	linkTo.toString = (): string => `route \`${templatePath}\``;
	linkTo.hasOptional = false;
	linkTo.keys = placeholders as unknown as (keyof R)[];
	linkTo.markOptional = (): Route<R> => {
		if (!linkTo.hasOptional) {
			linkTo.templatePath += '?';
			templatePath += '?';
		}
		linkTo.hasOptional = true;
		return linkTo;
	};
	return linkTo;
}
/* eslint-disable max-len */
// Use the ES6 template syntax to declare these routes, this makes it really clean

// Home page - informs user that no application is specified.
export const home = route`/`;

// Project:
export const application = route`/${'applicationName'}`;

export const applicationProperty = route`/${'applicationName'}/property/${'propertyId'}`;
export const applicationAsset = route`/${'applicationName'}/asset/${'assetId'}`;
export const applicationPropertyCreate = route`/${'applicationName'}/property/create/${'assetId'}`;
export const applicationPropertyCreateQuestion = route`/${'applicationName'}/property/createquestion/${'assetId'}`;

export const applicationAssetCreate = route`/${'applicationName'}/asset/create/${'categoryId'}`;

export const applicationForm = route`/${'applicationName'}/forms/${'formId'}`;
export const applicationForms = route`/${'applicationName'}/forms`;

export const applicationCategoryCreate = route`/${'applicationName'}/category/create`;
export const applicationCategoryUpdate = route`/${'applicationName'}/category/${'categoryId'}`;

export const applicationSubCategoryUpdate = route`/${'applicationName'}/category/${'categoryId'}/${'subCategoryId'}`;
export const applicationSubCategoryCreate = route`/${'applicationName'}/category/${'categoryId'}/create`;
