import { Auth0Client, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

interface ProviderSelectorProps {
	auth0Client: Auth0Client;
}

const ProviderSelector: React.FC<ProviderSelectorProps> = ({ auth0Client }) => {
	const { inProgress, instance } = useMsal();

	const loginAuth0 = React.useCallback(() => {
		const redirectOptions: RedirectLoginOptions = { appState: window.location.pathname };
		auth0Client.loginWithRedirect(redirectOptions);
	}, [auth0Client]);

	const loginMsal = React.useCallback(() => {
		instance.loginRedirect();
	}, [instance]);

	return (
		<Container maxWidth="sm" sx={{ marginTop: 3, minHeight: '100%' }}>
			<Card>
				<CardContent>
					<Grid alignItems="center" container direction="column" padding={3} spacing={2}>
						<Grid item marginBottom={5}>
							<Typography variant="h3">Welcome to Kes</Typography>
						</Grid>
						{inProgress === InteractionStatus.Login && (
							<Grid item>
								<Typography variant="body1">Logging in</Typography>
							</Grid>
						)}
						{inProgress !== InteractionStatus.Login && (
							<>
								<Grid item>
									<Button fullWidth onClick={loginMsal}>
										I am a Royal HaskoningDHV employee
									</Button>
								</Grid>
								<Grid item>
									<Typography variant="subtitle1">or</Typography>
								</Grid>
								<Grid item>
									<Button fullWidth onClick={loginAuth0}>
										I am an external user
									</Button>
								</Grid>
							</>
						)}
					</Grid>
				</CardContent>
			</Card>
		</Container>
	);
};

export default ProviderSelector;
