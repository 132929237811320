import { ApiActionDto } from '@/../../common/src';
import State from '@/store/state';
import { Property, Rule } from '@/store/types';

interface ActionByPropertyAndRuleIdsOptions {
	propertyId: Property['id'];
	ruleId: Rule['id'];
}

export const actionsByPropertyId =
	(propertyId: Property['id']) =>
	(state: State): ApiActionDto[] =>
		Object.values(state.actions.byId).filter((action) => action.propertyId === propertyId);

export const actionByPropertyAndRuleIds =
	({ propertyId, ruleId }: ActionByPropertyAndRuleIdsOptions) =>
	(state: State): ApiActionDto | null => {
		const {
			actions: { byId },
		} = state;
		const action = Object.values(byId).find(
			(actionById) => actionById.propertyId === propertyId && actionById.rule === ruleId,
		);
		return action || null;
	};

interface ActionDependentPropertiesOptions {
	actionId: ApiActionDto['id'];
}

export const actionDependentProperties =
	({ actionId }: ActionDependentPropertiesOptions) =>
	(state: State): Property[] => {
		const action = state.actions.byId[actionId];
		if (action) {
			return action.dependentPropertyIds.reduce((accumulator, dependentPropertyId) => {
				const property = state.properties.byId[dependentPropertyId];
				return property ? [...accumulator, property] : accumulator;
			}, [] as Property[]);
		}
		return [];
	};
