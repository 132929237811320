import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { ApiStudyDto, useGoogleTagManager } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';

import { KES_FRONTEND_URL } from '@/constants';
import { studyTestProjectId } from '@/selectors';
import ActivityPreviewDrawer from '@/components/ActivityPreviewDrawer';

interface ButtonActivityPreviewProps {
	disabled: boolean;
	onBeforePreview(): Promise<boolean>;
	studyId: ApiStudyDto['id'];
}

const ButtonActivityPreview: React.FC<ButtonActivityPreviewProps> = ({
	disabled,
	onBeforePreview,
	studyId,
}) => {
	const buttonRef = React.useRef<HTMLDivElement>(null);
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

	const testProjectId = useSelector(studyTestProjectId);

	const { trackCustomEvent } = useGoogleTagManager();
	const { enqueueSnackbar } = useSnackbar();

	const isMenuOpen = React.useMemo<boolean>(() => Boolean(anchorElement), [anchorElement]);

	const onActivityOpen = React.useCallback(async () => {
		trackCustomEvent({ event: 'template_previewed', mode: 'window', templateId: studyId });
		const canPreview = await onBeforePreview();
		setAnchorElement(null);
		if (canPreview) {
			window.open(`${KES_FRONTEND_URL}project/${testProjectId}/preview`, '_blank');
		} else {
			enqueueSnackbar('There are errors in the Template. Cannot preview.');
		}
	}, [
		enqueueSnackbar,
		onBeforePreview,
		setAnchorElement,
		studyId,
		testProjectId,
		trackCustomEvent,
	]);

	const onDrawerClose = React.useCallback(() => {
		setIsDrawerOpen(false);
	}, [setIsDrawerOpen]);

	const onDrawerOpen = React.useCallback(async () => {
		trackCustomEvent({ event: 'template_previewed', mode: 'drawer', templateId: studyId });
		const canPreview = await onBeforePreview();
		setAnchorElement(null);
		if (canPreview) {
			setIsDrawerOpen(true);
		} else {
			enqueueSnackbar('There are errors in the Template. Cannot preview.');
		}
	}, [enqueueSnackbar, setAnchorElement, setIsDrawerOpen, studyId, trackCustomEvent]);

	const onMenuClose = React.useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);

	const onMenuOpen = React.useCallback((): void => {
		setAnchorElement(buttonRef.current);
	}, [buttonRef, setAnchorElement]);

	return (
		<>
			<ButtonGroup disabled={disabled} ref={buttonRef} variant="contained">
				<Button onClick={onDrawerOpen} startIcon={<PreviewIcon />}>
					Preview Activity
				</Button>
				<Button
					size="small"
					aria-controls={isMenuOpen ? 'activity-preview' : undefined}
					aria-expanded={isMenuOpen ? 'true' : undefined}
					aria-label="Preview activity"
					aria-haspopup="menu"
					onClick={isMenuOpen ? onMenuClose : onMenuOpen}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>

			<ActivityPreviewDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} />

			<Menu anchorEl={anchorElement} id="activity-preview" onClose={onMenuClose} open={isMenuOpen}>
				<MenuItem onClick={onDrawerOpen} sx={{ alignItems: 'start', flexDirection: 'column' }}>
					<Typography>
						<strong>Preview activity</strong>
					</Typography>
					<Typography>Preview the activity in a drawer with minimal controls</Typography>
				</MenuItem>
				<MenuItem onClick={onActivityOpen} sx={{ alignItems: 'start', flexDirection: 'column' }}>
					<Typography>
						<strong>Open activity preview</strong>
					</Typography>
					<Typography>Open the activity preview in a new tab/window</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export default ButtonActivityPreview;
