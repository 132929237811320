import { ApiActionDto, ApiForm, ApiAssetFilter } from 'kes-common';
import {
	Category,
	Asset,
	Property,
	Choice,
	Report,
	ReportValidationError,
	UUID,
	RuleSet,
} from './types';
import { State as RepositoryState } from './repository';
import { PropertyState } from './reducers/properties';

export interface StateRouter {
	path: string;
	params: Record<string, string>;
	pageTitle: string;
	redirectUrl: string | null;
}

export interface StateChanges {
	status: 'saving' | 'offline' | 'done' | 'failed';
	updateCount: number;
	savedAt?: Date | null;
}

export interface StateApplication {
	id: UUID;
	assetLibraryId: UUID;
	name: string;
	prettyName: string;
	testProjectId: UUID;
	categories: Category['id'][];
	isMilicense: boolean;
	loaded: boolean;
}

export interface StateAsset {
	byId: Record<Asset['id'], Asset>;
	allIds: Asset['id'][];
	error: string | null;
}

export interface StateForms {
	allIds: ApiForm['id'][];
	byId: Record<ApiForm['id'], ApiForm>;
	status: 'error' | 'loading' | 'success';
}

export enum SidebarMode {
	CLASSIC = 'CLASSIC',
	QUESTION = 'QUESTION',
}

export interface StateUI {
	isDependencyGraphOpen: boolean;
	sidebarMode: SidebarMode;
}

export default interface State {
	actions: RepositoryState<ApiActionDto>;
	application: StateApplication;
	categories: RepositoryState<Category>;
	assets: StateAsset;
	forms: StateForms;
	properties: PropertyState<Property>;
	choice: RepositoryState<Choice>;
	report: Report | null;
	reportValidation: ReportValidationError[];
	router: StateRouter;
	changes: StateChanges;
	ruleSets: RepositoryState<RuleSet>;
	assetFilters: RepositoryState<ApiAssetFilter>;
	ui: StateUI;
} // eslint-disable-line semi
