import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import React, { FC, ReactNode } from 'react';
import { useFeatureFlag } from 'kes-common';

import { Asset, PropertyType } from '@/store/types';

const TypeNames: Record<PropertyType, string> = {
	[PropertyType.DATE]: 'Date',
	[PropertyType.RICH_TEXT]: 'Rich text',
	[PropertyType.STRING]: 'Text',
	[PropertyType.DECIMAL]: 'Number',
	[PropertyType.SINGLE_SELECT]: 'Single select',
	[PropertyType.MULTI_SELECT]: 'Multiple select',
	[PropertyType.SINGLE_SUBSTANCE]: 'Single substance',
	[PropertyType.MULTI_SUBSTANCE]: 'Multiple substances',
	[PropertyType.IMAGE]: 'Image',
	[PropertyType.LOCATIONS]: 'Locations',
};

const TypeNameKeys = (isMilicense: boolean) =>
	(Object.keys(TypeNames) as PropertyType[]).filter(
		(typeName) =>
			isMilicense ||
			(typeName !== PropertyType.SINGLE_SUBSTANCE && typeName !== PropertyType.MULTI_SUBSTANCE),
	);

interface TypeSelectorProps {
	asset: Asset;
	isMilicense: boolean;
	selectedType: PropertyType;
	onChange: (type: PropertyType) => void;
}

const TypeSelector: FC<TypeSelectorProps> = ({ asset, isMilicense, selectedType, onChange }) => {
	const propertyTypeDateEnabled = useFeatureFlag({ name: 'PROPERTY_TYPE_DATE' });
	return (
		<Box display="flex" flexWrap="wrap" sx={{ gap: 1 }}>
			{TypeNameKeys(isMilicense).map((type): ReactNode => {
				if (
					[PropertyType.LOCATIONS, PropertyType.RICH_TEXT].includes(type) &&
					asset.type === 'FLAG'
				) {
					return null;
				}

				if (type === PropertyType.DATE && !propertyTypeDateEnabled) {
					return null;
				}

				return (
					<Chip
						key={type}
						data-testid={`select-property-type-${type}`}
						label={TypeNames[type]}
						onClick={(): void => onChange(type)}
						color={selectedType === type ? 'primary' : 'default'}
					/>
				);
			})}
		</Box>
	);
};

export default TypeSelector;
