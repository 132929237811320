import makeReducer from '@/store/makeReducer';
import { ApiCategory } from 'kes-common';
import * as actions from '../actions';
import { initialState, makeRepository } from '../repository';
import { Category } from '../types';

const repository = makeRepository<Category>();

function mapApiToLocal(api: ApiCategory): Category {
	return api;
}

export default makeReducer(initialState<Category>(), {
	[actions.applicationLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.categoriesById, mapApiToLocal);
	},
	[actions.categoryPersist.type]: (draft, payload): void => {
		repository.upsert(draft, payload.category);
	},
	[actions.categoryUpdate.type]: (draft, payload): void => {
		repository.update(draft, payload);
	},
	[actions.assetPersist.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.assetTypeIds.indexOf(payload.asset.id);
			if (payload.categoryId === item.id && index !== payload.position) {
				if (index >= 0) item.assetTypeIds.splice(index, 1);
				item.assetTypeIds.splice(payload.position, 0, payload.asset.id);
			}
			if (payload.categoryId !== item.id && index >= 0) {
				item.assetTypeIds.splice(index, 1);
			}
		});
	},
	[actions.assetGenerate.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.assetTypeIds.indexOf(payload.id);
			if (index < 0) {
				if (payload.categoryId === item.id) {
					item.assetTypeIds.push(payload.id);
				}
				// While the following else block should never be triggered in practice, it still happens when a
				// generate action gets called with an id that already exists, in this case, we remove the
				// passed in id, and re-assign it to the correct parent
			} else if (payload.categoryId !== item.id) {
				item.assetTypeIds.splice(index, 1);
			}
		});
	},
});
