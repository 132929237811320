import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/noneditable';
import 'tinymce/icons/default/icons';
import 'tinymce/skins/ui/oxide/skin.css';
import { v4 as uuid } from 'uuid';
import { Editor } from '@tinymce/tinymce-react';
import WarningIcon from '@mui/icons-material/Warning';
import { useGoogleTagManager } from 'kes-common';
import { connect, useSelector } from 'react-redux';
import { Report, ReportValidationError } from '@/store/types';
import { ActionDispatcher } from '@/store/utils';
import { reportUpdate as reportUpdateAction } from '@/store/actions';
import State from '@/store/state';
import { BACKEND_URL, CLIENT_ID } from '@/constants';
import { imageUpload } from '@/net/api';
import { studyId as studyIdSelector } from '@/selectors';
import { requires } from './hoc/withRenderGuard';
import classes from './ReportContent.module.css';
import TextWithBreaks from './TextWithBreaks';

interface ValidationError {
	height: number;
	margin: number;
	error: string;
	lineNumber: number;
}

const errorTooltip: FC<ValidationError> = ({ height, margin, error, lineNumber }) => {
	if (error) {
		return (
			<span
				className={classes.errorContainer}
				key={lineNumber}
				style={{
					height,
					marginTop: margin,
					marginBottom: margin,
				}}
			>
				<Tooltip
					title={<TextWithBreaks text={error} />}
					enterDelay={0}
					placement="bottom-start"
					classes={{ tooltip: classes.errorTooltip, popper: classes.errorPopper }}
					TransitionProps={{ timeout: 0 }}
				>
					<span className={classes.lineError}>
						<WarningIcon className={classes.lineErrorIcon} />
					</span>
				</Tooltip>
			</span>
		);
	}
	return (
		<span
			className={classes.errorContainer}
			key={lineNumber}
			style={{
				height,
				marginTop: margin,
				marginBottom: margin,
			}}
		/>
	);
};

function groupErrors(reportValidation: ReportValidationError[]): Record<number, string> {
	const errors: Record<number, string> = {};
	if (reportValidation) {
		// eslint-disable-next-line no-restricted-syntax
		for (const error of reportValidation) {
			errors[error.lineNumber] = errors[error.lineNumber]
				? `${errors[error.lineNumber]}\n${error.message}`
				: error.message;
		}
	}
	return errors;
}

interface ContentProps {
	report: Report;
	reportValidation: ReportValidationError[];
	reportUpdate: ActionDispatcher<typeof reportUpdateAction>;
	interactionEnabled: boolean;
}

interface HasOffsetHeight extends Element {
	offsetHeight: number;
}

function hasOffsetHeight(object: ChildNode): object is HasOffsetHeight {
	return 'offsetHeight' in object;
}

const Content: FC<ContentProps> = ({
	report,
	reportUpdate,
	interactionEnabled,
	reportValidation,
}): JSX.Element | null => {
	const [overlayErrors, setOverlayErrors] = useState<ValidationError[]>([]);
	const overlay = useRef<HTMLDivElement>(null);
	const studyId = useSelector(studyIdSelector);
	const { trackCustomEvent } = useGoogleTagManager();
	const match = useRouteMatch<{
		applicationName?: string;
		assetId?: string;
		categoryId?: string;
		propertyId?: string;
	}>({
		path: [
			'/:applicationName/asset/:assetId',
			'/:applicationName/property/:propertyId',
			'/:applicationName/category/:categoryId',
			'/:applicationName/category/:categoryId/:subCategoryId',
		],
	});

	let editor: Document | null;

	const updateOverlay = useCallback(() => {
		const iframe = document.getElementsByClassName('tox-edit-area__iframe')[0];
		const errors: ValidationError[] = [];
		if (iframe instanceof HTMLIFrameElement) {
			const innerDoc =
				iframe.contentDocument || (iframe.contentWindow && iframe.contentWindow.document);
			const editorBody = innerDoc && innerDoc.getElementById('tinymce');

			if (editorBody) {
				editorBody.childNodes.forEach((childNode, key) => {
					if (hasOffsetHeight(childNode)) {
						const styles = window.getComputedStyle(childNode);
						const margin = styles.marginBottom;
						if (margin)
							errors.push({
								height: childNode.offsetHeight,
								margin: parseFloat(margin),
								error: groupErrors(reportValidation)[key + 1],
								lineNumber: key + 1,
							});
					}
				});
			}
			setOverlayErrors(errors);
		}
	}, [reportValidation]);

	const updateScrollPosition = (): void => {
		if (editor && overlay.current) {
			overlay.current.scrollTop = editor.scrollingElement ? editor.scrollingElement.scrollTop : 0;
			overlay.current.scrollLeft = editor.scrollingElement ? editor.scrollingElement.scrollLeft : 0;
		}
	};

	const onInitEditor = () => {
		updateOverlay();
		const iframe = document.getElementsByClassName('tox-edit-area__iframe')[0];
		if (iframe instanceof HTMLIFrameElement) {
			if (iframe.contentWindow) {
				iframe.contentWindow.addEventListener('scroll', updateScrollPosition);
			}
			editor = iframe.contentWindow && iframe.contentWindow.document;
		} else {
			throw Error('Editor could not be found');
		}
	};

	useEffect((): (() => void) => {
		window.addEventListener('resize', updateScrollPosition);
		window.addEventListener('resize', updateOverlay);
		return (): void => {
			if (editor) {
				editor.removeEventListener('scroll', updateScrollPosition);
			}
			window.removeEventListener('resize', updateScrollPosition);
			window.removeEventListener('resize', updateOverlay);
		};
	});

	useEffect(() => {
		updateOverlay();
	}, [reportValidation, updateOverlay]);

	const onUpdate = (content: string): void => {
		reportUpdate({
			name: reportUpdate.name,
			template: content,
			latestUpdateClientId: CLIENT_ID,
		});
	};

	const onChange = React.useCallback(
		(content: string) => {
			onUpdate(content);
			updateOverlay();
		},
		[onUpdate, updateOverlay],
	);

	if (!reportValidation) {
		return null;
	}

	return (
		<div
			className={classnames({
				[classes.contentContainer]: true,
				[classes.hasProperty]:
					Boolean(match?.params.propertyId || match?.params.assetId || match?.params.categoryId) ||
					window.location.href.includes('category/create'),
			})}
		>
			<div className={classes.errors} ref={overlay}>
				{overlayErrors && overlayErrors.map((error: ValidationError) => errorTooltip(error))}
			</div>
			<Editor
				disabled={!interactionEnabled}
				value={report.template}
				onEditorChange={onChange}
				onInit={onInitEditor}
				init={{
					file_picker_types: 'image',
					height: '100%',
					image_title: false,
					image_description: false,
					image_caption: true,
					images_upload_handler: (blobInfo, success, failure) => {
						const file = new File([blobInfo.blob()], blobInfo.filename());
						const imageId = uuid();
						imageUpload(imageId, studyId, { file })
							.then(() => {
								trackCustomEvent({
									event: 'template_image_added',
									imageId,
									name: file.name,
									size: file.size,
									studyId,
								});
								success(`${BACKEND_URL}image/${imageId}`);
							})
							.catch(() => {
								failure('Failed to upload your image, please try again');
							});
					},
					width: '100%',
					resize: false,
					menubar: false,
					statusbar: false,
					plugins: ['advlist autolink lists link image', 'searchreplace table paste noneditable'],
					advlist_bullet_styles: 'default',
					advlist_number_styles: 'default,lower-alpha,lower-roman,upper-alpha,upper-roman',
					toolbar:
						'formatselect | bold italic underline | subscript superscript |' +
						'bullist numlist | image | removeformat | table | undo redo',
					content_css: [
						'/tinymce/ui-content.css',
						'/tinymce/content.css',
						'/tinymce/custom-content.css',
						'https://fonts.googleapis.com/css?family=Material+Icons',
					],
					block_formats:
						'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Heading 4=h4; Heading 5=h5',
					table_clone_elements: 'strong em b i font h1 h2 h3 h4 h5 h6 p div',
					inline_styles: false,
					extended_valid_elements: 'td[style]',
					formats: {
						underline: { inline: 'u', exact: true },
					},
					table_appearance_options: false,
					table_advtab: false,
					table_cell_advtab: false,
					table_row_advtab: false,
					table_header_type: 'sectionCells',
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablerowheader',
				}}
			/>
		</div>
	);
};
export default connect(
	(state: State) => ({
		report: state.report,
		reportValidation: state.reportValidation,
	}),
	{ reportUpdate: reportUpdateAction },
)(requires('report')(Content));
