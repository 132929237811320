import React from 'react';
import styled from 'styled-components';
import { SnackbarProvider as NSSnackbarProvider, SnackbarProviderProps } from 'notistack';

// eslint-disable-next-line import/prefer-default-export
const SnackbarProvider = styled((props: SnackbarProviderProps) => (
	<NSSnackbarProvider {...props} />
))`
	// Add future custom styling here, for example
	// &.info {
	// background-color: #ef4f4f; // According to design */
	// }
`;

export default SnackbarProvider;
