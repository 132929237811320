import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { ApiField, ApiForm, ApiStudyDto } from 'kes-common';
import { debounce } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { applicationName as applicationNameSelector } from '@/selectors';
import * as actions from '@/store/actions';
import { DragHandle } from '@mui/icons-material';
import { Draggable } from 'react-beautiful-dnd';
import FieldChoices from './FieldChoices';
import AddButton from './AddButton';

const CHOICE_TYPES = ['MULTIPLE_SELECT', 'SINGLE_SELECT'];

interface FieldProps {
	canAddField?: boolean;
	field: ApiField;
	formId: ApiForm['id'];
	studyId: ApiStudyDto['id'];
}

const Field: React.FC<FieldProps> = ({ canAddField = false, field, formId, studyId }) => {
	const dispatch = useDispatch();
	const [name, setName] = React.useState<ApiField['name']>(field.name);
	const [type, setType] = React.useState<ApiField['type']>(field.type);
	const removeField = React.useCallback(() => {
		dispatch(actions.formsFieldRemove({ fieldId: field.id, formId, studyId }));
	}, []);
	const applicationName = useSelector(applicationNameSelector);
	const updateField = React.useCallback(
		debounce((newFieldValues: Partial<ApiField> = {}) => {
			const { choices, ...fieldValues } = field;
			dispatch(actions.formsFieldUpdate({ ...fieldValues, ...newFieldValues, formId, studyId }));
		}, 500),
		[field, formId, studyId],
	);

	const addField = React.useCallback(() => {
		dispatch(
			actions.formsFieldAdd({
				formId,
				id: uuid(),
				position: field.position + 1,
				studyId: applicationName,
				type: 'STRING',
			}),
		);
	}, [applicationName, field, formId]);

	return (
		<Draggable draggableId={field.id} index={field.position}>
			{({ draggableProps, dragHandleProps, innerRef }) => (
				// @ts-ignore Typing of box have no ref; this is fixed in MUI 5 https://github.com/mui-org/material-ui/issues/17010#issuecomment-724187064
				<Box ref={innerRef} {...draggableProps}>
					<Grid container spacing={2}>
						<Grid container item justifyContent="center" alignItems="center" xs={6}>
							<Grid item xs={1}>
								<span {...dragHandleProps}>
									<DragHandle />
								</span>
							</Grid>
							<Grid item xs={11}>
								<TextField
									fullWidth
									label="Field name"
									name="name"
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										const newName = event.target.value;
										setName(newName);
										updateField({ name: newName });
									}}
									placeholder="Field name"
									value={name}
									variant="filled"
								/>
							</Grid>
						</Grid>
						<Grid container item alignItems="center" justifyContent="center" xs={6}>
							<Grid item xs={11}>
								<TextField
									fullWidth
									label="Type"
									name="type"
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										const newType = event.target.value as ApiField['type'];
										setType(newType);
										updateField({ type: newType });
									}}
									placeholder="Type"
									select
									SelectProps={{ native: true }}
									value={type}
									variant="filled"
								>
									<option value="STRING">Text</option>
									<option value="DECIMAL">Number</option>
									<option value="SINGLE_SELECT">Single select</option>
									<option value="MULTIPLE_SELECT">Multiple select</option>
									<option value="IMAGE">Image</option>
								</TextField>
							</Grid>
							<Grid item xs={1}>
								<IconButton onClick={removeField} size="large">
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
					{CHOICE_TYPES.includes(type) && (
						<FieldChoices field={field} formId={formId} studyId={studyId} />
					)}
					<AddButton alwaysHidden={!canAddField} onClick={addField} />
				</Box>
			)}
		</Draggable>
	);
};

export default Field;
