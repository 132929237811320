import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button/Button';

import { Category } from '@/store/types';

interface CategoryDialogProps {
	title: string;
	actionCaption: string;
	error: string | undefined;
	categoryInfo: Category['info'];
	categoryName: string;
	onClose: () => void;
	onCategoryAction: (newCategoryName: Category['name'], newCategoryInfo: Category['info']) => void;
}
const CategoryDialog: React.FC<CategoryDialogProps> = ({
	title,
	actionCaption,
	error,
	categoryInfo: initialCategoryInfo,
	categoryName: initialCategoryName,
	onClose,
	onCategoryAction,
}) => {
	const [categoryInfo, setCategoryInfo] = useState<string>(initialCategoryInfo || '');
	const [categoryName, setCategoryName] = useState<string>(initialCategoryName);

	return (
		<Dialog maxWidth="sm" fullWidth open onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					error={!!error}
					fullWidth
					helperText={error}
					inputProps={{
						'data-testid': 'textfield-category-name',
					}}
					label="Category name"
					margin="normal"
					onChange={(e) => setCategoryName(e.target.value)}
					value={categoryName}
				/>
				<TextField
					fullWidth
					helperText="Informatory text to display at the beginning of the category"
					inputProps={{
						'data-testid': 'textfield-category-info',
					}}
					label="Information"
					margin="normal"
					multiline
					onChange={(event) => setCategoryInfo(event.target.value)}
					value={categoryInfo}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="text" autoFocus>
					Cancel
				</Button>
				<Button
					data-testid="actions-save-category"
					color="primary"
					onClick={(): void => onCategoryAction(categoryName, categoryInfo)}
					variant="contained"
					autoFocus
				>
					{actionCaption}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CategoryDialog;
