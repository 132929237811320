import FlagIcon from '@mui/icons-material/Flag';
import { ApiActionDto } from 'kes-common';
import {
	actionDependentPropertyAdd,
	actionDependentPropertyRemove,
	assetPersist,
	propertyAddDependent,
	propertyConnectAsInput,
	propertyDisconnectFromInput,
	propertyRemoveDependent,
} from '@/store/actions';
import { Asset, Category, Choice, Property } from '@/store/types';
import State from '@/store/state';
import React, { FC, useState } from 'react';
import { SIDEBAR_ASSET_EXPANDED_BY_DEFAULT } from '@/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetWithPropertyId } from '@/selectors/assets';
import { assetsGet, choiceGet, propertiesGet, propertiesGetOrNull } from '@/selectors';
import classes from '@/components/SideBar.module.css';
import Header from '@/components/SideBar/Header';
import * as routes from '@/routes';
import MenuCheckbox from '@/components/SideBar/MenuCheckbox';
import CollapseIcon from '@/components/SideBar/CollapseIcon';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import classNames from 'classnames';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InsertIcon from '@/components/SideBar/InsertIcon';
import { getCategoryWithAssetTypeId } from '@/selectors/category';
import { getPropertyByChoiceId } from '@/selectors/property';
import LockIndicator from '@/components/SideBar/LockIndicator';
import PropertyItem from '@/components/SideBar/PropertyItem';
import { PropertySelectionMode } from '@/store/reducers/properties';
import FilterItem from '@/components/SideBar/AssetItem/FilterItem';
import List, { ItemProps } from '../List';

interface AssetItemProps extends ItemProps {
	orderMode: boolean;
}

const AssetItem: FC<AssetItemProps> = ({ id, orderMode, index, listLength }): JSX.Element => {
	const [open, setOpen] = useState(SIDEBAR_ASSET_EXPANDED_BY_DEFAULT);
	const dispatch = useDispatch();
	const state = useSelector<State, State>((globalState) => globalState);
	const item = useSelector<State, Asset>((globalState) => assetsGet(globalState, id));
	const selectionMode = useSelector<State, PropertySelectionMode>(
		(globalState) => globalState.properties.selectionMode,
	);

	const selected = useSelector<State, Property | null>((globalState) => {
		if (globalState.properties.selectionMode === PropertySelectionMode.Dependent) {
			return getPropertyByChoiceId(globalState, globalState.properties.selected);
		}

		return propertiesGetOrNull(globalState, globalState.properties.selected);
	});

	const choice = useSelector<State, Choice | undefined>((globalState) => {
		if (globalState.properties.selectionMode === PropertySelectionMode.Dependent) {
			return choiceGet(globalState, globalState.properties.selected);
		}
		return undefined;
	});

	const category = useSelector<State, Category>((globalState) =>
		getCategoryWithAssetTypeId(globalState, item.id),
	);

	const selectedAction = useSelector<State, ApiActionDto | null>((globalState) => {
		if (globalState.properties.selectedAction) {
			return globalState.actions.byId[globalState.properties.selectedAction] || null;
		}
		return null;
	});

	const checkDisabled = (property: Property) => {
		let disabled = true;
		if (selected !== null) {
			const parentAsset = getAssetWithPropertyId(state, selected.id);
			const childAsset = getAssetWithPropertyId(state, property.id);
			if (selectionMode === PropertySelectionMode.Computed) {
				disabled =
					selected.id === property.id ||
					property.fixed ||
					property.type === 'SINGLE_SUBSTANCE' ||
					property.type === 'MULTI_SUBSTANCE';
			} else if (
				[PropertySelectionMode.Dependent, PropertySelectionMode.DependentRule].includes(
					selectionMode,
				)
			) {
				disabled =
					selected.id === property.id ||
					(parentAsset.repeating && parentAsset.id !== childAsset.id);
			}
		}
		return disabled;
	};

	const checked = () => {
		let count = 0;
		let disabled = 0;
		let isDisabled = false;
		let isChecked: boolean | null;
		item.propertyIds.forEach((propId) => {
			const property = propertiesGet(state, propId);
			const isPropertyDisabled = checkDisabled(property);

			if (selectionMode === PropertySelectionMode.Dependent && choice) {
				const isChildSelected = property.parentChoiceIds.includes(choice.id);
				if (selected !== null && (isChildSelected || isPropertyDisabled)) {
					count += 1;
				}
			}

			if (selectionMode === PropertySelectionMode.DependentRule && selectedAction) {
				const isChildSelected = property.parentActionIds.includes(selectedAction.id);
				if (isChildSelected || isPropertyDisabled) {
					count += 1;
				}
			}

			if (isPropertyDisabled) {
				disabled += 1;
			}
		});
		isChecked = Boolean(selected !== null && item.propertyIds.length === count);
		if (disabled === item.propertyIds.length) {
			isChecked = false;
			isDisabled = true;
		}

		return [isChecked, isDisabled];
	};

	const change = () => {
		if (selected !== null) {
			if (selectionMode === PropertySelectionMode.Computed) {
				if (checked()[0]) {
					item.propertyIds.forEach((propId) =>
						dispatch(
							propertyDisconnectFromInput({
								computedPropertyId: selected.id,
								id: propId,
							}),
						),
					);
				} else {
					item.propertyIds.forEach((propId) => {
						const property: Property = propertiesGet(state, propId);
						if (!checkDisabled(property)) {
							dispatch(
								propertyConnectAsInput({
									computedPropertyId: selected.id,
									id: propId,
								}),
							);
						}
					});
				}
			} else if (selectionMode === PropertySelectionMode.Dependent && choice) {
				if (checked()[0]) {
					item.propertyIds.forEach((propId) =>
						dispatch(
							propertyRemoveDependent({
								choiceId: choice.id,
								id: propId,
							}),
						),
					);
				} else {
					item.propertyIds.forEach((propId) => {
						const property: Property = propertiesGet(state, propId);
						if (!checkDisabled(property)) {
							dispatch(
								propertyAddDependent({
									choiceId: choice.id,
									id: propId,
								}),
							);
						}
					});
				}
			} else if (selectionMode === PropertySelectionMode.DependentRule) {
				if (selectedAction) {
					if (checked()[0]) {
						item.propertyIds.forEach((propertyId) => {
							dispatch(actionDependentPropertyRemove({ actionId: selectedAction.id, propertyId }));
						});
					} else {
						item.propertyIds.forEach((propertyId) => {
							const property = propertiesGet(state, propertyId);
							if (!checkDisabled(property)) {
								dispatch(actionDependentPropertyAdd({ actionId: selectedAction.id, propertyId }));
							}
						});
					}
				}
			}
		}
	};

	return (
		<li className={classes.item}>
			<Header
				to={item.fixed || item.isDefault || selected ? undefined : routes.applicationAsset}
				params={{ assetId: item.id, applicationName: null }}
				className={`${
					item.fixed || item.isDefault ? classes.assetHeaderReadonly : classes.assetHeaderEditable
				} ${selected && classes.headerSelecting}`}
				titleClassName={
					item.fixed || item.isDefault ? classes.assetLinkReadonly : classes.assetLink
				}
				title={item.name}
				data-testid="asset-name"
			>
				{selected && selectionMode !== PropertySelectionMode.Relationship ? (
					<div className={classes.checkBox}>
						<MenuCheckbox
							disabled={checked()[1]}
							checked={checked()[0]}
							onClick={() => {
								change();
							}}
							data-testid={`select-assetitem-${item.name}`}
						/>
					</div>
				) : null}
				{(item.fixed || item.isDefault) && !selected && <LockIndicator />}
				{item.type === 'FLAG' && <FlagIcon sx={{ marginRight: 2 }} />}
				<CollapseIcon
					state={open}
					setState={setOpen}
					shown={item.propertyIds.length > 0}
					data-testid={`expand-assetitem-${item.name}`}
				/>
				<div className={classes.orderIcons}>
					{orderMode && (
						<ArrowUpwardIcon
							className={classNames(classes.orderIconUp, {
								[classes.orderIconDisabled]: index === 0,
							})}
							onClick={(): void => {
								dispatch(
									assetPersist({
										asset: item,
										categoryId: category.id,
										position: index - 1,
									}),
								);
							}}
						/>
					)}
					{orderMode && (
						<ArrowDownwardIcon
							className={classNames(classes.orderIconDown, {
								[classes.orderIconDisabled]: index === listLength - 1,
							})}
							onClick={(): void => {
								dispatch(
									assetPersist({
										asset: item,
										categoryId: category.id,
										position: index + 1,
									}),
								);
							}}
						/>
					)}
				</div>
				{item.repeating && !orderMode && <InsertIcon assetId={item.id} />}
			</Header>
			<List
				hidden={!open}
				list={item.propertyIds}
				component={PropertyItem}
				assetId={item.id}
				assetRepeating={item.repeating}
				orderMode={orderMode}
			/>
			<List hidden={!open} list={item.assetFilterIds} component={FilterItem} assetId={item.id} />
		</li>
	);
};

export default AssetItem;
