import {
	ApiActionDto,
	ApiBuilderProject,
	ApiCreateForm,
	ApiDuplicatePropertyDto,
	ApiField,
	ApiFieldChoice,
	ApiForm,
	ApiProperty,
	ApiReportError,
	ApiStudyDto,
	ApiCreateOrUpdateField,
	ApiUpsertActionMutation,
	ApiReOrderDto,
	ApiAssetFilter,
	QuestionFormValues,
} from 'kes-common';

import { Route } from '@/routes';
import PartialExpect from '@/utils/PartialExpect';
import { PropertySelectionMode } from '@/store/reducers/properties';
import { SidebarMode } from '@/store/state';

import { Asset, Category, Property, Report, Choice, UUID } from './types';
import { makeAction } from './utils';

/* Actions */
enum ActionTypes {
	ACTION_DELETE = '@@kesBuilder/ACTION/DELETE',
	ACTION_DEPENDENT_PROPERTY_ADD = '@@kesBuilder/ACTION/DEPENDENT_PROPERTY_ADD',
	ACTION_DEPENDENT_PROPERTY_REMOVE = '@@kesBuilder/ACTION/DEPENDENT_PROPERTY_REMOVE',
	ACTION_SELECT = '@@kesBuilder/ACTION/SELECT',
	ROUTER_UPDATE_PATH = '@@kesBuilder/ROUTER/UPDATE_PATH',
	ROUTER_UPDATE_TITLE = '@@kesBuilder/ROUTER/UPDATE_TITLE',
	ROUTER_REDIRECT = '@@kesBuilder/ROUTER/REDIRECT',
	APPLICATION_LOAD = '@@kesBuilder/APPLICATION/LOAD',
	APPLICATION_LOAD_SUCCESS = '@@kesBuilder/APPLICATION/LOAD_SUCCESS',
	APPLICATION_LOAD_ERROR = '@@kesBuilder/APPLICATION/LOAD_ERROR',
	APPLICATION_RELOAD = '@@kesBuilder/APPLICATION/RELOAD',
	APPLICATION_SAVE = '@@kesBuilder/APPLICATION/SAVE',
	APPLICATION_SAVE_COMPLETE = '@@kesBuilder/APPLICATION/SAVE_COMPLETE',
	APPLICATION_SAVE_ERROR = '@@kesBuilder/APPLICATION/SAVE_ERROR',
	ASSET_GENERATE = '@@kesBuilder/ASSET/GENERATE',
	ASSET_PERSIST = '@@kesBuilder/ASSET/PERSIST',
	ASSET_UPDATE = '@@kesBuilder/ASSET/UPDATE',
	ASSET_DELETE = '@@kesBuilder/ASSET/DELETE',
	ASSET_CLEAR_ERROR = '@@kesBuilder/ASSET/CLEAR_ERROR',
	CATEGORY_PERSIST = '@@kesBuilder/CATEGORY/PERSIST',
	CATEGORY_UPDATE = '@@kesBuilder/CATEGORY/UPDATE',
	CATEGORY_DELETE = '@@kesBuilder/CATEGORY/DELETE',
	FORMS_ADD = '@@kesBuilder/FORMS/ADD',
	FORMS_DELETE = '@@kesBuilder/FORMS/DELETE',
	FORMS_FIELD_ADD = '@@kesBuilder/FORMS/FIELD_ADD',
	FORMS_FIELD_CHOICE_ADD = '@@kesBuilder/FORMS/FIELD/CHOICE_ADD',
	FORMS_FIELD_CHOICE_REMOVE = '@@kesBuilder/FORMS/FIELD/CHOICE_REMOVE',
	FORMS_FIELD_CHOICE_UPDATE = '@@kesBuilder/FORMS/FIELD/CHOICE_UPDATE',
	FORMS_FIELD_REMOVE = '@@kesBuilder/FORMS/FIELD_REMOVE',
	FORMS_FIELD_UPDATE = '@@kesBuilder/FORMS/FIELD_UPDATE',
	FORMS_FIELD_REORDER = '@@kesBuilder/FORMS/FIELD_REORDER',
	FORMS_LOAD = '@@kesBuilder/FORMS/LOAD',
	FORMS_LOAD_ERROR = '@@kesBuilder/FORMS/LOAD_ERROR',
	FORMS_LOAD_SUCCESS = '@@kesBuilder/FORMS/LOAD_SUCCESS',
	FORMS_UPDATE = '@@kesBuilder/FORMS/UPDATE',
	PROPERTY_NEW_QUESTION = '@@kesBuilder/PROPERTY/NEW_QUESTION',
	PROPERTY_GENERATE = '@@kesBuilder/PROPERTY/GENERATE',
	PROPERTY_GENERATE_SILENT = '@@kesBuilder/PROPERTY/GENERATE_SILENT',
	PROPERTY_PERSIST = '@@kesBuilder/PROPERTY/PERSIST',
	PROPERTY_UPDATE = '@@kesBuilder/PROPERTY/UPDATE',
	PROPERTY_UPDATE_REQUEST = '@@kesBuilder/PROPERTY/UPDATE_REQUEST',
	PROPERTY_UPDATE_SUCCESS = '@@kesBuilder/PROPERTY/UPDATE_SUCCESS',
	PROPERTY_DELETE = '@@kesBuilder/PROPERTY/DELETE',
	PROPERTY_DELETE_REQUEST = '@@kesBuilder/PROPERTY/DELETE_REQUEST',
	PROPERTY_SELECT = '@@kesBuilder/PROPERTY/SELECT',
	PROPERTY_CONNECT_AS_INPUT = '@@kesBuilder/PROPERTY/CONNECT_AS_INPUT',
	PROPERTY_DISCONNECT_FROM_INPUT = '@@kesBuilder/PROPERTY/DISCONNECT_FROM_INPUT',
	PROPERTY_ADD_DEPENDENT = '@@kesBuilder/PROPERTY/ADD_DEPENDENT',
	PROPERTY_REMOVE_DEPENDENT = '@@kesBuilder/PROPERTY/REMOVE_DEPENDENT',
	PROPERTY_ADD_REFERENCE = '@@kesBuilder/PROPERTY/PROPERTY_ADD_REFERENCE',
	PROPERTY_REMOVE_REFERENCE = '@@kesBuilder/PROPERTY/PROPERTY_REMOVE_REFERENCE',
	PROPERTY_REMOVE_RULES = '@@kesBuilder/PROPERTY/PROPERTY_REMOVE_RULES',
	PROPERTY_UPDATE_RULES = '@@kesBuilder/PROPERTY/PROPERTY_UPDATE_RULES',
	PROPERTY_MOVE_REQUEST = '@@kesBuilder/PROPERTY/PROPERTY_MOVE_REQUEST',
	PROPERTY_MOVE = '@@kesBuilder/PROPERTY/PROPERTY_MOVE',
	PROPERTY_MOVE_ERROR = '@@kesBuilder/PROPERTY/PROPERTY_MOVE_ERROR',
	PROPERTY_DUPLICATE_REQUEST = '@@kesBuilder/PROPERTY/PROPERTY_DUPLICATE_REQUEST',
	PROPERTY_DUPLICATE = '@@kesBuilder/PROPERTY/PROPERTY_DUPLICATE',
	PROPERTY_DUPLICATE_ERROR = '@@kesBuilder/PROPERTY/PROPERTY_DUPLICATE_ERROR',
	SIDEBAR_ORDER_MODE = '@@kesBuilder/SIDEBAR/ORDER_MODE',
	REPORT_PERSIST = '@@kesBuilder/REPORT/PERSIST',
	REPORT_UPDATE = '@@kesBuilder/REPORT/UPDATE',
	REPORT_UPDATE_ASSET_NAME = '@@kesBuilder/REPORT/UPDATE_ASSET_NAME',
	REPORT_UPDATE_PROPERTY_NAME = '@@kesBuilder/REPORT/UPDATE_PROPERTY_NAME',
	REPORT_UPDATE_SUCCESS = '@@kesBuilder/REPORT/UPDATE_SUCCESS',
	REPORT_VALIDATE = '@@kesBuilder/REPORT/VALIDATE',
	REPORT_DELETE = '@@kesBuilder/REPORT/DELETE',
	REPORT_DELETE_REQUEST = '@@kesBuilder/REPORT/DELETE_REQUEST',
	CHOICE_GENERATE = '@@kesBuilder/CHOICE/GENRATE',
	CHOICE_PERSIST = '@@kesBuilder/CHOICE/PERSIST',
	CHOICE_UPDATE = '@@kesBuilder/CHOICE/UPDATE',
	CHOICE_UPDATE_SUCCESS = '@@kesBuilder/CHOICE/UPDATE_SUCCESS',
	CHOICE_DELETE = '@@kesBuilder/CHOICE/DELETE',
	CHOICE_DELETE_REQUEST = '@@kesBuilder/CHOICE/DELETE_REQUEST',
	SAVE_STARTING = '@@kesBuilder/SAVE/STARTING',
	SAVE_UPDATE = '@@kesBuilder/SAVE/UPDATE',
	SAVE_DONE = '@@kesBuilder/SAVE/DONE',
	SAVE_SAVED = '@@kesBuilder/SAVE/SAVED',
	SAVE_OFFLINE = '@@kesBuilder/SAVE/OFFLINE',
	SAVE_ERROR = '@@kesBuilder/SAVE/ERROR',
	SAVE_FAILED = '@@kesBuilder/SAVE/FAILED',
	SAVE_VALIDATION_ERROR = '@@kesBuilder/SAVE/VALIDATION_ERROR',
	REPORT_VALIDATION_RESULT = '@@kesBuilder/REPORT_VALIDATION/RESULT',
	REPORT_VALIDATION_ERROR = '@@kesBuilder/REPORT_VALIDATION/ERROR',
	MENDIX_SAVE = '@@kesBuilder/MENDIX/SAVE',
	MENDIX_SAVE_SUCCESS = '@@kesBuilder/MENDIX/SAVE_SUCCESS',
	MENDIX_SAVE_FAIL = '@@kesBuilder/MENDIX/SAVE_FAIL',
	MENDIX_EXPORT_SUCCESS = '@@kesBuilder/MENDIX/EXPORT_SUCCESS',
	MENDIX_EXPORT_FAIL = '@@kesBuilder/MENDIX/EXPORT_FAIL',
	MENDIX_STATUS = '@@kesBuilder/MENDIX/STATUS',
	MENDIX_STATUS_RESET = '@@kesBuilder/MENDIX/STATUS/RESET',
	FILTER_CREATE = '@@kesBuilder/FILTER/FILTER_CREATE',
	FILTER_UDATE = '@@kesBuilder/FILTER/FILTER_UPDATE',
	FILTER_DELETE = '@@kesBuilder/FILTER/FILTER_DELETE',
	UI_DEPENDENCY_GRAPH_TOGGLE = '@@kes/UI/DEPENDENCY_GRAPH_TOGGLE',
	UI_SIDEBAR_MODE_TOGGLE = '@@kes/UI/SIDEBAR_MODE_TOGGLE',
}

// The actions use "double function calls", because this helps typescript with the type inferince

/* router to store */
export const routerUpdatePath = makeAction<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	route: Route<any>;
	path: string;
}>()(ActionTypes.ROUTER_UPDATE_PATH);
export const routerUpdateTitle = makeAction<string>()(ActionTypes.ROUTER_UPDATE_TITLE);
export const routerRedirect = makeAction<string>()(ActionTypes.ROUTER_REDIRECT);

export const actionDelete = makeAction<{ actionId: ApiActionDto['id'] }>()(
	ActionTypes.ACTION_DELETE,
);
export const actionDependentPropertyAdd = makeAction<{
	actionId: ApiActionDto['id'];
	propertyId: ApiProperty['id'];
}>()(ActionTypes.ACTION_DEPENDENT_PROPERTY_ADD);
export const actionDependentPropertyRemove = makeAction<{
	actionId: ApiActionDto['id'];
	propertyId: ApiProperty['id'];
}>()(ActionTypes.ACTION_DEPENDENT_PROPERTY_REMOVE);
export const actionSelect = makeAction<{
	id: string;
	propertyId: Property['id'];
	selectionMode: PropertySelectionMode;
}>()(ActionTypes.ACTION_SELECT);

export const applicationLoad = makeAction<string>()(ActionTypes.APPLICATION_LOAD);
export const applicationLoadSuccess = makeAction<ApiBuilderProject>()(
	ActionTypes.APPLICATION_LOAD_SUCCESS,
);
export const applicationLoadError = makeAction<Error>()(ActionTypes.APPLICATION_LOAD_ERROR);
export const applicationReload = makeAction<string>()(ActionTypes.APPLICATION_RELOAD);

export const assetGenerate = makeAction<{
	id: Asset['id'];
	categoryId: Category['id'];
	repeating?: boolean;
	type: 'FLAG' | 'FORM' | 'SURVEY';
}>()(ActionTypes.ASSET_GENERATE);
export const assetPersist = makeAction<{
	asset: Asset;
	categoryId: Category['id'];
	position: number;
}>()(ActionTypes.ASSET_PERSIST);
export const assetUpdate = makeAction<PartialExpect<Asset, 'id'>>()(ActionTypes.ASSET_UPDATE);
export const assetDelete = makeAction<Asset>()(ActionTypes.ASSET_DELETE);
export const assetClearError = makeAction()(ActionTypes.ASSET_CLEAR_ERROR);

export const categoryPersist = makeAction<{ category: Category; position?: number }>()(
	ActionTypes.CATEGORY_PERSIST,
);
export const categoryUpdate = makeAction<PartialExpect<Category, 'id'>>()(
	ActionTypes.CATEGORY_UPDATE,
);
export const categoryDelete = makeAction<Category>()(ActionTypes.CATEGORY_DELETE);

export const formsAdd = makeAction<Omit<ApiCreateForm, 'name'> & { studyId: ApiStudyDto['id'] }>()(
	ActionTypes.FORMS_ADD,
);
export const formsDelete = makeAction<{ formId: ApiForm['id']; studyId: ApiStudyDto['id'] }>()(
	ActionTypes.FORMS_DELETE,
);
export const formsFieldAdd = makeAction<
	Omit<ApiCreateOrUpdateField, 'name'> & { formId: ApiForm['id']; studyId: ApiStudyDto['id'] }
>()(ActionTypes.FORMS_FIELD_ADD);
export const formsFieldChoiceAdd = makeAction<
	Omit<ApiFieldChoice, 'name'> & {
		fieldId: ApiField['id'];
		formId: ApiForm['id'];
		studyId: ApiStudyDto['id'];
	}
>()(ActionTypes.FORMS_FIELD_CHOICE_ADD);

export const formsFieldReorder = makeAction<{
	formId: ApiForm['id'];
	fieldId: ApiField['id'];
	position: ApiReOrderDto['position'];
	studyId: ApiStudyDto['id'];
}>()(ActionTypes.FORMS_FIELD_REORDER);

export const formsFieldChoiceRemove = makeAction<{
	choiceId: ApiFieldChoice['id'];
	fieldId: ApiField['id'];
	formId: ApiForm['id'];
	studyId: ApiStudyDto['id'];
}>()(ActionTypes.FORMS_FIELD_CHOICE_REMOVE);
export const formsFieldChoiceUpdate = makeAction<
	ApiFieldChoice & { fieldId: ApiField['id']; formId: ApiForm['id']; studyId: ApiStudyDto['id'] }
>()(ActionTypes.FORMS_FIELD_CHOICE_UPDATE);
export const formsFieldRemove = makeAction<{
	fieldId: ApiField['id'];
	formId: ApiForm['id'];
	studyId: ApiStudyDto['id'];
}>()(ActionTypes.FORMS_FIELD_REMOVE);
export const formsFieldUpdate = makeAction<
	ApiCreateOrUpdateField & { formId: ApiForm['id']; studyId: ApiStudyDto['id'] }
>()(ActionTypes.FORMS_FIELD_UPDATE);
export const formsLoad = makeAction<{ studyId: ApiStudyDto['id'] }>()(ActionTypes.FORMS_LOAD);
export const formsLoadError = makeAction<Error>()(ActionTypes.FORMS_LOAD_ERROR);
export const formsLoadSuccess = makeAction<ApiForm[]>()(ActionTypes.FORMS_LOAD_SUCCESS);
export const formsUpdate = makeAction<ApiCreateForm & { studyId: ApiStudyDto['id'] }>()(
	ActionTypes.FORMS_UPDATE,
);

export interface PropertyNewQuestionPayload {
	applicationName: string;
	assetId: Asset['id'];
	formValues: QuestionFormValues;
}
export const propertyNewQuestion = makeAction<PropertyNewQuestionPayload>()(
	ActionTypes.PROPERTY_NEW_QUESTION,
);

export const propertyGenerate = makeAction<{ id: Property['id']; assetId: Asset['id'] }>()(
	ActionTypes.PROPERTY_GENERATE,
);

export const propertyGenerateSilent = makeAction<{ id: Property['id']; assetId: Asset['id'] }>()(
	ActionTypes.PROPERTY_GENERATE_SILENT,
);

export interface PropertyPersistPayload {
	property: Property;
	assetId: Asset['id'];
	position: number;
}
export const propertyPersist = makeAction<PropertyPersistPayload>()(ActionTypes.PROPERTY_PERSIST);
export const propertyUpdate = makeAction<PartialExpect<Property, 'id'>>()(
	ActionTypes.PROPERTY_UPDATE,
);
export const propertyUpdateRequest = makeAction<
	PartialExpect<Property, 'allowMultipleAnswers' | 'id' | 'type'>
>()(ActionTypes.PROPERTY_UPDATE_REQUEST);
export const propertyDelete = makeAction<Property>()(ActionTypes.PROPERTY_DELETE);
export const propertyDeleteRequest = makeAction<Pick<Property, 'id'>>()(
	ActionTypes.PROPERTY_DELETE_REQUEST,
);
export const propertySelect = makeAction<{ id: string; selectionMode: PropertySelectionMode }>()(
	ActionTypes.PROPERTY_SELECT,
);
export const propertyConnectAsInput = makeAction<{
	id: Property['id'];
	computedPropertyId: Property['id'];
}>()(ActionTypes.PROPERTY_CONNECT_AS_INPUT);
export const propertyDisconnectFromInput = makeAction<{
	id: Property['id'];
	computedPropertyId: Property['id'];
}>()(ActionTypes.PROPERTY_DISCONNECT_FROM_INPUT);
export const propertyAddDependent = makeAction<{ id: Property['id']; choiceId: Choice['id'] }>()(
	ActionTypes.PROPERTY_ADD_DEPENDENT,
);
export const propertyRemoveDependent = makeAction<{ id: Property['id']; choiceId: Choice['id'] }>()(
	ActionTypes.PROPERTY_REMOVE_DEPENDENT,
);

export const propertyAddReference = makeAction<{
	propertyId: UUID;
	parentAssetTypeId: UUID;
	targetPropertyId: UUID;
}>()(ActionTypes.PROPERTY_ADD_REFERENCE);

export const propertyRemoveReference = makeAction<{
	propertyId: UUID;
}>()(ActionTypes.PROPERTY_REMOVE_REFERENCE);
export const propertyRemoveRules = makeAction<{
	propertyId: UUID;
}>()(ActionTypes.PROPERTY_REMOVE_RULES);

export const propertyUpdateRules = makeAction<ApiUpsertActionMutation>()(
	ActionTypes.PROPERTY_UPDATE_RULES,
);

export const sidebarOrderMode = makeAction<boolean>()(ActionTypes.SIDEBAR_ORDER_MODE);

export const reportPersist = makeAction<{ report: Report }>()(ActionTypes.REPORT_PERSIST);
export const reportUpdate = makeAction<Partial<Report>>()(ActionTypes.REPORT_UPDATE);
export const reportUpdateAssetName = makeAction<{ assetNameNew: string; assetNameOld: string }>()(
	ActionTypes.REPORT_UPDATE_ASSET_NAME,
);
export const reportUpdatePropertyName = makeAction<{
	propertyPlaceholderNew: string;
	propertyPlaceholderOld: string;
}>()(ActionTypes.REPORT_UPDATE_PROPERTY_NAME);
export const reportUpdateSuccess = makeAction<Pick<Report, 'updatedAt'>>()(
	ActionTypes.REPORT_UPDATE_SUCCESS,
);
export const reportValidate = makeAction<{ studyId: ApiStudyDto['id'] }>()(
	ActionTypes.REPORT_VALIDATE,
);

export const choiceGenerate = makeAction<{
	id: Choice['id'];
	propertyId: Property['id'];
	position: number;
}>()(ActionTypes.CHOICE_GENERATE);
export const choicePersist = makeAction<{ choice: Choice; propertyId: Property['id'] }>()(
	ActionTypes.CHOICE_PERSIST,
);
export const choiceUpdate = makeAction<PartialExpect<Choice, 'id'>>()(ActionTypes.CHOICE_UPDATE);
export const choiceUpdateSuccess = makeAction<PartialExpect<Choice, 'id'>>()(
	ActionTypes.CHOICE_UPDATE_SUCCESS,
);
export const choiceDelete = makeAction<Pick<Choice, 'id'>>()(ActionTypes.CHOICE_DELETE);
export const choiceDeleteRequest = makeAction<Pick<Choice, 'id'>>()(
	ActionTypes.CHOICE_DELETE_REQUEST,
);

export const saveStarting = makeAction()(ActionTypes.SAVE_STARTING);
export const saveUpdate = makeAction()(ActionTypes.SAVE_UPDATE);
export const saveDone = makeAction()(ActionTypes.SAVE_DONE);
export const saveSaved = makeAction()(ActionTypes.SAVE_SAVED);
export const saveError = makeAction<Error>()(ActionTypes.SAVE_ERROR);
export const saveFailed = makeAction()(ActionTypes.SAVE_FAILED);
export const saveValidationError = makeAction<Error>()(ActionTypes.SAVE_VALIDATION_ERROR);
export const saveOffline = makeAction()(ActionTypes.SAVE_OFFLINE);

export const reportValidationError = makeAction<Error>()(ActionTypes.REPORT_VALIDATION_ERROR);
export const reportValidationResult = makeAction<{
	result: ApiReportError[];
}>()(ActionTypes.REPORT_VALIDATION_RESULT);

export const propertyMoveRequest = makeAction<{
	currentAssetId: string;
	destinationAssetId: string;
	propertyId: string;
	studyId: string;
	position: number | null;
}>()(ActionTypes.PROPERTY_MOVE_REQUEST);

export const propertyMove = makeAction<{
	currentAssetId: string;
	destinationAssetId: string;
	propertyId: string;
	studyId: string;
}>()(ActionTypes.PROPERTY_MOVE);

export const propertyMoveError = makeAction<string>()(ActionTypes.PROPERTY_MOVE_ERROR);

export const propertyDuplicateRequest = makeAction<{
	property: Property;
	studyId: string;
}>()(ActionTypes.PROPERTY_DUPLICATE_REQUEST);

export const propertyDuplicate = makeAction<ApiDuplicatePropertyDto>()(
	ActionTypes.PROPERTY_DUPLICATE,
);

export const propertyDuplicateError = makeAction<string>()(ActionTypes.PROPERTY_DUPLICATE_ERROR);

export const filterCreate = makeAction<ApiAssetFilter & { assetId: string }>()(
	ActionTypes.FILTER_CREATE,
);
export const filterUpdate = makeAction<ApiAssetFilter>()(ActionTypes.FILTER_UDATE);
export const filterDelete = makeAction<{ filterId: string; assetId: string }>()(
	ActionTypes.FILTER_DELETE,
);

export const uiDependencyGraphToggle = makeAction()(ActionTypes.UI_DEPENDENCY_GRAPH_TOGGLE);
export const uiSidebarModeToggle = makeAction<SidebarMode>()(ActionTypes.UI_SIDEBAR_MODE_TOGGLE);
