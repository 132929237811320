import { SplitContext } from '@splitsoftware/splitio-react';
import React from 'react';

interface UseFeatureFlagOptions {
	name: string;
}

const useFeatureFlag = (options: UseFeatureFlagOptions): boolean => {
	const splitIO = React.useContext(SplitContext);
	const client = splitIO.client as SplitIO.IClient;
	if (client) {
		return client.getTreatment(options.name) === 'on';
	}
	return false;
};

export default useFeatureFlag;
