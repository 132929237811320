import { ApiField, ApiFieldChoice, ApiForm } from 'kes-common';

import { StateForms } from '@/store/state';
import { makeSelectors } from '@/store/repository';

const selector = makeSelectors<ApiForm>();
export const { allIds, get, getAll, getOrNull, size } = selector;

export const getField = (state: StateForms, fieldId: ApiField['id'], formId: ApiForm['id']) =>
	get(state, formId).fields.find((field) => field.id === fieldId);

export const getFieldChoice = (
	state: StateForms,
	choiceId: ApiFieldChoice['id'],
	fieldId: ApiField['id'],
	formId: ApiForm['id'],
) => {
	const field = getField(state, fieldId, formId);
	if (field) {
		return field.choices.find((choice) => choice.id === choiceId);
	}
	return undefined;
};

export const status = (state: StateForms) => state.status;
