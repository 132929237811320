import { MSAL_CLIENT_ID, TEST_USERS_ENABLED } from '@/constants';
import msalInstance from '@/authentication/msal';
import { acquireToken } from 'kes-common';
import auth0Client from './auth0';

const acquireAccessToken = (): Promise<string> =>
	acquireToken({
		auth0Client,
		hasTestUsersEnabled: TEST_USERS_ENABLED,
		msalClientId: MSAL_CLIENT_ID,
		msalInstance,
	});

export default acquireAccessToken;
