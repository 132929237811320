import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';
import React from 'react';
import { PropertyPanel } from './QuestionSetup/PropertyPanel';

interface PropertyUpsertProps {
	propertyId: string;
	assetId: string;
}

const PropertyUpsert: React.FC<PropertyUpsertProps> = ({ propertyId, assetId }) => (
	<div className={classes.base}>
		<PropertyPanel assetId={assetId} propertyId={propertyId} />
	</div>
);

export default PropertyUpsert;
