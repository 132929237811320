import { useFeatureFlag } from 'kes-common';
import React from 'react';
import { useSelector } from 'react-redux';

import { applicationProperty, PropsOfRoute } from '@/routes';
import { uiGetSidebarMode } from '@/selectors';
import { SidebarMode } from '@/store/state';

import QuestionPane from '../QuestionPane';
import { PropertyEdit } from '../ReportPropertyUpdate/ReportPropertyUpdate';

const PropertyContainer: React.FC<PropsOfRoute<typeof applicationProperty>> = (props) => {
	const QUESTIONS_FIRST = useFeatureFlag({ name: 'QUESTIONS_FIRST' });
	const uiSidebarMode = useSelector(uiGetSidebarMode);

	if (QUESTIONS_FIRST && uiSidebarMode === SidebarMode.QUESTION) {
		return <QuestionPane {...props} />;
	}

	return <PropertyEdit {...props} />;
};

export default PropertyContainer;
