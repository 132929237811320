import styled from 'styled-components';

export const ChoiceReport = styled.div`
	grid-area: report-text;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
`;

export const ReportSelect = styled.div`
	display: flex;
	align-items: center;
`;

export const ReportSelectText = styled.span`
	font-size: 18px;
`;
