import Button from '@mui/material/Button';
import React from 'react';
import { ReactReduxContext } from 'react-redux';
import tinymce from 'tinymce';

import { getReferenceText } from '@/components/SideBar/InsertIcon/InsertIcon.utils';
import { assetsGet } from '@/selectors';
import { Asset, Property } from '@/store/types';
import State from '@/store/state';

interface InsertIntoTemplateProps {
	assetId: Asset['id'];
	propertyId: Property['id'];
}

const InsertIntoTemplate: React.FC<InsertIntoTemplateProps> = ({ assetId, propertyId }) => {
	const { store } = React.useContext(ReactReduxContext);

	const onClick = React.useCallback(() => {
		const state: State = store.getState();
		const asset = assetsGet(state, assetId);
		const text = getReferenceText({ state, asset, propertyId });
		tinymce.activeEditor.insertContent(text);
	}, [assetId, propertyId, store]);

	return (
		<Button color="primary" onClick={onClick} fullWidth variant="outlined">
			Insert into template
		</Button>
	);
};

export default InsertIntoTemplate;
