import React, { ComponentType, ReactNode } from 'react';
import classes from '@/components/SideBar.module.css';

export interface ItemProps {
	id: string;
	index: number;
	listLength: number;
}

const List = <
	P extends {
		hidden?: boolean;
		list: I[];
		component: ComponentType<
			{
				id: P['list'][number];
				index: number;
				listLength: number;
			} & Pick<P, Exclude<keyof P, 'hidden' | 'list' | 'component'>>
		>;
	} & { assetList?: boolean; categoryId?: string },
	I extends string | number,
>({
	hidden = false,
	list,
	component: Component,
	...props
}: P): JSX.Element => (
	<ul className={classes.list} hidden={hidden}>
		{list.map(
			(id, index): ReactNode => (
				<Component id={id} key={id} index={index} listLength={list.length} {...props} />
			),
		)}
	</ul>
);

export default List;
