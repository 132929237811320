import React from 'react';

import { ApiUserProfile } from '../../net/swagger';
import UserContext from './context';

interface UserContextProps {
	children: React.ReactNode;
	signout(): void;
	value: ApiUserProfile;
}

const UserProvider: React.FC<UserContextProps> = ({ children, signout, value }) => {
	const providerValue = React.useMemo(() => ({ signout, user: value }), [signout, value]);
	return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};

export default UserProvider;
