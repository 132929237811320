import Alert from '@mui/material/Alert';
import { QuestionFooterProps } from 'kes-common';
import React from 'react';
import { Prompt } from 'react-router-dom';

const QuestionFooter: React.FC<QuestionFooterProps> = ({ formik }) => (
	<>
		{Object.keys(formik.touched).length > 0 && (
			<Alert
				severity="warning"
				sx={{ bottom: (theme) => theme.spacing(8), position: 'sticky', zIndex: 1 }}
			>
				You have unsaved changes.
			</Alert>
		)}
		<Prompt
			message="You have unsaved changes, are you sure you want to navigate away?"
			when={Object.keys(formik.touched).length > 0 && formik.isSubmitting === false}
		/>
	</>
);

export default QuestionFooter;
