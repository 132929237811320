import styled from 'styled-components';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Typography from '@mui/material/Typography';

export const OptionContainer = styled.div`
	align-items: center;
	display: grid;
	padding: 16px;
	grid-template-columns: 36px auto 36px;
	background: white;
	margin-bottom: 16px;
	cursor: pointer;
`;

export const Icon = styled(CheckBoxOutlineBlankIcon)`
	margin: 6px 12px 6px 0;
`;

export const Text = styled(Typography)`
	font-weight: 500;
	margin-right: 8px;
`;

export const TextContainer = styled.div`
	display: flex;
	align-items: center;
	padding-right: 8px;
`;
