import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import React from 'react';

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	height: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: #f5f5f5aa;
	z-index: 1;
`;

const Loader = () => (
	<LoadingContainer>
		<CircularProgress size={70} />
	</LoadingContainer>
);

export default Loader;
