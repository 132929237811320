import Avatar from '@mui/material/Avatar';
import styled, { css } from 'styled-components';

const shared = css`
	font-size: 14px;
	width: 30px;
	height: 30px;
`;

export const AddAvatar = styled(Avatar)`
	${shared}
	background-color: ${({ theme }) => theme.palette.primary.main};
	cursor: pointer;
`;

export const AddAvatarNonClickable = styled(Avatar)`
	${shared}
	background-color: ${({ theme }) => theme.palette.background.default};
`;
