/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { ApiActionDto, ApiProperty } from 'kes-common';
import assertNever from '@/utils/assertNever';
import { create } from '@/entities/property';
import { PROPERTY_NEW_PREFIX } from '@/constants';
import { Property, PropertyType, PropertyDependentCombinator } from '../types';
import { makeRepository } from '../repository';
import * as actions from '../actions';
import { generateUniqueEntityName } from '../utils';

const repository = makeRepository<Property>();

interface Entity {
	readonly id: string | number;
}

export type MendixStatus = 'saving' | 'fail' | 'succes' | 'idle';

export enum PropertySelectionMode {
	None = '',
	Dependent = 'dependent',
	DependentRule = 'dependent-rule',
	Relationship = 'relationship',
	Computed = 'computed',
}

export interface PropertyState<Property extends Entity> {
	byId: Record<Property['id'], Property>;
	allIds: Property['id'][];
	selected: string;
	selectedAction: ApiActionDto['id'];
	selectionMode: PropertySelectionMode;
	mendixStatus: MendixStatus;
	orderMode: boolean;
}

const initialState = <Property extends Entity>(): PropertyState<Property> => ({
	byId: {} as Record<Property['id'], Property>,
	allIds: [],
	selectionMode: PropertySelectionMode.None,
	// These two selection states are now becoming a bit strange.
	// It made sense when only properties could be selected, but now we can select choices/actions.
	selected: '',
	selectedAction: '',
	mendixStatus: 'idle',
	orderMode: false,
});

function mapTypeToLocal(type: ApiProperty['type']): PropertyType {
	switch (type) {
		case 'DATE':
			return PropertyType.DATE;
		case 'RICH_TEXT':
			return PropertyType.RICH_TEXT;
		case 'STRING':
			return PropertyType.STRING;
		case 'DECIMAL':
			return PropertyType.DECIMAL;
		case 'SINGLE_SELECT':
			return PropertyType.SINGLE_SELECT;
		case 'MULTI_SELECT':
			return PropertyType.MULTI_SELECT;
		case 'SINGLE_SUBSTANCE':
			return PropertyType.SINGLE_SUBSTANCE;
		case 'MULTI_SUBSTANCE':
			return PropertyType.MULTI_SUBSTANCE;
		case 'SINGLE_ASSET_REFERENCE':
			return PropertyType.SINGLE_SELECT;
		case 'MULTI_ASSET_REFERENCE':
			return PropertyType.MULTI_SELECT;
		case 'IMAGE':
			return PropertyType.IMAGE;
		case 'LOCATIONS':
			return PropertyType.LOCATIONS;
		default:
			return assertNever(type);
	}
}

function mapDependentCombinatorToLocal(
	dependentCombinator: ApiProperty['dependentCombinator'],
): PropertyDependentCombinator {
	switch (dependentCombinator) {
		case 'ANY':
			return PropertyDependentCombinator.ANY;
		case 'ALL':
			return PropertyDependentCombinator.ALL;
		default:
			return assertNever(dependentCombinator);
	}
}

function mapApiToLocal(api: ApiProperty): Property {
	return {
		...api,
		type: mapTypeToLocal(api.type),
		dependentCombinator: mapDependentCombinatorToLocal(api.dependentCombinator),
		choiceIds: api.choiceIds || [],
	};
}

export default makeReducer(initialState<Property>(), {
	[actions.actionDelete.type]: (draft, payload) => {
		repository.modifyAll(draft, (item) => {
			const index = item.parentActionIds.indexOf(payload.actionId);
			if (index !== -1) {
				item.parentActionIds.splice(index, 1);
			}
		});
	},

	[actions.actionDependentPropertyAdd.type]: (draft, payload): void => {
		repository.modify(draft, payload.propertyId, (item) => {
			item.parentActionIds.push(payload.actionId);
		});
	},

	[actions.actionDependentPropertyRemove.type]: (draft, payload): void => {
		repository.modify(draft, payload.propertyId, (item) => {
			const index = item.parentActionIds.indexOf(payload.actionId);
			if (index !== -1) {
				item.parentActionIds.splice(index, 1);
			}
		});
	},

	[actions.applicationLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.propertiesById, mapApiToLocal);
	},
	[actions.propertyPersist.type]: (draft, payload): void => {
		repository.upsert(draft, payload.property);
	},
	[actions.propertyGenerate.type]: (draft, payload): void => {
		const name = generateUniqueEntityName(
			Object.values(draft.byId).map((property) => property.placeholder),
			PROPERTY_NEW_PREFIX,
		);
		repository.upsert(draft, {
			...create(payload.id),
			name,
			placeholder: name,
		});
	},
	[actions.propertyGenerateSilent.type]: (draft, payload): void => {
		repository.upsert(draft, create(payload.id));
	},
	[actions.propertyDelete.type]: (draft, payload): void => {
		repository.delete(draft, payload.id);
	},

	[actions.propertyRemoveRules.type]: (draft, payload): void => {
		repository.modify(draft, payload.propertyId, (item) => {
			item.ruleIds = [];
		});
	},

	[actions.propertyUpdate.type]: (draft, payload): void => {
		repository.update(draft, payload);
	},
	[actions.propertyUpdateRules.type]: (draft, payload): void => {
		repository.modify(draft, payload.propertyId, (property) => {
			property.ruleIds = payload.upsertActions.map((upsertAction) => upsertAction.ruleId);
		});
	},
	[actions.choicePersist.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.choiceIds.indexOf(payload.choice.id);
			if (index < 0) {
				if (payload.propertyId === item.id) {
					item.choiceIds.push(payload.choice.id);
				}
			} else if (payload.propertyId !== item.id) {
				item.choiceIds.splice(index, 1);
			}
		});
	},
	[actions.choiceGenerate.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.choiceIds.indexOf(payload.id);
			if (index < 0) {
				if (payload.propertyId === item.id) {
					item.choiceIds.push(payload.id);
				}
				// While the following else block should never be triggered in practice, it still happens when a
				// generate action gets called with an id that already exists, in this case, we regenerate the
				// passed in id, and re-assing it to the correct parent
			} else if (payload.propertyId !== item.id) {
				item.choiceIds.splice(index, 1);
			}
		});
	},
	[actions.choiceDelete.type]: (draft, payload): void => {
		repository.modifyAll(draft, (item): void => {
			const index = item.choiceIds.indexOf(payload.id);
			if (index >= 0) {
				item.choiceIds.splice(index, 1);
			}
		});
	},
	[actions.propertySelect.type]: (draft, payload): void => {
		draft.selected = payload.id;
		draft.selectionMode = payload.selectionMode;
		draft.orderMode = false;
	},
	[actions.actionSelect.type]: (draft, payload): void => {
		draft.selected = payload.propertyId;
		draft.selectedAction = payload.id;
		draft.selectionMode = payload.selectionMode;
		draft.orderMode = false;
	},
	[actions.propertyAddDependent.type]: (draft, payload): void => {
		repository.modify(draft, payload.id, (item): void => {
			const index = item.parentChoiceIds.indexOf(payload.choiceId);
			if (index === -1) {
				item.parentChoiceIds.push(payload.choiceId);
			}
		});
	},
	[actions.propertyRemoveDependent.type]: (draft, payload): void => {
		repository.modify(draft, payload.id, (item): void => {
			const index = item.parentChoiceIds.indexOf(payload.choiceId);
			if (index !== -1) {
				item.parentChoiceIds.splice(index, 1);
			}
		});
	},

	[actions.propertyAddReference.type]: (draft, payload): void => {
		repository.modify(draft, payload.propertyId, (item): void => {
			item.identifyingProperty = payload.targetPropertyId;
			item.referencedAssetType = payload.parentAssetTypeId;
		});
	},

	[actions.propertyRemoveReference.type]: (draft, payload): void => {
		repository.modify(draft, payload.propertyId, (item): void => {
			item.identifyingProperty = null;
			item.referencedAssetType = null;
		});
	},
	[actions.sidebarOrderMode.type]: (draft, payload): void => {
		draft.orderMode = payload;
	},
	[actions.propertyDuplicate.type]: (draft, payload): void => {
		repository.upsert(draft, mapApiToLocal(payload.property));
	},
});
