import { ApiRuleSetDto } from 'kes-common';
import {
	Box,
	Checkbox,
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import List from '@mui/material/List';
import React from 'react';

interface RuleListProps {
	ruleSet: ApiRuleSetDto;
	toggleRule: (ruleId: string) => void;
	selectedRuleIds: string[];
}
const RuleList: React.FC<RuleListProps> = ({ ruleSet, selectedRuleIds, toggleRule }) => (
	<Box mt={2}>
		<List
			subheader={
				<>
					<Typography variant="body1" style={{ fontWeight: 500 }}>
						{ruleSet.name}
					</Typography>
					<Divider />
				</>
			}
			disablePadding
		>
			{ruleSet.rules.map((rule) => (
				<ListItem key={rule.id} dense>
					<ListItemButton
						data-testid="list-item"
						onClick={() => {
							toggleRule(rule.id);
						}}
					>
						<ListItemIcon>
							<Checkbox edge="start" checked={selectedRuleIds.some((id) => id === rule.id)} />
						</ListItemIcon>
						<ListItemText>
							<Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
								<Typography>{rule.name}</Typography>
								<Typography>{rule.description}</Typography>
								<Typography>{rule.check}</Typography>
							</Box>
						</ListItemText>
					</ListItemButton>
				</ListItem>
			))}
		</List>
	</Box>
);

export default RuleList;
