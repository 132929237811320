import { AuthenticationProvider, AuthenticationProviderProps } from 'kes-common';
import React from 'react';

import auth0Client from '@/authentication/auth0';
import msalInstance from '@/authentication/msal';
import { TEST_USERS_ENABLED } from '@/constants';
import { getUserProfile, updateUserFromActiveDirectory } from '@/net/api';

interface AuthenticationProps {
	children: React.ReactNode;
	onSuccess?: AuthenticationProviderProps['onSuccess'];
}

const Authentication: React.FC<AuthenticationProps> = ({ children, onSuccess }) => (
	<AuthenticationProvider
		auth0Client={auth0Client}
		fetchUserProfile={() => getUserProfile().then((response) => response.expectSuccess())}
		hasTestUsersEnabled={TEST_USERS_ENABLED}
		msalInstance={msalInstance}
		onSuccess={onSuccess}
		updateUserProfile={(user) =>
			updateUserFromActiveDirectory(user).then((response) => response.expectSuccess())
		}
	>
		{children}
	</AuthenticationProvider>
);

export default Authentication;
