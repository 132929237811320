import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormikConfig, useFormik } from 'formik';
import React from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';

import { ApiCategory } from '../../net/swagger';

const categoryCreate = (
	partialCategory: Partial<ApiCategory> & Pick<ApiCategory, 'name'>,
): ApiCategory => ({
	id: v4(),
	isDefault: false,
	stableId: v4(),
	assetTypeIds: [],
	fixed: false,
	info: null,
	type: 'SURVEY',
	...partialCategory,
});

interface FormValues {
	info: ApiCategory['info'];
	name: ApiCategory['name'];
}

export interface CategoryFormProps {
	category?: ApiCategory;
	l10n: {
		actionPrimary: string;
		fieldInformationHelperText: string;
		fieldInformationLabel: string;
		fieldNameErrorRequired: string;
		fieldNameHelperText: string;
		fieldNameLabel: string;
	};
	onSubmit(category: ApiCategory): Promise<void>;
}

const CategoryForm: React.FC<CategoryFormProps> = ({ category, l10n, onSubmit }) => {
	const initialValues = React.useMemo<FormValues>(
		() => ({
			info: category?.info || '',
			name: category?.name || '',
		}),
		[category],
	);

	const onSubmitFormik = React.useCallback<FormikConfig<FormValues>['onSubmit']>(
		async (values, formikHelpers) => {
			formikHelpers.setSubmitting(true);
			if (category && category.id) {
				await onSubmit({ ...category, ...values });
			} else {
				await onSubmit(categoryCreate(values));
			}
			formikHelpers.setSubmitting(false);
		},
		[category, onSubmit],
	);

	const validationSchema = yup.object({
		info: yup.string(),
		name: yup.string().required(l10n.fieldNameErrorRequired),
	});

	const formik = useFormik<FormValues>({
		enableReinitialize: true,
		initialValues,
		onSubmit: onSubmitFormik,
		validationSchema,
	});

	const fieldHasError = React.useCallback(
		(fieldName: keyof FormValues): boolean =>
			Boolean(formik.errors[fieldName] && formik.touched[fieldName]),
		[formik],
	);

	const fieldHelperText = React.useCallback(
		(fieldName: keyof FormValues, helperText: string): string =>
			fieldHasError(fieldName) ? (formik.errors[fieldName] as string) : helperText,
		[fieldHasError, formik],
	);

	return (
		<form onSubmit={formik.handleSubmit}>
			<TextField
				error={fieldHasError('name')}
				fullWidth
				helperText={fieldHelperText('name', l10n.fieldNameHelperText)}
				inputProps={{ 'data-testid': 'category-form__field--name' }}
				label={l10n.fieldNameLabel}
				margin="normal"
				name="name"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				value={formik.values.name}
			/>
			<TextField
				error={fieldHasError('info')}
				fullWidth
				inputProps={{ 'data-testid': 'category-form__field--info' }}
				helperText={fieldHelperText('info', l10n.fieldInformationHelperText)}
				label={l10n.fieldInformationLabel}
				margin="normal"
				multiline
				name="info"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				value={formik.values.info}
			/>
			<Button
				color="primary"
				data-testid="category-form__action--primary"
				disabled={formik.isSubmitting || formik.isValidating}
				fullWidth
				type="submit"
				variant="contained"
			>
				{l10n.actionPrimary}
			</Button>
		</form>
	);
};

export default CategoryForm;
