import Chip from '@/components/Chip';
import Link from '@/components/rhdhv/Link';
import { applicationProperty } from '@/routes';
import { applicationName, choiceGet, propertiesGet } from '@/selectors';
import { getAssetWithPropertyId } from '@/selectors/assets';
import { propertyRemoveDependent } from '@/store/actions';
import State from '@/store/state';
import { Asset, Choice, Property } from '@/store/types';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from '@/pages/application/ReportPropertyUpdate/ReportPropertyUpdate.module.css';

interface Props {
	selectedPropertyId: Property['id'];
	propertyId?: Property['id'];
	choiceId: Choice['id'];
	className?: string;
	showChoice?: boolean;
}

const DependentPropertiesChip: FC<Props> = ({
	selectedPropertyId,
	choiceId,
	className,
	propertyId,
	showChoice,
}): JSX.Element => {
	const dispatch = useDispatch();
	const selectedProperty = useSelector(
		(state: State): Property => propertiesGet(state, selectedPropertyId),
	);
	const assetByPropertyId = useSelector(
		(state: State): Asset => getAssetWithPropertyId(state, selectedPropertyId),
	);
	const appName = useSelector((state: State) => applicationName(state));
	const choice = useSelector((state: State): Choice => choiceGet(state, choiceId));

	return (
		<Chip
			deleteIcon={
				<CancelIcon classes={{ root: classes.cancelIcon }} color="primary" fontSize="small" />
			}
			className={className}
			label={
				<Link
					className={classes.dependentPropertiesLink}
					to={applicationProperty}
					params={{
						propertyId: selectedPropertyId,
						applicationName: appName,
					}}
				>
					{showChoice ? (
						<>
							<span className={classes.dependentPropertiesText}>{assetByPropertyId.name}</span>
							<span className={classes.dependentPropertiesText}>: {selectedProperty.name}</span>
							<span className={classes.dependentPropertiesText}>: {choice.name}</span>
						</>
					) : (
						<>
							<span className={classes.dependentPropertiesTextLong}>{assetByPropertyId.name}</span>
							<span className={classes.dependentPropertiesTextLong}>: {selectedProperty.name}</span>
						</>
					)}
				</Link>
			}
			onDelete={(): void => {
				dispatch(
					propertyRemoveDependent({
						id: propertyId || selectedPropertyId,
						choiceId,
					}),
				);
			}}
			classes={{ label: classes.dependentPropertiesChipLabel }}
		/>
	);
};

export default DependentPropertiesChip;
