import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

interface ReactQueryProvidersProps {
	children: React.ReactNode;
}

const ReactQueryProviders: React.FC<ReactQueryProvidersProps> = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<ReactQueryDevtools initialIsOpen={false} />
		{children}
	</QueryClientProvider>
);

export default ReactQueryProviders;
