import React from 'react';
import { MenuItem } from '@mui/material';
import State from '@/store/state';
import { assetsGet } from '@/selectors';
import tinymce from 'tinymce';
import { ReactReduxContext } from 'react-redux';
import { getReferenceText } from '@/components/SideBar/InsertIcon/InsertIcon.utils';
import { Property } from '@/store/types';

interface Props {
	assetId: string;
	closeMenu: () => void;
	itemId: Property['id'];
}

const InsertMenuOption: React.FC<Props> = ({ assetId, closeMenu, itemId }) => {
	const storeValue = React.useContext(ReactReduxContext);

	const insertIntoReport = () => {
		const state: State = storeValue.store.getState();
		const asset = assetsGet(state, assetId);
		const text = getReferenceText({ state, asset, propertyId: itemId });
		tinymce.activeEditor.insertContent(text);
		closeMenu();
	};

	return (
		<MenuItem data-testid="property-context-menu__insert-into-report" onClick={insertIntoReport}>
			Insert in template
		</MenuItem>
	);
};

export default InsertMenuOption;
