import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { User } from 'microsoft-graph';
import buildMSALGraphClient from './buildMSALGraphClient';

interface GetUserFieldsFromADv2Options {
	account: AccountInfo;
	publicClientApplication: PublicClientApplication;
}

export interface GetUserFieldsFromADResponse {
	department: string | null;
	officeLocation: string | null;
}

const getUserFieldsFromAD = async (
	options: GetUserFieldsFromADv2Options,
): Promise<GetUserFieldsFromADResponse> => {
	const graphClient = buildMSALGraphClient(options);
	const userDetails: User = await graphClient
		.api('/me')
		.select('department,officeLocation,jobTitle')
		.get();
	return {
		department: userDetails.department || null,
		officeLocation: userDetails.officeLocation || null,
	};
};

export default getUserFieldsFromAD;
