import { StateApplication as State } from '@/store/state';
import makeSlicer from '@/utils/makeSlicer';

const rootSlicer = makeSlicer<State>();

export const categories = rootSlicer('categories');

export const studyId = rootSlicer('id');

export const loaded = rootSlicer('loaded');

export const name = rootSlicer('name');

export const assetLibraryId = rootSlicer('assetLibraryId');

export const prettyName = rootSlicer('prettyName');

export const isMilicense = rootSlicer('isMilicense');

export const testProjectId = rootSlicer('testProjectId');
