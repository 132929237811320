import * as actions from '../actions';
import { Report } from '../types';
import AppAction from '../appAction';

// eslint-disable-next-line @typescript-eslint/default-param-last
const reportReducer = (state: Report | null = null, action: AppAction): Report | null => {
	switch (action.type) {
		case actions.applicationLoadSuccess.type:
			return action.payload.reportTemplate;
		case actions.reportPersist.type:
			return action.payload.report;
		case actions.reportUpdate.type:
			return state ? { ...state, ...action.payload } : state;
		case actions.reportUpdateSuccess.type: {
			return state ? { ...state, ...action.payload } : state;
		}
		default:
			return state;
	}
};

export default reportReducer;
