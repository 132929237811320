import { applicationProperty, applicationPropertyCreate, PropsOfRoute } from '@/routes';
import { assetsAllIds, assetsGet } from '@/selectors';
import { propertyGenerate } from '@/store/actions';
import State from '@/store/state';
import { Asset, Property } from '@/store/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import PropertyUpsert from './PropertyUpsert';

export const PropertyEdit = (props: PropsOfRoute<typeof applicationProperty>) => {
	const {
		match: {
			params: { propertyId },
		},
	} = props;
	const assetId = useSelector<State, Asset['id'] | undefined>((state) =>
		assetsAllIds(state).find(
			(id): boolean => assetsGet(state, id).propertyIds.indexOf(propertyId) >= 0,
		),
	);
	if (!assetId) {
		return null;
	}
	return <PropertyUpsert key={propertyId} propertyId={propertyId} assetId={assetId} />;
};

export const PropertyCreate = (
	props: PropsOfRoute<typeof applicationPropertyCreate>,
): JSX.Element | null => {
	const [newId, setNewId] = useState<Property['id'] | null>(null);
	const dispatch = useDispatch();
	const {
		match: { params },
	} = props;
	const { assetId } = params;
	useEffect((): void => {
		const id = uuidv4();
		dispatch(propertyGenerate({ id, assetId }));
		setNewId(id);
	}, [dispatch, assetId]);
	if (newId) {
		return (
			<Redirect
				to={applicationProperty({
					...params,
					propertyId: newId,
				})}
			/>
		);
	}
	return null;
};
